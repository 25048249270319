
import { IAction } from '../actions/Action'
import ActionType from '../actions/ActionType';


export enum UPLOAD_STATES {
  PENDING = 'PENDING',
  IN_PROGRESS = 'IN_PROGRESS',
  DONE = 'DONE',
  FAILED ='FAILED'
}

export const UPLOAD_TYPE = 'Upload'

export interface IUploadState {
  uploadItems: Array<IUploadItem>;
}

const initState = {
  uploadItems: new Array<IUploadItem>()
}

export interface IUploadItem{
  status: UPLOAD_STATES;
  progress: number;
  fileUrl: string;
  error: string;
  id: string;
}




const updateUploadItem = (items: IUploadItem[], item: IUploadItem, id: string) => {
  const remainingItems = items.filter((item: IUploadItem) => item.id !== id)  
  return [...remainingItems, item];
};

const getItem = (items: IUploadItem[], id: string) => {
  return items.find((item: IUploadItem) => item.id === id)
};

const uploads = (state = initState, action: IAction) => {
  switch (action.type) {
    case ActionType.UPLOAD_FILE:{
      const item = {
        status:  UPLOAD_STATES.PENDING,
        progress: 0,  
        id: action.data.id
      }

      return {
        ...state, importItems: [...state.uploadItems, item]
     }
    }  
    
    case ActionType.UPLOAD_START: {  
      const item = getItem(state.uploadItems, action.data.id) 
      if (item !== undefined){
        return {
          ...state,        
          importItems: updateUploadItem(state.uploadItems,{...item, status: UPLOAD_STATES.IN_PROGRESS,
            progress: 0}, action.data.id)
        }; 
      }
      return {
        ...state
      }; 
    }
    case ActionType.UPDATE_PROGRESS: {
      const item = getItem(state.uploadItems, action.data.id) 
      if (item !== undefined){
        return {
          ...state,        
          importItems: updateUploadItem(state.uploadItems,{...item, progress: action.data.progress}, action.data.id)
        }; 
      }
      return {
        ...state
      }; 
    }
      
    case ActionType.UPLOAD_COMPLETE: {
      const item = getItem(state.uploadItems, action.data.id) 
      if (item !== undefined){
        return {
          ...state,        
          importItems: updateUploadItem(state.uploadItems,{...item, status: UPLOAD_STATES.DONE, fileUrl: action.data.fileUrl}, action.data.id)
        }; 
      }
      return {
        ...state
      }; 
    }
     
   
      case ActionType.UPLOAD_FAIL:{
        const item = getItem(state.uploadItems, action.data.id) 
        if (item !== undefined){
          return {
            ...state,        
            importItems: updateUploadItem(state.uploadItems,{...item, status: UPLOAD_STATES.FAILED, error: action.data.error}, action.data.id)
          }; 
        }
        return {
          ...state
        }; 
      }
      
  
          
    default:
        return state;
  }
};

export default uploads