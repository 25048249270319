/** @jsxImportSource @emotion/react */
import React, { ReactNode } from 'react';
import mikeSharedTheme from '../../styles/mikeSharedTheme';
import { css } from '@emotion/react'
import { Typography, ClickAwayListener } from '@mui/material';
import MikeSupportMenuButton from './MikeSupportMenuButton';
import MikeTopbarDropdown, { wrapperStyle } from '../mike-topbar-dropdown';
import MikeSupportMenuMailItem from './MikeSupportMenuMailItem';
import MikeSupportMenuDocumentationItem from './MikeSupportMenuDocumentationItem';
import { IMailConfig } from './IMailConfig';
import { ILinkConfig } from './ILinkConfig';
import MikeTooltip from '../mike-tooltip';
import { IMikeTooltipPosition } from '../mike-tooltip/MikeTooltip';

interface IProps {
  /**
   * Documentation configuration.
   */
  documentationConfig?: Array<ILinkConfig>;
  /**
   * Mail configuration.
   */
  mailConfig?: IMailConfig;
  /**
   * Custom children.
   */
  children?: ReactNode;
  /**
   * Click away callback.
   */
  clickAwayCallback?: () => void;
  /**
   * Custom title.
   */
  title?: string;
  /**
   * Tooltip title.
   * Use "" for no tooltip.
   */
  tooltipTitle?: string;
  /**
   * How to position the tooltip in relation to its target/anchor.
   * Structure is `{ top?: number, left?: number }`.
   */
  tooltipPosition?: IMikeTooltipPosition;
}

const supportMenuTitleStyle = css`
  padding-top: 0px;
  padding-bottom: ${mikeSharedTheme.spacing(1)};
  font-weight: bold;
`;

const supportMenuInnerContainerStyle  =
   css`
    background-color: ${mikeSharedTheme.palette.mediumGrey?.light};
    padding-bottom: ${mikeSharedTheme.spacing(2)};
`;

/**
 * @name MikeSupportMenuContainer
 * @summary The main support menu wrapper-component. Used to display support menu in the topbar.
 */
export const MikeSupportMenuContainer = (props: IProps) => {
  const {   
    children,
    clickAwayCallback,
    documentationConfig,
    mailConfig,
    title = 'Help and support',
    tooltipTitle = 'Help and support',
    tooltipPosition,
  } = props;
 
  const ttPos = { left: -9, top: -1, ...tooltipPosition };
  const [showMenu, setShowMenu] = React.useState(false);

  const handleOnClick = React.useCallback(() => {
    if (clickAwayCallback) {
      clickAwayCallback();
    }
    setShowMenu(true);
  }, [clickAwayCallback]);



  // Only show tooltips when collapsed:
  const ttTitle = showMenu ? '' : tooltipTitle;

  return (
    <div css={wrapperStyle}>
      <MikeTooltip title={ttTitle} arrow mikeposition={ttPos}>
        <MikeSupportMenuButton onClickCallBack={handleOnClick} />
      </MikeTooltip>
      {showMenu && (
        <ClickAwayListener onClickAway={() => setShowMenu(false)}>
           <div>
           <MikeTopbarDropdown>
              <> 
                <Typography css={supportMenuTitleStyle} variant="h4">
                  {title}
                </Typography>
                <div css={supportMenuInnerContainerStyle}>
                  {children ? children : null}           
                  {documentationConfig && documentationConfig.length > 0
                    ? documentationConfig.map((docConfig, index) => {
                        return <MikeSupportMenuDocumentationItem key={index} config={docConfig} />;
                      })
                    : null}
                  {mailConfig && <MikeSupportMenuMailItem config={mailConfig} />}
                </div>
              </>
            </MikeTopbarDropdown>
          </div> 
        </ClickAwayListener>
      )}
    </div>
  );
};

export default MikeSupportMenuContainer;
