/* eslint-disable @typescript-eslint/no-explicit-any */

/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { useDropzone } from 'react-dropzone';
import Plus from '../../icons/Plus.svg?react';
import Button from '@mui/material/Button';
import ContextHelp from '../ContextHelp';
import mikeSharedTheme from '../../styles/mikeSharedTheme';
import { iconSecondaryStyle, iconWhiteStyle } from '../../styles/iconStyles';

interface IProps { 
  fileType: string | Array<string>;
  onDrop: (files) => void;
  disabled: boolean;
  label: string;
  variant?: string;
  info?: Array<string>; 
}

const buttonStyle = css`
  white-space: nowrap;
  &.MuiButton-root {
    padding-right: ${mikeSharedTheme.spacing(2)};
    padding-left: ${mikeSharedTheme.spacing(0.5)};
  }
  `
  const containerStyle = css`
    display: flex;
    padding-right: ${mikeSharedTheme.spacing(2)};
    padding-left: ${mikeSharedTheme.spacing(0.5)};
    white-space: nowrap;
  `

const UploadButton = (props: IProps) => {
  const { 
    fileType,
    onDrop,
    disabled,
    label,
    variant = "text",
    info
  } = props;

  const { getInputProps, getRootProps  } = useDropzone({ onDrop, accept: fileType as any });  

  return info ?  (
    <div css={containerStyle}>     
      <Button disabled={disabled} variant={variant as any}  {...getRootProps()}>
        <Plus css={variant === "contained" ? iconWhiteStyle(disabled) : iconSecondaryStyle(disabled)} width={24} height={24} viewBox={"0 0 40 40"}/>
        {label}
        <input {...getInputProps()}/>
      </Button>
      <ContextHelp primary={variant === "outlined"} helpTexts={info} disabled={disabled}/>
    </div>
  ) : (
    <Button disabled={disabled} variant={variant as any}  {...getRootProps()} css={buttonStyle}>
      <Plus css={variant === "contained" ? iconWhiteStyle(disabled) : iconSecondaryStyle(disabled)} width={24} height={24} viewBox={"0 0 40 40"}/>
      {label}
      <input {...getInputProps()}/>
    </Button>
  );
};

export default UploadButton;
