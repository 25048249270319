/** @jsxImportSource @emotion/react */
import ButtonBase from '@mui/material/ButtonBase';
import mikeSharedTheme from '../../styles/mikeSharedTheme';
import { css } from '@emotion/react'
import Support from '../../icons/Support.svg?react';


interface IProps {
  /**
   * Click callback.
   */
  onClickCallBack?: () => void;
}

const buttonStyle = css`
  display: flex;
  align-items: center;
  padding: 0 10px;
  position: relative;
  min-height: 60px;
  &:hover: {
    background-color: ${mikeSharedTheme.palette.text.disabled};
  };
  cursor: pointer;
`;

/**
 * @name MikeSupportMenuButton
 * @summary The support bottom, opening the support menu.
 */
export const MikeSupportMenuButton = (props: IProps) => {
  const { onClickCallBack } = props;

  return (
    <ButtonBase css={buttonStyle} onClick={onClickCallBack}>
      <Support />
    </ButtonBase>
  );
};

export default MikeSupportMenuButton;
