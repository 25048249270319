import {  put, takeEvery } from 'redux-saga/effects'
import ActionType from '../actions/ActionType'

import { getContentOfDatset } from '../actions/exports'
import { addError } from '../actions/errors'

export default function* watchExportAndDownload() {
  yield takeEvery(ActionType.EXPORT_AND_DOWNLOAD_DATASET, exportAndDownloadDataset)  
}

export function* exportAndDownloadDataset(action) {
  
  const { id, fileName, datasetId,  data, datasetType, download, setPreviousExtraction } = action.data

  try {
    yield put(getContentOfDatset(id, fileName, datasetId, data, datasetType, download, setPreviousExtraction))
   
  } catch {
    yield put(addError(ActionType.EXPORT_AND_DOWNLOAD_DATASET))
  }
}
