/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { useSelector } from 'react-redux';
import { IState } from '../../reducers';
import { toLocaleRoundedString } from '../../helpers/utils';
import { ViewerMapScale } from './ViewerMapScale';
import mikeSharedTheme from '../../styles/mikeSharedTheme';

const footerStyle =  css`
  height: ${mikeSharedTheme.spacing(4)};
  width: calc(100vw - ${mikeSharedTheme.spacing(100)});
  background-color:${mikeSharedTheme.palette.mediumGrey.main};
  position: fixed;
  bottom: 0;
  left: 800;
`
const flexContainer = css`
  display: flex;
  padding:${mikeSharedTheme.spacing(1)};
  justify-content: center;
`
const epsgStyle = css`
  padding-left:${mikeSharedTheme.spacing(1)};
  padding-right:${mikeSharedTheme.spacing(1)};
`
const coordinatesStyle = css`
  display: flex;
  min-width: ${mikeSharedTheme.spacing(25)};
  justify-content: center;
`;

const coordinateMap = {
  0: 'x',
  1: 'y',
  2: 'z',
};

interface IProps {
  currentViewerCoordinates: Array<number>;
}

export const ViewerFooter = (props: IProps) => {
  const { currentViewerCoordinates } = props;

  const epsg = useSelector(
    (state: IState) => state.mapContent.epsgCode
  ); 
  
  return (
    <div css={footerStyle}>      
      <div css={flexContainer}>
        <ViewerMapScale/>
        {currentViewerCoordinates ? (
          <div css={coordinatesStyle}>
            {currentViewerCoordinates
              .slice(0, 2) // Exclude z for the moment, it is confusing.
              .map((coord, index) => {
                const roundedCoordinate = toLocaleRoundedString(coord, 10);
                const value = roundedCoordinate ? roundedCoordinate.replace(',', '.') : '';
                return coordinateMap[index] + ':' + value;
              })
              .join(', ')}
          </div>
        ) : null}
        <div css={epsgStyle}>{epsg ? "EPSG: " + epsg : ""}</div>
      </div>
    </div>
  );
};

