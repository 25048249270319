
import ActionType from "./ActionType";
import { IProgressItem } from "../components/mike-topbar-progress-spinner";

export const removeProgressItem = (progressItem: IProgressItem) => ({
  type: ActionType.REMOVE_PROGRESS_ITEM,
  data: progressItem
})

export const updateOrAddProgressItem = (progressItem: IProgressItem) => ({
  type: ActionType.UPDATE_OR_ADD_PROGRESS_ITEM,
  data: progressItem
})
