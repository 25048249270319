import { IUser } from "../model/IUser";
import { IConfig } from "./../model/IConfig"
import ActionType from "./ActionType";

export const setConfig = (config: IConfig) => ({
  type: ActionType.SET_CONFIG,
  data: config
});

export const setUser = (user: IUser | null) => ({
  type: ActionType.SET_USER,
  data: user
});

export const setIsAuthenticated = (isAuthenticated: boolean) => ({
  type: ActionType.SET_IS_AUTHENTICATED,
  data: isAuthenticated
})

export const setIsRootContributor = (isRootContributor: boolean) => ({
  type: ActionType.SET_ROOT_CONTRIBUTOR_USERROLE,
  data: isRootContributor
})