/**
 * Extends vtk Actor to allow getting/setting actor ids.
 *
 * @module vtkEnhancedActor
 * @version 1.0.0
 */
import macro from '@kitware/vtk.js/macro.js';
import vtkActor from '@kitware/vtk.js/Rendering/Core/Actor.js';

// vtkEnhancedActor methods
function vtkEnhancedActor(publicAPI, model) {
  // Set our className
  model.classHierarchy.push('vtkEnhancedActor');
}

// Object factory
const DEFAULT_VALUES = {
  actorId: null,
};

export function extend(publicAPI, model, initialValues = {}) {
  Object.assign(model, DEFAULT_VALUES, initialValues);

  // Inheritance
  vtkActor.extend(publicAPI, model, initialValues);

  // Create get-set macros
  macro.setGet(publicAPI, model, ['actorId']);

  // Object specific methods
  vtkEnhancedActor(publicAPI, model);
}

export const newInstance = macro.newInstance(extend, 'vtkEnhancedActor');
export default Object.assign({ newInstance, extend });
