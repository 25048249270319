/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { Link } from 'react-router-dom';
import mikeSharedTheme from '../../styles/mikeSharedTheme';

const rootStyle = 
  css`   
    color: ${mikeSharedTheme.palette.text.primary};    
    text-decoration: none;
    font-size: 14px;
    &>span:hover {
      text-decoration: underline;
    },
    &>svg {
      vertical-align: middle;
      margin-right: ${mikeSharedTheme.spacing(1)};
    },
  `;

interface IProps {
  to: string;
  text: string;
}

const ProjectLink = (props: IProps) => {
  const { to, text } = props;
 
  return (
    <Link css={rootStyle} to={to} >
      {text}
    </Link>
  );
};

export default ProjectLink;
