import tinycolor from 'tinycolor2';
import { values, cloneDeep, merge } from 'lodash-es';
import { MIKE_COLORS, MIKE_MAP_COLORS } from '../colors/mike-colors';
import { IDrawnDataGradientSettings } from './IMikeVisualizerModels';
import { IConfiguration } from './models/IConfiguration';
import { DeepPartial } from '../utils/ts-utils';

/**
 * Exposes methods regarding reading / updating visualizer global configuration,
 * such as: colors, sizes of things, etc.
 *
 * @module MikeVisualizerConfiguration
 * @version 1.0.0
 */

/**
 * Return [r, g, b] values for the provided color.
 *
 * @param color
 */
const _getRgbArray = (color: string): Array<number> => {
  const rgb = tinycolor(color).toRgb();
  const res = values(rgb)
    .slice(0, 3)
    .map((val) => val / 255);
  return res;
};
export const RGB_COLORS = {
  BRANDBLUE_DEFAULT: _getRgbArray(MIKE_COLORS.BRANDBLUE_DEFAULT),
  BRANDBLUE_DARK: _getRgbArray(MIKE_COLORS.BRANDBLUE_DARK),
  BRANDBLUE_LIGHT: _getRgbArray(MIKE_COLORS.BRANDBLUE_LIGHT),

  ACTIONBLUE_DEFAULT: _getRgbArray(MIKE_COLORS.ACTIONBLUE_DEFAULT),
  ACTIONBLUE_DARK: _getRgbArray(MIKE_COLORS.ACTIONBLUE_DARK),
  ACTIONBLUE_LIGHT: _getRgbArray(MIKE_COLORS.ACTIONBLUE_LIGHT),

  GREEN_DEFAULT: _getRgbArray(MIKE_COLORS.GREEN_DEFAULT),
  GREEN_DARK: _getRgbArray(MIKE_COLORS.GREEN_DARK),
  GREEN_LIGHT: _getRgbArray(MIKE_COLORS.GREEN_LIGHT),

  PINK_DEFAULT: _getRgbArray(MIKE_COLORS.PINK_DEFAULT),
  PINK_DARK: _getRgbArray(MIKE_COLORS.PINK_DARK),
  PINK_LIGHT: _getRgbArray(MIKE_COLORS.PINK_LIGHT),

  DARKGREY_DEFAULT: _getRgbArray(MIKE_COLORS.DARKGREY_DEFAULT),
  DARKGREY_DARK: _getRgbArray(MIKE_COLORS.DARKGREY_DARK),
  DARKGREY_LIGHT: _getRgbArray(MIKE_COLORS.DARKGREY_LIGHT),

  MEDIUMGREY_DEFAULT: _getRgbArray(MIKE_COLORS.MEDIUMGREY_DEFAULT),
  MEDIUMGREY_DARK: _getRgbArray(MIKE_COLORS.MEDIUMGREY_DARK),
  MEDIUMGREY_LIGHT: _getRgbArray(MIKE_COLORS.MEDIUMGREY_LIGHT),

  XLIGHTGREY: _getRgbArray(MIKE_COLORS.XLIGHTGREY),

  WHITE: _getRgbArray(MIKE_COLORS.WHITE),
  BLACK: _getRgbArray(MIKE_COLORS.BLACK),

  CORAL: _getRgbArray(MIKE_MAP_COLORS.CORAL),
};

export const DEFAULT_ALPHAS = {
  colors: {
    inactive: 1,

    default: {
      edge: 1,
      surface: 0.9,
    },

    select: {
      edge: 1,
      surface: 0.9,
    },
  },

  drawColors: {
    primary: 1,
    secondary: 1,

    bright: 1,
    background: 0.3,

    selection: 0.7,
    selectionBright: 0.2,
    selectionBackground: 0.4,
    selectionTextLabel: 1,

    accent: 1,
    dashed: 0.2,
  },
};

export const DEFAULTS: IConfiguration = {
  colors: {
    inactive: [...RGB_COLORS.BRANDBLUE_DEFAULT, 1],
    default: {
      edge: [...RGB_COLORS.MEDIUMGREY_LIGHT, 1],
      surface: [...RGB_COLORS.BRANDBLUE_DEFAULT, 0.9],
    },
    select: {
      edge: [...RGB_COLORS.MEDIUMGREY_LIGHT, 1],
      surface: [...RGB_COLORS.CORAL, 0.9],
    },
  },

  drawColors: {
    primary: tinycolor(MIKE_COLORS.BRANDBLUE_DEFAULT).toRgbString(),
    secondary: tinycolor(MIKE_MAP_COLORS.SKYBLUE).toRgbString(),
    bright: tinycolor(MIKE_COLORS.ACTIONBLUE_DEFAULT).toRgbString(),
    background: tinycolor(MIKE_COLORS.ACTIONBLUE_LIGHT)
      .setAlpha(0.3)
      .toRgbString(),
    selection: tinycolor(MIKE_MAP_COLORS.SKYBLUE)
      .setAlpha(0.7)
      .toRgbString(),
    selectionBright: tinycolor(MIKE_MAP_COLORS.SKYBLUE)
      .setAlpha(0.2)
      .toRgbString(),
    selectionBackground: tinycolor(MIKE_MAP_COLORS.SKYBLUE)
      .setAlpha(0.4)
      .toRgbString(),
    selectionTextLabel: tinycolor(MIKE_COLORS.WHITE).toRgbString(),
    accent: 'rgba(255, 227, 0, 1)',
    dashed: 'rgba(255, 255, 255, 0.2)',
  },

  ol: {
    tolerance: 10,
    pointNumber: 4,
    pointRadius: 8,
    pointAngle: Math.PI / 4,
    strokeWidth: 4,
  },

  opacity: 0.8,
  pointSize: 2,
  highlight: {
    lineWidth: 2,
  },

  gradientSettings: {
    gradientPreset: 'erdc_rainbow_bright', // alternative: 'hsv';,
    numberOfLegends: 10,
    numberOfSignificantDigits: 3,
  } as IDrawnDataGradientSettings,

  epsgCode: 4326,

  keyboardShortcuts: {
    removeLastPoint: 'Backspace', // BACKSPACE: Remove last point (while sketch in progress)
    cancelSketch: 'Escape', // ESCAPE: Cancel current sketch (while sketch in progress)
    finishSketch: ' ', // SPACE: Finish current sketch (while sketch in progress)
    deleteSelected: 'Delete', // DELETE: delete selected features (while sketch NOT in progress)
  },
};

let configuration = cloneDeep(DEFAULTS);

// Make all nested properties of IConfiguration optional:
export type SetConfigurationArg = DeepPartial<IConfiguration>;

/**
 * Allows configuring the global settings of the visualizer.
 * NB: for now, it does not update already-drawn items. Could be a future enhancement.
 * In practice, configurations should be set before drawing items.
 * Dynamic configurations will only affect future-drawn items.
 *
 * @param customConfiguration
 */
export const setConfiguration = (customConfiguration: SetConfigurationArg) => {
  // TODO: joel; ensure that undefined values don't overwrite existing values:
  configuration = merge(cloneDeep(configuration), customConfiguration);
};

/**
 * Get the current configuration of the visualizer.
 */
export const getConfiguration = (): IConfiguration => {
  return cloneDeep(configuration);
};

const self = {
  setConfiguration,
  getConfiguration,
};

export default self;
