/* eslint-disable @typescript-eslint/no-explicit-any */
import Geometry from 'ol/geom/Geometry';
import LineString from 'ol/geom/LineString';
import MultiPoint from 'ol/geom/MultiPoint.js';
import Point from 'ol/geom/Point';
import Polygon from 'ol/geom/Polygon';
import RenderFeature from 'ol/render/Feature';

import { Fill, Stroke, Circle as CircleStyle, Style, RegularShape, Text } from 'ol/style';

import { getConfiguration } from '../MikeVisualizerConfiguration';

/**
 * Styles are building blocks for presets.
 */
const getOlStyles = () => {
  const { drawColors, ol } = getConfiguration();

  return {
    // Styles when an item is selected (shift / ctrl + click)
    SELECTED_ITEM: {
      SELECTION_POLYGON: new Style({
        fill: new Fill({
          color: drawColors.selectionBackground,
        }),
      }),

      POINT: new Style({
        image: new RegularShape({
          fill: new Fill({
            color: drawColors.primary,
          }),
          points: ol.pointNumber,
          radius: ol.pointRadius,
          angle: ol.pointAngle,
        }),
        fill: new Fill({
          color: drawColors.bright,
        }),
      }),

      DEFAULT: new Style({
        fill: new Fill({
          color: drawColors.background,
        }),
        stroke: new Stroke({
          color: drawColors.bright,
          width: ol.strokeWidth,
        }),
      }),
    },

    // Styles for an item that is being inspected (with inspect mode enabled => click on feature).
    INSPECTED_ITEM: {
      POLYGON_OR_POLYLINE_NODES: new Style({
        image: new RegularShape({
          fill: new Fill({
            color: drawColors.primary,
          }),
          stroke: new Stroke({
            color: 'white',
            width: 1,
          }),
          points: ol.pointNumber,
          radius: ol.pointRadius / 2,
          angle: ol.pointAngle,
        }),

        geometry: (feature) => {
          // TODO: joel; Fix hack below to the pattern of line 218.
          // Hacky handle of errors after @types/ol install:
          const featr = feature as any;
          if (featr && featr.values_ && featr.values_.geometry) {
            const type = featr.values_.geometry.getType();
            if (type === 'LineString') {
              // return a point for each coord in the line string
              const coordinates = featr.getGeometry().getCoordinates();
              return new MultiPoint(coordinates);
            }

            if (type === 'Point') {
              return featr.values_.geometry;
            }
          }

          // return the coordinates of the first ring of the polygon
          return new MultiPoint(featr.getGeometry().getCoordinates()[0]);
        },
      }),

      WITH_LABEL: (labelText: string) =>
        new Style({
          text: new Text({
            text: labelText,
            fill: new Fill({ color: drawColors.selectionTextLabel }),
            font: 'normal 1rem Roboto',
          }),
        }),

      DEFAULT: new Style({
        fill: new Fill({
          color: drawColors.background,
        }),
        stroke: new Stroke({
          color: drawColors.primary,
          width: 4,
        }),
      }),
    },

    MEASURE: {
      DEFAULT: new Style({
        fill: new Fill({
          color: drawColors.dashed,
        }),
        stroke: new Stroke({
          color: drawColors.accent,
          width: 2,
        }),
        image: new CircleStyle({
          radius: 7,
          fill: new Fill({
            color: drawColors.accent,
          }),
        }),
      }),

      WHILE_SKETCHING: new Style({
        fill: new Fill({
          color: drawColors.dashed,
        }),
        stroke: new Stroke({
          color: 'rgba(0, 0, 0, 0.5)',
          lineDash: [10, 10],
          width: 2,
        }),
        image: new CircleStyle({
          radius: 5,
          stroke: new Stroke({
            color: 'rgba(0, 0, 0, 0.7)',
          }),
          fill: new Fill({
            color: drawColors.dashed,
          }),
        }),
      }),
    },

    // Styles applied to any members of the vector layer that are not somehow modified by an interaction (i.e. select).
    VECTOR_LAYER: {
      // Styles when drawing a polygon selection
      SELECTION_POLYGON: new Style({
        image: new CircleStyle({
          fill: new Fill({
            color: drawColors.selectionBackground,
          }),

          radius: 4,
        }),
        fill: new Fill({
          color: drawColors.selectionBackground,
        }),
        stroke: new Stroke({
          color: drawColors.selectionBright,
          width: 2,
        }),
      }),

      POINT: new Style({
        image: new RegularShape({
          fill: new Fill({
            color: drawColors.bright,
          }),
          points: ol.pointNumber,
          radius: 4,
          angle: ol.pointAngle,
        }),
        fill: new Fill({
          color: drawColors.bright,
        }),
      }),

      // Default styles are used for example when drawing items.
      DEFAULT: new Style({
        image: new CircleStyle({
          fill: new Fill({
            color: drawColors.background,
          }),
          stroke: new Stroke({
            color: drawColors.bright,
            width: 2,
          }),
          radius: 4,
        }),
        fill: new Fill({
          color: drawColors.background,
        }),
        stroke: new Stroke({
          color: drawColors.bright,
          width: 2,
        }),
      }),
    },

    MISC: {
      // The aspect of nodes on drawn polygons (selected or otherwise).
      POLYGON_OR_POLYLINE_NODES: new Style({
        image: new CircleStyle({
          radius: 4,
          fill: new Fill({
            color: 'white',
          }),
          stroke: new Stroke({
            color: drawColors.bright,
            width: 2,
          }),
        }),
        geometry: (feature) => {
          const geom = feature.getGeometry();
          if (geom instanceof RenderFeature || geom === undefined) {
            console.debug(`Geometry not valid`, geom);
            return;
          }
          const geomType = (geom as Geometry).getType();
          if (geomType === 'LineString') {
            const coords = (geom as LineString).getCoordinates();
            return new MultiPoint(coords);
          }
          if (geomType === 'Polygon') {
            const coords = (geom as Polygon).getCoordinates();
            return new MultiPoint(coords[0]);
          }
          if (geomType === 'Circle') {
            // Currently do nothing
            return;
          }
          if (geomType === 'Point') {
            const coords = (geom as Point).getCoordinates();
            return new Point(coords);
          }
          console.debug(`No match for geometry type "${geomType}"`);
          return;
        },
      }),

      SELECTION_POLYGON_OR_POLYLINE_NODES: new Style({
        image: new RegularShape({
          fill: new Fill({
            color: 'white',
          }),
          stroke: new Stroke({
            color: drawColors.secondary,
            width: 2,
          }),
          points: ol.pointNumber,
          radius: 5,
          angle: ol.pointAngle,
        }),
        geometry: (feature) => {
          // Hacky handle of errors after @types/ol install:
          const featr = feature as any;
          if (featr && featr.values_ && featr.values_.geometry) {
            const type = featr.values_.geometry.getType();
            if (type === 'LineString') {
              // return a point for each coord in the line string
              const coordinates = featr.getGeometry().getCoordinates();
              return new MultiPoint(coordinates);
            }

            if (type === 'Point') {
              return featr.values_.geometry;
            }
          }

          // return the coordinates of the first ring of the polygon
          return new MultiPoint(featr.getGeometry().getCoordinates()[0]);
        },
      }),
    },
  };
};

/**
 * Default preset.
 * Should be used as a base for composing specific presets.
 */
const getOlDefaultStylePreset = () => {
  const { VECTOR_LAYER, SELECTED_ITEM, MISC } = getOlStyles();

  return {
    vectorLayer: (feature) => {
      if (
        feature &&
        feature.values_ &&
        feature.values_.geometry &&
        feature.values_.geometry.getType() === 'Point'
      ) {
        return [VECTOR_LAYER.POINT];
      }

      return [VECTOR_LAYER.DEFAULT];
    },

    drawInteraction: () => [VECTOR_LAYER.DEFAULT],

    selectInteraction: (feature) => {
      if (
        feature &&
        feature.values_ &&
        feature.values_.geometry &&
        feature.values_.geometry.getType() === 'Point'
      ) {
        return [SELECTED_ITEM.POINT];
      }

      return [SELECTED_ITEM.DEFAULT, MISC.POLYGON_OR_POLYLINE_NODES];
    },
  };
};

/**
 * Presets are a collection of style functions that can be overriden to achieve a mix of select/draw/vector layer collection.
 */
export const getOlStylePresets = () => {
  const OL_DEFAULT_STYLE_PRESET = getOlDefaultStylePreset();
  const { VECTOR_LAYER, INSPECTED_ITEM, MEASURE, MISC } = getOlStyles();

  return {
    // Default preset.
    DEFAULT: OL_DEFAULT_STYLE_PRESET,

    // Preset for drawing.
    DRAW: OL_DEFAULT_STYLE_PRESET,

    // Preset for when items are selected during drawing.
    DRAW_SELECTION: {
      selectInteraction: () => [
        VECTOR_LAYER.SELECTION_POLYGON,
        MISC.SELECTION_POLYGON_OR_POLYLINE_NODES,
      ],
      drawInteraction: () => [VECTOR_LAYER.SELECTION_POLYGON],
      vectorLayer: () => [VECTOR_LAYER.SELECTION_POLYGON],
    },

    // Preset for selections that show information. Given a feature id, shows the feature id as a label on top of it.
    INFO_SELECTION: {
      selectInteraction: () => {
        return [INSPECTED_ITEM.DEFAULT, INSPECTED_ITEM.POLYGON_OR_POLYLINE_NODES];
      },
    },

    // Preset for measurement tools
    MEASUREMENT: {
      drawInteraction: () => [MEASURE.WHILE_SKETCHING],
      vectorLayer: () => [MEASURE.DEFAULT],
    },
  };
};
