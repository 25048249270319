import { throttle } from 'lodash-es';
import vtkPointPicker from '@kitware/vtk.js/Rendering/Core/PointPicker';
import vtkCellPicker from '@kitware/vtk.js/Rendering/Core/CellPicker';

import MikeVisualizerStore from './store/MikeVisualizerStore';
import MikeVisualizerUtil from './MikeVisualizerUtil';
import { getEmitters } from './MikeVisualizerEvents';
import { IIndexChanged } from './models/IIndexChanged';
import { Vector3 } from '@kitware/vtk.js/types';

const { rendererReady } = MikeVisualizerUtil;
const { getState, setState } = MikeVisualizerStore;

/**
 * Allows 'picking' points or cells from the viewer.
 *
 * @module MikeVisualizerPickers
 * @version 1.0.0
 */

/**
 * Notifies of new point coordinates on mouse move.
 */
const enableMouseMovePointPicker = () => {
  if (!rendererReady()) {
    return false;
  }

  const { renderWindow, renderer } = getState();

  const picker = vtkPointPicker.newInstance();
  const mouseMoveCallback = (callData) => {
    if (renderer !== callData.pokedRenderer) {
      return;
    }

    try {
      const pos = callData.position;
      const point: Vector3 = [pos.x, pos.y, 0.0];
      picker.pick(point, renderer);

      const pickedPoint = picker.getPickPosition();
      getEmitters().emitMouseMove(pickedPoint);
    } catch (error) {
      console.debug('Failed to get mouse position', error);
    }
  };
  const throttledMouseMoveCallback = throttle(mouseMoveCallback, 500);
  renderWindow.getInteractor().onMouseMove(throttledMouseMoveCallback);
  return true;
};

const setCellIndexActorIds = (actorIds: Array<string>) => {
  setState({cellIndexActorIds: actorIds})
}


const setPointIndexActorIds = (actorIds: Array<string>) => {setState({pointIndexActorIds: actorIds})}
/**
 * Notifies of new cell index on mouse move.
 */
const enableMouseMoveCellIdPicker = (tolerance?: number) => {
  if (!rendererReady()) {
    return false;
  }

  const { renderWindow, renderer, cellIndexActorIds } = getState();
  if (!cellIndexActorIds || cellIndexActorIds.length === 0){
    return;
  }

  const actors = renderer.getActors().filter((a) => cellIndexActorIds.includes(a.getActorId()));
  if (actors.length === 0){
    return;
  }

  const picker = vtkCellPicker.newInstance();
  picker.setPickFromList(1);

  if(tolerance){
    picker.setTolerance(tolerance);
  }
  picker.initializePickList();
  actors.forEach(actor => {
    picker.addPickList(actor)
  });
  
  const cellIndexCallback = (callData) => {
    if (renderer !== callData.pokedRenderer) {
      return;
    }

    try {
      const pos = callData.position;
      const point = [pos.x, pos.y, 0.0];
      picker.pick(point, renderer);
      if (picker.getActors().length > 0) {
              
        const pickedPoint: Array<number> = picker.getPickPosition();
        const id: number = picker.getCellId(); 
        
        const cellIndex: IIndexChanged = {
          coordinates: pickedPoint,
          index: id,
          displayCoordinates: pos,
          actorId: (picker.getActors()[0] as any).getActorId(), // actorId property is defined by vtkEnhancedActor
        }
        getEmitters().emitCellIdChanged(cellIndex);
      }else {
        const pickedPoint: Array<number> = picker.getPickPosition();        
        const cellIndex: IIndexChanged = {
          coordinates: pickedPoint,
          index: -1,
          displayCoordinates: pos
        }
        getEmitters().emitCellIdChanged(cellIndex); 
      }
      
    } catch (error) {
      console.debug('Failed to get cell index', error);
    }
  };
  const throttledMouseMoveCallback = throttle(cellIndexCallback, 500);

  renderWindow.getInteractor().onMouseMove(throttledMouseMoveCallback);
  return true;
};

/**
 * Notifies of new cell index on mouse move.
 */
const enableMouseMovePointIdPicker = (tolerance?: number) => {
  if (!rendererReady()) {
    return false;
  }

  const { renderWindow, renderer, pointIndexActorIds } = getState();
  if (!pointIndexActorIds || pointIndexActorIds.length === 0){
    return;
  }

  const actors = renderer.getActors().filter((a) => pointIndexActorIds.includes(a.getActorId()));
  if (actors.length === 0){
    return;
  }

  const picker = vtkPointPicker.newInstance();
  picker.setPickFromList(1);
  
  if(tolerance){
    picker.setTolerance(tolerance)
  }
  picker.initializePickList();
  actors.forEach(actor => {
    picker.addPickList(actor)
  });

  const pointIndexCallback = (callData) => {
    if (renderer !== callData.pokedRenderer) {
      return;
    }

    try {
      const pos = callData.position;
      const point: Vector3 = [pos.x, pos.y, 0.0];
      picker.pick(point, renderer);

      if (picker.getActors().length > 0) {
        const id: number = picker.getPointId();       
        const pickedPoint: Array<number> = picker.getPickPosition();
        const pointIndex: IIndexChanged = {
          coordinates: pickedPoint,
          index: id,
          displayCoordinates: pos,
          actorId: (picker.getActors()[0] as any).getActorId(),
        }
        getEmitters().emitPointIdChanged(pointIndex);

      }else {
         
        const pickedPoint: Array<number> = picker.getPickPosition();
        const pointIndex: IIndexChanged = {
          coordinates: pickedPoint,
          index: -1,
          displayCoordinates: pos
        }
        getEmitters().emitPointIdChanged(pointIndex);
      }
    } catch (error) {
      console.debug('Failed to get cell index', error);
    }
  };
  const throttledMouseMoveCallback = throttle(pointIndexCallback, 500);

  renderWindow.getInteractor().onMouseMove(throttledMouseMoveCallback);
  return true;
};

/**
 * Notifies of new point coordinates on press (click or touch events).
 */
const enablePressPointPicker = () => {
  if (!rendererReady()) {
    return false;
  }

  const { renderWindow, renderer } = getState();

  const picker = vtkPointPicker.newInstance();
  const pressMoveCallback = (callData) => {
    if (renderer !== callData.pokedRenderer) {
      return;
    }

    try {
      const pos = callData.position;
      const point: Vector3 = [pos.x, pos.y, 0.0];
      picker.pick(point, renderer);

      const pickedPoint = picker.getPickPosition();
      getEmitters().emitPress(pickedPoint);
    } catch (error) {
      console.debug('Failed to get mouse position', error);
    }
  };
  const throttledPressCallback = throttle(pressMoveCallback, 100);

  renderWindow.getInteractor().onLeftButtonPress(throttledPressCallback);
  return true;
};

const self = { 
  enableMouseMovePointPicker,
  enablePressPointPicker,
  enableMouseMoveCellIdPicker,
  setCellIndexActorIds,
  enableMouseMovePointIdPicker,
  setPointIndexActorIds
};

export default self;
