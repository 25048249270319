/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import mikeSharedTheme from '../../styles/mikeSharedTheme';
import React from 'react';

import { Typography } from '@mui/material';
import { INotifications } from './INotifications';
import MikeNotificationItem from './MikeNotificationItem';
import { formatDate } from '../../mike-shared-helpers/date';
import { INotification } from './INotification';

interface IProps extends React.HTMLAttributes<HTMLElement> {
  /**
   * Notifications of one day.
   */
  notificationsPerDate?: INotifications;
  /**
   * href.
   */
  href?: string;
  /**
   * To add a placeholder for the first group.
   */
  isFirst: boolean;
  /**
   * Link callback.
   */
  onLinkCallBack?: (arg0: INotification) => void;
  /**
   * Custom render function.
   * To display the content of a notification item.
   */
  renderContent?: (notification: INotification) => React.ReactNode;
  /**
   * Custom render function.
   * To display the title of a notification item.
   */
  renderTitle?: (notification: INotification) => React.ReactNode;
}

const notificationDateStyle = css`  
  margin-bottom: ${mikeSharedTheme.spacing(1)};
`;

const notificationPerDayInnerContainerStyle = css`  
  background-color: ${mikeSharedTheme.palette.mediumGrey?.main};
`;

const notificationStyle = css`  
  border-radius: 4px;
  background-color: ${mikeSharedTheme.palette.mediumGrey?.light};
  padding-left: ${mikeSharedTheme.spacing(1)};
  padding-right: ${mikeSharedTheme.spacing(1)};
`;

const placeHolderStyle = css`  
  background-color: ${mikeSharedTheme.palette.mediumGrey?.main};
  padding-bottom: ${mikeSharedTheme.spacing(3)};
`;

/**
 * @name MikeNotificationItemGroup
 * @summary Shows the date of a list of notification items, as well as listing the notification items themselves.
 */
export const MikeNotificationItemGroup: React.FC<IProps> = ({
  notificationsPerDate,
  href,
  isFirst,
  onLinkCallBack,
  renderContent,
  renderTitle,
}: IProps) => {
 
  return (
    <div css={notificationPerDayInnerContainerStyle}>
      {!isFirst && <div css={placeHolderStyle} />}
      <Typography css={notificationDateStyle} variant="body2">
        {formatDate(notificationsPerDate && notificationsPerDate.date)}
      </Typography>
      <div css={notificationStyle}>
        {notificationsPerDate &&
          notificationsPerDate.notifications.map((notification: INotification, j) => (
            <MikeNotificationItem
              key={j}
              notification={notification}
              href={href}
              onLinkCallBack={onLinkCallBack}
              renderContent={renderContent}
              renderTitle={renderTitle}
            />
          ))}
      </div>
    </div>
  );
};

export default MikeNotificationItemGroup;
