/**
 * Extends vtk XMLReader to read PolyData & ImageData.
 * It can create output from either:
 * - xml as array buffer
 * - xml as Document instance
 *
 * Also, parses root fieldData if present so it can be accessed via getFieldData().
 *
 * @module vtkEnhancedDataReader
 * @version 1.0.0
 */
import vtkXMLReader from '@kitware/vtk.js/IO/XML/XMLReader';
import macro from '@kitware/vtk.js/macro';
import vtkImageData from '@kitware/vtk.js/Common/DataModel/ImageData';
import vtkPolyData from '@kitware/vtk.js/Common/DataModel/PolyData';

import { SUPPORTED_VTK_FORMATS } from '../MikeVisualizerConstants';

const { vtkErrorMacro } = macro;

// Global methods
/**
 * Handles a data array, setting polydata value for the given cell type.
 *
 * @param { Object } polydata
 * @param { string } cellType
 * @param { Document } piece
 * @param { string } compressor
 * @param { string } byteOrder
 * @param { string } headerType
 * @param { Int8Array | Int16Array | Int32Array } binaryBuffer
 */
function handleArray(polydata, cellType, piece, compressor, byteOrder, headerType, binaryBuffer) {
  const size = Number(piece.getAttribute(`NumberOf${cellType}`));
  if (size > 0) {
    const dataArrayElem = piece
      .getElementsByTagName(cellType)[0]
      .getElementsByTagName('DataArray')[0];
    const { values, numberOfComponents } = vtkXMLReader.processDataArray(
      size,
      dataArrayElem,
      compressor,
      byteOrder,
      headerType,
      binaryBuffer
    );
    polydata[`get${cellType}`]().setData(values, numberOfComponents);
  }
  return size;
}

/**
 * Handles cells, setting polydata value for the given cell type.
 *
 * @param { Object } polydata
 * @param { string } cellType
 * @param { Document } piece
 * @param { string } compressor
 * @param { string } byteOrder
 * @param { string } headerType
 * @param { Int8Array | Int16Array | Int32Array } binaryBuffer
 */
function handleCells(polydata, cellType, piece, compressor, byteOrder, headerType, binaryBuffer) {
  const size = Number(piece.getAttribute(`NumberOf${cellType}`));
  if (size > 0) {
    const values = vtkXMLReader.processCells(
      size,
      piece.getElementsByTagName(cellType)[0],
      compressor,
      byteOrder,
      headerType,
      binaryBuffer
    );
    polydata[`get${cellType}`]().setData(values);
  }
  return size;
}

// vtkEnhancedDataReader methods
function vtkEnhancedDataReader(publicAPI, model) {
  // Set our className
  model.classHierarchy.push('vtkEnhancedDataReader');

  /**
   * Process generic vtk data, then sets result as output for the given index.
   *
   * @param { vtkImageData | vtkPolyData } vtkDataInstance
   * @param { Document } piece Parent Piece.
   * @param { Document } dataElement Parent data element.
   * @param { number } outputIndex
   * @param { string } compressor
   * @param { string } byteOrder
   * @param { string } headerType
   * @param { number } pointNumber
   * @param { number } cellNumber
   * @param { number } fieldNumber
   */
  publicAPI.processData = (
    vtkDataInstance,
    piece,
    dataElement,
    outputIndex,
    compressor,
    byteOrder,
    headerType,
    pointNumber,
    cellNumber,
    fieldNumber
  ) => {
    const fieldData = vtkDataInstance.getFieldData();
    const pointData = vtkDataInstance.getPointData();
    const cellData = vtkDataInstance.getCellData();

    // Fill in data: root fieldData, pointData, cellData.
    const fieldDataElement = dataElement.getElementsByTagName('FieldData')[0];
    const pointElement = piece.getElementsByTagName('PointData')[0];
    const cellElement = piece.getElementsByTagName('CellData')[0];

    if (fieldData) {
      /**
       * Process root field data if present.
       * This essentially makes the root field data available under data.getFieldData().getArrays()
       */
      vtkXMLReader.processFieldData(
        fieldNumber,
        fieldDataElement,
        fieldData,
        compressor,
        byteOrder,
        headerType,
        model.binaryBuffer
      );
    }

    vtkXMLReader.processFieldData(
      pointNumber,
      pointElement,
      pointData,
      compressor,
      byteOrder,
      headerType,
      model.binaryBuffer
    );

    vtkXMLReader.processFieldData(
      cellNumber,
      cellElement,
      cellData,
      compressor,
      byteOrder,
      headerType,
      model.binaryBuffer
    );

    // Add new output to the given index.
    model.output[outputIndex] = vtkDataInstance;
  };

  /**
   * Parses a vtk XML Document.
   * For each piece of the document, it creates a corresponding reader and calls processData to set the data to the model output index.
   *
   * To understand naming, a comparison between a vtp XML and naming is made in the examples below.
   *
   * @example vtk XML
   * <VTKFile>
   *    <PolyData>
   *      <Piece>
   *        <!-- actual data -->
   *      </Piece>
   *    </PolyData>
   * </VTKFile>
   *
   * @example naming in this file
   * <rootElem>
   *    <dataElement>
   *      <piece>
   *        <!-- actual data -->
   *      </piece>
   *    </dataElement>
   * </rootElem>
   *
   * @param { Document } rootElem Root XML element.
   * @param { string } type One of SUPPORTED_VTK_FORMATS.
   * @param { string } compressor
   * @param { string } byteOrder
   * @param { string } headerType
   */
  publicAPI.parseXML = (rootElem, type, compressor, byteOrder, headerType) => {
    const dataElement = rootElem.getElementsByTagName(type)[0];
    const pieces = dataElement.getElementsByTagName('Piece');
    const pieceNumber = pieces.length;

    for (let outputIndex = 0; outputIndex < pieceNumber; outputIndex++) {
      // Create corresponding data for each piece.
      const piece = pieces[outputIndex];

      if (type === SUPPORTED_VTK_FORMATS.VTI) {
        publicAPI.parseVti(dataElement, piece, outputIndex, compressor, byteOrder, headerType);
      } else if (type === SUPPORTED_VTK_FORMATS.VTP) {
        publicAPI.parseVtp(dataElement, piece, outputIndex, compressor, byteOrder, headerType);
      } else {
        vtkErrorMacro(
          `Tried to parse XML of unsupported VTK format. Supported formats: ${Object.keys(
            SUPPORTED_VTK_FORMATS
          )}`
        );
      }
    }
  };

  /**
   * Parses a ImageData element.
   *
   * @param { Document } dataElement
   * @param { Document } piece
   * @param { number } outputIndex
   * @param { string } compressor
   * @param { string } byteOrder
   * @param { string } headerType
   */
  publicAPI.parseVti = (dataElement, piece, outputIndex, compressor, byteOrder, headerType) => {
    const origin = dataElement
      .getAttribute('Origin')
      .split(' ')
      .map((t) => Number(t));
    const spacing = dataElement
      .getAttribute('Spacing')
      .split(' ')
      .map((t) => Number(t));

    const extent = piece
      .getAttribute('Extent')
      .split(' ')
      .map((t) => Number(t));

    const vtkDataInstance = vtkImageData.newInstance({
      origin,
      spacing,
      extent,
    });

    const fieldNumber = dataElement.getElementsByTagName('FieldData').length;
    const pointNumber = vtkDataInstance.getNumberOfPoints();
    const cellNumber = vtkDataInstance.getNumberOfCells();

    publicAPI.processData(
      vtkDataInstance,
      piece,
      dataElement,
      outputIndex,
      compressor,
      byteOrder,
      headerType,
      pointNumber,
      cellNumber,
      fieldNumber
    );
  };

  /**
   * Parses a PolyData element.
   *
   * @param { Document } dataElement
   * @param { Document } piece
   * @param { number } outputIndex
   * @param { string } compressor
   * @param { string } byteOrder
   * @param { string } headerType
   */
  publicAPI.parseVtp = (dataElement, piece, outputIndex, compressor, byteOrder, headerType) => {
    const vtkDataInstance = vtkPolyData.newInstance();
    const fieldNumber = dataElement.getElementsByTagName('FieldData').length;

    // Points
    const pointNumber = handleArray(
      vtkDataInstance,
      'Points',
      piece,
      compressor,
      byteOrder,
      headerType,
      model.binaryBuffer
    );

    // Cells
    let cellNumber = 0;
    ['Verts', 'Lines', 'Strips', 'Polys'].forEach((cellType) => {
      cellNumber += handleCells(
        vtkDataInstance,
        cellType,
        piece,
        compressor,
        byteOrder,
        headerType,
        model.binaryBuffer
      );
    });

    publicAPI.processData(
      vtkDataInstance,
      piece,
      dataElement,
      outputIndex,
      compressor,
      byteOrder,
      headerType,
      pointNumber,
      cellNumber,
      fieldNumber
    );
  };

  publicAPI.requestData = () => {
    publicAPI.parseArrayBuffer(model.rawDataBuffer);
  };
}

// Object factory
const DEFAULT_VALUES = {
  dataType: 'ImageData',
};

export function extend(publicAPI, model, initialValues = {}) {
  Object.assign(model, DEFAULT_VALUES, initialValues);

  // Inheritance
  vtkXMLReader.extend(publicAPI, model, initialValues);

  // Object specific methods
  vtkEnhancedDataReader(publicAPI, model);
}

export const newInstance = macro.newInstance(extend, 'vtkEnhancedDataReader');
export default { newInstance, extend };
