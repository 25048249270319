
import ActionType from "../actions/ActionType"
import { IAction } from "../actions/Action"
import { IGetProject } from '../model/IGetProject';

export interface IProjectsState {
  loading: boolean;
  projects: IGetProject[];
  namePrefix: string;
  sortBy: string;
  sortOrder: 'asc' | 'desc';
  totalCount: number;
  offset: number;
  page: number;
  rowsPerPage: number;
  limit: number;
  creatingRootProject: boolean;
  createPanelIsOpen: boolean;
  updatingProject: boolean;
}

const initState = {
  loading: false,  
  projects: Array<IGetProject>(),
  namePrefix: '',
  sortBy: 'createdAt',
  sortOrder: 'desc',
  totalCount: 0,
  offset: 0,
  page: 0,
  rowsPerPage: 25,
  limit: 25,
  creatingRootProject: false,
  createPanelIsOpen: false,
  updatingProject: false
}

const projects = (state = initState, action: IAction) => {
  switch (action.type) {
    case ActionType.UPDATING_PROJECT: {
      return {...state, updatingProject: action.data}
    }
    case ActionType.SET_ROOTPROJECT_PANEL_OPEN: {
      return {...state, createPanelIsOpen: action.data}
    }
    case ActionType.CREATING_ROOTPROJECT:
      return {...state, creatingRootProject: action.data}
    case ActionType.LOADING_PROJECTS:
      return { ...state, loading: action.data };
    case ActionType.SET_PROJECTS_PAGINATION:
      return { ...state, page: action.data }
    case ActionType.SET_PROJECTS_ROWSPERPAGE:
      return { ...state, rowsPerPage: action.data }
    case ActionType.SET_PROJECTS_SORT:
      return { ...state, 
        sortOrder: action.data.sortOrder,
        sortBy: action.data.sortBy 
      }
    case ActionType.SET_PROJECTS_NAMEPREFIX:
      return { ...state, namePrefix: action.data }
    case ActionType.SET_PROJECTS: {     
      return {
        ...state,
        projects: action.data.projects,
        loading: false,
        offset: action.data.offset,
        limit: action.data.limit,    
        totalCount: action.data.totalCount
      }; 
    }
          
    default:
        return state;
  }
};

export default projects