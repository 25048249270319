
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import {
  Typography,
  Breadcrumbs,
  Link,
  Tooltip,
} from '@mui/material';
import ProjectLink from './ProjectLink';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { useSelector } from 'react-redux';
import { IUser } from '../../model/IUser';
import mikeSharedTheme from '../../styles/mikeSharedTheme';
import { IGetProjectPath } from '../../model/IGetProject';
import { IState } from '../../reducers';

interface IProps { 
  rootBreadcrumpsName?: string;
}

const mikeBreadcrumpsLastLabelStyle = 
  css`
    font-weight: bold;
    color: ${mikeSharedTheme.palette.text.primary};    
    max-width: 15vw;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
  `;

const mikeBreadcrumpsTenantLabelStyle = 
css` 
  color: ${mikeSharedTheme.palette.text.primary};    
  max-width: 15vw;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
`;

const mikeBreadcrumpsLinkLabelStyle = 
  css`
    cursor: pointer;   
    color: ${mikeSharedTheme.palette.text.primary}; 
    &:hover: {
      color: ${mikeSharedTheme.palette.text.primary};
    };
      &:active: {
      color: ${mikeSharedTheme.palette.text.primary};
    };
      &:focus: {
      color: ${mikeSharedTheme.palette.text.primary};
    };
    max-width: 15vw;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
  `;

/**
 * @name AppBreadcrumpsNav
 * @summary Renders a list of links. Meant to be used as breadcrumbs in the MikeTopbar.
 */
export const AppBreadcrumpsNav = (props: IProps) => {
  const {   
    rootBreadcrumpsName,
    ...rest
  } = props;

  const breadcrumpsContent: Array<IGetProjectPath> = useSelector((state: IState) => state.folders.projectPath);
  const  user: IUser | null = useSelector((state: IState) => state.auth.user);  

  const handleRootClick = (e) => {
    e.preventDefault(); 
  };

  return (
    <Breadcrumbs
      maxItems={5}
      separator={<NavigateNextIcon color="primary" fontSize="small" />}
      aria-label="mikebreadcrumb"
      {...rest}>
      {rootBreadcrumpsName && (
        <Tooltip title={rootBreadcrumpsName}>
          <Link css={mikeBreadcrumpsLinkLabelStyle} onClick={handleRootClick}>
            <Typography css={mikeBreadcrumpsLinkLabelStyle} variant="body2">
              {rootBreadcrumpsName}
            </Typography>
          </Link>
        </Tooltip>
      )}
      {breadcrumpsContent.map((breadcrump: IGetProjectPath, index: number) => {
        const last = index === breadcrumpsContent.length - 1;
        const tenant = index === 0 && breadcrump.name === user?.tenantName
        return (
          <Tooltip key={index + '_' + breadcrump.name} title={breadcrump.name}>
            <Typography
              variant="body2"
              css={
                tenant ? mikeBreadcrumpsTenantLabelStyle : last ? mikeBreadcrumpsLastLabelStyle : mikeBreadcrumpsLinkLabelStyle
              }>
              {tenant ? (
                <ProjectLink
                key={index}
                to={'/'}
                text={breadcrump.name}                
              />
              ) : (
                <ProjectLink
                  key={index}
                  to={'/project/' + breadcrump.id}
                  text={breadcrump.name}                
                />
              )} 
            </Typography>
          </Tooltip>
        );
      })}
    </Breadcrumbs>
  );
};

export default AppBreadcrumpsNav;
