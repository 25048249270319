/** @jsxImportSource @emotion/react */
/* eslint-disable react-refresh/only-export-components */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { css } from '@emotion/react';
import React from 'react';
import get from 'lodash/get';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import ColorCircle from '../../icons/ColorCircle.svg?react';
import { getInputStyle, helperTextPlaceHolderStyle, menuItemStyle, menuListStyle, primaryDisabledStyle, primarySelectedStyle } from '../../styles/sharedDropdownStyles';
import mikeSharedTheme from '../../styles/mikeSharedTheme';

interface IProps {
  autoFocus?: boolean;
  disabled?: boolean;
  error?: boolean;
  fullWidth?: boolean;
  items: Array<any>;
  helperText?: string;
  hideIcon?: boolean;
  label?: string;
  name: string;
  value: any;
  valueField: string;
  primaryField: string;
  secondaryField?: string;
  onChange?: (event: React.ChangeEvent<{ value: unknown }>, child?: React.ReactNode) => void;
  showColorIcon?: boolean;
}

export const iconStyle = (color: string) => { 
  return css`  
  path {
    fill: ${color};
  }
`}

export const StyledSelect = (props: IProps) => {
  const {
    autoFocus,
    disabled,
    error,
    fullWidth,  
    helperText,
    items,
    label,
    name,
    primaryField,
    secondaryField,
    value,
    valueField,
    onChange,
    showColorIcon = true
  } = props;
  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleChange = (event, child?: React.ReactNode) => {
    setOpen(false);
    if (onChange) {
      onChange(event, child);
    }
  };

  const forceUpdateOnItemListChange = `${name}-${items.length}`;

  return (
    <FormControl 
      sx={{ p: 0, minWidth: mikeSharedTheme.spacing(15) }}
      disabled={disabled}
      error={error}
      fullWidth={fullWidth}
      size="small"
      variant={'filled'}    
    >
      {label && (
        <InputLabel id="styled-select-label" htmlFor={name} required={false} disabled={disabled}>
          {label}
        </InputLabel>
      )}
      <Select 
        labelId="styled-select-label"
        autoFocus={autoFocus}
        key={forceUpdateOnItemListChange}       
        sx={getInputStyle(!label)}
        inputProps={{         
          name,
          id: name,
         /*  classes: {
            root: value !== '' ? (label ? inputStyle : inputNoLabelStyle) : '',
            icon: hideIcon
              ? iconHiddenStyle
              : error
              ? iconErrorStyle
              : disabled && iconDisabledStyle,
          }, */
        }}
        open={open}
        onChange={handleChange}
        onClose={handleClose}
        onOpen={handleOpen}
        MenuProps={{ sx: { paper: menuListStyle } }}
        value={value}
      >
        {items && items.map((item) => {
          const itemValue = get(item, valueField);
          const selectedItemValue = value;
          const primary = get(item, primaryField);
          const secondary = secondaryField && get(item, secondaryField);
          return (
            <MenuItem  key={itemValue} value={itemValue}>
              {item.color ? 
              <div css={menuItemStyle}>
                {showColorIcon && <ColorCircle width={30} height={30}  viewBox={"0 0 40 40"} css={iconStyle(item.color)}/>}           
                <ListItemText
                  sx={
                    disabled
                      ? { primary: primaryDisabledStyle }
                      : itemValue === selectedItemValue && open
                      ? { primary: primarySelectedStyle }
                      : {}
                  }
                  primary={primary}
                  secondary={itemValue === selectedItemValue && !open ? null : secondary}
                />
              </div> :
              <ListItemText
                sx={
                  disabled
                    ? { primary: primaryDisabledStyle }
                    : itemValue === selectedItemValue && open
                    ? { primary: primarySelectedStyle }
                    : {}
                }
                primary={primary}
                secondary={itemValue === selectedItemValue && !open ? null : secondary}
              />}
            </MenuItem>
          );
        })}
      </Select>
      {helperText ? (
        <FormHelperText>{helperText}</FormHelperText>
      ) : (
        <div css={helperTextPlaceHolderStyle} />
      )}
    </FormControl>
  );
};
