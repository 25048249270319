import vtkEnhancedDataReader from './vtk-extensions/vtkEnhancedDataReader.js';
import vtkEnhancedActor from './vtk-extensions/vtkEnhancedActor.js';
import vtkMapper from '@kitware/vtk.js/Rendering/Core/Mapper';
import vtkColorTransferFunction from '@kitware/vtk.js/Rendering/Core/ColorTransferFunction';
import vtkColorMaps from '@kitware/vtk.js/Rendering/Core/ColorTransferFunction/ColorMaps';
import { ColorMode, ScalarMode } from '@kitware/vtk.js/Rendering/Core/Mapper/Constants';
import { SUPPORTED_VTK_FORMATS, DATA_ARRAY_TYPES } from './MikeVisualizerConstants.js';
import MikeVisualizerCosmetic from './MikeVisualizerCosmetic.js';
import MikeVisualizerUtil from './MikeVisualizerUtil.js';
import MikeVisualizerStore from './store/MikeVisualizerStore.js';
import MikeVisualizerConverter from './converters/MikeVisualizerConverter.js';
import { IDrawnDataGradientSettings } from './IMikeVisualizerModels.js';
import { IThreeDRenderElement, IElementDataArray } from './IMikeVisualizerModels.js';
import { getConfiguration } from './MikeVisualizerConfiguration.js';
const { getState } = MikeVisualizerStore;
const { rendererReady, getXmlAttributes, containsCellData, containsPointData } = MikeVisualizerUtil;
const { _getOpacity, setScaleToActor } = MikeVisualizerCosmetic;
const { convertVtiToVtpTriangles, convertVtpPointsToVtpTriangles } = MikeVisualizerConverter;

/**
 * This module handles viewer tiles.
 *
 * @module MikeVisualizerTileManager
 * @version 1.0.0
 */
let lookupTable;
let lookupTableCurrentGradientPreset = null;
let lookupTableCurrentGradientName = null;

/**
 * Creates an actor from a vtp xml string and adds it to the currently instantiated renderer.
 * NB: shared lookup table to apply gradients uniformly on all tiles.
 * TODO dan: this could be refactored a bit to re-use some of the mapper setup logic between this module & cosmetic.
 *
 * @param { vtkData } parsedVtpData The parsed vtp data to be added to the renderer.
 * @param actorId The desired that should be given to the actor.
 * @param actorEdgeColor Rgba color array for the edge.
 * @param actorSurfaceColor Rgba color array for the surface.
 * @param [gradientSettings] Optional. Gradient settings to be used if applying a gradient,  @see IDrawnDataGradientSettings.
 * @param [gradientAttributeName] Use this attribute to set a gradient color. By default the  first available attribute will be used.
 */
const _addVtpTileToRenderer = (
  parsedVtpData,
  actorId,
  actorEdgeColor,
  actorSurfaceColor,
  gradientSettings,
  gradientAttributeName
) => {
  const { renderWindow, renderer, viewerZScale } = getState();
  const { gradientSettings: defaultGradientSettings } = getConfiguration();
  const actor = vtkEnhancedActor.newInstance();
  actor.setActorId(actorId);
  setScaleToActor(actor, 1, 1, viewerZScale);
  const containsCellDataArrays = containsCellData(parsedVtpData);
  const containsPointDataArrays = containsPointData(parsedVtpData);

  if (containsCellDataArrays || containsPointDataArrays) {
    const dataArrays: Array<IElementDataArray> = MikeVisualizerUtil.getDataArrays(parsedVtpData);
    let dataArray;
    if (dataArrays) {
      // If there is some array of cell or point data (i.e. Area, Quality, etc), color it based on a range.
      // Use the provided attribute name or default to the first.
      dataArray = gradientAttributeName
        ? (dataArrays.find((arr) => arr.id === gradientAttributeName) as IElementDataArray)
        : (dataArrays[0] as IElementDataArray);
    }

    if (!dataArray) {
      // If a bad attributeName is passed, abort.
      throw new Error('Failed to read tile metadata');
    }

    const { gradientPreset, gradientColorMap } = gradientSettings || defaultGradientSettings;
    const colorArrayRange = dataArray.range;
    const colorByArrayName = dataArray.id;
    const colorMode = ColorMode.MAP_SCALARS;
    const scalarMode =
      dataArray.type === DATA_ARRAY_TYPES.CELLDATA
        ? ScalarMode.USE_CELL_FIELD_DATA
        : ScalarMode.USE_POINT_FIELD_DATA;
    const dataRange = [colorArrayRange[0], colorArrayRange[1]];
    const preset =
      gradientColorMap ||
      vtkColorMaps.getPresetByName(gradientPreset || defaultGradientSettings.gradientPreset);

    if (
      !lookupTable ||
      lookupTableCurrentGradientName !== gradientAttributeName ||
      lookupTableCurrentGradientPreset !== gradientPreset // the lookup table gets reset whenever the gradient name or preset changes. This is important, because it assumes there aren't any mixed gradient names or presets at a given point. Typically a gradient name/preset would only change if all tiles would change.
    ) {
      lookupTable = vtkColorTransferFunction.newInstance();

      lookupTable.setVectorModeToMagnitude();
      lookupTable.applyColorMap(preset);
      lookupTable.setMappingRange(dataRange[0], dataRange[1]);
      lookupTable.updateRange();

      lookupTableCurrentGradientName = gradientAttributeName;
      lookupTableCurrentGradientPreset = gradientPreset;
    }

    const mapper = vtkMapper.newInstance({
      interpolateScalarsBeforeMapping: false,
      useLookupTableScalarRange: true,    
      scalarVisibility: true,
    });
    mapper.setLookupTable(lookupTable)
    mapper.set({
      colorByArrayName,
      colorMode,
      scalarMode,
    });
    mapper.setInputData(parsedVtpData);
    actor.setMapper(mapper);
    actor.getProperty().setOpacity(_getOpacity(actorEdgeColor, actorSurfaceColor));

    // TODO dan: this could be registered somewhere in the state (renderedTileElements?), but it requires that clearing tiles also removes it from that said state. For now there's no reason to do it, but it will be needed when higher-res tiles will be retrieves for lower zoom levels.
    renderer.addActor(actor);
    renderWindow.render();
    return actor;
  }

  return false;
};

/**
 * Draws a tile.
 * Converts vti to vtp (triangles) and vtp points to vtp triangles.
 *
 * @param vtkXmlString An XML string with the representation to update. Can be any valid VTK format.
 * @param elementId The id of the element to update.
 * @param elementEdgeColor Rgba color array for the edge.
 * @param elementSurfaceColor Rgba color array for the surface.
 * @param [gradientSettings] Optional. Gradient settings if applying a gradient,  @see IDrawnDataGradientSettings.
 * @param [gradientAttributeName] Optional. Use this attribute to apply a gradient color. By default the first available attribute will be used.
 * @param [elevationAttributeName] Optional. Custom name of attribute to use for z values in 3D view.
 */
const drawTile = (
  vtkXmlString: string,
  elementId: string,
  elementEdgeColor: Array<number>,
  elementSurfaceColor: Array<number>,
  gradientSettings?: IDrawnDataGradientSettings,
  gradientAttributeName?: string,
  elevationAttributeName?: string
): IThreeDRenderElement | boolean => {
  if (!rendererReady()) {
    return false;
  }
  const { renderWindow, renderer } = getState();
  const domParser = new DOMParser();
  const vtkXmlData = domParser.parseFromString(vtkXmlString, 'application/xml');
  const vtkXmlNode = vtkXmlData.documentElement;

  if (vtkXmlNode.nodeName === 'parsererror') {
    console.error('Failed to parse VTP XML', vtkXmlNode);
    return false;
  }

  try {
    let renderResult;
    const vtkRootXmlAttributes = getXmlAttributes(vtkXmlNode);
    const vtkEnhancedReader = vtkEnhancedDataReader.newInstance();
    const { type, byte_order, header_type, compressor } = vtkRootXmlAttributes;
    vtkEnhancedReader.parseXML(vtkXmlData, type, compressor, byte_order, header_type);
    const parsedVtkData = vtkEnhancedReader.getOutputData(0);

    if (vtkRootXmlAttributes.type === SUPPORTED_VTK_FORMATS.VTP) {
      const vtpPolyData = convertVtpPointsToVtpTriangles(parsedVtkData, { elevationAttributeName })
        .vtpPolyData;
      renderResult = self._addVtpTileToRenderer(
        vtpPolyData,
        elementId,
        elementEdgeColor,
        elementSurfaceColor,
        gradientSettings,
        gradientAttributeName
      );
    } else if (vtkRootXmlAttributes.type === SUPPORTED_VTK_FORMATS.VTI) {
      const { vtpPolyData } = convertVtiToVtpTriangles(
        parsedVtkData,
        true,
        false,
        -10 // Use negative Z values to force flat vtis to be just under all other elements. This helps with visualisation without distorting the data too much.
      );
      renderResult = self._addVtpTileToRenderer(
        vtpPolyData,
        elementId,
        elementEdgeColor,
        elementSurfaceColor,
        gradientSettings,
        gradientAttributeName
      );
    } else {
      throw new Error(
        `VTK format not supported. Supported formats: ${Object.keys(SUPPORTED_VTK_FORMATS)}`
      );
    }

    renderer.resetCameraClippingRange();
    renderWindow.render();
    return renderResult.renderedElement;
  } catch (e) {
    console.error('Failed to update tile', e);
    return false;
  }
};

const self = {
  _addVtpTileToRenderer,
  // clearTiles, todo dan: in the future, we should allow clearing from here instead of managing tiles in vtp-viewer
  drawTile,
};

export default self;
