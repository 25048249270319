import { parseError } from '../helpers/errors'
import ActionType from "./ActionType";

export const getContentOfDatset = (id: string, fileName: string, datasetId: string, data: object, datasetType: string, download = false, setPreviousExtraction = false) => ({
  type: ActionType.GET_CONTENT_OF_DATASET,
  data: {
    id: id,
  fileName: fileName,
  datasetId: datasetId,  
  data: data,
  datasetType: datasetType,
  download: download,
  setPreviousExtraction: setPreviousExtraction}
})

export const exportStart = (id: string, title: string) => ({
  type: ActionType.EXPORT_START,
  data: {
    id: id,
    title: title
  }
})


export const updateExportProgress = (id: string, progress: number, title: string) => ({
  type:  ActionType.UPDATE_EXPORT_PROGRESS,
  data: {
    id, 
    progress,
    title
  }
})

export const exportComplete = (id: string) => ({
  type: ActionType.EXPORT_COMPLETE,
  data: id
})

export const exportFail = (id: string, error) => ({
  type: ActionType.EXPORT_FAIL,
  data: {
    id: id,
    error: parseError(error, { id })
  }
})


export const removeExport = (id: string) => ({
  type: ActionType.REMOVE_EXPORT,
  data: id
})
