import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Skeleton from '@mui/material/Skeleton';

interface IProps {
  skeletonRowCount: number;
  skeletonColumnCount: number;
  skeletonCellHeight?: number;
}

const TableBodySkeleton = (props: IProps) => {
  const { skeletonRowCount, skeletonColumnCount, skeletonCellHeight } = props;

  const height = skeletonCellHeight ? skeletonCellHeight : 38;
  const skeletonRows = Array.from({ length: skeletonRowCount }, (_v, k) => k + 1);
  const skeletonColumns = Array.from({ length: skeletonColumnCount }, (_v, k) => k + 1);

  return (
    <TableBody>
      {skeletonRows.map((_row, ind) => {
        return (
          <TableRow key={ind}>
            {skeletonColumns.map((_head, index) => {
              return (
                <TableCell key={index} align="left">
                  <Skeleton height={height} />
                </TableCell>
              );
            })}
            <TableCell align="left">
              <Skeleton height={height} />
            </TableCell>
          </TableRow>
        );
      })}
    </TableBody>
  );
};

export default TableBodySkeleton;
