/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import React from 'react';
import mikeSharedTheme from '../../styles/mikeSharedTheme';
import MIKE_COLORS from '../../styles/mike-colors';

const PanelBottomActionsStyle = css`
  background: ${mikeSharedTheme.palette.background.paper};
  border-top: 2px solid ${MIKE_COLORS.DARKGREY_DEFAULT};
  margin-top: -1px;
  padding: ${mikeSharedTheme.spacing(3)} ${mikeSharedTheme.spacing(2)};
  position: sticky;
  bottom: 0;

  z-index: var(--panel-header-zindex);
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

type PanelBottomActionsProps = {
  children?: React.ReactNode;
};

/**
 * @name MikeStickyPanelBottomActions
 * @summary Panels can have optional bottom actions, usually containing buttons.
 * The bottom actions are 'sticky to the bottom'.
 *
 * @param props
 */
export const MikeStickyPanelBottomActions = (props: PanelBottomActionsProps) => {
  return <div css={PanelBottomActionsStyle}> {props.children}</div>;
};
