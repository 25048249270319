import { noop } from 'lodash-es';
import Graticule, { Options } from 'ol/layer/Graticule';
import Stroke from 'ol/style/Stroke';
import MikeVisualizerStore from '../store/MikeVisualizerStore';
const { getState } = MikeVisualizerStore;

export interface IAddGraticuleHandler {
  show?: () => void;
  hide?: () => void;
  toggle?: () => boolean;
  showOutline?: () => void;
  hideOutline?: () => void;
  toggleOutline?: () => boolean;
  addToMap?: () => void;
  removeFromMap?: () => void;
  error?: string;
  layers?: {
    graticule: Graticule;
    outline: Graticule;
  };
}

export interface IAddGraticule {
  visible?: boolean;
  addToMap?: boolean;
  graticuleOptions?: Partial<Options>;
  graticuleOutlineOptions?: Partial<Options>;
}

/**
 * Adds coordinate grid overlay / Graticule to the map and returns a handler.
 * Currently only works in EPGS:4326 but will soon be fixed to work for "all" projections,
 * like this sandbox by JOEL: https://ellvtr.github.io/graticule1/
 *
 * @param options
 * @param options.visible default is true
 * @param options.addToMap default is true
 * @param options.graticuleOptions OpenLayers Graticule Options for grid
 * @param options.graticuleOutlineOptions OpenLayers Graticule Options for grid outline
 * @returns IAddGraticuleHandler
 */
export const addGraticule = async (options?: IAddGraticule): Promise<IAddGraticuleHandler> => {
  const { visible = true, addToMap = true, graticuleOptions = {}, graticuleOutlineOptions = {} } =
    options || {};
  const { baseMapPromise, epsgCode } = getState();
  // Handle constraints:
  if (!baseMapPromise) {
    const error = `No baseMapPromise in addGraticule`;
    console.error(error);
    return { error };
  }
  const baseMap = await baseMapPromise;
  if (!baseMap) {
    const error = `No baseMap in addGraticule`;
    console.error(error);
    return { error };
  }
  if (epsgCode !== 4326) {
    const error = `addGraticule currently only supports EPGS:4326`;
    console.error(error);
    return { error };
  }

  // Set up layers:
  const graticuleLayer = new Graticule({
    strokeStyle: new Stroke({
      color: 'rgba(0,0,0,0.3)',
      width: 1,
      lineCap: 'butt',
    }),
    showLabels: true,
    visible,
    wrapX: false,
    zIndex: 100,
    ...graticuleOptions,
  });
  const graticuleOutline = new Graticule({
    strokeStyle: new Stroke({
      color: 'rgba(255,255,255,0.7)',
      width: 2,
      lineCap: 'butt',
    }),
    showLabels: false,
    visible,
    wrapX: false,
    zIndex: 99,
    ...graticuleOutlineOptions,
  });
  // Add to map:
  if (addToMap) {
    baseMap.addLayer(graticuleOutline);
    baseMap.addLayer(graticuleLayer);
  }

  // Return the handler:
  return {
    show: () => {
      graticuleLayer.setVisible(true);
      graticuleOutline.setVisible(true);
    },
    hide: () => {
      graticuleLayer.setVisible(false);
      graticuleOutline.setVisible(false);
    },
    toggle: () => {
      graticuleLayer.setVisible(!graticuleLayer.getVisible());
      graticuleOutline.setVisible(!graticuleOutline.getVisible());
      return graticuleLayer.getVisible();
    },
    showOutline: () => {
      graticuleOutline.setVisible(true);
    },
    hideOutline: () => {
      graticuleOutline.setVisible(false);
    },
    toggleOutline: () => {
      graticuleOutline.setVisible(!graticuleOutline.getVisible());
      return graticuleOutline.getVisible();
    },
    addToMap: () => {
      baseMap.addLayer(graticuleOutline);
      baseMap.addLayer(graticuleLayer);
    },
    removeFromMap: () => {
      baseMap.removeLayer(graticuleOutline);
      baseMap.removeLayer(graticuleLayer);
    },
    layers: {
      graticule: graticuleLayer,
      outline: graticuleOutline,
    },
  };
}; // addGraticule

// The weirdest bug; compile error unless this:
try {
  noop();
} catch (error) {
  console.error(`error`, error);
}
