import { IViewerBounds } from './IMikeVisualizerModels';
import MikeVisualizerStore from './store/MikeVisualizerStore';
const { getState } = MikeVisualizerStore;

/**
 * Reduce bounds by a factor between 0 and 1.
 * Reason: The VTK.js viewer adds a buffer to the bounds when using
 * renderer.resetCamera(bounds); https://kitware.github.io/vtk-js/api/Rendering_Core_Renderer.html
 * NB: Treats any coordinates as Cartesian, i.e. results not accurate for lat/lon.
 * TODO: joel; Is there a way to prevent VTK.js from doing that?
 *
 * @param options
 * @param options.bounds IViewerBounds.
 * @param options.factor number; 0-1.
 */
export const reduceBounds = ({
  bounds,
  factor = getReduceBoundsFactor(),
}: {
  bounds: IViewerBounds;
  factor?: number;
}): IViewerBounds | void => {
  if (isNaN(factor)) {
    return void 0;
  }
  if (typeof factor !== 'number') {
    factor = 0.2;
  }
  if (factor > 1 || factor < 0) {
    throw Error(`Value for factor must be 0-1 but was: ${factor}`);
  }
  // Destructure coordinates:
  const [xMin, xMax, yMin, yMax, zMin, zMax] = bounds;
  // Find length and delta for each coordinate:
  const deltaX = getCoordDelta(xMin, xMax, factor);
  const deltaY = getCoordDelta(yMin, yMax, factor);
  const deltaZ = getCoordDelta(zMin, zMax, factor);
  // Calculate new reduced coordinates:
  const newBounds = [
    xMin + deltaX,
    xMax - deltaX,
    yMin + deltaY,
    yMax - deltaY,
    zMin + deltaZ,
    zMax - deltaZ,
  ];
  return newBounds as IViewerBounds;
};

/**
 * Util function for `reduceBounds`
 */
const getCoordDelta = (someMin: number, someMax: number, factor: number) => {
  const someLength = someMax - someMin;
  const someDelta = someLength * factor;
  return someDelta;
};

/**
 * Util function for `reduceBounds`
 * Get factor for reducing bounds based on viewer size; try and error:
 */
const getReduceBoundsFactor = () => {
  const { container } = getState();
  let factor = 0.2;
  if (!container) {
    console.warn('no container');
    return factor;
  }
  // `container` doesn't always have a size, but the viewers inside do:
  const mapViewer = container.querySelector('#viewerBaseMap');
  if (!mapViewer) {
    console.warn('no mapViewer');
    return factor;
  }
  const { height, width } = mapViewer.getBoundingClientRect();
  factor = (1 - height / width) / 1.75;
  if (factor > 1){
    factor = 1
  }
  else if (factor < 0){
    factor = 0
  }
  return factor;
};
