import { takeLatest, put, call, select, takeEvery, all } from 'redux-saga/effects'
import ActionType from '../actions/ActionType'
import { delProject, delProjectDataset, getProject, getProjectDatasets, getProjectPath, getProjectSubprojects } from "../apis/metadataApi"
import { IAction } from '../actions/Action'
import { IResponse } from '../model/IResponse'
import { IGetProject, IGetProjectPath } from '../model/IGetProject';
import { IGetDataset } from "../model/IGetDataset"; 
import { setHasModelResults, setLoadingProject, setLoadingProjectContent, setProject, setProjectContent } from '../actions/projectContent'
import { addError } from '../actions/errors'
import { intl } from '../main'
import { AREAOFINTEREST, BACKGROUNDFOLDER, DATASETS, EVENTSCONFIG, EXTRACTION_ARCHIVE_EXTENSION, FILE, GEOJSON, MESH, MESHTYPE, OUTLINE, OUTPUTFILENAME, OUTPUTFILES, OUTPUTFOLDER, SHAPEFILE, SHORELINE } from '../shared/constants'
import { clearMapContent, clearResults, fastWaveConfigGet, pointLayerGet } from '../actions/mapContent'
import { jobsGet } from '../actions/job'
import { getEventsSelector, getIsMapReady, getLayerConfiguration, getProject as getProjectSelector, getSelectedEvents } from '../reducers/state'
import { addMessage } from '../actions/message'
import { setFolderPath } from '../actions/folders'
import { exportAndDownloadDataset } from '../actions/exportAndDownload'
import { setMeshType } from '../actions/createMesh'
import {  addLayers, getBackgroundLayer, loadBackgroundLayer, removeHiddenId, removeLayers, setLayerConfig, setLoadingLayerConfig, updateHiddenIds } from '../actions/legend'
import { backgroundLayers, ILayerConfig } from '../reducers/legend'
import { getLayerConfigFile } from '../helpers/fastwave'
import { isEqual } from 'lodash'
import { postEvents } from '../apis/backendApi'

export function* watchProject() {
  yield takeLatest(ActionType.GET_PROJECTANDPATH, handleGetProjectAndPath)
  yield takeLatest(ActionType.GET_PROJECTCONTENT, handleGetProjectContent)
  yield takeLatest(ActionType.DELETE_OUTPUT_FOLDER, handleDeleteOutputFolder)
  yield takeLatest(ActionType.GET_HAS_MODEL_RESULTS, handleCheckCanPublishResults)
  yield takeLatest(ActionType.DOWNLOAD_MODEL_RESULTS, handleDownloadResults)
  yield takeLatest(ActionType.DOWNLOAD_EVENTS, handleDownloadEvents)
  yield takeEvery(ActionType.DELETE_BACKGROUND, handleDeleteBackground)
  yield takeLatest(ActionType.LAYERCONFIG_GET, handleGetLayerConfig) 
  yield takeLatest(ActionType.GET_BACKGROUND_LAYERS, handleGetBackgroundLayers) 
  yield takeLatest(ActionType.LAYERCONFIG_UPDATE_HIDDEN_IDS, handleUpdateHiddenIds)
  yield takeEvery(ActionType.LAYERCONFIG_ADD_HIDDEN_ID, handleAddHiddenBackgroundId)
  yield takeEvery(ActionType.LAYERCONFIG_REMOVE_HIDDEN_ID, handleDeleteHiddenBackgroundId)
}

function* handleUpdateHiddenIds(action){
  const ids = action.data
  const layerConfig: ILayerConfig = yield select(getLayerConfiguration)
  if (!isEqual(ids, layerConfig.hiddenBackgroundLayers)){
    const updatedConfig = {...layerConfig, hiddenBackgroundLayers: ids}
    yield put(setLayerConfig(updatedConfig, true))
  }
}

function* handleAddHiddenBackgroundId(action){
  const id = action.data
  const layerConfig: ILayerConfig = yield select(getLayerConfiguration)
  const updatedConfig = {...layerConfig, hiddenBackgroundLayers: [...layerConfig.hiddenBackgroundLayers, id]}
  yield put(setLayerConfig(updatedConfig, true))
}

function* handleDeleteHiddenBackgroundId(action){
  const id = action.data
  const layerConfig: ILayerConfig = yield select(getLayerConfiguration)
  if (layerConfig.hiddenBackgroundLayers.includes(id)){
    const updatedConfig = {...layerConfig, hiddenBackgroundLayers: layerConfig.hiddenBackgroundLayers.filter((hiddenId: string) => hiddenId !== id )}
    yield put(setLayerConfig(updatedConfig, true))
  }
}

function* handleGetLayerConfig(action: IAction){
  yield put(setLoadingLayerConfig())
  const projectId = action.data   
  let datasets = new Array<IGetDataset>()
  try{
    const folders = yield call(getProjectSubprojects, projectId)  
    const backgroundFolder = folders.find((f: IGetProject) => f.name.toLowerCase() === BACKGROUNDFOLDER)
    if (backgroundFolder){
      datasets = yield call(getProjectDatasets, backgroundFolder.id)
    }
  }
  catch(error){
    console.log(error)
  }  
  const layerConfigDataset: IGetDataset | undefined = getLayerConfigFile(datasets) 
  
  if (layerConfigDataset !== undefined){
    const {name, id } = layerConfigDataset
    if (name && id){
      yield put(exportAndDownloadDataset(name,id,
      {
        importData: 
        {
          name: name,
          reader: "FileReader",
          writer: "FileWriter"
        }
      },
      DATASETS.LAYER_CONFIG 
      ))
    }
    else{
      yield put(setLoadingLayerConfig(false))
    }
  }
  else{
    const hiddenLayers = Array<string>()
    yield put(setLayerConfig({hiddenBackgroundLayers: hiddenLayers}))
    yield put(setLoadingLayerConfig(false))
    yield put(getBackgroundLayer(projectId, hiddenLayers))
  }    
}

function* handleDeleteBackground(action){
  const id = action.data
  try{
    yield call(delProjectDataset, id)
  }
  catch(error){
    console.log(error)
  }
  yield put(removeLayers(backgroundLayers, [id]))
  yield put(removeHiddenId(id))
}

function* handleDownloadEvents() {
  const project: IGetProject | null = yield select(getProjectSelector) 
  
  if (project){
    const projectId = project.id    
    const events: Array<string> = yield select(getEventsSelector) 
    const selectedEvents: Array<string> = yield select(getSelectedEvents)
    if (events.length !== selectedEvents.length){
      const sortedEvents = events.sort();
      const sortedSelEvents = selectedEvents.sort();
      if (!isEqual(sortedEvents, sortedSelEvents)){
        try{
          yield call(postEvents, projectId, selectedEvents)        
        }
        catch (error){
          console.log(error)
        }
      }  
    }
    
    let dataset = undefined;
    try{
      const folders = yield call(getProjectSubprojects, projectId)
      const outputFolder = folders.find((f: IGetProject) => f.name.toLowerCase() === OUTPUTFOLDER)
      if (outputFolder && outputFolder.id){
        const datasets = yield call(getProjectDatasets, outputFolder.id)    
        dataset = datasets.find((ds: IGetDataset) =>ds.name === EVENTSCONFIG)       
      } 
    }
    catch(error){
      console.log(error)
    }

    if (dataset !== undefined){
      yield put(
        exportAndDownloadDataset(dataset.name, dataset.id, 
          {importData: {name: dataset.name, reader: "FileReader",writer: "FileWriter"}}, 
          DATASETS.EVENTS, true
        )       
      )            
    }
  }  
}

function* handleDownloadResults(action){
  const fileExtension = action.data
  const project: IGetProject | null = yield select(getProjectSelector) 
  if (project && fileExtension){
    const projectId = project.id    
    let dataset = undefined;
    try{
      const folders = yield call(getProjectSubprojects, projectId)
      const outputFolder = folders.find((f: IGetProject) => f.name.toLowerCase() === OUTPUTFOLDER)
      if (outputFolder && outputFolder.id){
        const datasets = yield call(getProjectDatasets, outputFolder.id)    
        dataset = datasets.find((ds: IGetDataset) =>ds.name === OUTPUTFILENAME + fileExtension)       
      } 
    }
    catch(error){
      console.log(error)
    }

    if (dataset !== undefined){
      yield put(
        exportAndDownloadDataset(dataset.name, dataset.id, 
          {importData: {name: dataset.name, reader: "FileReader",writer: "FileWriter"}}, 
          DATASETS.RESULTS, true
        )       
      )            
    }
  }  
}

function* handleDeleteOutputFolder(){
  const project: IGetProject | null = yield select(getProjectSelector)
  if (project && project.capabilities && project.capabilities.canDeleteContent){
    const projectId = project.id   
    try{
      const folders = yield call(getProjectSubprojects, projectId)
      const outputFolder = folders.find((f: IGetProject) => f.name.toLowerCase() === OUTPUTFOLDER)
      if (outputFolder && outputFolder.id){
        yield call(delProject, outputFolder.id)
      }      
    }
    catch (error){
      console.log(error)
    }  
  }  
}

function* handleCheckCanPublishResults (action){
  const projectId = action.data  
  try{
    const folders = yield call(getProjectSubprojects, projectId)
    const outputFolder = folders.find((f: IGetProject) => f.name.toLowerCase() === OUTPUTFOLDER)
    if (outputFolder && outputFolder.id){
      const datasets = yield call(getProjectDatasets, outputFolder.id)        
      const outputDatasets = datasets.filter((d: IGetDataset) => OUTPUTFILES.includes(d.name))
      const hasModelResults = outputDatasets.length === OUTPUTFILES.length
      let updatedAt = "";
      if (hasModelResults){
        const firstDataset: IGetDataset = outputDatasets[0];
        updatedAt = firstDataset.updatedAt
      }
      yield put(setHasModelResults(hasModelResults, updatedAt))
    }      
  }
  catch (error){
    console.log(error)
  } 
}

function* handleGetProjectAndPath(action: IAction) {
  const projectId = action.data
  if (projectId){
    yield put(clearResults())
    yield put(setMeshType(MESHTYPE.UPLOAD))
    const mapIsReady: boolean = yield select(getIsMapReady)
    if (mapIsReady){
      yield put(clearMapContent())
    }   
    try{
      yield put(setLoadingProject())
      let project = null
      try{
        const response: IGetProject = yield call(getProject, projectId)
        if (response) {
          project = response   
        }
        else{
          yield put(addMessage(intl.formatMessage({id: 'project.noAccess'}) +  " " + projectId + ". " + intl.formatMessage({id: 'project.contactOwner'}))); 
        }
      }
      catch{
        yield put(addMessage(intl.formatMessage({id: 'project.noAccess'}) +  " " + projectId + ". " + intl.formatMessage({id: 'project.contactOwner'}))); 
        yield put(setProject(null, new Array<IGetProjectPath>() ))
      }     

      if (project){    
        let projectPath = Array<IGetProjectPath>()   
        if (!project.capabilities.canReadContent){
          yield put(addMessage(intl.formatMessage({id: 'project.noReadAccess'}) +  " " + projectId + ". " + intl.formatMessage({id: 'project.contactOwner'}))); 
        }
        else{
          
          try{
            projectPath = yield call(getProjectPath, project.id)        
          }
          catch (error){
            yield put(addError(error)); // Add error to global store (errors are shown in an error snackbar)
          }
          yield put(fastWaveConfigGet(project.id))
          yield put(jobsGet(project.id))
          yield put(pointLayerGet(project.id))          
        }
        yield put(setFolderPath(projectPath))
        yield put(setProject(project, projectPath))       
      }
    }catch (error) {
      yield put(addError(error))
    } 
  }
}

function* handleGetBackgroundLayers(action){
  const {projectId, hiddenLayerIds} = action.data
  if (projectId){   
    const folders = yield call(getProjectSubprojects, projectId)  
    const backgroundFolder = folders.find((f: IGetProject) => f.name.toLowerCase() === BACKGROUNDFOLDER)
    if (backgroundFolder){
      const datasets = yield call(getProjectDatasets, backgroundFolder.id)
      const excludeName = [AREAOFINTEREST, OUTLINE, SHORELINE]
      const backgroundDatasets = datasets.filter((ds: IGetDataset) => {            
        return !excludeName.includes(ds.name) && ds.datasetFormat.toLowerCase() === 'gis' 
      })
      if (backgroundDatasets.length > 0){
        const canDelete = backgroundFolder.capabilities && backgroundFolder.capabilities.canDeleteContent
        const legendLayers = backgroundDatasets.map((dataset: IGetDataset) => {
          return {groupTitle: backgroundLayers, title: dataset.name,id: dataset.id,visible: false,isTwoD: false,loaded: false, order: 1000, loading: false, canDelete: canDelete, canBeLoadedByUser: true}
        })
        yield put(addLayers(backgroundLayers, legendLayers) )
        const backgroundDatasetsIds = backgroundDatasets.map((dataset: IGetDataset) => dataset.id)
        const idsToLoad = backgroundDatasetsIds.filter((id: string) => !hiddenLayerIds.includes(id))
        if (idsToLoad.length > 0){
          yield all(idsToLoad.map((id: string) => put(loadBackgroundLayer(id, true))))
        }
        if (hiddenLayerIds.length > 0){
          const cleanedHiddenIds = hiddenLayerIds.filter((id: string) => backgroundDatasetsIds.includes(id))
          yield put(updateHiddenIds(cleanedHiddenIds))
        }
      }          
    }
  }  
}

function* handleGetProjectContent(action) {
  const { project, filter } = action.data
  if (project){
    try{

      yield put(setLoadingProjectContent())
      let projectParent = null
      if (project.parentProjectId){
        try{      
          const projectParentResponse: IResponse = yield call(getProject, project.parentProjectId)
          if ((projectParentResponse.status === 200 || projectParentResponse.status === 201) && projectParentResponse.data) {
            projectParent = projectParentResponse.data   
          }
        }
        catch (error){
          yield put(addError(error)); // Add error to global store (errors are shown in an error snackbar)
        }
      }
      let projectPath = Array<IGetProjectPath>()
      try{
        projectPath = yield call(getProjectPath, project.id)        
      }
      catch (error){
        yield put(addError(error)); // Add error to global store (errors are shown in an error snackbar)
      }

      const itemInPath = projectPath.find((path: IGetProjectPath) => path.id === project.id)
      const capabilities = itemInPath && itemInPath.capabilities ? itemInPath.capabilities : null     
      let datasets = []
      let folders = []
      // We should only query the backend for folder's content if it has respective capabilities      
      if (capabilities && capabilities.canListContent){
        
        
        try{
          datasets = yield call(getProjectDatasets, project.id)     
          folders = yield call(getProjectSubprojects, project.id)    
        }
        catch (error){
          yield put(addError(error)); // Add error to global store (errors are shown in an error snackbar)
        }
      }
   
      const filteredDatasets = filter === MESH ? datasets.filter((ds: IGetDataset) => {
        return ds.datasetFormat.toLowerCase() === MESH.toLowerCase()
      }) : filter === DATASETS.POINTS ? datasets.filter((ds: IGetDataset) => {
        return ds.datasetFormat.toLowerCase() === SHAPEFILE.toLowerCase() || ds.datasetFormat.toLowerCase() === GEOJSON.toLowerCase()
      }) : filter === DATASETS.BOUNDARYCONDITION ? datasets.filter((ds: IGetDataset) => {
        return ds.datasetFormat.toLowerCase() === FILE.toLowerCase() && ds.name.endsWith(EXTRACTION_ARCHIVE_EXTENSION)
      }) : (filter === DATASETS.OUTLINE || filter === DATASETS.AREAOFINTEREST) ? datasets.filter((ds: IGetDataset) => {
        const isGisvectorData = ds.datasetFormat.toLowerCase() === 'gis'
        const isPolygonData = ds.properties && ds.properties["GeometryType"] && ds.properties["GeometryType"].startsWith("Polygon")
        return isGisvectorData && isPolygonData        
      }) : (filter === DATASETS.OWN_SHORELINE) ? datasets.filter((ds: IGetDataset) => {
        const lineGeometries = ["LineString", "Polyline", "Polyline25D", "LineString25D"]
        const isGisvectorData = ds.datasetFormat.toLowerCase() === 'gis'
        const isPolylineData = ds.properties && lineGeometries.includes(ds.properties["GeometryType"])
        return isGisvectorData && isPolylineData        
      }): (filter === DATASETS.BATHYMETRY) ? datasets.filter((ds: IGetDataset) => {
        const formats = ['xyz', 'vtu']
        const correctFormat = formats.includes(ds.datasetFormat.toLowerCase())
        const originalFileName: string = ds.metadata && ds.metadata["OriginalFileName"] ? ds.metadata["OriginalFileName"] : ""
        const hasXYZExtension =  originalFileName && originalFileName.toLowerCase().endsWith(".xyz")
        return correctFormat && hasXYZExtension
      }) : (filter === DATASETS.BACKGROUND) ? datasets.filter((ds: IGetDataset) => {
        const excludeName = [AREAOFINTEREST, OUTLINE, SHORELINE]
        const isGisvectorData = !excludeName.includes(ds.name) && ds.datasetFormat.toLowerCase() === 'gis'       
        return isGisvectorData       
      }): datasets

      yield put(setProjectContent(filteredDatasets.concat(folders), project, projectParent, projectPath, capabilities))
    }
    catch (error) {
      yield put(addError(error))
    } 
  }
  else{   
    yield put(setProjectContent(Array<IGetDataset | IGetProject>(), null, null, Array<IGetProjectPath>(), null))
  }
}