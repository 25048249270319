/** @jsxImportSource @emotion/react */
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import ChartContainer from '../ChartContainer/ChartContainer';
import { IState } from '../../reducers';
import { useSelector } from 'react-redux';
import { IListItem } from '../../reducers/mapContent';
import { getJob } from '../../helpers/fastwave';
import { DEFAULT_RESULTING_DIMENSIONS, DEFAULT_SELECTED_NODES, JOBS } from '../../shared/constants';
import { IFastWaveConfig, IJob } from '../../model/IFastWaveConfig';
import { JOBS_IN_PROGRESS } from '../../model/IJobStatus';
import AdvancedSettingsForm from '../AdvancedSettings';
import { titleContainer, titleStyle, subTitleStyle, chartContainerStyle, centerSpinnerStyle } from '../../shared/styles';
import { useIntl } from 'react-intl';

  const QualityControl = () => {
    const intl = useIntl();
   
    const loadingCentralPoints: boolean  = useSelector(
      (state: IState) => state.mapContent.loadingCentralPoints
    ); 

    const selectedBoundaryOrForcing: IListItem | null = useSelector(
      (state: IState) => state.mapContent.selectedBoundaryOrForcing
    );

    const jobs: Array<IJob>  = useSelector(
      (state: IState) => state.job.jobs
    ); 

    const fastWaveConfig: IFastWaveConfig = useSelector(
      (state: IState) => state.mapContent.fastWaveConfig
    );  

    const totalExplainedVarianceRatio: number = useSelector(
      (state: IState) => state.mapContent.totalExplainedVarianceRatio
    )

    const loadingChartData: boolean  = useSelector(
      (state: IState) => state.mapContent.loadingChartData
    ); 

    const job = getJob(jobs, JOBS.MDA) 
    const isProcssing = job !== undefined && JOBS_IN_PROGRESS.includes(job.status.toLowerCase())

    const getMissingDataUI = () => {
      return (
        <div>
          <Typography variant="h5" css={titleStyle}>{intl.formatMessage({id: 'qualityControl.missingData.title'})}</Typography>
          <Typography variant="body2" css={subTitleStyle}>{intl.formatMessage({id: 'qualityControl.missingData.subTitle'})}</Typography>
        </div>
      )
    }

    const getProcessingDataUI = () => {
      return (
        <div>
          <Typography variant="h5" css={titleStyle}>{intl.formatMessage({id: 'qualityControl.processingData.title'})}</Typography>
          <Typography variant="body2" css={subTitleStyle}>{intl.formatMessage({id: 'qualityControl.processingData.subTitle'})}</Typography>
          <div css={centerSpinnerStyle}><CircularProgress/></div>
        </div>
      )
    }

    return (
      <div>   
        <div css={titleContainer}>
          <div>
            <Typography variant="h5" css={titleStyle}>{intl.formatMessage({id: 'advancedSettings.title'})}</Typography>
            <Typography variant="body2" css={subTitleStyle}>{intl.formatMessage({id: 'advancedSettings.subTitle'})}</Typography>
          </div>
        </div>   
    
          <div>
            <AdvancedSettingsForm 
              initialValues={{noModelRuns: fastWaveConfig.no_events ? fastWaveConfig.no_events.toString() : DEFAULT_SELECTED_NODES.toString(), desiredDimensions: fastWaveConfig.n_pca_components ? fastWaveConfig.n_pca_components.toString() : DEFAULT_RESULTING_DIMENSIONS.toString()}}
              noEvents={fastWaveConfig.no_events} 
              resultingDimensions={fastWaveConfig.n_pca_components} 
              totalExplainedVarianceRatio={totalExplainedVarianceRatio}
            />
            <div css={titleContainer}>
              <div>
                <Typography variant="h5" css={titleStyle}>{intl.formatMessage({id: 'qualityControl.title'})}</Typography>
                <Typography variant="body2" css={subTitleStyle}>{intl.formatMessage({id: 'qualityControl.subTitle'})}</Typography>
              </div>
            </div>
            {
              isProcssing || loadingCentralPoints || loadingChartData ? getProcessingDataUI() :              
              selectedBoundaryOrForcing ? 
                <div css={chartContainerStyle}>
                  <ChartContainer selectForTestRun={false}/> 
                </div>
              : getMissingDataUI() 
            }
          </div>
   
        
      </div> 
    )
  }

  export default QualityControl;