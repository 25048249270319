import { isPlainObject } from 'lodash-es';

import vtkEnhancedActor from './vtk-extensions/vtkEnhancedActor.js';
import vtkEnhancedDataReader from './vtk-extensions/vtkEnhancedDataReader.js';
import vtkSTLReader from '@kitware/vtk.js/IO/Geometry/STLReader';
import vtkOBJReader from '@kitware/vtk.js/IO/Misc/OBJReader';
import vtkMTLReader from '@kitware/vtk.js/IO/Misc/MTLReader';
import vtkPolyData from '@kitware/vtk.js/Common/DataModel/PolyData';

import {
  SUPPORTED_VTK_CLASSES,
  SUPPORTED_VTK_FORMATS,
  DATA_ARRAY_TYPES,
} from './MikeVisualizerConstants.js';

import MikeVisualizerStore from './store/MikeVisualizerStore.js';
import MikeVisualizerUtil from './MikeVisualizerUtil.js';
import MikeVisualizerCosmetic from './MikeVisualizerCosmetic.js';
import MikeVisualizerConverter from './converters/MikeVisualizerConverter.js';
import MikeVisualizerViewManager from './MikeVisualizerViewManager.js';

import {
  IDrawnDataGradientSettings,
  IThreeDRenderElement,
  IOBJTextureMap,
  IElementDataArray,
} from './IMikeVisualizerModels.js';
import { IRepresentation } from './models/IRepresentation.js';
import { getConfiguration } from './MikeVisualizerConfiguration.js';

const { getState, setState } = MikeVisualizerStore;
const { resetCameraToInitialBounds } = MikeVisualizerViewManager;
const {
  rendererReady,
  getDataArrays,
  getXmlAttributes,
  getFieldData,
  containsCellData,
  containsPointData,
} = MikeVisualizerUtil;
const {
  _getOpacity,
  setGradientToActor,
  setBasicColorToActor,
  setScaleToActor,
} = MikeVisualizerCosmetic;
const { convertVtiToVtpTriangles } = MikeVisualizerConverter;

const DEFAULT_VTI_ZVALUE_REPLACEMENT = 0; // Don't use negative Z values to force flat vtis to be just under all other elements. Else flat vti layers cannot be moved / rendered above vtp layers.

/**
 * This module allows 3d, vtk data to be added, updated or removed.
 *
 * @module MikeVisualizerIO
 * @version 1.0.0
 */

/**
 * Creates an actor from a vtk object and adds it to the currently instantiated renderer.
 *
 * @param { vtkData } parsedVtpData The parsed vtp data to be added to the renderer.
 * @param actorId The desired that should be given to the actor.
 * @param actorEdgeColor Rgba color array for the edge.
 * @param actorSurfaceColor Rgba color array for the surface.
 * @param representation The type of representation.
 * @param pointSize The size of points if representation is set to 'point'.
 * @param visible Is the actor hidden or visible.
 * @param applyGradient Tries to color as a gradient.
 * @param [gradientSettings] Optional. Gradient settings to be used if applying a gradient,  @see IDrawnDataGradientSettings.
 * @param [gradientAttributeName] Use this attribute to set a gradient color. By default the  first available attribute will be used.
 * @param [sendToBottom] Draws this at the bottom.
 *
 * @return { { actor: vtkActor, renderedElement: IThreeDRenderElement } | boolean } resulting actor & rendered element, corresponding to the data added to the renderer.
 *
 * @private
 */
const _addVtpToRenderer = (
  parsedVtpData,
  actorId: string,
  actorEdgeColor: Array<number> = getConfiguration().colors.default.edge,
  actorSurfaceColor: Array<number> = getConfiguration().colors.default.surface,
  representation: IRepresentation,
  pointSize: number = getConfiguration().pointSize,
  visible = true,
  applyGradient = false,
  gradientSettings?: IDrawnDataGradientSettings,
  gradientAttributeName?: string,
  sendToBottom?: boolean,
  zAttributeName?: string
) => {
  const [edgeR, edgeG, edgeB] = actorEdgeColor;
  const [surfaceR, surfaceG, surfaceB] = actorSurfaceColor;

  /**
   * 1. Create actor and patch missing data.
   */
  const { renderer, renderWindow, renderedElements, initialBounds, viewerZScale } = getState();

  /* TODO: data without points errors out when trying to getNumberOfValues, getBounds.
   * In there's data that doesn't have points, for example 'groups' -
   * or the so-called empty 'containers' that are parents to other data pieces that do contain geometry.
   *
   * For now, we mock the points as shown below. This is a workaround and will hopefully be fixed in future versions of vtk.js.
   */
  if (!parsedVtpData.getPoints().getData().length) {
    parsedVtpData.setPoints({
      getNumberOfValues: () => 0,
      getBounds: () => [0, 0, 0, 0, 0, 0],
    });
  }

  const actor = vtkEnhancedActor.newInstance({
    visibility: visible,
  });

  /**
   * 2. Set actor display properties.
   * This needs to be done before `renderer.addActor(actor);` is called.
   */
  // Set the actor id. It's used later for delete, update, color, etc.
  actor.setActorId(actorId);

  // Set default actor visual properties: representation, edge color & point size.
  actor.getProperty().set({
    ...representation,
    color: [surfaceR, surfaceG, surfaceB],
    edgeColor: [edgeR, edgeG, edgeB],
    pointSize,
  });

  // todo hevo For now changes in the opacity is only set in colorByGradient. Opacity should be handled and updated everywhere, including setBasicColor
  const opacity = _getOpacity(actorEdgeColor, actorSurfaceColor);
  actor.getProperty().setOpacity(opacity);

  /**
   * 3. Try to render actor & update state accordingly.
   * If it fails, there's no reason to update state.
   * Rendered element state is used across the MikeVisualizer to get metadata about actors.
   */
  try {
    if (sendToBottom) {
      renderer.addEnhancedActorToBottom(actor);
    } else {
      renderer.addActor(actor);
    }

    // Register rendered element in state.
    const renderedElement: IThreeDRenderElement = {
      id: actorId,
      edgeColor: actorEdgeColor,
      surfaceColor: actorSurfaceColor,
      opacity,
      representation,
      dataArrays: getDataArrays(parsedVtpData),
      pointSize,
      gradientSettings,
      gradientAttributeName,
      fieldData: getFieldData(parsedVtpData),
      zAttributeName,
    };

    setState({
      renderedElements: [...renderedElements, renderedElement],
    });

    /**
     * 4. Set actor colors & scale.
     * The color functions might update the rendered element state, based on their success in applying a gradient.
     */
    // Set zScale
    setScaleToActor(actor, 1, 1, viewerZScale);

    if (applyGradient) {
      setGradientToActor(
        actor,
        parsedVtpData,
        actorEdgeColor,
        actorSurfaceColor,
        gradientSettings,
        gradientAttributeName
      );
    } else {
      setBasicColorToActor(actor, parsedVtpData, actorEdgeColor, actorSurfaceColor);
    }

    /**
     * 5. Reset camera or render based on initialBounds.
     * It is expected that resetCameraToInitialBounds() renders, so there is no need to render twice.
     */
    if (!initialBounds) {
      // If there are no initial bounds, reset the camera at each step to center to geometry.
      resetCameraToInitialBounds();
    } else {
      // Only render if the camera won't be reset.
      renderWindow.render();
    }

    /**
     * 6. Recompute clipping range to make sure all props are visible.
     */
    renderer.resetCameraClippingRange();

    return {
      actor,
      renderedElement,
    };
  } catch (e) {
    console.error('Failed to add actor to renderer', e);
    return false;
  }
};

/**
 * Creates an actor from a vti parsed data object and adds it to the currently instantiated renderer.
 *
 * @param { vtkData } parsedVtiData The parsed vti data to be added to the renderer.
 * @param actorId The desired that should be given to the actor.
 * @param actorEdgeColor Rgba color array for the edge.
 * @param actorSurfaceColor Rgba color array for the surface.
 * @param representation The type of representation.
 * @param [pointSize] The size of points if representation is set to 'point'.
 * @param applyGradient Tries to color as a gradient.
 * @param [gradientSettings]  Optional. Gradient settings to be used if applying a gradient,  @see IDrawnDataGradientSettings.
 * @param [gradientAttributeName] Use this attribute to set a gradient color. By default the  first available attribute will be used.
 * @param [usePointsAsZIndex] Only relevant for vti files. Uses vti points as z-index.
 * @param [sendToBottom] Draws this at the bottom.
 *
 * @return { { actor: vtkActor, renderedElement: IThreeDRenderElement } | boolean } resulting actor & rendered element, corresponding to the data added to the renderer.
 *
 * @private
 */
const _addVtiToRendererAsVtp = (
  parsedVtiData,
  actorId: string,
  actorEdgeColor: Array<number> = getConfiguration().colors.default.edge,
  actorSurfaceColor: Array<number> = getConfiguration().colors.default.surface,
  representation: IRepresentation,
  pointSize?: number,
  applyGradient = false,
  gradientSettings?: IDrawnDataGradientSettings,
  gradientAttributeName?: string,
  usePointsAsZIndex = true,
  sendToBottom?: boolean,
  zAttributeName?: string
) => {
  const { hiddenElementIds } = getState();

  try {
    const { vtpPolyData, appliedZAttributeName } = convertVtiToVtpTriangles(
      parsedVtiData,
      usePointsAsZIndex,
      false,
      DEFAULT_VTI_ZVALUE_REPLACEMENT,
      zAttributeName
    );

    const result = self._addVtpToRenderer(
      vtpPolyData,
      actorId,
      actorEdgeColor,
      actorSurfaceColor,
      representation,
      pointSize,
      hiddenElementIds.indexOf(actorId) === -1,
      applyGradient,
      gradientSettings,
      gradientAttributeName,
      sendToBottom,
      // Default value:
      appliedZAttributeName
    );

    return result;
  } catch (e) {
    console.error('Failed to render vti as vtp', e);
    return false;
  }
};

/**
 * Parses an STL string & adds it to the renderer as a vtk object.
 * The result data will be contained by an actor.
 * It general, functions the same as `appendData`. It can be deleted, updated, etc.
 *
 * @param stlContent:
 * @param elementId
 * @param elementEdgeColor
 * @param elementSurfaceColor
 * @param representation
 */
const appendStlData = (
  stlContent: string | ArrayBuffer,
  elementId: string,
  elementEdgeColor: Array<number> = getConfiguration().colors.default.edge,
  elementSurfaceColor: Array<number> = getConfiguration().colors.default.surface,
  representation: IRepresentation
) => {
  const stlReader = vtkSTLReader.newInstance();
  stlReader.parse(stlContent);

  const parsedStlData = stlReader.getOutputData(0);

  self._addVtpToRenderer(
    parsedStlData,
    elementId,
    elementEdgeColor,
    elementSurfaceColor,
    representation
  );
};

/**
 * Parses an OBJ, MTL string & possible raster textures and adds it to the renderer as a vtk object.
 * The result data will be contained by one or more actors with the same id.
 * It general, functions the same as `appendData`. It can be deleted, updated, etc.
 *
 * @todo this is experimental. It is unclear if plain mtl textures (non-raster) are applied correctly, seems like vtk.js it skipping some properties.
 * @todo because the result of this can be multiple actors, other cosmetic methods might not work as expected.
 *
 * @param objString
 * @param mtlString
 * @param textureImageMap
 * @param elementId
 * @param elementEdgeColor
 * @param elementSurfaceColor
 * @param representation
 */
const appendObjData = (
  objString: string,
  mtlString: string,
  textureImageMap: Array<IOBJTextureMap> = [],
  elementId: string,
  elementEdgeColor: Array<number> = getConfiguration().colors.default.edge,
  elementSurfaceColor: Array<number> = getConfiguration().colors.default.surface,
  representation: IRepresentation
) => {
  const { renderWindow } = getState();

  const objReader = vtkOBJReader.newInstance({ splitMode: 'usemtl' });
  objReader.parseAsText(objString);

  // It is common that obj data contains multiple output ports (so, multiple self-contained objects).
  const size = objReader.getNumberOfOutputPorts();

  for (let i = 0; i < size; i++) {
    // NB: this needs some thought. At the moment, OBJs can have multiple actors with the same id associated with it.
    // It is also not currently possible to set individual representation or cosmetic properties.
    const parsedObjData = objReader.getOutputData(i);
    const result = self._addVtpToRenderer(
      parsedObjData,
      elementId,
      elementEdgeColor,
      elementSurfaceColor,
      representation
    );

    if (result) {
      // Apply the map textures to each resulting polydata object.
      const { actor } = result;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const mtlReader: any = vtkMTLReader.newInstance();

      // Textures in the mtl files are re-mapped based on the provided parameters.
      let mtlStringWithMappedRasterUrls = mtlString;

      textureImageMap.forEach(({ imgName, imgUrl }) => {
        const search = new RegExp(imgName, 'g');
        mtlStringWithMappedRasterUrls = mtlStringWithMappedRasterUrls.replace(search, imgUrl);
      });

      mtlReader.parseAsText(mtlStringWithMappedRasterUrls);
      Object.keys(mtlReader.get('materials').materials).forEach((key) =>
        mtlReader.applyMaterialToActor(key, actor)
      );
    }
  }

  renderWindow.render();
};

export interface IAppenDataArgs {
  vtkObjectOrXmlString: string;
  elementId: string;
  elementEdgeColor?: Array<number>;
  elementSurfaceColor?: Array<number>;
  representation: IRepresentation;
  pointSize?: number;
  applyGradient?: boolean;
  gradientSettings?: IDrawnDataGradientSettings;
  gradientAttributeName?: string;
  usePointsAsZIndex?: boolean;
  sendToBottom?: boolean;
  zAttributeName?: string;
}
export const appendDataV2 = (args: IAppenDataArgs) => {
  return appendData(
    args.vtkObjectOrXmlString,
    args.elementId,
    args.elementEdgeColor,
    args.elementSurfaceColor,
    args.representation,
    args.pointSize,
    args.applyGradient,
    args.gradientSettings,
    args.gradientAttributeName,
    args.usePointsAsZIndex,
    args.sendToBottom,
    args.zAttributeName
  );
};
export const updateDataV2 = (args: IAppenDataArgs) => {
  return updateData(
    args.vtkObjectOrXmlString,
    args.elementId,
    args.elementEdgeColor,
    args.elementSurfaceColor,
    args.representation,
    args.pointSize,
    args.applyGradient,
    args.gradientSettings,
    args.gradientAttributeName,
    args.usePointsAsZIndex,
    args.sendToBottom,
    args.zAttributeName
  );
};
/**
 * Uses a vtk object or parses a vtk XML string, then forwards it to the a method that can add it on the renderer as an actor, based on the data type.
 * The result data will be contained by an actor.
 * Supported xml string types: vti and vtp. Other formats, such as vtu, have to be parsed separately and passed on as vtk objects.
 *
 * @param vtkObjectOrXmlString An XML string or vtk object (`vtk({...})`).
 * @param elementId The desired id to give to the drawn data.
 * @param elementEdgeColor Rgba color array for the edge.
 * @param elementSurfaceColor Rgba color array for the surface.
 * @param representation The type of representation.
 * @param [pointSize] The size of points if representation is set to 'point'.
 * @param [applyGradient] Tries to color as a gradient.
 * @param [gradientSettings] Optional. Gradient settings if applying a gradient,  @see IDrawnDataGradientSettings.
 * @param [gradientAttributeName] Use this attribute to apply a gradient color. By default the first available attribute will be used.
 * @param [usePointsAsZIndex] Only relevant for vti files. Uses vti points as z-index.
 * @param [sendToBottom] Draws this at the bottom.
 *
 * @public
 */
const appendData = (
  vtkObjectOrXmlString: string,
  elementId: string,
  elementEdgeColor: Array<number> = getConfiguration().colors.default.edge,
  elementSurfaceColor: Array<number> = getConfiguration().colors.default.surface,
  representation: IRepresentation,
  pointSize?: number,
  applyGradient?: boolean,
  gradientSettings?: IDrawnDataGradientSettings,
  gradientAttributeName?: string,
  usePointsAsZIndex?: boolean,
  sendToBottom?: boolean,
  zAttributeName?: string
): IThreeDRenderElement | false => {
  if (!rendererReady()) {
    return false;
  }
  const { hiddenElementIds, renderWindow } = getState();

  try {
    const parsedVtkData = isPlainObject(vtkObjectOrXmlString)
      ? vtkObjectOrXmlString
      : self._parseVtkDataXml(vtkObjectOrXmlString);

    if (!parsedVtkData) {
      console.error(`Failed to parse vtkData, no data to append: ${elementId}`);
      return false;
    }

    const parsedVtkClass = parsedVtkData.getClassName();
    let renderResult;

    if (parsedVtkClass === SUPPORTED_VTK_CLASSES.VTP) {
      renderResult = self._addVtpToRenderer(
        parsedVtkData,
        elementId,
        elementEdgeColor,
        elementSurfaceColor,
        representation,
        pointSize,
        hiddenElementIds.indexOf(elementId) === -1,
        applyGradient,
        gradientSettings,
        gradientAttributeName,
        sendToBottom
      );
    } else if (parsedVtkClass === SUPPORTED_VTK_CLASSES.VTI) {
      renderResult = self._addVtiToRendererAsVtp(
        parsedVtkData,
        elementId,
        elementEdgeColor,
        elementSurfaceColor,
        representation,
        pointSize,
        applyGradient,
        gradientSettings,
        gradientAttributeName,
        usePointsAsZIndex,
        sendToBottom,
        zAttributeName
      );
    } else {
      throw new Error(
        `VTK format not supported. Supported classes: ${Object.keys(SUPPORTED_VTK_CLASSES)}`
      );
    }

    renderWindow.render();
    return renderResult.renderedElement;
  } catch (e) {
    console.error('Failed to append vtk xml string data', e);
    return false;
  }
};

const _parseVtkDataXml = (vtkXmlString) => {
  const domParser = new DOMParser();
  const vtkXmlData = domParser.parseFromString(vtkXmlString, 'application/xml');
  const vtkXmlNode = vtkXmlData.documentElement;

  if (vtkXmlNode.nodeName === 'parsererror') {
    console.error('Failed to parse VTK XML', vtkXmlNode);
    return false;
  }

  try {
    const vtkRootXmlAttributes = getXmlAttributes(vtkXmlNode);

    const vtkEnhancedReader = vtkEnhancedDataReader.newInstance();
    const { type, byte_order, header_type, compressor } = vtkRootXmlAttributes;

    vtkEnhancedReader.parseXML(vtkXmlData, type, compressor, byte_order, header_type);

    const parsedVtkData = vtkEnhancedReader.getOutputData(0);

    return parsedVtkData;
  } catch (e) {
    console.error('Failed to parse vtk xml string ', e);

    return false;
  }
};
/**
 * Updates data, looking up its actor by id and replacing it.
 *
 * @param vtkObjectOrXmlString An XML string or vtk object (`vtk({...})`).
 * @param elementId The id of the element to update.
 * @param elementEdgeColor Rgba color array for the edge.
 * @param elementSurfaceColor Rgba color array for the surface.
 * @param representation The type of representation.
 * @param [pointSize] The size of points if representation is set to 'point'.
 * @param [applyGradient] Tries to color as a gradient.
 * @param [gradientSettings]  Optional. Gradient settings if applying a gradient, @see IDrawnDataGradientSettings.
 * @param [gradientAttributeName] Optional. Use this attribute to apply a gradient color. By default the first available attribute will be used.
 * @param [usePointsAsZIndex] Only relevant for vti files. Uses vti points as z-index.
 * @param [sendToBottom] Draws this at the bottom.
 *
 * @public
 */
const updateData = (
  vtkObjectOrXmlString: string,
  elementId: string,
  elementEdgeColor: Array<number> = getConfiguration().colors.default.edge,
  elementSurfaceColor: Array<number> = getConfiguration().colors.default.surface,
  representation: IRepresentation,
  pointSize?: number,
  applyGradient?: boolean,
  gradientSettings?: IDrawnDataGradientSettings,
  gradientAttributeName?: string,
  usePointsAsZIndex?: boolean,
  sendToBottom?: boolean,
  zAttributeName?: string
): IThreeDRenderElement | false => {
  if (!rendererReady()) {
    return false;
  }

  try {
    self.deleteData(elementId);

    return self.appendData(
      vtkObjectOrXmlString,
      elementId,
      elementEdgeColor,
      elementSurfaceColor,
      representation,
      pointSize,
      applyGradient,
      gradientSettings,
      gradientAttributeName,
      usePointsAsZIndex,
      sendToBottom,
      zAttributeName
    );
  } catch (e) {
    console.error('Failed to update element data', e);
    return false;
  }
};

/**
 * Delete data, finding its containing actor and then deleting it.
 *
 * @param elementId
 *
 * @public
 */
const deleteData = (elementId: string) => {
  if (!rendererReady()) {
    return false;
  }
  const { renderer, renderWindow, renderedElements } = getState();

  try {
    renderer.removeEnhancedActorById(elementId);

    setState({
      renderedElements: [...(renderedElements || []).filter(({ id }) => id !== elementId)],
    });

    renderWindow.render();

    return true;
  } catch (e) {
    console.error('Failed to delete element data', e);
    return false;
  }
};

/**
 * Appends a <DataArray> with cell or pointData to existing data.
 * The size of the Array must match the count of the existing data's points.
 * NB: the resulting name of the data array is carried over from `<DataArray Name="...">`
 * *
 * @param elementId
 * @param vtkObjectOrXmlString  Expect the xml to be one of the supported formats (vti or vtp), or similar to vtu XML structure.
 * @param [usePointsAsZIndex] Only relevant for vti files. Uses vti points as z-index.
 * @param [sendToBottom] Draws this at the bottom.
 */
const appendDataArray = (
  elementId: string,
  vtkObjectOrXmlString: string,
  usePointsAsZIndex?: boolean,
  sendToBottom?: boolean
): IThreeDRenderElement | boolean => {
  if (!rendererReady()) {
    return false;
  }

  try {
    const { renderer } = getState();

    const actor = renderer.getActors().find((a) => a.getActorId() === elementId);

    if (!actor) {
      console.error(`Failed to append data array, element does not exist: ${elementId}`);
      return false;
    }

    const vtkData = isPlainObject(vtkObjectOrXmlString)
      ? vtkObjectOrXmlString
      : self._parseDataArraysXml(vtkObjectOrXmlString);

    if (!vtkData) {
      console.error(`Failed to append data array, no data array to append: ${elementId}`);
      return false;
    }

    // we update the entire element with the new vti data to ensure z-values also gets updated
    if (vtkData.getClassName() === SUPPORTED_VTK_CLASSES.VTI) {
      const { renderedElements } = getState();
      const renderedElement = renderedElements.find(
        ({ id }) => id === elementId
      ) as IThreeDRenderElement;

      const {
        edgeColor,
        surfaceColor,
        representation,
        pointSize,
        gradientApplied,
        gradientSettings,
        gradientAttributeName,
      } = renderedElement;

      self.updateData(
        vtkObjectOrXmlString,
        elementId,
        edgeColor || getConfiguration().colors.default.edge,
        surfaceColor || getConfiguration().colors.default.surface,
        representation,
        pointSize,
        gradientApplied,
        gradientSettings,
        gradientAttributeName,
        usePointsAsZIndex,
        sendToBottom
      );

      return self._syncSavedStateDataArrays(elementId);
    }

    const containsCellDataArrays = containsCellData(vtkData);
    const containsPointDataArrays = containsPointData(vtkData);

    // Update cell and point data of vtp's
    // todo hevo we might want to update fielddata as well at some point
    const inputData = actor.getMapper().getInputData();

    if (containsCellDataArrays) {
      const cellDataArrays = vtkData.getCellData().getArrays();

      cellDataArrays.forEach((cellDataArray) => {
        inputData.getCellData().addArray(cellDataArray);
      });
    }

    if (containsPointDataArrays) {
      const pointDataArrays = vtkData.getPointData().getArrays();

      pointDataArrays.forEach((pointDataArray) => {
        inputData.getPointData().addArray(pointDataArray);
      });
    }

    return self._syncSavedStateDataArrays(elementId);
  } catch (error) {
    console.error('Failed to append xml data array to existing actor', error);
    return false;
  }
};

/**
 * Updates a <DataArray> with new data. If the DataArray does not exists already, it will be appended.
 * The size of the Array must match the count of the existing data's cells.
 * NB: the resulting name of the data array is carried over from `<DataArray Name="...">`
 *
 * @param elementId
 * @param vtkObjectOrXmlString Expect the xml to be one of the supported formats (vti or vtp), or similar to vtu XML structure.
 * @param [usePointsAsZIndex] Only relevant for vti files. Uses vti points as z-index.
 * @param [sendToBottom] Draws this at the bottom.
 *
 */
const updateDataArray = (
  elementId: string,
  vtkObjectOrXmlString: string,
  usePointsAsZIndex?: boolean,
  sendToBottom?: boolean
): IThreeDRenderElement | boolean => {
  try {
    if (!rendererReady()) {
      return false;
    }

    const { renderer } = getState();

    const actor = renderer.getActors().find((a) => a.getActorId() === elementId);

    if (!actor) {
      console.error(`Failed to update data array, element does not exist: ${elementId}`);
      return false;
    }

    const vtkData = isPlainObject(vtkObjectOrXmlString)
      ? vtkObjectOrXmlString
      : self._parseDataArraysXml(vtkObjectOrXmlString);

    if (!vtkData) {
      console.error(`Failed to update dataarray, no data to be updated: ${elementId}`);
      return false;
    }

    // remove data, if exists already
    const inputData = actor.getMapper().getInputData();

    const shouldHandleVtiAsVtp =
      vtkData.getClassName() === SUPPORTED_VTK_CLASSES.VTI &&
      inputData.getClassName() === SUPPORTED_VTK_CLASSES.VTP;

    const containsCellDataArrays = containsCellData(vtkData);
    const containsPointDataArrays = containsPointData(vtkData);

    if (containsCellDataArrays) {
      const cellDataArrays = vtkData.getCellData().getArrays();

      cellDataArrays.forEach((cellDataArray) => {
        const attributeName = cellDataArray.getName();

        if (shouldHandleVtiAsVtp) {
          // cell data was added as point data
          inputData.getPointData().removeArray(attributeName);
        } else {
          inputData.getCellData().removeArray(attributeName);
        }
      });
    }

    if (containsPointDataArrays) {
      const pointDataArrays = vtkData.getPointData().getArrays();

      pointDataArrays.forEach((pointDataArray) => {
        const attributeName = pointDataArray.getName();
        inputData.getPointData().removeArray(attributeName);
      });
    }

    return self.appendDataArray(elementId, vtkData, usePointsAsZIndex, sendToBottom);
  } catch (error) {
    console.error('Failed to update xml point data to existing actor', error);
    return false;
  }
};

/**
 * Delete data array, finding its containing inputdata and then removing.
 *
 * @param elementId
 *
 * @public
 */
const removeDataArray = (elementId: string, attributeName: string) => {
  if (!rendererReady()) {
    return false;
  }
  const { renderer } = getState();

  try {
    const actor = renderer.getActors().find((a) => a.getActorId() === elementId);

    const renderedDataArrays = MikeVisualizerUtil.getDataArraysById(elementId);
    const renderedDataArray = renderedDataArrays.find(
      (arr) => arr.id === attributeName
    ) as IElementDataArray;

    if (!actor || !renderedDataArray) {
      console.error(`Failed to remove data array, element does not exist: ${elementId}`);
      return false;
    }

    const inputData = actor.getMapper().getInputData();

    const { type } = renderedDataArray;
    if (type === DATA_ARRAY_TYPES.CELLDATA) {
      inputData.getCellData().removeArray(attributeName);
    } else if (type === DATA_ARRAY_TYPES.POINTDATA) {
      inputData.getPointData().removeArray(attributeName);
    }

    self._syncSavedStateDataArrays(elementId);

    return true;
  } catch (e) {
    console.error('Failed to remove data array', e);
    return false;
  }
};

/**
 * Parses the vtkXmlString provided and picks the DataArray elements for cell and pointdata. Converts to vtk object.
 * Expect the xml to be one of the supported formats (vti or vtp), or similar to vtu XML structure.
 * @param vtkXmlString
 */
const _parseDataArraysXml = (vtkXmlString: string) => {
  try {
    const domParser = new DOMParser();
    const vtkXmlData = domParser.parseFromString(vtkXmlString, 'application/xml');
    const vtkXmlNode = vtkXmlData.documentElement;

    if (vtkXmlNode.nodeName === 'parsererror') {
      console.error('Failed to parse data array XML', vtkXmlNode);
      return false;
    }

    const rootXmlAttributes = getXmlAttributes(vtkXmlNode);
    const { type, compressor, byte_order, header_type } = rootXmlAttributes;

    if (Object.values(SUPPORTED_VTK_FORMATS).indexOf(type) !== -1) {
      return self._parseVtkDataXml(vtkXmlString);
    }

    // parse vtu XML structure as vtp assuming only one piece element
    try {
      const dataElement = vtkXmlNode.getElementsByTagName(type)[0];

      const piece = vtkXmlNode.getElementsByTagName('Piece')[0];

      if (!piece) {
        console.error('Failed to parse data array XML. Must contain one piece element', vtkXmlNode);
        return false;
      }

      const pieceXmlAttributes = getXmlAttributes(piece);
      const { NumberOfPoints, NumberOfCells } = pieceXmlAttributes;
      const fieldNumber = dataElement.getElementsByTagName('FieldData').length;

      const vtkDataInstance = vtkPolyData.newInstance();

      const vtkEnhancedReader = vtkEnhancedDataReader.newInstance();
      vtkEnhancedReader.processData(
        vtkDataInstance,
        piece,
        dataElement,
        0,
        compressor,
        byte_order,
        header_type,
        NumberOfPoints,
        NumberOfCells,
        fieldNumber
      );

      return vtkDataInstance;
    } catch (e) {
      console.error('Failed to parse vtu as vtp xml string ', e);

      return false;
    }
  } catch (error) {
    console.error('Failed to parse xml cell data', error);
  }
};

/**
 * Updates the data arrays in saved state for the rendered element given by elementId to match the current data
 * @param elementId
 */
const _syncSavedStateDataArrays = (elementId: string): IThreeDRenderElement | boolean => {
  if (!rendererReady()) {
    return false;
  }
  const { renderer, renderedElements } = getState();

  const actor = renderer.getActors().find((a) => a.getActorId() === elementId);

  // Update state with information regarding the dataArrays.
  const renderedElementIndex = renderedElements.findIndex(({ id }) => id === elementId);
  if (!actor || renderedElementIndex === -1) {
    return false;
  }

  // get the current dataArrays
  const appendedDataArrays = getDataArrays(actor.getMapper().getInputData());

  const renderedElement: IThreeDRenderElement = {
    ...renderedElements[renderedElementIndex],
    dataArrays: appendedDataArrays,
  };

  setState({
    renderedElements: [
      ...renderedElements.slice(0, renderedElementIndex),
      renderedElement,
      ...renderedElements.slice(renderedElementIndex + 1),
    ],
  });

  return renderedElement;
};

const self = {
  _addVtpToRenderer,
  _addVtiToRendererAsVtp,
  _parseDataArraysXml,
  _parseVtkDataXml,
  _syncSavedStateDataArrays,

  appendStlData,
  appendObjData,
  appendData,
  appendDataV2,
  updateData,
  updateDataV2,
  deleteData,

  appendDataArray,
  updateDataArray,
  removeDataArray, 
};

export default self;
