/** @jsxImportSource @emotion/react */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { css } from '@emotion/react';

import {
  Grid,
  IconButton,
  DialogTitle as MuiDialogTitle,
  DialogContent as MuiDialogContent,
  DialogActions as MuiDialogActions,
} from '@mui/material';

import CloseIcon from '@mui/icons-material/Clear';

import './mike-sliding-panel.css';
import mikeSharedTheme from '../../styles/mikeSharedTheme';

interface IProps {
  titleArea: any;
  contentArea: any;
  actionsArea: any;
  isOpen: boolean;
  position: 'left' | 'right';
  noGrayOverlay: boolean;
  onClose: () => void;
}


const panelTitleStyle = css`
    padding: ${mikeSharedTheme.spacing(1)};
`;
  const panelActionsStyle = css`
    padding: ${mikeSharedTheme.spacing(2)};
`;
  const titleBarStyle = css`
    display: flex;
`;
  const titleHeadingsStyle = css`
    padding: ${mikeSharedTheme.spacing(2, 2, 0, 2)};
    flex: 1;
`;
  const closeButtonStyle = css`
    color: ${mikeSharedTheme.palette.grey[500]};
`;


/**
 * @name MikeSlidingPanel
 * @summary A modal, meant to be temporary shown during creation of data (of any kind). Similar to the MikeSidePanel. However, this component should not be permanently shown.
 */
const MikeSlidingPanel = (props: IProps) => {
  const GetPositionPostfix = (pos: string) => {
    const safePos = pos.toLowerCase();
    switch (safePos) {
      case 'left':
        return safePos;
      default:
        return 'right';
    }
  }
  
    const { titleArea, contentArea, actionsArea } = props;
    const postfix = GetPositionPostfix(props.position);
    const overlay = props.noGrayOverlay ? ' sliding-panel--noOverlay' : '';

    return (
      <div className={'fader fade--' + (props.isOpen ? 'in' : 'out')}>
        <div className={'sliding-panel  sliding-panel--' + postfix + overlay}>
          <div className={'panel-content  panel-content--' + postfix}>
            {props.isOpen && (
              <MuiDialogTitle css={panelTitleStyle}>
                <Grid
                  container
                  key="titlebar"
                  direction="row"
                  justifyContent="space-between"
                  alignItems="flex-start"
                  css={titleBarStyle}
                >
                  <Grid key="headings" css={titleHeadingsStyle}>
                    {titleArea}
                  </Grid>
                  <Grid key="button" css={closeButtonStyle}>
                    <IconButton aria-label="Close" onClick={props.onClose}>
                      <CloseIcon />
                    </IconButton>
                  </Grid>
                </Grid>
              </MuiDialogTitle>
            )}
            {props.isOpen && <MuiDialogContent>{contentArea}</MuiDialogContent>}
            {props.isOpen && (
              <MuiDialogActions css={panelActionsStyle}>{actionsArea}</MuiDialogActions>
            )}
          </div>
        </div>
      </div>
    
    )
}

  


export default MikeSlidingPanel;
