/* eslint-disable @typescript-eslint/no-explicit-any */
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React, { useEffect, useCallback, useState, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { Typography } from '@mui/material';
import { IState } from '../../reducers'
import { IGetProject, IGetProjectPath } from '../../model/IGetProject';
import FolderTable from './FolderTable';
import { useNavigate, useParams } from 'react-router-dom'
import { getFolders, setFoldersPagination, setFoldersRowsPerPage, setFoldersNamePrefix, setFoldersSort, setCreateFolderPanelOpen, deleteFolders } from '../../actions/folders'
import SearchInput from '../SearchInput'
import { Link } from 'react-router-dom'
import CreateFolderForm from '../CreateFolderForm';
import { useIntl } from 'react-intl';
import Plus from '../../icons/Plus.svg?react';
import PlayFilled from '../../icons/PlayFilled.svg?react';
import MikeDialog from '../DialogComponents/MikeDialog';
import mikeSharedTheme, { mikePalette } from '../../styles/mikeSharedTheme';
import MikeSlidingPanel from '../mike-sliding-panel';
import MikeButton from '../mike-button';
import { iconSecondaryStyle, iconWhiteStyle } from '../../styles/iconStyles';


const appBarSpacerStyle = css`
      height: ${mikeSharedTheme.spacing(4)};
      background-color: ${mikeSharedTheme.palette.lightGrey?.main};
 `;
    const tableTopHeaderStyle = css` 
      position: sticky;
      top: 0;    
      z-index: 1;
      background-color: ${mikeSharedTheme.palette.lightGrey?.main};
 `;
    const titleStyle = css`
      padding-left: ${mikeSharedTheme.spacing(4)};
      padding-top: ${mikeSharedTheme.spacing(0)};
      padding-bottom: ${mikeSharedTheme.spacing(2)};
  `;
    const flexStyle = css`    
      display: flex;
      justify-content: space-between;
      align-items: center;  
      width: 99vw;   
      padding-left: ${mikeSharedTheme.spacing(4)};
      padding-bottom: ${mikeSharedTheme.spacing(5)};
      padding-right: ${mikeSharedTheme.spacing(4)};
`;
    const buttonStyle = css`     
      border: 2px solid ${mikePalette.secondary.main};
      color: mikePalette.background.default;
      &[disabled]: {
        color: ${mikePalette.background.paper};
        opacity: 0.5;
        cursor: not-allowed;
        border: 2px solid ${mikePalette.secondary.light};
      };
    `;
    const buttonPaddingStyle = css`
      padding-left: ${mikeSharedTheme.spacing(1)};
 `;
    const linkStyle = css`
      text-decoration: none;
      display: content;
`;
    const buttonsContainerStyle = css`      
      display: flex;      
   `; 


const Folders = () => {   
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const intl = useIntl();

  useEffect(() => {
    if (id){
      dispatch(setFoldersNamePrefix('')) 
      dispatch(getFolders(id, 0, 25, 'createdAt', 'desc', ''))
    }
   }, [dispatch, id]);

   const projects: IGetProject[] = useSelector(
    (state: IState) => state.folders.folders
  ); 
  
  const sortBy: string = useSelector(
    (state: IState) => state.folders.sortBy
  );  

  const sortOrder: 'asc' | 'desc' = useSelector(
    (state: IState) => state.folders.sortOrder
  );  

  const rowsPerPage: number = useSelector(
    (state: IState) => state.folders.rowsPerPage
  );

  const namePrefix: string = useSelector(
    (state: IState) => state.folders.namePrefix
  );

  const loading: boolean = useSelector(
    (state: IState) => state.folders.loading
  ); 

  const page: number = useSelector(
    (state: IState) => state.folders.page
  ); 

  const totalCount: number = useSelector(
    (state: IState) => state.folders.totalCount
  ); 

  const createFolderPanelIsOpen: boolean = useSelector(
    (state: IState) => state.folders.createFolderPanelIsOpen
  ); 

  const projectPath: Array<IGetProjectPath> = useSelector(
    (state: IState) => state.folders.projectPath
  ); 

  const canEditFolder = useMemo(() => {
    if (projectPath && projectPath.length > 0){
      const itemInPath = projectPath.find((path: IGetProjectPath) => path.id === id)
      if (itemInPath !== undefined){
        if (!itemInPath.isDeleted && itemInPath.capabilities && itemInPath.capabilities.canUpdateContent){
          return true
        }
      }
    }
    return false
  }, [projectPath, id])
  
  const handleSelect = useCallback((selectedProject: IGetProject) => {
    navigate('/project/' + selectedProject.id);
  }, [navigate])

  const handleRequestSort = useCallback((orderBy: string | ((item: any) => string | number), order: 'asc' | 'desc') => {   
    dispatch(setFoldersSort(orderBy.toString(), order)) 
    dispatch(setFoldersPagination(0))   
  }, [dispatch])

  const handleChangePage = useCallback((newPage: number) => { 
     dispatch(setFoldersPagination(newPage))
  }, [dispatch])

  const handleChangeRowsPerPage = useCallback((newRowsPerPage: number) => {  
    dispatch(setFoldersRowsPerPage(newRowsPerPage))
  }, [dispatch])

  const [topOffset, setTopOffset] = useState(0)
  const measuredRef = useCallback(node => {
    if (node !== null) {
      setTopOffset(node.getBoundingClientRect().height)
    }
  }, [])

  const [selectedRows, setSelectedRows] = useState(new Array<IGetProject>());
  const [projectsToBeDeleted, setProjectsToBeDeleted] = useState(new Array<IGetProject>());
  const [projectToBeEdited, setProjectToBeEdited] = useState<IGetProject | null>(null);
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);

  const onEditProject =  useCallback((editProject: IGetProject) => {   
    if (editProject.capabilities.canEdit) {
      setProjectToBeEdited(editProject)
      dispatch(setCreateFolderPanelOpen(true))   
    }
  }, [dispatch])
  
  const onDeleteOneProject = React.useCallback(
    (deleteProject: IGetProject) => {
      if (deleteProject.capabilities.canDelete) {
        setProjectsToBeDeleted([deleteProject])
        setDeleteConfirmationOpen(true)
      }
    },
    [setDeleteConfirmationOpen, setProjectsToBeDeleted]
  )

  const onCancelDeleteProjects = () => {
    setProjectsToBeDeleted(new Array<IGetProject>())
    setDeleteConfirmationOpen(false)
  }

  const onOkDeleteProjects = React.useCallback(() => {
    const ids = projectsToBeDeleted.filter((project: IGetProject) => project.capabilities.canDelete).map((project: IGetProject) => project.id)
    dispatch(deleteFolders(ids))
    setProjectsToBeDeleted(new Array<IGetProject>())
    setDeleteConfirmationOpen(false)
  }, [dispatch, projectsToBeDeleted])

  const onSelectionChange = (selectedItems: Array<IGetProject>) => {
    setSelectedRows(selectedItems)
  };

  const handleSetFilter = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {   
    dispatch(setFoldersNamePrefix(e.target.value))     
    dispatch(setFoldersPagination(0))
  }, [dispatch]);

  const handleClearFilter = useCallback(() => { 
    dispatch(setFoldersNamePrefix('')) 
    dispatch(setFoldersPagination(0))
  }, [dispatch]);

  const onShowCreateFolder = useCallback((show?: boolean) => { 
    setProjectToBeEdited(null)
    dispatch(setCreateFolderPanelOpen(show))    
  }, [dispatch]);

  return (
   <>
    <div css={tableTopHeaderStyle} ref={measuredRef}>
      <div css={appBarSpacerStyle} />
      <Typography  css={titleStyle} variant={'h1'}>{"Subfolders"}</Typography>
      <div css={flexStyle}>
        <SearchInput        
          value={namePrefix}
          onChangeValue={handleSetFilter}
          onResetValue={handleClearFilter}
        /> 
        <div css={buttonsContainerStyle}>          
          <Link to={canEditFolder ? `/project/${id}/initialselection` : ""} css={linkStyle}>
            <MikeButton variant="contained" css={buttonStyle} disabled={!canEditFolder}>
              <PlayFilled css={iconWhiteStyle(!canEditFolder)}/>
              {intl.formatMessage({ id: 'app.goToFWE'})}
            </MikeButton>  
          </Link>
          <div css={buttonPaddingStyle}>
            <MikeButton variant="outlined" color="secondary" disabled={!canEditFolder} onClick={() => onShowCreateFolder()}>
              <Plus css={iconSecondaryStyle(!canEditFolder)} />
              {intl.formatMessage({ id: 'createFolder.title'})}
            </MikeButton>
          </div> 
        </div>       
      </div>  
    </div>
    <FolderTable     
      data={projects}
      loading={loading}
      onChangePage={handleChangePage}
      onChangeRowsPerPage={handleChangeRowsPerPage}
      onItemClick={handleSelect}
      onHandleRequestSort={handleRequestSort}
      order={sortOrder}
      orderBy={sortBy}
      page={page}
      rowsPerPage={rowsPerPage}
      topOffset={topOffset}
      selectable={false}
      totalCount={totalCount}
      pagination={totalCount > 10}
      notSortableColumns={["description"]}
      onSelectionChange={onSelectionChange}
      selectedItems={selectedRows}
      onDeleteOneProject={onDeleteOneProject}
      onEdit={onEditProject}
    />
    <MikeDialog 
      open={deleteConfirmationOpen} 
      onCancel={onCancelDeleteProjects} 
      onOk={onOkDeleteProjects}
      dialogTitle={intl.formatMessage({id: 'warnings.pleaseConfirm'})}
      contentTitle={intl.formatMessage({id: projectsToBeDeleted.length === 1 ? 'project.deleteOneConfirmation' : 'project.deleteManyConfirmation'})}
      message={''}    
      okButtonLabel={intl.formatMessage({id: 'project.delete'})}
    />
    <MikeSlidingPanel
      position="right"
      isOpen={createFolderPanelIsOpen}
      onClose={() => onShowCreateFolder(false)}
      titleArea={intl.formatMessage({id: projectToBeEdited ? 'editFolder.title' : 'createFolder.title'})}
      contentArea={<CreateFolderForm project={projectToBeEdited}/>}
      actionsArea={null}
      noGrayOverlay={false}
    />
  </>
  )
}

export default Folders