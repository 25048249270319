/* eslint-disable @typescript-eslint/no-explicit-any */
import { parseError } from '../helpers/errors'
import ActionType from "./ActionType";

export const uploadFile = (id: string, fileName: string, obj: any, isFile=true, update=false) => ({
  type: ActionType.UPLOAD_FILE,
  data: {id: id, fileName: fileName, obj: obj, isFile: isFile, update: update } 
})

export const uploadStart = id => ({
  type: ActionType.UPLOAD_START,
  data: id
})


export const updateProgress = (id, progress, fileName) => ({
  type: ActionType.UPDATE_PROGRESS,
  data: {id: id,  progress: progress,  fileName: fileName}
})


export const uploadComplete = (id, fileUrl, size=0, lastModified="") => ({
  type: ActionType.UPLOAD_COMPLETE,
  data: {id: id, fileUrl: fileUrl, size: size, lastModified: lastModified}
})


export const uploadFail = (id, error) => ({
  type: ActionType.UPLOAD_FAIL,
  data: {id: id,  error: parseError(error, { id })}
})

export const REMOVE_UPLOAD = 'UPLOADS/REMOVE_UPLOAD'
export const removeUpload = id => ({
  type: REMOVE_UPLOAD,
  data: id
})
