import { createTheme, Palette, PaletteColor, Theme } from '@mui/material/styles';
import { MIKE_COLORS } from './mike-colors';
import 'typeface-roboto';

export const SPACING = 8;
export const FONTSIZE = 14;
export const FONTFAMILY = [
  'Roboto',
  '-apple-system',
  'BlinkMacSystemFont',
  'Arial',
  'sans-serif',
].join(',');

export interface IMikePalette extends Palette {
  ultimate?: PaletteColor;
  darkGrey?: PaletteColor;
  mediumGrey?: PaletteColor;
  lightGrey?: PaletteColor;  
}

export interface IMikeTheme extends Theme {
  palette: IMikePalette;
}

const defaultTheme = createTheme();

export const mikePalette: IMikePalette = {  
    ...defaultTheme.palette,
    primary: {
      main: MIKE_COLORS.BRANDBLUE_DEFAULT,
      dark: MIKE_COLORS.BRANDBLUE_DARK,
      light: MIKE_COLORS.BRANDBLUE_LIGHT,
      contrastText: MIKE_COLORS.WHITE,
    },
    secondary: {
      main: MIKE_COLORS.ACTIONBLUE_DEFAULT,
      dark: MIKE_COLORS.ACTIONBLUE_DARK,
      light: MIKE_COLORS.ACTIONBLUE_LIGHT,
      contrastText: MIKE_COLORS.WHITE,
    },
    error: {
      main: MIKE_COLORS.PINK_DEFAULT,
      dark: MIKE_COLORS.PINK_DARK,
      light: MIKE_COLORS.PINK_LIGHT,
      contrastText: MIKE_COLORS.WHITE,
    },
    darkGrey: {
      main: MIKE_COLORS.DARKGREY_DEFAULT,
      dark: MIKE_COLORS.DARKGREY_DARK,
      light: MIKE_COLORS.DARKGREY_LIGHT,
      contrastText: MIKE_COLORS.BLACK,
    },
    mediumGrey: {
      main: MIKE_COLORS.MEDIUMGREY_DEFAULT,
      dark: MIKE_COLORS.MEDIUMGREY_DARK,
      light: MIKE_COLORS.MEDIUMGREY_LIGHT,
      contrastText: MIKE_COLORS.BLACK,
    },
    lightGrey: {
      main: MIKE_COLORS.XLIGHTGREY,
      contrastText: MIKE_COLORS.BLACK,
      dark: MIKE_COLORS.MEDIUMGREY_LIGHT,
      light: MIKE_COLORS.XLIGHTGREY,
    },
    text: {
      primary: MIKE_COLORS.BRANDBLUE_DEFAULT,
      secondary: MIKE_COLORS.DARKGREY_DEFAULT,
      disabled: MIKE_COLORS.MEDIUMGREY_DEFAULT,
      // hint: MIKE_COLORS.ACTIONBLUE_DEFAULT,
    },
    background: {
      default: MIKE_COLORS.XLIGHTGREY,
      paper: MIKE_COLORS.WHITE,
    },

    divider: MIKE_COLORS.MEDIUMGREY_DEFAULT,   

    // Custom 'intention' objects
    ultimate: {
      main: MIKE_COLORS.GREEN_DEFAULT,
      dark: MIKE_COLORS.GREEN_DARK,
      light: MIKE_COLORS.GREEN_LIGHT,
      contrastText: MIKE_COLORS.WHITE,
    },
  
};



const muiRadioStyles = {
  background: 'transparent',
  height: '20px',
  width: '20px',
  margin: '-4px 0px',
  '& svg:nth-of-type(1)': {
    transform: 'scale(0.9)',
  },
  '& svg:nth-of-type(1) > path': {
    fill: mikePalette.darkGrey?.main,
  },
  '&.Mui-disabled': {
    '& svg:nth-of-type(1) > path': {
      fill: mikePalette.darkGrey?.light,
    },
  },
  /*  JSS `:after` won't apply to target unless there is a valid value for content: */
  '&:after': {
    content: 'open-quote',
    color: mikePalette.mediumGrey?.light,
    background: mikePalette.mediumGrey?.light,
    display: 'block',
    height: '20px',
    width: '20px',
    marginLeft: '-20px',
    borderRadius: '10px',
  },
};

export const mikeSharedTheme: IMikeTheme = createTheme({
  typography: {
    htmlFontSize: 16,
    fontSize: FONTSIZE,
    fontFamily: FONTFAMILY,
  },
  palette: mikePalette,
  shape: {
    borderRadius: 4, // default mui: 4
  },
  components: {
    MuiCssBaseline: {
      styleOverrides:{
        '*::-webkit-scrollbar': {
          width: '12px',
          height: '8px',
          backgroundColor: MIKE_COLORS.ACTIONBLUE_LIGHT,
          borderRadius: '100px',
        },
        '*::-webkit-scrollbar:hover': {
          backgroundColor: MIKE_COLORS.ACTIONBLUE_LIGHT,
        },
        '*::-webkit-scrollbar-thumb': {
          background: MIKE_COLORS.ACTIONBLUE_DEFAULT,
          webkitBorderRadius: '100px'
        },
        '*::-webkit-scrollbar-thumb:active': {
          background: MIKE_COLORS.ACTIONBLUE_DEFAULT,
          webkitBorderRadius: '100px',
        }
      }
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          color: mikePalette.primary.main,
        },
        h1: {
          fontSize: '2rem', // 32px
          lineHeight: 1.25, // 40px - default mui:1
          fontWeight: 'normal',
        },
        h2: {
          fontSize: '1.5rem', // 24px
          lineHeight: 1.33, // 32px  - default mui:1
          fontWeight: 'normal',
        },
        h3: {
          fontSize: '1.25rem', // 20px
          lineHeight: 1.2, // 24px  - default mui:1.04
          fontWeight: 'bold',
        },
        h4: {
          fontSize: '1rem', // 16px
          lineHeight: 1.25, // 20px  - default mui:1.17
          fontWeight: 'bold',
        },
        h5: {
          // not defined in DHI guidelines
          fontSize: '1rem', // 16px
          lineHeight: 1, // 16px  - default mui:1.33
          fontWeight: 'bold',
        },
        h6: {
          // Used by mui for DialogTitles
          fontSize: '1.25rem', // 20 px
          lineHeight: 1.2, // 24px  - default mui:1.6
          fontWeight: 'bold',
        },
        subtitle1: {}, // default mui: 1rem / 1.75
        subtitle2: {}, // default mui: 0.875rem / 1.57
        body1: {
          // In Figma: Body Text
          // default mui: 1rem / 1.5.
          fontSize: '1rem', // 16px
          lineHeight: 1.374, // 22px
        },
        body2: {
          // In Figma: Body Text (S)
          // default mui: 0.875rem / 1.43
          fontSize: '0.875rem', // 14px
          lineHeight: 1.286, // 18px
        },
        button: {}, // default mui: 0.875rem / 1.75 / UPPERCASE
        caption: {}, // default mui: 0.75rem / 1.66
        overline: {}, // default mui: 0.75rem / 2.66 / UPPERCASE
      }
    },

    MuiAppBar: {
      styleOverrides: {
        colorPrimary: {
          backgroundColor: MIKE_COLORS.MEDIUMGREY_LIGHT,
          height: '60px',
          borderBottom: '4px solid #DBE4E9', // MEDIUMGREY
        },
      },
      defaultProps: {
        position: 'fixed',
        elevation: 0,
      },
    },    
    MuiButton: {
      styleOverrides: {
        root: {          
          fontWeight: 500,
          letterSpacing: 0.1,
          textTransform: 'none',
          height: '3rem', // 48px
          '&:disabled': {
            height: '3rem', // 48px
          }, 
          variants: [
            {
              props: { variant: 'contained'  },
              style: {
                minWidth: 156,
              },
            },
            {
              props: { variant: 'outlined'  },
              style: {
                minWidth: 156,
                border: '2px solid',
              },
            },
            {
              props: { color: 'primary', variant: 'contained'  },
              style: {
                '&:disabled': {
                  backgroundColor: mikePalette.primary.light,
                  color: '#fff',
                },
              },
            },
            {
              props: { color: 'primary', variant: 'outlined'  },
              style: {
                color: mikePalette.primary.main,
                border: '2px solid',
                '&:hover': {
                  border: '2px solid',
                  borderColor: mikePalette.primary.dark,
                },
                '&:disabled': {
                  border: '2px solid',
                  color: mikePalette.primary.light,
                  borderColor: mikePalette.primary.light,
                },
              },
            },
            {
              props: { color: 'secondary', variant: 'outlined'  },
              style: {
                color: mikePalette.secondary.main,
                border: '2px solid',
                '&:hover': {
                  border: '2px solid',
                  borderColor: mikePalette.secondary.dark,
                },
                '&:disabled': {
                  border: '2px solid',
                  color: mikePalette.secondary.light,
                  borderColor: mikePalette.secondary.light,
                },
              },
            },
            {
              props: { color: 'primary', variant: 'text'  },
              style: {
                color: mikePalette.primary.main,
                '&:disabled': {
                  color: mikePalette.primary.light,
                },
              },
            },
            {
              props: { color: 'secondary', variant: 'text'  },
              style: {
                color: mikePalette.secondary.main,
                '&:disabled': {
                  color: mikePalette.secondary.light,
                },
              },
            },
            {
              props: { color: 'secondary', variant: 'contained'  },
              style: {
                backgroundColor: mikePalette.secondary.main,
                color: mikePalette.secondary.contrastText,
                '&:disabled': {
                  backgroundColor: mikePalette.secondary.light,
                  color: '#fff',
                },
              },
            },
          ],           
        },
        sizeLarge: {
          minWidth: 328,
        },
        sizeSmall: {
          height: '2.5rem', // 40px
          minWidth: 0,
          padding: '0 1rem', // 16px
        },
       
       /*  outlined: {
          minWidth: 156,
          border: '2px solid',
        },
        contained: {
          minWidth: 156,
        },     
        containedPrimary: {
          '&:disabled': {
            backgroundColor: mikePalette.primary.light,
            color: '#fff',
          },
        },
        containedSecondary: {          
          '&:disabled': {
            backgroundColor: mikePalette.secondary.light,
            color: '#fff',
          },
        }, 
        outlinedPrimary: {         
          border: '2px solid',
          '&:hover': {
            border: '2px solid',
            borderColor: mikePalette.primary.dark,
          },
          '&:disabled': {
            border: '2px solid',
            color: mikePalette.primary.light,
            borderColor: mikePalette.primary.light,
          },
        },
        outlinedSecondary: {         
          border: '2px solid',
          '&:hover': {
            border: '2px solid',
            borderColor: mikePalette.secondary.dark,
          },
          '&:disabled': {
            border: '2px solid',
            color: mikePalette.secondary.light,
            borderColor: mikePalette.secondary.light,
          },
        },
        textPrimary: {
          '&:disabled': {
            color: mikePalette.primary.light,
          },
        },
        textSecondary: {         
          '&:disabled': {
            color: mikePalette.secondary.light,
          },
        },*/
      },
      defaultProps: {color: 'secondary'}
    },
    MuiBadge: {
      styleOverrides: {
        colorPrimary: {
          backgroundColor: mikePalette.error.main,
        },
      }
    },
    MuiBreadcrumbs: {},
    MuiCheckbox: {
      styleOverrides: {
        indeterminate: {
          color: MIKE_COLORS.GREEN_DEFAULT,
        },
        colorSecondary: {
          '&.Mui-checked': {
            color: MIKE_COLORS.GREEN_DEFAULT,
          },
        },
      }
    },
    MuiCircularProgress: {
      defaultProps: {
        color: 'secondary', // set the default color prop for spinners to secondary.
      }      
    },
    MuiDialog: {},
    MuiDialogTitle: {},
    MuiDialogContentText: {
      styleOverrides: {
        root: {
          color: mikePalette.text.primary,
        },
      }
    },
    MuiFab: {
      styleOverrides: {
        primary: {
          '&:disabled': {
            backgroundColor: mikePalette.primary.light,
            color: '#fff',
          },
          '&:hover': {
            backgroundColor: mikePalette.primary.dark,
          },
        },
        secondary: {
          '&:disabled': {
            backgroundColor: mikePalette.secondary.light,
            color: '#fff',
          },
          '&:hover': {
            backgroundColor: mikePalette.secondary.dark,
          },
        },
      },
      defaultProps: {
        color: 'secondary',
      }
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          color: MIKE_COLORS.DARKGREY_DEFAULT,
          backgroundColor: MIKE_COLORS.MEDIUMGREY_LIGHT,
          '&:hover': {
            backgroundColor: MIKE_COLORS.MEDIUMGREY_DEFAULT,
          },
          '&:error': {
            backgroundColor: MIKE_COLORS.MEDIUMGREY_DARK,
          },
          '&.Mui-disabled': {
            color: MIKE_COLORS.DARKGREY_DEFAULT,
            backgroundColor: MIKE_COLORS.MEDIUMGREY_LIGHT,
          },
          '&.Mui-focused': {
            color: MIKE_COLORS.BRANDBLUE_DEFAULT,
            backgroundColor: MIKE_COLORS.MEDIUMGREY_DARK,
          },
        },
        underline: {
          color: MIKE_COLORS.BRANDBLUE_DEFAULT,
          '&:after &:not($error)': {
            borderBottom: '1px solid ' + MIKE_COLORS.DARKGREY_DEFAULT,
          },
          '&:before &:not($error)': {
            borderBottom: '1px solid ' + MIKE_COLORS.DARKGREY_DEFAULT,
          },
          '&.Mui-focused:before': {
            borderBottom: '2px solid ' + MIKE_COLORS.BRANDBLUE_DEFAULT,
          },
          '&.Mui-focused:after': {
            borderBottom: '2px solid ' + MIKE_COLORS.BRANDBLUE_DEFAULT,
          },
          '&.Mui-error:before': {
            borderBottom: '2px solid ' + MIKE_COLORS.PINK_DEFAULT,
          },
          '&.Mui-error:after': {
            borderBottom: '2px solid ' + MIKE_COLORS.PINK_DEFAULT,
          },
        },
        multiline: {
          color: MIKE_COLORS.BRANDBLUE_DEFAULT,
          '&:before &:not($error)': {
            borderBottom: '1px solid ' + MIKE_COLORS.DARKGREY_DEFAULT,
          },
          '&:after &:not($error)': {
            borderBottom: '1px solid ' + MIKE_COLORS.DARKGREY_DEFAULT,
          },
          '&.Mui-focused:before': {
            borderBottom: '2px solid ' + MIKE_COLORS.BRANDBLUE_DEFAULT,
          },
          '&.Mui-focused:after': {
            borderBottom: '2px solid ' + MIKE_COLORS.BRANDBLUE_DEFAULT,
          },
          '&.Mui-error:before': {
            borderBottom: '2px solid ' + MIKE_COLORS.PINK_DEFAULT,
          },
          '&.Mui-error:after': {
            borderBottom: '2px solid ' + MIKE_COLORS.PINK_DEFAULT,
          },
        }
      }
    },
   /*  MuiFormControlLabel: {
      styleOverrides: {
        root: {
          '& .MuiTypography-root, .MuiTypography-root.Mui-disabled': {
            fontSize: '90%',
            color: mikePalette.darkGrey?.main,
          },
        },
      }
    }, */
    MuiFormLabel: {
      styleOverrides: {
        root: {
          '&.Mui-focused': {
            color: MIKE_COLORS.DARKGREY_DEFAULT,
          },
          '&.Mui-error': {
            color: MIKE_COLORS.PINK_DEFAULT,
          },
        }
      }
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          fontSize: '0.75rem',
          lineHeight: 1.66,
          color: MIKE_COLORS.DARKGREY_DEFAULT,
          backgroundColor: 'transparent',
          '&.Mui-error': {
            color: MIKE_COLORS.PINK_DEFAULT,
          },
        }
      }
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          '&:hover': {
            backgroundColor: MIKE_COLORS.MEDIUMGREY_DEFAULT,
          },
        },
      }
    },
    MuiInput:{
      defaultProps: {
        size:'small',
        margin: 'dense',
      }
    },
    MuiInputLabel: {
      styleOverrides: {
        filled: {
          color: MIKE_COLORS.DARKGREY_DEFAULT,
          fontSize: '1rem', // 16px
          lineHeight: 1.374, // 22px
          '&:focused &:not($error)': {
            color: MIKE_COLORS.DARKGREY_DEFAULT,
          },
          '&:error': {
            color: MIKE_COLORS.PINK_DEFAULT,
          },
          '&:disabled': {
            color: MIKE_COLORS.MEDIUMGREY_LIGHT,
          },
        }
      }
    },
    MuiLink: {
      defaultProps: {color: 'secondary'}      
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          color: MIKE_COLORS.BRANDBLUE_DEFAULT,
          backgroundColor: MIKE_COLORS.MEDIUMGREY_LIGHT,
          '&:hover': {
            backgroundColor: MIKE_COLORS.MEDIUMGREY_DEFAULT,
          },
          '&.Mui-selected, &.Mui-selected:hover, &.Mui-selected:focus': {
            backgroundColor: MIKE_COLORS.MEDIUMGREY_DARK,
          },
        },
      }      
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          boxSizing: 'content-box',
        },
        colorPrimary: {
          ...muiRadioStyles,
          color: mikePalette.primary.main,
          '&.Mui-checked': {
            '& svg:nth-of-type(2)': {
              transform: 'scale(0.8)',
              fill: mikePalette.primary.main,
            },
          },
        },
        colorSecondary: {
          ...muiRadioStyles,
          color: mikePalette.ultimate?.main,
          '&.Mui-checked': {
            '& svg:nth-of-type(2)': {
              transform: 'scale(0.8)',
              fill: mikePalette.ultimate?.main,
            },
          },
        },
      }
    }, 
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          [defaultTheme.breakpoints.up('xs')]: {
            fontSize: '0.75rem', // 12px
            fontWeight: 'normal',
            paddingLeft: '0.75rem', // 12px
            paddingRight: '0.75rem',
            minWidth: '0',
          },
          '&:hover, &:focus': {
            color: mikePalette.primary.main,
          },
          '&.Mui-selected': {
            fontWeight: 'bold', // todo hevo: should we use theme.typography.fontWeight instead?
          },
        },
        selected: {},
      }
    },
    MuiTabs: {
      styleOverrides: {
        scrollButtons: {
          [defaultTheme.breakpoints.up('xs')]: {
            minWidth: 0,
            width: 'auto',

            '&&& > *:first-child': {
              width: 40,
            },
          },
        },
      },
      defaultProps: {
        indicatorColor: 'primary',
        textColor: 'primary',
      }
    },
    MuiTable: {
      styleOverrides: {
        root: { overflowX: 'auto' },
      }
    },
    MuiTableCell: {
      styleOverrides: {
        body: {
          height: '44px',
          color: mikePalette.primary.main,
          paddingTop: '0',
          paddingBottom: '0',
          userSelect: 'none',
          width: '300px',
        },
        head: {
          paddingTop: '0',
          paddingBottom: '0',
          height: '44px',
          backgroundColor: 'white',
          borderBottom: '2px solid ' + mikePalette.divider,
          userSelect: 'none',
          cursor: 'pointer ',
        },
      },
      defaultProps: {
        align: 'left',
      }
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          '&:hover, &:focus': {
            backgroundColor: mikePalette.lightGrey?.main,
          },
        },
      }
    },
    MuiTextField:{
      defaultProps: {
        variant: 'filled',
        size: 'small',
        margin: 'dense'
      }
    },
    MuiTooltip: {
      styleOverrides: {
        arrow: {
          color: mikePalette.primary.main,
        },
        popperArrow: {
          '&[x-placement*="bottom"] span[class*="MuiTooltip-arrow"]': {
            '&::before': {
              borderWidth: '0 12px 12px 12px',
              marginTop: '-7px',
              marginLeft: '-6px',
              color: mikePalette.darkGrey?.main,
            },
            '&::after': {
              borderWidth: '0 11px 11px 11px',
              borderBottomColor: mikePalette.mediumGrey?.main,
              borderLeftColor: 'transparent',
              borderRightColor: 'transparent',
              borderTopColor: 'transparent',
              borderStyle: 'solid',
              marginTop: '-29px',
              marginLeft: '-5px',
              color: 'transparent',
              backgroundColor: 'transparent',
              width: 0,
              content: 'open-quote',
              position: 'absolute',
              display: 'block',
            },
          },
        },
        tooltip: {
          backgroundColor: mikePalette.mediumGrey?.main,
          border: `1px solid ${mikePalette.darkGrey?.main}`,
          maxWidth: SPACING * 46, // 368px
          minHeight: SPACING * 4, // 32px
          maxHeight: SPACING * 6.5, // 52px
          fontSize: FONTSIZE,
          fontWeight: 'normal',
          fontFamily: FONTFAMILY,
          padding: SPACING,
          color: mikePalette.primary.main,
          boxSizing: 'border-box',
        },
      }
    },
    MuiStepConnector: {
      styleOverrides: {
        line: {
          borderColor: mikePalette.text.secondary,
        },
      }
    },
    MuiStepIcon: {
      styleOverrides: {
        root: {
          fill: mikePalette.background.paper,
          color: mikePalette.text.secondary,
          border: 'solid',
          borderColor: mikePalette.text.secondary,
          borderRadius: 25,
          borderWidth: 1,
          '&.Mui-active': {
            border: 'none',
            fill: mikePalette.secondary.main, 
            text: {
              fill: mikePalette.secondary.contrastText
            }                   
          },
          '&.Mui-completed': {
            border: 'none',
            fill: mikePalette.secondary.main,
          },
        },
        text: {
          fill: mikePalette.text.secondary,
          fontSize: '1rem', // 16px          
        },
      }
    },
    MuiStepper: {
      styleOverrides: {
        root: {
          padding: '0.5rem', // 8px
        },
      }
    },
    MuiSwitch: {
      styleOverrides: {
        root: {
          width: 36,
          height: 18,
          margin: 1,
          padding: 1,
        },
        colorSecondary: {
          color: MIKE_COLORS.DARKGREY_DEFAULT, 
          '& + .MuiSwitch-track': {
            opacity: 1,
            backgroundColor: MIKE_COLORS.MEDIUMGREY_LIGHT,
            borderColor: MIKE_COLORS.DARKGREY_DEFAULT,
          },
          '&:hover': {
            backgroundColor: 'transparent',
          },
          '&.Mui-checked': {
            // transform: 'translateX(18px)', TODO used by DataLink ?
            color: MIKE_COLORS.GREEN_DARK,
            '& + .MuiSwitch-track': {
              opacity: 1,
              backgroundColor: MIKE_COLORS.GREEN_LIGHT,
              borderColor: MIKE_COLORS.GREEN_LIGHT,
            },

            '&:hover': {
              backgroundColor: 'transparent',
            },
          },        
        },
        switchBase: {
          padding: 4,
        },
        thumb: {
          width: 10,
          height: 10,
          boxShadow: 'none',
        },
        track: {
          borderRadius: 20 / 2,
          border: `2px solid ${MIKE_COLORS.DARKGREY_DEFAULT}`,
        },   
        
            
      },
    
    }
  }
});

// console.log('mikeSharedTheme', mikeSharedTheme);
export default mikeSharedTheme;
