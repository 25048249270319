import { takeEvery, put, select, take, call, delay } from 'redux-saga/effects'
import ActionType from '../actions/ActionType'
import { IFastWaveConfig } from '../model/IFastWaveConfig'
import {  getFastWaveConfig, getProject } from '../reducers/state'
import {  fastWaveConfigSet, setBoundaryCondition } from '../actions/mapContent'
import { deleteOutputFolder } from '../actions/projectContent'
import { JOBS, OUTPUTFOLDER } from '../shared/constants'
import uniqid from 'uniqid'
import { removeUpload, updateProgress, uploadFail, uploadFile } from '../actions/uploads'
import { addError, addErrors } from '../actions/errors'
import { IGetProject } from '../model/IGetProject'
import { getProjectDataset, getProjectDatasets, getProjectSubprojects, moveDataset, putProjectDataset } from '../apis/metadataApi'
import { getOwnEnvironmentalDataTemplate, uploadOwnEnvironmentalData } from '../apis/backendApi'
import { removeProgressItem } from '../actions/progressItems'
import { createContainer } from '../actions/job'
import { IGetDataset } from '../model/IGetDataset'
import downloadWithNewName from "../apis/downloadWithNewName";
import { intl } from '../main'

const ZIPREFERENCE = "Userdata Extraction file: ";
const INFOREFERENCE = "Userdata Extraction-info file: "

export default function* watchUseOwnEnvironmentalData() {
  yield takeEvery(ActionType.RESET_BOUNDARY_CONDITION, handleResetBoundaryCondition);
  yield takeEvery(ActionType.OWNDATA_UPLOAD, handleUploadData);
  yield takeEvery(ActionType.OWNDATA_DOWNLOAD, handleDownloadOwnDataTemplate)
  yield takeEvery(ActionType.SET_TRANFORM_DATA_LOG, handleUserDataTransformed)
}

function* handleUserDataTransformed(action){
  const config: IFastWaveConfig = yield select(getFastWaveConfig);
  const sourceName = config && config.userdefined_metocean_data
  const log = action.data  
  const lines = log.split("\n");
  const zipReference = lines.find((l: string) => l.startsWith(ZIPREFERENCE))
  const infoReference = lines.find((l: string) => l.startsWith(INFOREFERENCE))
  if (zipReference !== undefined && infoReference !== undefined){
    const zipName = zipReference.replace(ZIPREFERENCE, "")
    const infoName = infoReference.replace(INFOREFERENCE, "")
    const project = yield select(getProject)
    if (project && project.id){
      const folders = yield call(getProjectSubprojects, project.id)
      const outputFolder = folders.find((f: IGetProject) => f.name.toLowerCase() === OUTPUTFOLDER)
      if (outputFolder && outputFolder.id){
        const datasets = yield call(getProjectDatasets, outputFolder.id)
        const zipDataset = datasets.find((d: IGetDataset) => d.name === zipName)
        const infoDataset = datasets.find((d: IGetDataset) => d.name === infoName)       
        if (zipDataset !== undefined && infoDataset !== undefined){    
          try{
            if (sourceName){
              const dataset = yield call(putProjectDataset, project.id, {...zipDataset, description: intl.formatMessage({id: 'boundaryConditions.source'}) + ": " + sourceName})
              yield call(moveDataset, dataset.id, project.id) 
            }
            else{
              yield call(moveDataset, zipDataset.id, project.id)   
            }   
            yield call(moveDataset, infoDataset.id, project.id)
            yield delay(2000)
            const datasets = yield call(getProjectDatasets, project.id) 
            const zipDS = datasets.find((d: IGetDataset) => d.name === zipName)             
            if (zipDS !== undefined){
              yield put(setBoundaryCondition(zipDS, true)) 
            } 
          }
          catch (error){
            console.log(error)
          }   
        }
      }      
   }
  }  
}

function* handleDownloadOwnDataTemplate(){

  try{
    const downloadPath = yield call(getOwnEnvironmentalDataTemplate)  
    try{
      yield call(downloadWithNewName, downloadPath, "Template_MMS_userdata.csv")
      /* const response = yield call(getTextDatasetContent, downloadPath)
      const copy = new File([response], "Template_MMS_userdata.csv", {type: 'plain/text' });      
      const path = URL.createObjectURL(copy)
      yield call(window.open, path, "_self") */
    }
    catch{
      yield call(window.open, downloadPath, "_self")
    } 
  }
  catch(error){
    console.log(error)
  } 
}

function* handleResetBoundaryCondition(){  
  const config: IFastWaveConfig = yield select(getFastWaveConfig);
  yield put(deleteOutputFolder())
  const updatedConfig = {...config}
  if (updatedConfig.no_events){              
    delete updatedConfig.no_events
  } 
  if (updatedConfig.n_pca_components){              
    delete updatedConfig.n_pca_components
  } 
  if (updatedConfig.userdefined_metocean_data){              
    delete updatedConfig.userdefined_metocean_data
  } 
  if (updatedConfig.data_link_output_info_file){              
    delete updatedConfig.data_link_output_info_file
  } 
  if (updatedConfig.data_link_output_file){              
    delete updatedConfig.data_link_output_file
  } 
  if (updatedConfig.start_time){              
    delete updatedConfig.start_time
  } 
  if (updatedConfig.end_time){              
    delete updatedConfig.end_time
  } 
  if (updatedConfig.test_event_time){              
    delete updatedConfig.test_event_time
  } 
  yield put(fastWaveConfigSet(updatedConfig, true))
}

function* handleUploadData(action) {
  const DATASETIDEQUALS = "DatasetId="
  const config: IFastWaveConfig = yield select(getFastWaveConfig)
  const updatedConfig = {...config}
  const id = uniqid.time()  
  const { data, fileName, projectId } = action.data  
  try {
    /* yield put(deleteOutputFolder())    
    if (updatedConfig.no_events){              
      delete updatedConfig.no_events
    } 
    if (updatedConfig.n_pca_components){              
      delete updatedConfig.n_pca_components
    } 
    if (updatedConfig.data_link_output_info_file){              
      delete updatedConfig.data_link_output_info_file
    } 
    if (updatedConfig.data_link_output_file){              
      delete updatedConfig.data_link_output_file
    } 
    if (updatedConfig.start_time){              
      delete updatedConfig.start_time
    } 
    if (updatedConfig.end_time){              
      delete updatedConfig.end_time
    } 
    if (updatedConfig.test_event_time){              
      delete updatedConfig.test_event_time
    }   */
    yield put(uploadFile(id, fileName, data))
    while (true) {
      const uploadAction = yield take([ActionType.UPLOAD_COMPLETE, ActionType.UPLOAD_FAIL])
      if (uploadAction.data.id === id) {
        const project : IGetProject | null = yield select(getProject);
        if (project && project.id && project.id !== projectId){
          yield put(removeProgressItem({id, title: "", progressValue: 0}))      
        }
        if (uploadAction.type === ActionType.UPLOAD_COMPLETE) {  
          const title = 'Importing (' + data.name + ')'
          yield put(updateProgress(id, 50, title))        
          const project : IGetProject | null = yield select(getProject); 
          try{
            const response = yield call(uploadOwnEnvironmentalData, project.id, uploadAction.data.fileUrl)  
            if (response && response.length > 0){
              if (response[0].startsWith(DATASETIDEQUALS)){
                const datasetId = response[0].replace(DATASETIDEQUALS, "")
                if (datasetId){
                  const dataset = yield call(getProjectDataset,datasetId)
                  if (dataset){
                    yield put(createContainer(JOBS.ENVIRONMENTALDATA, {...updatedConfig, userdefined_metocean_data: dataset.name}))               
                    yield put(updateProgress(id, 100, title))
                  }                
                }   
              }
              else{
                const lastLine = intl.formatMessage({id: 'boundaryConditions.ownDataHints.editAndReUplaod'})
                if (response[response.length - 1].startsWith(DATASETIDEQUALS)){                  
                  const enriched = response.slice(0, -1).concat(lastLine)
                  yield put(addErrors(enriched))
                }
                else{
                  yield put(addErrors(response.concat(lastLine)))
                }                
              }                          
            }
            else{
              yield put(addError("Failed to upload data"))
            }           
          }
          catch(error) {
            yield put(uploadFail(id, error))
            if (error){
              yield put(addError(error))
            }
            
          }
          finally{               
            yield put(removeUpload(id))
            yield put(removeProgressItem({id, title: "", progressValue: 0}))
          }          
        }
        break
      }
    }
  } catch (error) {
    yield put(addError(error))
  }    
}