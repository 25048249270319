/** @jsxImportSource @emotion/react */
import React, { useState, ReactNode } from 'react';
import MikeNotificationButton from './MikeNotificationButton';
import MikeNotificationGroups from './MikeNotificationGroups';
import { ClickAwayListener } from '@mui/material';
import MikeTopbarDropdown, { wrapperStyle } from '../mike-topbar-dropdown';
import { INotification } from './INotification';
import MikeTooltip from '../mike-tooltip';
import { IMikeTooltipPosition } from '../mike-tooltip/MikeTooltip';

interface IProps {
  /**
   * Notifications.
   */
  notifications: Array<INotification>;
  /**
   * To set the blue dot visibility from parent.
   */
  showDot: boolean;
  /**
   * href.
   */
  href?: string;
  /**
   * Link callback.
   */
  onLinkCallBack?: (arg0: INotification) => void;
  /**
   * Click away callback, e.g. to hide the blue dot.
   */
  clickAwayCallback?: () => void;
  /**
   * Custom content.
   */
  content?: ReactNode;
  /**
   * Tooltip title.
   * Use "" for no tooltip.
   */

  tooltipTitle?: string;
  /**
   * How to position the tooltip in relation to its target/anchor.
   * Structure is `{ top?: number, left?: number }`.
   */
  tooltipPosition?: IMikeTooltipPosition;
  /**
   * Custom title.
   */
  title?: string;
  /**
   * Custom 'no content' label.
   */
  noContentLabel?: string;
  /**
   * Custom render function.
   * To display the content of a notification item.
   */
  renderContent?: (notification: INotification) => React.ReactNode;
  /**
   * Custom render function.
   * To display the title of a notification item.
   */
  renderTitle?: (notification: INotification) => React.ReactNode;
}

/**
 * @name MikeNotificationContainer
 * @summary The main notification wrapper-component. Used to display generic notifications in the topbar.
 */
export const MikeNotificationContainer = (props: IProps) => {
  const {
    notifications,
    showDot,
    href,
    onLinkCallBack,
    clickAwayCallback,
    content,
    tooltipTitle = 'Notifications',
    tooltipPosition,
    title = 'Notifications',
    noContentLabel = 'No notifications to show yet',
    renderContent,
    renderTitle,
  } = props;
  const ttPos = { left: -9, top: -1, ...tooltipPosition };
  const [showNotifications, setShowNotifications] = useState(false);
  const [dotInvisible, setDotInvisible] = useState(true);

  React.useEffect(() => {
    setDotInvisible(!showDot);
  }, [showDot]);

  const handleOnClick = React.useCallback(() => {
    if (clickAwayCallback) {
      clickAwayCallback();
    }
    setShowNotifications(true);
  }, [clickAwayCallback]);

  // Only show tooltips when collapsed:
  const ttTitle = showNotifications ? '' : tooltipTitle;

  return (
    <div css={wrapperStyle}>
      <MikeTooltip title={ttTitle} arrow mikeposition={ttPos}>
        <MikeNotificationButton dotInvisible={dotInvisible} onClickCallBack={handleOnClick} />
      </MikeTooltip>
      {showNotifications && (
        <ClickAwayListener onClickAway={() => setShowNotifications(false)}>
          <div>
            <MikeTopbarDropdown>
              {content ? (
                content
              ) : (
                <MikeNotificationGroups
                  notifications={notifications}
                  href={href}
                  onLinkCallBack={onLinkCallBack}
                  title={title}
                  noContentLabel={noContentLabel}
                  renderContent={renderContent}
                  renderTitle={renderTitle}
                />
              )}
            </MikeTopbarDropdown>
          </div>
        </ClickAwayListener>
      )}
    </div>
  );
};

export default MikeNotificationContainer;
