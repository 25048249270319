import { takeEvery, select, call } from 'redux-saga/effects'
import ActionType from '../actions/ActionType'
import { checkMeshContainsPoints } from '../apis/backendApi'
import { IFastWaveConfig, IOutputPoint } from '../model/IFastWaveConfig'
import { IGetProject } from '../model/IGetProject'
import { getFastWaveConfig, getProject } from '../reducers/state'

export default function* watchCheck() {
  yield takeEvery(ActionType.CHECK_MESH_AND_POINTS, handleCheckMeshAndPoints)
}

function* handleCheckMeshAndPoints() {
  const project: IGetProject | null = yield select(getProject)
  const config: IFastWaveConfig = yield select(getFastWaveConfig)
  if (project && config && config.mesh_file && config.mesh_file.dataset_id && config.output_points && config.output_points.length > 0){
    const points = config.output_points.map((point: IOutputPoint) => {return {x: point.x, y: point.y, z: 0, m: 0, coordinateValue: point.name}} )
    const response = yield call(checkMeshContainsPoints, project.id, config.mesh_file.dataset_id, points )
    console.log(response)
  }
}