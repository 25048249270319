/** @jsxImportSource @emotion/react */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { css } from '@emotion/react';
import React from 'react';
import { getMonth, getYear } from 'date-fns';
import { Grid, IconButton } from '@mui/material';
import Left from '../../icons/Left.svg?react';
import Right from '../../icons/Right.svg?react';
import MikeDatePickerSelectMonth from './MikeDatePickerSelectMonth';
import MikeDatePickerSelectYear from './MikeDatePickerSelectYear';
import mikeSharedTheme, { mikePalette } from '../../styles/mikeSharedTheme';

interface IProps {
  date: Date;
  changeYear: (param) => void;
  changeMonth: (param) => void;
  decreaseMonth?: () => void;
  increaseMonth?: () => void;
  dateChange: (date: Date) => void;
  prevMonthButtonDisabled?: boolean;
  nextMonthButtonDisabled?: boolean;
  minYear?: number | undefined;
  maxYear?: number | undefined;
}


const containerStyle = css`
    width: 100%;
    height: 100%;
    margin-top: 15px;
    font-weight: bold;
    font-size: 14px;
 `;
  const arrowIconStyle = css`
    color: ${mikeSharedTheme.palette.primary.main};
`;
  const leftIconButtonStyle = css`
    padding: 0px;
    float: left;
    margin-top: -10px;
    border-radius: 25px;
    &:hover: {
      background-color: ${mikePalette.mediumGrey.light};
    };
`;
  const rightIconButtonStyle = css`
    padding: 0px;
    float: right;
    margin-top: -10px;
    border-radius: 25px;
    &:hover: {
      background-color: ${mikePalette.mediumGrey.light};
    },
`;


const MikeDatePickerHeader: React.FC<IProps> = ({
  date,
  changeYear,
  changeMonth,
  decreaseMonth,
  increaseMonth,
  dateChange,
  prevMonthButtonDisabled,
  nextMonthButtonDisabled,
  minYear,
  maxYear
}) => {

  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  const TopBar = () => {
    return (
      <div css={containerStyle}>
        <IconButton
          onClick={decreaseMonth}
          disabled={prevMonthButtonDisabled}
          css={leftIconButtonStyle}
        >
          <Left css={arrowIconStyle} />
        </IconButton>

        <span style={{ fontWeight: 'bold', fontSize: '14px' }}>
          {months[getMonth(date)] + '   '}
          {getYear(date)}
        </span>
        <IconButton
          onClick={increaseMonth}
          disabled={nextMonthButtonDisabled}
          css={rightIconButtonStyle}
        >
          <Right css={arrowIconStyle} />
        </IconButton>
      </div>
    );
  };

  const onChangeMonth = (e) => {
    changeMonth(months.indexOf(e.target.innerText as any));
  };

  const onChangeYear = (e) => {
    changeYear(e.target.innerText);
  };

  const onDateChange = (newdate: Date) => {
    dateChange(newdate);
  };

  return (
    <div>
      <Grid container>
        <Grid item xs={12}>
          <TopBar />
        </Grid>
        <Grid item xs={12}>
          <div style={{ marginTop: '8px' }}>
            <MikeDatePickerSelectMonth
              onChangeMonth={onChangeMonth}
              onDateChange={onDateChange}
              date={date}
            />
            <MikeDatePickerSelectYear
              onChangeYear={onChangeYear}
              onDateChange={onDateChange}
              date={date}
              startYear={minYear ? minYear : 1900}
              endYear={maxYear ? maxYear : 3000}
            />
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default MikeDatePickerHeader;
