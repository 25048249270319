/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import theme from './../../styles/mikeSharedTheme';
import Close from '../../icons/Close.svg?react';
import Search from '../../icons/Search.svg?react';
import { ChangeEvent } from 'react'
import { Input, InputAdornment } from '@mui/material';

const searchFieldStyle = css`
  padding: ${theme.spacing(1 / 4, 2)};
  width: 320px;
  height: 40px;
  background-color: ${theme.palette.background.paper};
  border-radius: 25px;
  border: 1px solid  + ${theme.palette.divider};
`;

interface IProps {
  value: string;
  onChangeValue: (event: ChangeEvent<HTMLInputElement>) => void;
  onResetValue: () => void;
}

const SearchInput = (props: IProps) => {  
    const { value, onChangeValue, onResetValue } = props;
    return (
      <Input
        type="input"
        autoFocus
        onChange={onChangeValue}
        css={searchFieldStyle}
        disableUnderline={true}
        placeholder={"What are you searching for"}
        value={value}
        startAdornment={
          <InputAdornment position="start">
            <Search />
          </InputAdornment>
        }
        endAdornment={
          value !== '' ? (
            <InputAdornment position="end" onClick={onResetValue}>
              <Close />
            </InputAdornment>
          ) : null
        }
      />
    );
  };

export default SearchInput
