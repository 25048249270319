import ActionType from "../actions/ActionType";
import { IAction } from "../actions/Action";
export interface IMessageState {
  message: string; 
}

const initState = { message: "" }

const message = (state = initState, action: IAction) => {
  switch (action.type) {
      case ActionType.ADD_MESSAGE:
          return { ...state, message: action.data };
      case ActionType.REMOVE_MESSAGE:
          return {
              ...state,
              message: action.data
          };      
      default:
          return state;
  }
};

export default message