import { css } from '@emotion/react';
import theme, { mikePalette } from './mikeSharedTheme';



export const buttonStyle = css`  
  color: ${mikePalette.secondary.main};
  &:disabled: {
    color: ${theme.palette.primary.light};
  }  
`
export const buttonSecondaryBorderStyle = css`
  padding: 0;
  padding-right: 12px;
  border: 2px solid  + ${mikePalette.secondary.main};
  color: ${mikePalette.secondary.main};
  &[disabled]: {
    color: ${mikePalette.secondary.light};
    opacity: 0.5;
    cursor: not-allowed;
  };
`
export const iconButtonStyle = css`
  button {
    color: ${mikePalette.secondary.main};
    '&:disabled': {
      color: theme.palette.primary.light;
    }
  }
`

export const iconStyle = css`
  path {
    fill: ${mikePalette.secondary.main};
  }
`

export const iconPrimaryStyle = (disabled = false) => {
  const color = disabled ? mikePalette.primary.light : mikePalette.primary.main
  return css`
    path {
      fill: ${color};
      stroke: ${color};
    }
  `
}

export const iconStrokePrimaryStyle = (disabled = false) => {
  const color = disabled ? mikePalette.primary.light : mikePalette.primary.main
  return css`
    path {     
      stroke: ${color};
    }
  `
}

export const iconFillPrimaryStyle = (disabled = false) => {
  const color = disabled ? mikePalette.primary.light : mikePalette.primary.main
  return css`
    path {     
      stroke: ${color};
    }
  `
}

export const iconWhiteStyle = (disabled = false) => {
  const color = disabled
  ? mikePalette.background.paper
  : mikePalette.background.default  
 
  return css`
    path {
      fill: ${color};
      stroke: ${color};
    }
  `
}

export const uploadIconStyle = css`
&..MuiButton-startIcon {
  margin-right: 0;  
}
`;

export const linkStyle = (disabled = false) => {
  const cursor = disabled ? 'not-allowed' : 'default'
  return css`
    display: content,
    text-Decoration: none,
    cursor: ${cursor};
  `
}

export const iconSecondaryStyle = (disabled = false) => {
  const color = disabled
    ? mikePalette.secondary.light
    : mikePalette.secondary.main
  return css`
    path {
      fill: ${color};
      stroke: ${color};
    }
  `
}


export const iconCustomSizeStyle = (size = 32) => {
  const height = size
  return css`
    svg {
      height: ${height};
      width: ${height};
    }
  `
}
