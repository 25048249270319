/* eslint-disable @typescript-eslint/no-explicit-any */
import ActionType from "./ActionType";
import { Feature } from "geojson";
import { DRAWING, READER } from "../shared/constants";
import { IGetDataset } from "../model/IGetDataset";
import { IFastWaveConfig } from "../model/IFastWaveConfig";

import { IListItem } from "../reducers/mapContent";
import { IChartData } from "../model/IChartData";
import { IChartItem, IInitialChartData } from "../model/IInitialChartData";
import { IDisplayProvider, IExtractionBuilderData } from "../model/IGetDataLinkMeshStatus";
import { ISetup } from "../model/ISetup";
import IProjection from "../components/mike-projection-select/model/IProjection";
import { FeatureCollection } from "geojson";
import { ICreateMeshDataset } from "../reducers/createMesh";
import { IUploadedPoint } from "../apis/backendApi";
import { IViewerBounds } from "../MikeVisualizer/lib/IMikeVisualizerModels";

export const setLoadingPointZValues = (loading = true) => ({
  type: ActionType.POINTS_LOADING_Z,
  data: loading
})

export const loadPointZValues = () => ({
  type: ActionType.POINTS_GET_Z
})

export const replacePoints = () => ({
  type: ActionType.REPLACE_POINTS_WITH_TRANSFORMED_POINTS
})

export const renamePoints = () => ({
  type: ActionType.RENAME_UPLOADED_POINTS
})

export const setExistingPointNames = (names: Array<string>, uploadedPoints: Array<IUploadedPoint>) => ({
  type: ActionType.SET_EXISTING_POINT_NAMES,
  data: {names, uploadedPoints}
})

export const setDrawing = (drawing = DRAWING.POINT) => ( {
  type: ActionType.DRAWING_SET,
  data: drawing
})

export const uploadDrawnGeometry = (drawing: string, fc: FeatureCollection) => ({
  type: ActionType.UPLOAD_DRAWNGEOMETRY,
  data: {drawing, fc}
})

export const cancelDrawnGeometry = (drawing = "") => ({
  type: ActionType.CANCEL_DRAWN_GEOMETRY,
  data: drawing
})

export const updatePolyline = (drawing: string, fc: FeatureCollection) => ({
  type: ActionType.POLYLINE_UPDATE,
  data: {drawing, fc}
 })

export const getProj4String = (epsg: number) => ({
  type: ActionType.GET_PROJ4STRING,
  data: epsg  
})

export const setProj4String = (proj4String: string, epsgCode: number, initialBounds?: IViewerBounds, resetMap = true) => ({
  type: ActionType.SET_PROJ4STRING,
  data: {proj4String, epsgCode, initialBounds, resetMap}  
}) 

export const getPointDecimals = (wkt: string) => ({
  type: ActionType.GET_POINT_DECIMALS,
  data: wkt  
})

export const setPointDecimals = (decimals: number) => ({
  type: ActionType.SET_POINT_DECIMALS,
  data: decimals  
})

export const coordinateSystemsGet = () => ({
  type: ActionType.GET_COORDINATE_SYSTEMS
})

export const pointsTransform = (srid: number) => ({
  type: ActionType.TRANSFORM_POINTS,
  data: srid
})

export const uploadPointsUrlSet = (url: string) => ({
  type: ActionType.UPLOAD_POINTS_URL_SET,
  data: url
})

export const uploadingPoints = (uploading = true) => ({
  type: ActionType.UPLOADING_POINTS,
  data: uploading
})

export const loadingCoordinateSystems = (loading = true) => ({
  type: ActionType.LOADING_COORDINATE_SYSTEMS,
  data: loading
})

export const getProjectionSystemsById = (id: number) => ({
  type: ActionType.GET_PROJECTION_SYSTEMS_BY_ID,
  data: id,
});

export const filterProjectionSystems = (filter: string) => ({
  type: ActionType.FILTER_PROJECTION_SYSTEMS,
  data: filter,
});

export const coordinateSystemsSet = (cs: Array<IProjection>) => ({
  type: ActionType.SET_COORDINATE_SYSTEMS,
  data: cs
})

export const showProjectionSystemDialog = (show = true, type = READER.XY) => ({
  type: ActionType.SHOW_PROJECTION_SYSTEM_DIALOG,
  data: { show, type }
})

export const showSuccessDialog = (show = true) => ({
  type: ActionType.SHOW_SUCCESS_DIALOG,
  data: show
})

export const showTestRunDialog = (show = true) => ({
  type: ActionType.SHOW_TESTRUN_DIALOG,
  data: show
})

export const setMapReady = () => ({
  type: ActionType.SET_MAP_READY
})

export const setShowMesh = (datasetId: string) => ({
  type: ActionType.SHOW_MESH,
  data: datasetId
})

export const setMesh = (vtp: string) => ({
  type: ActionType.SET_MESH,
  data: vtp
})

export const setMeshExtent = (extent: IViewerBounds) => ({
  type: ActionType.MESH_SET_EXTENT,
  data: extent
})

export const loadMesh = (datasetId: string) => ({
  type: ActionType.LOAD_MESH,
  data: datasetId
})

export const uploadPoints = (data: any, fileName: string, projectId: string) => ({
  type: ActionType.POINTS_UPLOAD,
  data: {data: data, fileName: fileName, projectId}
})

export const uploadBathymetry = (data: any, fileName: string) => ({
  type: ActionType.BATHYMETRY_UPLOAD,
  data: {data: data, fileName: fileName}
})

export const uploadMesh = (data: any, fileName: string) => ({
  type: ActionType.MESH_UPLOAD,
  data: {data: data, fileName: fileName}
})

export const uploadOutlineOrAOIOrShoreline = (data: any, fileName: string, importType: string) => ({
  type: ActionType.OUTLINE_UPLOAD,
  data: {data, fileName, importType}
})

export const uploadBackground = (data: any, fileName: string, importType: string) => ({
  type: ActionType.BACKGROUND_UPLOAD,
  data: {data, fileName, importType}
})

export const backgroundUploaded = (datasetId: string) => ({
  type: ActionType.BACKGROUND_UPLOADED,
  data: datasetId
})

export const outlineUploaded = (datasetId: string) => ({
  type: ActionType.OUTLINE_UPLOADED,
  data: datasetId
})

export const areaOfInterestUploadedOrSelected = (datasetIds: Array<string>) => ({
  type: ActionType.AOIS_UPLOADED_OR_SELECTED,
  data: datasetIds
})

export const shorelineUploadedOrSelected = (datasetId: string) => ({
  type: ActionType.SHORELINE_UPLOADED_OR_SELECTED,
  data: datasetId
})

export const bathymetryDatasetsUploadedOrSelected = (datasetIds: Array<string>) => ({
  type: ActionType.BATHYS_UPLOADED_OR_SELECTED,
  data: datasetIds
})

export const meshUploaded = (datasetId: string) => ({
  type: ActionType.MESH_UPLOADED,
  data: datasetId
})

export const uploadSetup = (data: any, fileName: string) => ({
  type: ActionType.SETUP_UPLOAD,
  data: {data: data, fileName: fileName}
})

export const setupUploaded = (dataset: IGetDataset) => ({
  type: ActionType.SETUP_UPLOADED,
  data: dataset
})

export const chartBulletSetSelected = (id: string, forTestRun = false) => ({
  type: ActionType.CHART_SELECTED_BULLET_SET,
  data: {id: id, forTestRun: forTestRun}
})


export const setSelectedMesh = (selectedDataset: IGetDataset | null, projectId: string) => ({
  type: ActionType.SET_SELECTED_MESH,
  data: {selectedDataset, projectId}
})

export const setElevationName = (name: string) => ({
  type: ActionType.MAPCONTENT_SET_ELEVATION_NAME,
  data: name
})

/* export const setMeshIsWithinGWMErrors = (errors: Array<string>, checked: boolean) => ({
  type: ActionType.SET_MESH_IS_WITHIN_GWM_ERRORS,
  data: { errors, checked }
}) */

export const setGWM = (vtp: string) => ({
  type: ActionType.SET_GWM,
  data: vtp
})

export const setSelectedOutline = (selectedDataset: ICreateMeshDataset | null) => ({
  type: ActionType.SET_SELECTED_OUTLINE,
  data: selectedDataset
})

export const addAreasOfInterest = (datasets: Array<ICreateMeshDataset>) => ({
  type: ActionType.ADD_AREAS_OF_INTEREST,
  data: datasets
})

export const setAreasOfInterest = (datasets: Array<ICreateMeshDataset>) => ({
  type: ActionType.SET_AREAS_OF_INTEREST,
  data: datasets
})

export const setBathymetryDatasets = (datasets: Array<ICreateMeshDataset>, updateConfig = false) => ({
  type: ActionType.SET_BATHYMETRY_DATASETS,
  data: {datasets, updateConfig}
})

export const addBathymetryDatasets = (datasets: Array<ICreateMeshDataset>) => ({
  type: ActionType.ADD_BATHYMETRY_DATASETS,
  data: datasets
})

export const setBoundaryCondition = (bc: IGetDataset, readExtractionPeriodFromInfoFile = false) => ({
  type: ActionType.BOUNDARYCONDITION_SET,
  data: {boundaryConditionsDataset: bc, readExtractionPeriodFromInfoFile: readExtractionPeriodFromInfoFile}
})

export const getPreviousExtractions = (projectId: string) => ({
  type: ActionType.GET_PREVIOUS_EXTRACTIONS, data: projectId
})

export const setPreviousExtractions = (datasets: Array<IGetDataset>) => ({
  type: ActionType.SET_PREVIOUS_EXTRACTIONS, data: datasets 
})

export const setLoadingPreviousExtractions = (loading = true) => ({
  type: ActionType.LOADING_PREVIOUS_EXTRACTIONS, data: loading 
})

export const setExtractionInfo = (ds: IGetDataset) => ({
  type: ActionType.EXTRACTION_INFO_SET,
  data: ds
})

export const setLoadingMeshes = (loading = true) => ({
  type: ActionType.LOADING_MESH,
  data: loading
})

export const setLoadingConfig = (loading = true) => ({
  type: ActionType.LOADING_CONFIG,
  data: loading
})

export const setLoadingExtractionInfo = (loading = true) => ({
  type: ActionType.LOADING_EXTRACTION_INFO,
  data: loading
})

export const setPreviousExtraction = (extractionDataset: IGetDataset) => ({
  type: ActionType.SET_PREVIOUS_EXTRACTION, data: extractionDataset
})

export const extractionInfoGet = (infoFileName: string, archiveName: string) => ({
  type: ActionType.EXTRACTION_INFO_LAYER_GET,
  data: {infoFileName: infoFileName, archiveName: archiveName}
})

export const extractionPeriodStartSet = (start: string) => ({
  type: ActionType.EXTRACTION_PERIOD_START_SET,
  data: start
})

export const extractionPeriodEndSet = (end: string) => ({
  type: ActionType.EXTRACTION_PERIOD_END_SET,
  data: end
})

export const setLoadingDataLinkMesh = (loading = true) => ({
  type: ActionType.LOADING_DATALINK_MESH,
  data: loading
})

export const dataLinkMeshGet = (meshName: string, projectId: string) => ({
  type: ActionType.DATALINK_MESH_GET,
  data: {meshName, projectId}
})

export const dataLinkExtractData = ()=> ({
  type: ActionType.DATALINK_EXTRACT_DATA
})

export const dataLinkExtractionBuilderDataGet = () => ({
  type: ActionType.DATALINK_EXTRACTION_BUILDER_DATA_GET
})

export const loadingExtractionBuilderData = (loading = true) => ({
  type: ActionType.DATALINK_EXTRACTION_BUILDER_DATA_LOADING,
  data: loading
})

export const dataLinkExtractionBuilderDataSet = (extractionBuilderData: IExtractionBuilderData | null) => ({
  type: ActionType.DATALINK_EXTRACTION_BUILDER_DATA_SET,
  data: extractionBuilderData
})

export const dataLinkSetSelectedProvider = (provider: IDisplayProvider, type: string) => ({
  type: ActionType.DATALINK_SET_SELECTED_PROVIDER,
  data: {provider, type}
})

export const setSelectedBoundaryOrForcing = (data: IListItem) => ({
  type: ActionType.SELECTED_BOUNDARY_OR_FORCING_SET,
  data: data
})

export const setLoadingCentralPoints = (loading = true) => ({
  type: ActionType.LOADING_CENTRAL_POINTS,
  data: loading
})

export const centralPointsGet = (projectId = "") => ({
  type: ActionType.CENTRAL_POINTS_GET,
  data: projectId
})

export const centralPointsSet = (centralPoints: Array<Feature>, boundaryAndForcings: Array<IListItem>, events: Array<string>, totalExplainedVarianceRatio: number) => ({
  type: ActionType.CENTRAL_POINTS_SET,
  data: {centralPoints: centralPoints, boundaryAndForcings: boundaryAndForcings, events: events, totalExplainedVarianceRatio: totalExplainedVarianceRatio}
})

export const boundaryLinesSet = (boundaries: Array<Feature>) => ({
  type: ActionType.BOUNDARY_LINES_SET,
  data: boundaries
})

export const setLoadingTestRunOutput = (loading = true) => ({
  type: ActionType.LOADING_TESTRUN_OUTPUT,
  data: loading
})

export const testRunOutputGet = () => ({
  type: ActionType.TESTRUN_OUTPUT_GET
})

export const saveEvents = (projectId: string) => ({
  type: ActionType.EVENTS_SAVE,
  data: projectId
})

export const testRunOutputSet = (testRunOutput: Array<string>) => ({
  type: ActionType.TESTRUN_OUTPUT_SET,
  data: testRunOutput
})

export const setLoadingChartData = (loading = true) => ({
  type: ActionType.LOADING_CHART_DATA,
  data: loading
})

export const initialChartDataGet = (id: string) => ({
  type: ActionType.INITIAL_CHART_DATA_GET,
  data: id
})

export const initialChartDataSet = (initialChartData: IInitialChartData) => ({
  type: ActionType.INITIAL_CHART_DATA_SET,
  data: initialChartData
})

export const chartDataGet = (xItem: IChartItem, yItem: IChartItem) => ({
  type: ActionType.CHART_DATA_GET,
  data: {xItem: xItem, yItem: yItem}
})

export const chartDataSet = (chartData: IChartData) => ({
  type: ActionType.CHART_DATA_SET,
  data: chartData 
})

export const fastWaveConfigGet = (projectid: string) => ({
  type: ActionType.CONFIG_LAYER_GET,
  data: projectid
})
 
export const fastWaveConfigSet = (fastWaveConfig: IFastWaveConfig, store = false, startContainer = "") => ({
  type: ActionType.CONFIG_LAYER_SET,
  data: {fastWaveConfig: fastWaveConfig, store: store, startContainer: startContainer}
})

export const pointLayerGet = (projectid: string) => ({
  type: ActionType.POINT_LAYER_GET,
  data: projectid
})

export const pointLayerSet = (geojson: object) => ({
  type: ActionType.POINT_LAYER_SET,
  data: geojson
})

export const pointLayerInitialDraw = () => ({
  type: ActionType.POINT_LAYER_INITIAL_DRAW
})

export const drawnDataAdd = (features: Array<Feature>) => ({
  type: ActionType.DRAWN_DATA_ADD,
  data: features 
})

export const pointUpdate = (features: Array<Feature>, save = true) => ({
  type: ActionType.POINT_UPDATE,
  data: {features, save}  
})

export const loadPoints = (dataset: IGetDataset | null) => ({
  type: ActionType.LOAD_POINTS,
  data: dataset
})

export const clearMapContent = () => ({
  type: ActionType.CLEAR_MAPCONTENT
})

export const clearResults = () => ({
  type: ActionType.CLEAR_RESULTS
})

export const setupGet = (setup: string, download = false) => ({
  type: ActionType.SETUP_GET,
  data: {setup: setup, download: download}
})

export const setupsSet = (setups: Array<ISetup>) => ({
  type: ActionType.SETUPS_SET,
  data: setups
})

export const setLoadingSetups = (loading = true) => ({
  type: ActionType.LOADING_SETUPS,
  data: loading
})

export const setChartAxisRange = (axisRange) => ({
  type: ActionType.CHART_AXIS_RANGE_SET,
  data: axisRange
})
