/* eslint-disable @typescript-eslint/no-explicit-any */
import MuiTextField from '@mui/material/TextField';
import { useField } from 'formik';

interface IProps {
  autoComplete?: string;
  autoFocus?: boolean;
  disabled?: boolean; 
  fullWidth?: boolean; 
  label: string;
  name: string;  
  onChange?: (e: React.ChangeEvent, param: string) => void;
  required?: boolean;
  rows?: number;
  type?: string; 
  addPlaceHolder: boolean;
}


const TextField = (props: IProps) => {
  const {
    autoComplete = 'off',
    autoFocus,
    disabled, 
    fullWidth,   
    label,
    name,   
    onChange,
    required,
    rows = 3,
    type = 'text',  
    addPlaceHolder = false
  } = props;

  const [field, meta ] = useField(name);
  console.log(field)

  const onInputChanged = (event: React.ChangeEvent) => {
    const eventTarget = event.target as HTMLInputElement;
    // helpers.setValue(eventTarget.value)
    field.onChange(event);
    
    if (onChange) {
      onChange(event, eventTarget.id);
    }
  };


  return (
    <>
    <MuiTextField
      style={{ minWidth: '8em', whiteSpace: 'nowrap' }}
      autoComplete={autoComplete}
      autoFocus={autoFocus}
      disabled={disabled}
      error={meta.error ? true : false}
      fullWidth={fullWidth}
      helperText={meta.error}     
      label={label}
      multiline={type === 'textarea'}
      name={name}     
      onChange={onInputChanged}
      onBlur={field.onBlur}
      required={required}
      minRows={rows}
      type={type === 'textarea' ? 'text' : type}     
      size="small"
      variant={'filled'} 
      value={field.value !== undefined ? field.value : ""}     
    />
    {/* To avoid jumping when error message appears */}
    {addPlaceHolder && !meta.error && (
      <div
        style={{
          minHeight: '24px',
          backgroundColor: 'transparent'
        }}
      />
    )}
    </>
  )
}

export default TextField
