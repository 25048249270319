/* eslint-disable @typescript-eslint/no-explicit-any */
import ActionType from "../actions/ActionType";
import { IAction } from "../actions/Action";
import { ICreateMeshImport } from "../model/ICreateMeshImport";
import { DATASETS, DRAWING, MESHTYPE } from "../shared/constants";
import { AOI_ID, AUTO_MESH_ID, DOMAIN_ID, GEBCO_ID, OWN_SHORELINE_ID } from "../components/Viewer";

import MikeVisualizerLib from '../MikeVisualizer/lib/MikeVisualizer';
import MikeVisualizerUtil from '../MikeVisualizer/lib/MikeVisualizerUtil';
import { Stroke, Style } from 'ol/style'; 
import { FeatureCollection } from 'geojson';
import { COASTAL } from "../apis/backendApi";
import { IMeshInputEntity } from "../model/CreateMeshScenarioOptions";
import { MIKE_MAP_COLORS } from "../styles/mike-colors";

const { rendererReady } = MikeVisualizerUtil;

export interface ICreateMeshParameter {
  name: string;
  defaultValue: number | string;
  valueType: "integer" | "string" | "boolean";
  displayName: string;
  entityType: "Domain" | "AOI" | "Shoreline" | "Mesh";
  minValue?: number;
  maxValue?: number;
  value?: number | string | boolean;
  entityId?: string;
  info?: Array<string>;
}
export interface ICreateMeshInfo {
  scenarioName: string;
  type: string; 
  parameterDescriptions: Array<ICreateMeshParameter>;
}

export const AUTO_DOT_MESH = "FWE-Mesh.mesh"
export const AUTO_MESH = "Auto mesh"
export const DOMAIN = "Domain"
export const AOI = "AOI"
export const GEBCO_BATHYMETRY = "GEBCO bathymetry"
export const GEBCO = "GEBCO"
export const UPLOADING_BATHYMETRY = "Uploading bathymetry"
/* export const SHORELINE = "Shoreline"
export const NOAA_SHORELINE = "Coastline (NOAA)" */

export interface ICreateMeshDataset { 
  resolution?: number; // default resolution?
  dataset?: string; // reference to Platform dataset
  id?: string; // reference to Mesh Builder vtk item
  name?: string; 
  itemType?: string; 
  updated?: string;
  selected?: boolean; // selected for meshing (aois) or interpolation (bathymetries)
  priority?: number; // bathymetry prorization
}

export interface IEntity extends ICreateMeshDataset {
  status: string;  
  canUpdate: boolean;
  hasProperties: boolean;
  entityType: string;
  key?: string;
} 

export interface ICreateMeshConfig {
  targetSrid?: number;
  validBounds?: Array<number>;
  meshOutline?: ICreateMeshDataset; 
  areasOfInterest?: Array<ICreateMeshDataset>;
  bathymetryDatasets?: Array<ICreateMeshDataset>;  
}

export interface ICreateMeshState 
{
  targetSrid: number | null;
  meshOutline: ICreateMeshDataset | null;
  meshAreasOfInterest: Array<ICreateMeshDataset>;
  bathymetryDatasets: Array<ICreateMeshDataset>; 
  pendingImport: ICreateMeshImport | null; 
  creatingWorkspace: boolean;
  creatingMesh: boolean;
  loadingOutline: boolean;
  loadingAreasOfInterest: boolean;
  loadingGebco: boolean;
  loadingBathymetry: boolean;
  meshType: string;
  vtk_domain: string;
  vtk_aoi: string;
  vtk_gebco: string;
  vtk_mesh: string;
  vtk_shoreline: string;
  vtk_own_shoreline: string;
  canInterpolateMesh: boolean;
  interpolatingMesh: boolean;
  checkingMeshIsExported: boolean;
  meshIsExported: boolean;
  loadingShoreline: boolean;
  shorelines: Array<ICreateMeshDataset>;
  createMeshPayload: ICreateMeshInfo | null;
  drawnGeometry: FeatureCollection | null; 
  abortDotMeshIsExportedCheck: boolean;
  scenarios: Array<string>;
  loadingScenarios: boolean;
  workspaceScenario: string;
  meshInputEntities: Array<IMeshInputEntity>;
}

const initState = 
{ 
  meshOutline: null,
  meshAreasOfInterest: new Array<ICreateMeshDataset>(),
  bathymetryDatasets: [],
  targetSrid: null,
  pendingImport: null,
  creatingWorkspace: false,
  creatingMesh: false,
  loadingOutline: false,
  loadingAreasOfInterest: false,
  loadingGebco: false,
  meshType: MESHTYPE.UPLOAD,
  vtk_domain: "",
  vtk_aoi: "",
  vtk_gebco: "",
  vtk_mesh: "",
  vtk_shoreline: "",
  vtk_own_shoreline: "",
  canInterpolateMesh: false,
  interpolatingMesh: false,
  checkingMeshIsExported: false,
  meshIsExported: false,
  loadingShoreline: false,
  shorelines: [], //[{name: NOAA_SHORELINE, id: SHORELINE_ID, selected: true}],
  createMeshPayload: null,
  drawnGeometry: null,
  abortDotMeshIsExportedCheck: false,
  scenarios: new Array<string>(),
  loadingScenarios: false,
  workspaceScenario: "",
  meshInputEntities: new Array<IMeshInputEntity>(),
}

const getOutlineStyle = (color) => {
  const outlineStyle = () => {   
    return new Style({
      stroke: new Stroke({
        color: color,
        width: 3
      })
    });
  };
  return outlineStyle;
};

const createMesh = (state = initState, action: IAction) => {
  switch (action.type) {
    case ActionType.SET_CREATE_MESH_PAYLOAD: {
      const {payload, meshEntities} = action.data
      return {...state, createMeshPayload: payload, meshInputEntities: meshEntities}
    }
    case ActionType.DELETE_VTKITEMS: {
      const {itemsToDelete, datasetType} = action.data
      if (datasetType === DATASETS.BATHYMETRY && itemsToDelete && itemsToDelete.length > 0){
        const ids = itemsToDelete.map(item => item.id)
        const filtered = state.bathymetryDatasets.filter((b: ICreateMeshDataset) => !ids.includes(b.id))
        const gebco = filtered.find((variable: ICreateMeshDataset) => variable.name.startsWith(GEBCO));
        const other = filtered.filter((variable: ICreateMeshDataset) => !variable.name.startsWith(GEBCO));
        const all = gebco !== undefined ? [...other, gebco] : other   
        return {...state, bathymetryDatasets: all.map((b: ICreateMeshDataset, index: number) => {return {...b, priority: index + 1}}) };
      }
      else if (datasetType === DATASETS.OUTLINE){
        const filtered = state.bathymetryDatasets.filter((b: ICreateMeshDataset) => b.name !== GEBCO_BATHYMETRY)
        return {...state, bathymetryDatasets: filtered, meshOutline: null, vtk_domain: "", vtk_gebco: "", vtk_mesh: "", vtk_shoreline: ""}
      }
      else if (datasetType === DATASETS.AREAOFINTEREST){
        return {...state, meshAreasOfInterest: [], vtk_aoi: ""}
      }
      else if (datasetType === DATASETS.OWN_SHORELINE){
        return {...state, shorelines: initState.shorelines}
      }

      return state;
    }
    case ActionType.SET_MESH_IS_EXPORTED: {
      return {...state, meshIsExported: action.data}
    }
    case ActionType.CHECKING_MESH_IS_EXPORTED: {
      return {...state, checkingMeshIsExported: action.data}
    }
    case ActionType.SET_CAN_INTERPOLATE_MESH: {
      return {...state, canInterpolateMesh: action.data}
    }
    case ActionType.INTERPOLATING_MESH: {
      return {...state, interpolatingMesh: action.data}
    }
    case ActionType.ABORT_DOT_MESH_EXPORTED_CHECK: {
      return {...state, abortDotMeshIsExportedCheck: action.data}
    }
    case ActionType.LOADING_SHORELINE: {
      return {...state, loadingShoreline: action.data}
    }
    case ActionType.LOADING_OUTLINE: {
      return {...state, loadingOutline: action.data}
    }
    case ActionType.LOADING_AOI: {
      return {...state, loadingAreasOfInterest: action.data}
    }
    case ActionType.LOADING_GEBCO: {
      return {...state, loadingGebco: action.data}
    }
    case ActionType.LOADING_BATHYMETRY: {
      return {...state, loadingBathymetry: action.data}
    }
    case ActionType.CLEAR_AUTO_MESH: {    
      const {scenarios, scenario, meshType} = action.data 
      return {...initState, meshType, scenarios, workspaceScenario: scenario};
    }
    case ActionType.RESET_AUTO_MESH: {
      const interpolating = action.data
      if (interpolating){
         return state
      }     
      return {...state, vtk_mesh: ""}
    }
    case ActionType.VTK_SET: {
      const {vtk, layerId} = action.data;
      if (layerId === AOI_ID){
        return {...state, vtk_aoi: vtk, loadingAreasOfInterest: false};
      }
      else if (layerId === DOMAIN_ID){
        return {...state, vtk_domain: vtk, loadingOutline: false};
      }
      else if (layerId === GEBCO_ID){
        return {...state, vtk_gebco: vtk, loadingGebco: false};
      }
      else if (layerId === AUTO_MESH_ID){
        return {...state, vtk_mesh: vtk, creatingMesh: false, canInterpolateMesh: true};
      }
     /*  else if (layerId === SHORELINE_ID){
        return {...state, vtk_shoreline: vtk};
      } */
      else{
        return state
      }
    }
    case ActionType.LOADING_SCENARIOS: {
      return {...state, loadingScenarios: action.data}
    }
    case ActionType.SET_SCENARIOS: {
      const wsScenarios = action.data    
      return {...state, scenarios: action.data, loadingScenarios: false, workspaceScenario: wsScenarios && wsScenarios.includes(COASTAL) ? COASTAL : "" }
    }
    case ActionType.SET_SCENARIO: {
      return {...state, workspaceScenario: action.data}
    }
    case ActionType.MESHTYPE_SET: {
      return {...state, meshType: action.data.changeToType}
    }
    case ActionType.UPDATE_SELECTED_AOIS: {
      const vtkItemIds = action.data
      if (state.meshAreasOfInterest && state.meshAreasOfInterest.length > 0){
        return { ...state, meshAreasOfInterest: state.meshAreasOfInterest.map((aoi: ICreateMeshDataset) => {return {...aoi, selected: vtkItemIds.includes(aoi.id)}} ) };
      }
      return state
    }
    case ActionType.UPDATE_PARAMETERS: {
      const { parameters, entityType, entityId } = action.data
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const payload = state.createMeshPayload as any
      if (payload && payload.parameterDescriptions){
        const parameterDescriptions: Array<ICreateMeshParameter> = payload.parameterDescriptions;
        const params = parameterDescriptions.map((pd: ICreateMeshParameter) => {
          if (pd.entityType.toLowerCase() !== entityType.toLowerCase()){
            return pd
          }
          else {
            return {...pd, entityId, value: parameters[pd.name]}
          }        
        }) 
        return {...state, createMeshPayload: {...payload, parameterDescriptions: params }}
      }
      return state;
    }
    case ActionType.CREATING_WORKSPACE: {
      return { ...state, creatingWorkspace: action.data };
    }
    case ActionType.CREATING_MESH: {
      return { ...state, creatingMesh: action.data };
    }
    case ActionType.PENDING_IMPORT_SET:
      return { ...state, pendingImport: action.data };
    case ActionType.CANCEL_DRAWN_GEOMETRY: {
      const { delete2DData } = MikeVisualizerLib
      if (action.data){
        delete2DData(action.data)
      } 
      return {...state, drawnGeometry: null}
    }

    case ActionType.UPLOAD_DRAWNGEOMETRY: {
      
      if (rendererReady()){
        const { update2DData, disable2DPolygonDrawing } = MikeVisualizerLib
        const { fc, drawing } = action.data
        disable2DPolygonDrawing();
        if (drawing === DRAWING.AREAOFINTEREST){
          update2DData(fc, AOI_ID, undefined, undefined, 1, getOutlineStyle(MIKE_MAP_COLORS.VIOLET) as any); 
        }
        else if (drawing === DRAWING.MESHOUTLINE) {
          update2DData(fc, DOMAIN_ID, undefined, undefined, 1, getOutlineStyle(MIKE_MAP_COLORS.DUSTYROSE) as any);
        }
        else if (drawing === DRAWING.OWN_SHORELINE) {
          update2DData(fc, OWN_SHORELINE_ID, undefined, undefined, 1, getOutlineStyle(MIKE_MAP_COLORS.DUSTYROSE) as any);
        }
      }      
      return  {...state, drawnGeometry: null}
    }   
    case ActionType.POLYLINE_UPDATE: {
      if (rendererReady()){
        const { update2DData, disable2DPolylineDrawing } = MikeVisualizerLib
        const { fc, drawing } = action.data
        disable2DPolylineDrawing();
        if (drawing === DRAWING.OWN_SHORELINE){
          update2DData(fc, OWN_SHORELINE_ID, undefined, undefined, 1, getOutlineStyle(MIKE_MAP_COLORS.VIOLET) as any); 
        }        
      }      
      return {...state, drawnGeometry: action.data.fc}
    }
    case ActionType.ADD_SHORELINE: {    
      
      return {...state, shorelines: [{...action.data, selected: true}]} //, {name: NOAA_SHORELINE, id: SHORELINE_ID, selected: false}]}
    }
    case ActionType.SET_SELECTED_SHORELINE: {
      return {...state, shorelines: state.shorelines.map((s: ICreateMeshDataset) => {return {...s, selected: s.id === action.data}})}
    }
    case ActionType.SET_BATHYMETRY_DATASETS: {
      return {...state, bathymetryDatasets: action.data.datasets, loadingGebco: false}
    }
    case ActionType.ADD_BATHYMETRY_DATASETS:{   
      const existingIds = state.bathymetryDatasets.map((b: ICreateMeshDataset) => b.id)  
      const newBathys = action.data.filter((bathy: ICreateMeshDataset) => !existingIds.includes(bathy.id ))
      const allBathys = newBathys.concat(state.bathymetryDatasets)
      const gebco = allBathys.find((variable: ICreateMeshDataset) => variable.name.startsWith(GEBCO));
      const other = allBathys.filter((variable: ICreateMeshDataset) => !variable.name.startsWith(GEBCO));
      const all = gebco !== undefined ? [...other, gebco] : other
      return { ...state, bathymetryDatasets: all.map((b: ICreateMeshDataset, index: number) => {return {...b, priority: index + 1}}) }; 
    }
    case ActionType.SET_AREAS_OF_INTEREST: {
      if (rendererReady()){
        const { delete2DData } = MikeVisualizerLib
        delete2DData(AOI_ID)
      }
      return {...state, meshAreasOfInterest: action.data, loadingAreasOfInterest: false}
    }
    case ActionType.ADD_AREAS_OF_INTEREST:{   
      const existingIds = state.meshAreasOfInterest.map((aoi: ICreateMeshDataset) => aoi.id)  
      const newAOIs = action.data.filter((aoi: ICreateMeshDataset) => !existingIds.includes(aoi.id ))
      return { ...state, meshAreasOfInterest: state.meshAreasOfInterest.concat(newAOIs) };       
    }
    case ActionType.SET_SELECTED_OUTLINE: {
      
      if (rendererReady()){
        const { delete2DData } = MikeVisualizerLib
        delete2DData(DOMAIN_ID)
      }     
      return {...state, meshOutline: action.data}
    }
    default:
      return state;
  }
};

export default createMesh