/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { Typography } from '@mui/material';
import LandingLayout from './LandingLayout';
import { useOidc } from '@axa-fr/react-oidc';
import { useIntl } from 'react-intl'
import MikeButton from '../../mike-button';

const containerStyle = 
  css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  `;

type Props = {
  headline: string;
  body: string;
  showRetryButton?: boolean;
};

const OpenIdStatusPage = ({ headline, body, showRetryButton }: Props) => {
  const { login } = useOidc();
  const intl = useIntl()
  return (
    <LandingLayout>
      <div css={containerStyle}>        
        <Typography variant="h2">{headline}</Typography>        
        <Typography variant="body2">{body}</Typography>
        {showRetryButton && (
          <MikeButton color="secondary" variant="contained" onClick={() => login('/')}>
            {intl.formatMessage({id: 'openIdStatusPage.retry'})}
          </MikeButton>
        )}
      </div>
    </LandingLayout>
  );
};

export default OpenIdStatusPage;
