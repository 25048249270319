/** @jsxImportSource @emotion/react */
/* eslint-disable @typescript-eslint/no-explicit-any */
import  { useCallback, useMemo, useState } from 'react';
import DataTable from '../TableComponents/DataTable';
import { IGetProject } from '../../model/IGetProject';
import LongText from '../renders/LongText';
import DateTime from '../renders/DateTime';
import IconAndLongText from '../renders/IconAndLongText'
import Edit from '../../icons/Edit.svg?react';
import Project from '../../icons/Project.svg?react';
import { hoverStyle } from '../RootProjects/RootProjectsTable';
import Delete from '../../icons/Delete.svg?react';
import { actionStyle, iconStyle } from '../TableComponents/renderers';
import { getCapabilities } from '../../apis/metadataApi';

interface IProps { 
  data: Array<IGetProject>; 
  loading: boolean;
  selectedItems?: Array<IGetProject>;
  onChangePage?: (page: number) => void;
  onChangeRowsPerPage?: (rowsPerPage: number) => void;
  onItemClick: (item: IGetProject) => void; 
  onHandleRequestSort: (
    orderBy: string | ((item: any) => string | number),
    order: 'asc' | 'desc'
  ) => void;
  order: 'asc' | 'desc';
  orderBy: string;
  notSortableColumns: Array<string>;
  pagination?: boolean;
  page?: number;
  rowsPerPage?: number;
  topOffset: number;
  selectable: boolean;
  totalCount?: number;
  onSelectionChange?: (selectedItems: Array<IGetProject>) => void;
  onDeleteOneProject?: (item: IGetProject) => void;
  onEdit?: (item: IGetProject) => void;
}

const FolderTable = (props: IProps) => {
  const {   
    data,    
    loading,    
    onChangePage,
    onChangeRowsPerPage,
    onHandleRequestSort,
    onItemClick,   
    order,
    orderBy,
    page,
    pagination,
    rowsPerPage,
    topOffset,
    selectable,
    totalCount,
    notSortableColumns,
    selectedItems,
    onSelectionChange,
    onDeleteOneProject,
    onEdit
  } = props;

  const dateTimeRender = (value: string) => <DateTime jsonTime={value} />;
  const descRender = (value: string) => <LongText text={value} />;
  const projectNameRender = (value: string) => <IconAndLongText icon={<Project/>} text={value} />;
  const projectColumns = [
    {
      field: 'name',
      label: 'Name',
      render: projectNameRender,
      className: hoverStyle     
    }, 
    {
      field: 'description',
      label: 'Description',
      render: descRender,
      className: hoverStyle
    },    
    {
      field: 'createdAt',
      label: 'Created on',
      render: dateTimeRender,
      className: hoverStyle
    },
    {
      field: 'updatedAt',
      label: 'Edited on',
      render: dateTimeRender,
      className: hoverStyle
    },
  ];

  const [capabilities, setCapabilities] = useState({})

  const items = useMemo(() => {
    return data.map(item => {
      return {
        ...item,
        capabilities: capabilities[item.id] || null
      }
    })
  }, [data, capabilities])

  const renderDeleteItem = useCallback( (row: IGetProject) => {
    const canDelete = (id: string) => {
      return capabilities[id]?.canDelete || false
    }
    const canDel = canDelete(row.id)
    return (
      <div css={actionStyle(!canDel)}>
        <Delete css={iconStyle(!canDel)} />
        Delete
      </div>
    )
  }, [capabilities])

  const renderEditItem = useCallback( (row: IGetProject) => {
    const canEdit = (id: string) => {
      return capabilities[id]?.canEdit || false
    }
    const canEd = canEdit(row.id)
    return (
      <div css={actionStyle(!canEd)}>
        <Edit css={iconStyle(!canEd)} />
        Edit
      </div>
    )
  }, [capabilities])

  const fetchCapabilitiesIfNeeded = useCallback(
    (item: IGetProject) => {
      if ( capabilities[item.id] === null || capabilities[item.id] === undefined) {
        return getCapabilities(item.id).then(newCap => {
          setCapabilities({ ...capabilities, [item.id]: newCap })
          return newCap
        })
      }

      return new Promise(resolve => {
        resolve(capabilities[item.id])
      })
    },
    [capabilities]
  )

  const actionPopupStateChange = (open, row) => {
    if (open) {
      fetchCapabilitiesIfNeeded(row)
    }
  }
  

  return (
    <DataTable
      actionsPopupStateChange={actionPopupStateChange}
      actions={[
        {
          name: 'Edit',
          callBack: onEdit,
          render: renderEditItem,
          disableGutters: true
        },
        {
          name: 'Delete',
          callBack: onDeleteOneProject,
          render: renderDeleteItem,
          disableGutters: true
        }
      ]}
      loading={loading}
      columns={projectColumns as any}
      notSortableColumns={notSortableColumns} 
      idField="id"    
      data={items}    
      onCellClick={onItemClick}
      onChangePage={onChangePage}
      onChangeRowsPerPage={onChangeRowsPerPage}     
      onHandleRequestSort={onHandleRequestSort}
      onSelectionChange={onSelectionChange}
      selectedRows={selectedItems}
      orderBy={orderBy}
      order={order}
      pagination={pagination}
      page={page}
      rowsPerPage={rowsPerPage}
      selectable={selectable}
      topOffset={topOffset}
      totalCount={totalCount}     
    />
  );
};

export default FolderTable;
