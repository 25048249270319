/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import theme from './../../styles/mikeSharedTheme';
import { useCallback, useMemo } from 'react'
import { Formik, Form, } from 'formik'
import TextField from './../formik/TextField'
import * as Yup from "yup";
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux';
import { IState } from '../../reducers';
import Typography from '@mui/material/Typography';

import MikeButton from '../mike-button/MikeButton';
import Link from '@mui/material/Link';
import { useParams } from 'react-router-dom';
import { createFolder, updateFolder } from '../../actions/folders';
import { createRootProject, updateProject } from '../../actions/projects';
import { IGetProject } from '../../model/IGetProject';
import { IConfig } from '../../model/IConfig';

const appyButtonStyle = css`  
  display: flex;
  justify-content: end;
  padding-top: ${theme.spacing(1)};  
`
const hintContainerStyle = css`  
  display: flex;
  align-items: center;
  padding-top: ${theme.spacing(2)};  
`
const linkStyle = css` 
  display: contents;
  text-decoration: none;
`
export interface IValue {
  name: string; 
  description: string;
}

const validationSchema = Yup.object().shape({ 
  name: Yup.string().required("Name is required"),
  description: Yup.string()
});

interface IProps {
  project?: IGetProject | null;
}

const CreateFolderForm = (props: IProps) => { 
  const { project } = props;
  const { id } = useParams();
  const intl = useIntl();
  const dispatch = useDispatch();

  const creatingRootProject = useSelector((state: IState) => state.projects.creatingRootProject);  
  const creatingFolder = useSelector((state: IState) => state.folders.creatingFolder);  
  const updatingProject = useSelector((state: IState) => state.projects.updatingProject);
  const config: IConfig = useSelector((state: IState) => state.auth.config);

  const active = useMemo(() => {
    if (id){
      return creatingFolder || updatingProject
    } 
    else{
      return creatingRootProject || updatingProject
    }
  }, [id, creatingRootProject, creatingFolder, updatingProject])

  const cloudAdminUrl = useMemo(() => {
    const cloudAdminBaseUrl = config && config.cloudHomeUri ? config.cloudHomeUri : ""
   
    if (cloudAdminBaseUrl && id){
      return cloudAdminBaseUrl + "/project/" + id
    } 
    else{
      return cloudAdminBaseUrl
    }
  }, [id, config])

  const onSubmit = useCallback((values: IValue) => { 
    if (id){
      if (project){
        dispatch(updateFolder({...project, name: values.name, description: values.description}))
      }
      else{
        dispatch((createFolder(values, id)))
      }      
    } 
    else{
      if (project){
        dispatch(updateProject({...project, name: values.name, description: values.description}))
      }
      else{
        dispatch(createRootProject(values))
      }
    }     
  }, [dispatch, id, project])

  return (
    <div >       
      <Formik
        validationSchema={validationSchema}
        initialValues={project ? {name: project.name, description: project.description} : {name: "", description: ""}}
        enableReinitialize     
        onSubmit={onSubmit}
        render={formikProps => {
          const { handleChange, setFieldTouched, handleSubmit, isValid } = formikProps
        
          const handleOnChange = (name, e) => {
            e.persist()
            handleChange(e)
            setFieldTouched(name, true, false)              
          } 

          return (
            <Form>  
              <TextField
                onChange={handleOnChange.bind(null, 'name')}
                name='name'        
                label={intl.formatMessage({id: 'createFolder.name'})}                                        
                addPlaceHolder={true}
                fullWidth
              />   

              <TextField
                onChange={handleOnChange.bind(null, 'description')}
                name='description'        
                label={intl.formatMessage({id: 'createFolder.description'})}                                        
                addPlaceHolder={true}
                type="textarea"
                rows={4}
                fullWidth
              />  
              
              <div css={appyButtonStyle}>
                <MikeButton variant="contained" disabled={!isValid} active={active} id="createFolderButton" onClick={() => handleSubmit()}>
                  {intl.formatMessage({id: project ? 'editFolder.editFolder' : 'createFolder.createFolder'})}               
                </MikeButton> 
              </div>           
              </Form>
              )
            }}
          />
          {project && cloudAdminUrl && 
            <div css={hintContainerStyle}>
              <Typography variant='body2'>{intl.formatMessage({id: 'editFolder.advancedEditingHint'})}</Typography>
              <Link href={cloudAdminUrl} target="_blank" rel="noopener" css={linkStyle}>
                <MikeButton variant="text">
                  {intl.formatMessage({id: 'editFolder.cloudAdmin'})}
                </MikeButton>
              </Link>
            </div>
          }
    </div>
  )
}

export default CreateFolderForm