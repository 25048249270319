/** @jsxImportSource @emotion/react */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { css } from '@emotion/react';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import Link from '@mui/material/Link';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CircularProgress from '@mui/material/CircularProgress';
import { useDispatch, useSelector } from 'react-redux';
import { IState } from '../../reducers';
import { BOUNDARY_CONDITION_SOURCE, DATALINKJOBS, ENVIRONMENTALDATATYPE, JOBS, OWNDATAHINTS } from '../../shared/constants';
import { dataLinkExtractData, dataLinkSetSelectedProvider, extractionPeriodEndSet,  extractionPeriodStartSet, fastWaveConfigSet, getPreviousExtractions, setPreviousExtraction } from '../../actions/mapContent';
import { IFastWaveConfig, IJob } from '../../model/IFastWaveConfig';
import { StyledSelect } from '../Sidebar/StyledSelect';
import { customDateTimeFormat } from '../../helpers/fixTime';
import MikeButton from '../mike-button/MikeButton';
import { getJob } from '../../helpers/fastwave';
import { DATA_LINK_JOBS_IN_PROGRESS, JOBS_IN_PROGRESS } from '../../model/IJobStatus';
import { titleContainer, titleStyle, subTitleStyle, radioStandardStyle } from '../../shared/styles';
import { useIntl } from 'react-intl';
import { IWarning, WARNINGTYPE } from '../InitialSelection';
import { downloadOwnDataTemplate, resetBoundaryCondition, setEnvironmentalDataType, setOwnDataDialogType, uploadOwnData } from '../../actions/ownData';
import MikeDialog from '../DialogComponents/MikeDialog';
import OwnDataDialog from '../OwnDataDialog';
import UploadButton from '../UploadButton/UploadButton';
import { IGetProject } from '../../model/IGetProject';
import Download from '../../icons/Download.svg?react';
import { iconSecondaryStyle } from '../EditPointsForm/iconStyles';
import { IDataLinkProvider } from '../../reducers/mapContent';
import { IDisplayProvider } from '../../model/IGetDataLinkMeshStatus';
import { IGetDataset } from '../../model/IGetDataset';
import { formatDate } from '../../mike-shared-helpers/date';
import mikeSharedTheme from '../../styles/mikeSharedTheme';
import { helperTextPlaceHolderStyle } from '../../styles/sharedDropdownStyles';
import MikeDatePicker from '../mike-date-picker';

const spinnerContainerStyle = css`  
  padding-top:${mikeSharedTheme.spacing(2)};
  padding-bottom:${mikeSharedTheme.spacing(9)};
  align-items: center;
  justify-content: center;
`
const noProviderDataContainer = css`  
  padding-top:${mikeSharedTheme.spacing(2)};
  padding-bottom:${mikeSharedTheme.spacing(11)};
`
const providerContainerStyle = css`
  padding-top: ${mikeSharedTheme.spacing(2)};
  padding-left: ${mikeSharedTheme.spacing(2)};
  display: flex; 
  align-items: flex-end; 
`
const providerItemStyle = css`
  padding-right: ${mikeSharedTheme.spacing(5)};
`
const ownDataStyle = css`
  display: flex;  
  align-items: center;
`
const ownDataTemplateContainerStyle = css` 
  padding-right: ${mikeSharedTheme.spacing(1)};
`
const instructionsContainerStyle = css`
  padding-top: ${mikeSharedTheme.spacing(6)};
`

const selectWidth =  css`
  ${mikeSharedTheme.spacing(90)};
`

const fieldsetStyle =  css`
  padding: ${mikeSharedTheme.spacing(1)};
  margin: 0,
  & .MuiFormControl-root: {
    display: block;
  }
`

const radioGroupStyle =  css`
  display: block;
  color: ${mikeSharedTheme.palette.primary.main};
  & .MuiTypography-root, .MuiTypography-root.Mui-disabled, .MuiFormControlLabel-root: {
    color: ${mikeSharedTheme.palette.primary.main};
    font-size: 16px;
  }
`
const selectPeriodContainerStyle =  css`
  padding: ${mikeSharedTheme.spacing(2)}; 
  width: ${mikeSharedTheme.spacing(53)}; 
`
const uploadContainerStyle = css`
  padding: ${mikeSharedTheme.spacing(2)}; 
`
const flexStyle = css`
  display: flex;    
`
const flexWithFixedWidthStyle = css`
  display: flex;
  width: ${mikeSharedTheme.spacing(80)}; 
 `
const dateStartLabelStyle = css`
  width: 50vw;
`
const dateEndLabelStyle =  css`
  width: 50vw;
  padding-left: ${mikeSharedTheme.spacing(4)}; 
`
const providerLabelStyle =  css`  
  padding-bottom: ${mikeSharedTheme.spacing(1)}; 
`
const linkLabelStyle = css`  
  padding-left: ${mikeSharedTheme.spacing(0.5)}; 
  padding-right: ${mikeSharedTheme.spacing(0.5)}; 
  cursor: pointer;
  color: ${mikeSharedTheme.palette.secondary.main};
  &:hover: {
    color: ${mikeSharedTheme.palette.secondary.main};
  };
  &:active: {
    color: ${mikeSharedTheme.palette.secondary.main};
  };
  &:focus: {
    color: ${mikeSharedTheme.palette.secondary.main};
  };
  & .MuiTypography-root, .MuiTypography-root.Mui-disabled, .MuiFormControlLabel-root: {
    color: ${mikeSharedTheme.palette.secondary.main};
  };     
`


const VALUEFIELD = "name";
const PRIMARYFIELD ="displayName";

const EXTRACTIONVALUEFIELD = "id";
const EXTRACTIONPRIMARYFIELD ="name";

const BoundaryConditions = () => { 
  const dispatch = useDispatch();
  const intl = useIntl();

  const fastWaveConfig: IFastWaveConfig = useSelector(
    (state: IState) => state.mapContent.fastWaveConfig
  );   

/*   const meshIsWithinGWMErrors: Array<string> = useSelector(
    (state: IState) => state.mapContent.meshIsWithinGWMErrors
  );

  const meshIsWithinGWMChecked: boolean = useSelector(
    (state: IState) => state.mapContent.meshIsWithinGWMChecked
  ); */

  const extractionPeriodStart: string = useSelector(
    (state: IState) => state.mapContent.extractionPeriodStart
  );

  const extractionPeriodEnd: string = useSelector(
    (state: IState) => state.mapContent.extractionPeriodEnd
  );

  const dataLinkExtractionBuilderData = useSelector(
    (state: IState) => state.mapContent.dataLinkExtractionBuilderData
  ); 

  const dataLinkProviders: Array<IDataLinkProvider> = useSelector(
    (state: IState) => state.mapContent.dataLinkProviders
  ); 

  const dataLinkOverlappingPeriod = useSelector(
    (state: IState) => state.mapContent.dataLinkOverlappingPeriod
  ); 

  const jobs: Array<IJob> = useSelector(
    (state: IState) => state.job.jobs
  ); 

  const environmentalDataType: string =  useSelector(
    (state: IState) => state.ownData.environmentalDataType
  );

  const project: IGetProject | null = useSelector(
    (state: IState) => state.projectContent.project
  );

  const loadingDataLinkExtractionBuilderData: boolean = useSelector(
    (state: IState) => state.mapContent.loadingDataLinkExtractionBuilderData
  );

  const loadingPreviousExtractions: boolean = useSelector(
    (state: IState) => state.mapContent.loadingPreviousExtractions
  );

  const previousExtractions: Array<IGetDataset> = useSelector(
    (state: IState) => state.mapContent.previousExtractions
  );

  const boundaryConditionDataset: IGetDataset = useSelector(
    (state: IState) => state.mapContent.boundaryConditionDataset
  );

  useEffect(() => {
    project && dispatch(getPreviousExtractions(project.id))
  }, [dispatch, project])

  const ownDataFileName  = useMemo(() => {
    return fastWaveConfig && fastWaveConfig.userdefined_metocean_data ? fastWaveConfig.userdefined_metocean_data : '';    
  }, [fastWaveConfig])

  const [expanded, setExpanded] = React.useState('');

  const previousBoundaryConditions = useMemo(() => {
    if (previousExtractions){     
      return previousExtractions.map((d: IGetDataset) => {
        const dateLabel = formatDate(d.createdAt, true);
        const label = d.description ? dateLabel + " " + d.description : dateLabel;
        return {...d, secondaryLabel: + intl.formatMessage({id: 'boundaryConditions.createdOn'}) + ": " + label}});
    }
    return []; 
  }, [intl, previousExtractions])
  
  useEffect(() => {
    setExpanded(environmentalDataType)
  }, [environmentalDataType])

  const handlePanelChange = (panel) => (_event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  
  const onChangeStartDate = (e: Date) => {  
    const isoString = customDateTimeFormat(e)
    dispatch(extractionPeriodStartSet(isoString))   
  };
  const onChangeEndDate = (e: Date) => {
    const isoString = customDateTimeFormat(e)
    dispatch(extractionPeriodEndSet(isoString))
  };  

  const extractionJobIsRunning = useMemo(() => {
    const job = getJob(jobs, DATALINKJOBS.DATAEXTRACTION)
    const jobStatus = job !== undefined && job.status ? job.status : ""
    return DATA_LINK_JOBS_IN_PROGRESS.includes(jobStatus.toLowerCase())  
  }, [jobs])

  const ownDataJobIsRunning = useMemo(() => {
    const job = getJob(jobs,JOBS.ENVIRONMENTALDATA)
    const jobStatus = job !== undefined && job.status ? job.status : ""
    return JOBS_IN_PROGRESS.includes(jobStatus.toLowerCase())  
  }, [jobs])

  const [ fileTemp, setFileTemp ] = useState<any>();
  const [previousBoundaryDatasetId, setPreviousBoundaryDatasetId] = useState('');
  const [warning, setWarning] = useState<IWarning | null>(null); 

  const handleOnCancel = (_event?, reason?) => {
    if(!reason || (reason !== 'backdropClick' && reason !== 'escapeKeyDown')) {
      setWarning(null);    
      setFileTemp(null);
    }
  }  

  const showOwnDataLink = useCallback(() => {
    dispatch(setOwnDataDialogType(OWNDATAHINTS.LINK))
  }, [dispatch])
  
  const showOwnDataHowTo = useCallback(() => {
    dispatch(setOwnDataDialogType(OWNDATAHINTS.HOWTO))
  }, [dispatch])

  const handleChangeDataType = useCallback((event: { target: { value: string }; }) => {
    const changeToType = event.target.value.toString();
    dispatch(setEnvironmentalDataType(changeToType))
    const updatedConfig = {...fastWaveConfig, [BOUNDARY_CONDITION_SOURCE]: changeToType}
    dispatch(fastWaveConfigSet(updatedConfig, true))
  }, [dispatch, fastWaveConfig])

  const handleCloseOwnDataDialog = useCallback(() => {
    dispatch(setOwnDataDialogType(""))
  }, [dispatch])

  const handleDownloadOwnDataTemplate = useCallback(() => {
    dispatch(downloadOwnDataTemplate())
  }, [dispatch])

  const onDropOwnData = useCallback(
  (files) => {
    if (files.length > 0){  
      const file = files[0]  
      if (fastWaveConfig && fastWaveConfig.data_link_output_file){
        setWarning({warning: intl.formatMessage({id: 'warnings.outputAndConfigDeleted'}),type:WARNINGTYPE.UPLOADENVIRONMENTALDATA})
        setFileTemp(file) 
      }
      else{
        dispatch(uploadOwnData(file, file.name)) 
      }  
    }
  },[dispatch, fastWaveConfig, intl])    

  const handleExtractData = useCallback(() => {    
    /* if (!meshIsWithinGWMChecked){
      setGwmCheckWwarning({warning: intl.formatMessage({id: 'warnings.pendingGwmCheckTitle'}),type:WARNINGTYPE.PENDINGGWMCHECK})  
    }
    else if (meshIsWithinGWMErrors && meshIsWithinGWMErrors.length > 0){
      setGwmCheckWwarning({warning: intl.formatMessage({id: 'warnings.gwmWarningTitle'}),type:WARNINGTYPE.MESHNOTINGWM})      
    } */
    if (fastWaveConfig && fastWaveConfig.data_link_output_file){
      setWarning({warning: intl.formatMessage({id: 'warnings.outputAndConfigDeleted'}),type:WARNINGTYPE.EXTRACTPROVIDERDATA})     
    }
    else{
      dispatch(dataLinkExtractData())
    }  
  }, [dispatch, fastWaveConfig, intl])

  const canCreateContent = useMemo(() => {
    return project && project.capabilities && project.capabilities.canCreateContent
  }, [project])

  const handleWarningConfirmed = useCallback(() => {
    dispatch(resetBoundaryCondition())
    const warningType = warning ? warning.type : '';
    setWarning(null);
    switch (warningType){
      case WARNINGTYPE.UPLOADENVIRONMENTALDATA:
        if (fileTemp && fileTemp.name){
          setFileTemp(null);
          dispatch(uploadOwnData(fileTemp, fileTemp.name));
        }
        break;
      case WARNINGTYPE.EXTRACTPROVIDERDATA:
        dispatch(dataLinkExtractData());
        break;
      case WARNINGTYPE.USEPREVIOUSEXTRACTION:{
        setPreviousBoundaryDatasetId('')
        const boundaryDS = previousExtractions.find((d: IGetDataset) => d.id === previousBoundaryDatasetId)
        boundaryDS && dispatch(setPreviousExtraction(boundaryDS))        
        break;
      }
      default:
        break;
    }
  }, [dispatch, warning, fileTemp, previousExtractions, previousBoundaryDatasetId]) 

  const handleChangeProvider = (providers: Array<IDisplayProvider>, providerId: string, type: string) => {
    dispatch(dataLinkSetSelectedProvider(providers.find((p: IDisplayProvider) => p.name === providerId), type))
  }   

  const handleChangeBoundaryConditionDataset = useCallback((id: string) => {
    if (fastWaveConfig && fastWaveConfig.data_link_output_file && fastWaveConfig.data_link_output_file.dataset_id && fastWaveConfig.data_link_output_file.dataset_id !== id){
      setPreviousBoundaryDatasetId(id)
      setWarning({warning: intl.formatMessage({id: 'warnings.outputAndConfigDeleted'}),type:WARNINGTYPE.USEPREVIOUSEXTRACTION})     
    }
    else{
      const boundaryDS = previousExtractions.find((d: IGetDataset) => d.id === id)
      boundaryDS && dispatch(setPreviousExtraction(boundaryDS))
    }
   
  }, [dispatch, fastWaveConfig, intl, previousExtractions])

  return (
    <>
      <OwnDataDialog 
        onCancel={handleCloseOwnDataDialog} 
        onOk={handleCloseOwnDataDialog}
      />
      <MikeDialog 
        open={warning && warning.warning ? true : false} 
        onCancel={handleOnCancel} 
        onOk={handleWarningConfirmed}
        dialogTitle={intl.formatMessage({id: 'warnings.pleaseConfirm'})}
        contentTitle={intl.formatMessage({id: 'warnings.confirmDialogContentTitle'})}
        message={warning && warning.warning ? warning.warning : ''}   
        okButtonLabel={intl.formatMessage({id: 'warnings.change'})} 
      />
      <div>   
        <div css={titleContainer}>
          <div>
            <Typography variant="h5" css={titleStyle}>{intl.formatMessage({id: 'boundaryConditions.title'})}</Typography>
            <Typography variant="body2" css={subTitleStyle}>{intl.formatMessage({id: 'boundaryConditions.subTtile'})}</Typography>
          </div>        
        </div>
       <FormControl fullWidth component="fieldset" css={fieldsetStyle}>          
        <RadioGroup aria-label="fileformat" name="fileformat" value={environmentalDataType} onChange={handleChangeDataType} css={radioGroupStyle}> 
          <Accordion expanded={expanded === ENVIRONMENTALDATATYPE.PROVIDER} onChange={handlePanelChange(ENVIRONMENTALDATATYPE.PROVIDER)}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-label="Expand"
              aria-controls="additional-actions1-content"
              id="additional-actions1-header"
            >
              <FormControlLabel 
                disabled={!(dataLinkOverlappingPeriod && dataLinkOverlappingPeriod.length > 1)}
                value={ENVIRONMENTALDATATYPE.PROVIDER} 
                control={<Radio color="primary" 
                css={radioStandardStyle} />} 
                label={<b>{intl.formatMessage({id: 'boundaryConditions.extractDataFromOurProviders'})}</b>}                
              /> 
            </AccordionSummary>  
            <AccordionDetails>
              {loadingDataLinkExtractionBuilderData ? <div css={spinnerContainerStyle}><CircularProgress/></div>
              : dataLinkOverlappingPeriod && dataLinkOverlappingPeriod.length > 1 ? 
                <div>
                  <div css={selectPeriodContainerStyle}>
                    <div css={flexStyle}>
                      <Typography css={dateStartLabelStyle} variant="body2">{intl.formatMessage({id: 'boundaryConditions.startDate'})}</Typography>
                      <Typography css={dateEndLabelStyle} variant="body2">{intl.formatMessage({id: 'boundaryConditions.endDate'})}</Typography>                    
                    </div>                   
                    <MikeDatePicker
                      rangePicker
                      minDateStart={new Date(dataLinkOverlappingPeriod[0])}
                      maxDateStart={new Date(dataLinkOverlappingPeriod[1])}
                      minDateEnd={new Date(dataLinkOverlappingPeriod[0])}
                      maxDateEnd={new Date(dataLinkOverlappingPeriod[1])}
                      defaultStartDate={extractionPeriodStart ? new Date(extractionPeriodStart) : new Date(dataLinkOverlappingPeriod[0])}
                      onChangeStartDate={onChangeStartDate}
                      defaultEndDate={extractionPeriodEnd ? new Date(extractionPeriodEnd) : new Date(dataLinkOverlappingPeriod[1])}
                      onChangeEndDate={onChangeEndDate}
                      hideIcon={false}                  
                    />
                </div>
                <div css={providerContainerStyle}>
                  {dataLinkProviders.map((p: IDataLinkProvider, i: number) => (
                    <div css={providerItemStyle} key={i}>
                      <Typography css={providerLabelStyle} variant="body2">{p.name}</Typography>                   
                      <StyledSelect             
                        fullWidth={true}
                        name={p.selected ? p.selected.displayName : ''}
                        value={p.selected ? p.selected.name : ''}         
                        valueField={VALUEFIELD}
                        primaryField={PRIMARYFIELD}
                        items={p.providers}
                        onChange={(event: React.ChangeEvent<{value: string}>) => handleChangeProvider(p.providers, event.target.value, p.id)}
                        disabled={environmentalDataType !== ENVIRONMENTALDATATYPE.PROVIDER}
                        showColorIcon={false}
                      />                  
                    </div>      
                  ) )}
                  <div>
                  <MikeButton variant="outlined" active={extractionJobIsRunning} id="extractData" disabled={!canCreateContent || !dataLinkExtractionBuilderData || environmentalDataType !== ENVIRONMENTALDATATYPE.PROVIDER} color="secondary" onClick={() => handleExtractData()}>
                    {intl.formatMessage({id: 'boundaryConditions.extractData'})}               
                  </MikeButton> 
                  <div css={helperTextPlaceHolderStyle}></div>
                </div>
              </div>
              </div>
            : <div css={noProviderDataContainer}><Typography variant="body2">{intl.formatMessage({id: 'boundaryConditions.noProviderDataAvailableYet'})}</Typography></div>
            } 
            </AccordionDetails>
          </Accordion>  
          <Accordion expanded={expanded === ENVIRONMENTALDATATYPE.UPLOAD} onChange={handlePanelChange(ENVIRONMENTALDATATYPE.UPLOAD)} >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-label="Expand"
              aria-controls="additional-actions2-content"
              id="additional-actions2-header"
            >
              <FormControlLabel            
                value={ENVIRONMENTALDATATYPE.UPLOAD} 
                control={<Radio color="primary" 
                css={radioStandardStyle} />} 
                label={<b>{intl.formatMessage({id: 'boundaryConditions.useYourOwnData'})}</b>}                
              /> 
            </AccordionSummary>
            <AccordionDetails>
              <div css={uploadContainerStyle}>
                <div css={ownDataStyle}>
                  <div css={ownDataTemplateContainerStyle}>
                    <TextField 
                      label={intl.formatMessage({id: 'boundaryConditions.uploadedTemplate'})}
                      value={ownDataFileName}
                      disabled={environmentalDataType !== ENVIRONMENTALDATATYPE.UPLOAD}
                      onClick={showOwnDataHowTo}
                      fullWidth
                    />
                  </div>              
                  <div>
                    <MikeButton css={providerItemStyle} variant="text" disabled={environmentalDataType !== ENVIRONMENTALDATATYPE.UPLOAD} onClick={handleDownloadOwnDataTemplate}>
                      <Download css={iconSecondaryStyle(environmentalDataType !== ENVIRONMENTALDATATYPE.UPLOAD)} width={24} height={24} viewBox={"0 0 40 40"} />
                      {intl.formatMessage({id: 'boundaryConditions.downloadTemplate'})} 
                    </MikeButton>
                    <UploadButton
                      variant={"outlined"}
                      disabled={!canCreateContent || environmentalDataType !== ENVIRONMENTALDATATYPE.UPLOAD || ownDataJobIsRunning} 
                      fileType={['.csv']} 
                      label={intl.formatMessage({id: ownDataFileName ? 'boundaryConditions.replaceTemplate' : 'boundaryConditions.uploadTemplate'})} 
                      onDrop={onDropOwnData}
                    />               
                  </div>
                </div>
                <div css={instructionsContainerStyle}>
                  <div css={flexWithFixedWidthStyle}>
                    <Typography variant="body2">{intl.formatMessage({id: 'boundaryConditions.ownDataHintPart1'})}</Typography>
                    <Link css={linkLabelStyle} onClick={handleDownloadOwnDataTemplate}>
                      <Typography  variant="body2">
                        {intl.formatMessage({id: 'boundaryConditions.ownDataHintLink'})}
                      </Typography>
                    </Link> 
                    <Typography variant="body2">{intl.formatMessage({id: 'boundaryConditions.ownDataHintPart2'})}</Typography>
                  </div>
                  <div css={flexWithFixedWidthStyle}>
                    <Typography variant="body2">{intl.formatMessage({id: 'boundaryConditions.click'})}</Typography>
                    <Link css={linkLabelStyle} onClick={showOwnDataLink}>
                      <Typography  variant="body2">
                        {intl.formatMessage({id: 'boundaryConditions.instructionsForUsingFWEDataTemplate'})}
                      </Typography>
                    </Link>       
                    <Typography variant="body2">{intl.formatMessage({id: 'boundaryConditions.forMoreDetailedInstructions'})}</Typography>
                  </div>
                </div>
              </div>  
            </AccordionDetails>
          </Accordion> 
          <Accordion expanded={expanded === ENVIRONMENTALDATATYPE.PREVIOUS} onChange={handlePanelChange(ENVIRONMENTALDATATYPE.PREVIOUS)} >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-label="Expand"
              aria-controls="additional-actions2-content"
              id="additional-actions2-header"
            >
              <FormControlLabel  
                disabled={previousBoundaryConditions.length === 0}          
                value={ENVIRONMENTALDATATYPE.PREVIOUS} 
                control={<Radio color="primary" 
                css={radioStandardStyle} />} 
                label={<b>{intl.formatMessage({id: 'boundaryConditions.selectFromPreviousExtractions'})}</b>}                
              /> 
            </AccordionSummary>
            <AccordionDetails>
            {loadingPreviousExtractions ? <div css={spinnerContainerStyle}><CircularProgress/></div> :
              <div css={uploadContainerStyle}>
                <div css={selectWidth}>
                  <StyledSelect             
                    fullWidth={true}
                    name={boundaryConditionDataset ? boundaryConditionDataset.name : ''}
                    value={boundaryConditionDataset ? boundaryConditionDataset.id : ''}         
                    valueField={EXTRACTIONVALUEFIELD}
                    primaryField={EXTRACTIONPRIMARYFIELD}
                    secondaryField="secondaryLabel"
                    items={previousBoundaryConditions}
                    onChange={(event: React.ChangeEvent<{value: string}>) => handleChangeBoundaryConditionDataset(event.target.value)}
                    disabled={environmentalDataType !== ENVIRONMENTALDATATYPE.PREVIOUS}
                    showColorIcon={false}
                  />    
                </div>  
              </div> 
            }
            </AccordionDetails>
          </Accordion>
        </RadioGroup>
      </FormControl> 
    </div>
  </>
  )
}

export default BoundaryConditions
