/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React from 'react';
import Button from '@mui/material/Button';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import { getMonth, getDaysInMonth } from 'date-fns';
import DownTriangle from '../../icons/DownTriangle.svg?react';
import { selectStyle } from './MikeDatePickerStyles';

interface IProps {
  date: Date;
  onChangeMonth: (e) => void;
  onDateChange: (date: Date) => void;
}

const menuListStyle = css`
  max-height: 280px;   
  margin-top: 5px;
  overflow-y: auto;
  width: 105px;
`;

const MikeDatePickerSelectMonth: React.FC<IProps> = ({ date, onChangeMonth, onDateChange }) => {
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  const month = getMonth(date);  
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLButtonElement>(null);
  const [selectedIndex, setSelectedIndex] = React.useState(month);

  const handleToggle = () => {
    setOpen((wasOpen) => !wasOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }
    setOpen(false);
  };

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current!.focus();
    }
    prevOpen.current = open;
  }, [open]);

  const handleSelect = (e, index: number) => {
    setSelectedIndex(index);
    setOpen(!setOpen);
    onChangeMonth(e);
    checkDayOfMonth(index);
  };

  const checkDayOfMonth = (currentmonth: number) => {
    let day = date.getDate();
    date.setDate(1);
    date.setMonth(currentmonth);
    const daysInMonth = getDaysInMonth(date);
    if (day > daysInMonth) {
      day = daysInMonth;
    }
    date.setDate(day);
    onDateChange(date);
  };

  return (
    <span style={{ float: 'left', marginLeft: '10px'  }}>
      <Button
        css={selectStyle}
        ref={anchorRef}
        aria-controls={open ? 'menu-list-grow' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        style={{
          justifyContent: 'start',
        }}
        endIcon={<DownTriangle />}
      >
        {months[getMonth(date)]}
      </Button>
      <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  css={menuListStyle}
                  autoFocusItem={open}
                  id="menu-list-grow"
                  onKeyDown={handleListKeyDown}
                >
                  {months.map((option, index) => (
                    <MenuItem
                      key={option}
                      value={option}
                      selected={index === selectedIndex}
                      onClick={(event) => handleSelect(event, index)}
                    >                     
                      {option}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </span>
  );
};

export default MikeDatePickerSelectMonth;
