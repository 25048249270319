import { Button as MuiButton, CircularProgress } from '@mui/material';
import { ButtonProps as MuiButtonProps } from '@mui/material/Button';
import { css } from '@emotion/react'

const spinnerStyle = css`
  opacity: 0.8;  
`;

export interface IMikeButtonProps extends MuiButtonProps {
  /**
   * If true, a spinner will be shown and children will be hidden
   */
  active?: boolean;
}

/**
 * @name MikeButton
 * @summary A convenience wrapper for the MuiButton with support the the DHI Guideline buttons: Primary, Secondary (outlined), Ultimate and Text/Link
 */
const MikeButton = (props: IMikeButtonProps) => {
  const {    
    active,
    children,
    color: colorProp,
    variant: variantProp,
    className: classNameProp,
    ...rest
  } = props;

  return (
    <MuiButton css={classNameProp} variant={variantProp} color={colorProp} {...rest}>
      {active && (
        <CircularProgress
          size={28}
          variant="indeterminate"
          color="inherit"
          css={spinnerStyle}
        />
      )}

      {!active && children}
    </MuiButton>
  );
};

export default MikeButton;
