/* eslint-disable @typescript-eslint/no-explicit-any */
import { useRef } from 'react';
import debounce from 'lodash-es/debounce';
import size from 'lodash-es/size';
import { TextField, CircularProgress, ListItemText, Autocomplete } from '@mui/material';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import IProjection from './model/IProjection';
import ProjectionDetails from './MikeProjectionDetails';
import { useIsMounted } from '../../mike-shared-helpers/hooks';

export interface ITextResources {
  code: string;
  label: string;
  loading: string;
  noData: string;
  placeholder: string;
}

const optionSX = {
  overflowX: 'hidden'
};

const listboxSX = {
  maxHeight: '20vh'
};
const primarySX = {
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whiteSpace: 'nowrap'
};

interface IProps {
  disabled?: boolean;
  /**
   * Projection selected callback
   */
  onProjectionSelected: (epsg: number) => void;
  /**
   * Text input change callback (to fetch respective projections)
   */
  onProjectionSearchTextChanged: (searchText: string) => void;
  /**
   * Projections matching search string (to be updated by onProjectionSearchTextChanged)
   */
  projections: Array<IProjection>;
  /**
   * Loading state
   */
  projectionsLoading: boolean;
  /**
   * Set to true to use projection id in getOptionLabel (otherwise projection name is taken)
   */
  searchById?: boolean;
  /**
   * To overwrite text resources like e.g. input field label
   */
  textResources?: ITextResources;
  /**
   * Projection deleted callback
   */
  onProjectionDelected?: () => void;
}

const COORDINATE_SYSTEM_SEARCH_TEXT_MIN_LEN = 3;

/**
 * @name MikeProjectionSelect
 * @summary An autocomplete component to select projection systems by id or name
 *
 * @param props
 */
const MikeProjectionSelect = (props: IProps) => {
  const {
    disabled,
    onProjectionSelected,
    onProjectionSearchTextChanged,
    projectionsLoading,
    projections,
    searchById,
    textResources = {
      code: 'Authority code',
      label: 'Coordinates are in',
      loading: 'Loading...',
      noData: 'No projection found',
      placeholder: 'Type at least 3 chars of code or name',
    },
    onProjectionDelected
  } = props;

  const isMounted = useIsMounted();

  const debouncedSave = useRef(
    debounce((newSearchText: string) => onProjectionSearchTextChanged(newSearchText), 500)
  ).current;

  /**
   * Callback for when the projection search input has changed.
   * @param _event
   * @param newSearchText
   */
  const onProjectionInputChange = (_event, newSearchText) => {
    if (!isMounted) {
      return;
    }

    if (size(newSearchText) >= COORDINATE_SYSTEM_SEARCH_TEXT_MIN_LEN) {
      debouncedSave(newSearchText);
    }
  }; 

  return (
    <Autocomplete  
      disabled={disabled}   
      sx={{ option: optionSX, listbox: listboxSX }}
      autoComplete={false}
      id={'project-select'}
      disablePortal={false}
      loading={projectionsLoading}
      options={projections}
      getOptionLabel={(option) => (searchById ? option.id.toString() : option.name)}
      isOptionEqualToValue={(option, value) => {
        return option.id === value.id;
      }}
      onChange={(_event, newProjection: IProjection | null) => {
        if (newProjection) {
          onProjectionSelected(newProjection.id);
        }
        else{
          onProjectionDelected && onProjectionDelected();
        }
      }}
      onInputChange={onProjectionInputChange}
      noOptionsText={textResources.noData}
      loadingText={textResources.loading}
      selectOnFocus={true}
      forcePopupIcon={false}
      clearOnEscape={true}
      disableListWrap={true}
      renderOption={(props, option) => (
      <li {...props} key={option.id}>
        <ListItemText
          sx={{ primary: primarySX, secondary: primarySX }}
          disableTypography={true}
          primary={option.name}
          secondary={<ProjectionDetails item={option} />}
        />
        </li>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          label={textResources.label}
          size="small"
          placeholder={textResources.placeholder}
          variant="filled"
          fullWidth
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <>
                <SearchOutlinedIcon />
                {params.InputProps.startAdornment}
              </>
            ),
            endAdornment: (
              <>
                {projectionsLoading ? <CircularProgress size={'1rem'} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
          InputLabelProps={{
            ...params.InputLabelProps,
          }}
        />
      )}
    />
  );
};

export default MikeProjectionSelect;
