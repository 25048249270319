/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import Home from '../../icons/Home.svg?react';

const linkButtonStyle = css`
  display: flex;
  align-items: center;
  padding: 0 10px;
  min-height: 60px;
  &:hover: {
    background-color: #DBE4E9;
  };
  margin-top: -4px;
`;

interface IProps {
  loggedIn: boolean;
  hrefLink: string;
}

/**
 * @name MikeHomeButton
 * @summary A home button to be placed into the topbar.
 */
const MikeHomeButton = (props: IProps) => {  
  const { loggedIn, hrefLink } = props;
  if (loggedIn === true) {
    return (
      <a css={linkButtonStyle} target="_blank" href={hrefLink}>
        <Home />
      </a>
    );
  } else {
    return null;
  }  
}

export default MikeHomeButton;
