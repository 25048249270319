import ActionType from "../actions/ActionType";
import { IAction } from "../actions/Action";
import { ENVIRONMENTALDATATYPE } from "../shared/constants";

export interface IOwnDataState 
{
  environmentalDataType: string;
  dialogType: string; 
}

const initState = 
{ 
  dialogType: "",
  environmentalDataType: ENVIRONMENTALDATATYPE.PROVIDER,
}

const ownData = (state = initState, action: IAction) => {
  switch (action.type) {
    case ActionType.OWNDATA_SHOW_DIALOG:
      return { ...state, dialogType: action.data };
    case ActionType.OWNDATA_SET_DATATYPE:
      return { ...state, environmentalDataType: action.data };
    default:
      return state;
  }
};

export default ownData