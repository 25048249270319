const getConfigUrl = () => {
  const mode = import.meta.env.MODE;
  switch (mode){
    case 'development':
      return '/config/appsettings-dev.json'
    default:
    return '/config/appsettings.json'
  }
  /* switch (backendUrl) {
    case 'api_dev':
      return '/config/appsettings-dev.json'
    case 'api_local':
      return '/config/appsettings-local.json'
    case 'api_local_FWE':
      return '/config/appsettings-local-fwe.json'     
    default:
      return '/config/appsettings.json'
  } */
} 

async function fetchConfig() {
  const configPath = getConfigUrl()
  const resp = await fetch(configPath)
  const config = await resp.json()
  return config
}

export default fetchConfig
