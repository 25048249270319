/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { useState, useCallback } from 'react';
import { useOidc } from '@axa-fr/react-oidc';
import Popper from '@mui/material/Popper';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Paper from '@mui/material/Paper';
import Fab from '@mui/material/Fab';
import { Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import './mike-login.css';
import { IState } from '../../../reducers';
import MikeButton from '../../mike-button';
import mikeSharedTheme from '../../../styles/mikeSharedTheme';

interface IProps {
  changeSiteLabel?: string;
  logInLabel?: string;
  logOutLabel?: string;
  userLabel?: string;
  siteLabel?: string;
  flat?: boolean;
}

export const LOGGINGOUT = 'loggingOut';

const userDetailsPopperStyle = css`
  z-index: 10000;
  margin-top: ${mikeSharedTheme.spacing(0.25)};
`;

const buttonsContainerStyle = css`
  display: flex;
  justify-content: end;
`;

const MikeLogin = (props: IProps) => {
  const {
    logOutLabel = 'Logout',
    changeSiteLabel = 'Change site',
    userLabel = 'Name',
    siteLabel = 'Site',
    flat,
  } = props;
  const { logout, login } = useOidc();

  const user = useSelector((state: IState) => state.auth.user);
  const tenants = useSelector((state: IState) => state.auth.tenants);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [userDetailsShown, setUserDetailsShown] = useState(false);

  const handleLogout = async () => {
    const _paq = (window._paq = window._paq || []);
    _paq.push(['resetUserId']);
    _paq.push(['appendToTrackingUrl', 'new_visit=1']);
    _paq.push(['trackPageView']);
    _paq.push(['appendToTrackingUrl', '']);
    sessionStorage.setItem(LOGGINGOUT, 'true');
    logout('/');
  };

  const handleChangeSite = async () => {
    login('/');
  };

  const handleClickAway = useCallback(() => {
    setAnchorEl(null);
    setUserDetailsShown(false);
  }, []);

  const toggleUserDetails = useCallback(
    (event) => {
      if (!userDetailsShown){
        setAnchorEl(event.currentTarget);
      }
      setUserDetailsShown(!userDetailsShown);
    },
    [userDetailsShown],
  );

  const userInitials = user ? user.initials : '';
  const userName = user ? userLabel + ': ' + user.name : '';
  const siteName = user ? siteLabel + ': ' + user.tenantName : '';

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <div>
        <Fab
          onClick={toggleUserDetails}
          color="primary"
          size="small"
          style={{
            fontWeight: 300,
            fontSize: 12,
            minHeight: 0,
            width: 32,
            height: 32,
            boxShadow: flat ? 'none' : undefined,
          }}
        >
          {userInitials}
        </Fab>
        <Popper
          anchorEl={anchorEl}
          placement="bottom-end"
          open={userDetailsShown}
          disablePortal={false}
          onClick={toggleUserDetails}
          modifiers={[{
            name: 'preventOverflow',
            enabled: true,
            options: {
              rootBoundary: 'window'
            } 
          }]}
          css={userDetailsPopperStyle}
        >
          <Paper elevation={2} style={{ padding: 16 }}>
          <div>
              <Typography>{userName}</Typography>
              <Typography>{siteName}</Typography>
            
              <div css={buttonsContainerStyle}>
                {tenants &&
                  tenants.length > 1 && (
                    <MikeButton
                      color="secondary"
                      size="small"
                      onClick={handleChangeSite}
                      style={{ marginRight: 8 }}
                      variant="contained"
                    >
                      {changeSiteLabel}
                    </MikeButton>
                  )}
                <MikeButton color="secondary" variant="contained" size="small" onClick={handleLogout}>
                  {logOutLabel}
                </MikeButton>
              </div>
            </div>
          </Paper>
        </Popper>
      </div>
    </ClickAwayListener>
  );
};

export default MikeLogin;
