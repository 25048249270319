import { isEqual } from 'lodash-es';

import { defaults } from 'ol/interaction.js';
import Map from 'ol/Map';
import View from 'ol/View';
import { Vector as VectorSource } from 'ol/source.js';
import { Vector as VectorLayer } from 'ol/layer.js';
import { Style } from 'ol/style';

import MikeVisualizerStore from '../../store/MikeVisualizerStore';
import MikeVisualizer2DMapUtil from '../MikeVisualizer2DMapUtil';
import MikeVisualizer2DInteractions from './MikeVisualizer2DInteractions';
import MikeVisualizerUtil from '../../MikeVisualizerUtil';
import { IMapProperties } from '../../IMikeVisualizerModels';
import { MAP_IDS, VIEWER_ZINDEX } from '../../MikeVisualizerConstants';
import { bindInteractorEvents } from '../../vtk-extensions/vtkBasicFullScreenRenderWindow';
import { getOlStylePresets } from '../MikeVisualizer2DDrawConstants';

const { rendererReady } = MikeVisualizerUtil;
const { getState, setState } = MikeVisualizerStore;
const {
  _getViewerProperties,
  _getEpsgString,
  _verifyProjection,
  _zoomAndCenterOpenLayersView,
  _getVectorLayer,
} = MikeVisualizer2DMapUtil;
const { _removeDrawMapInteractions } = MikeVisualizer2DInteractions;

let subscriptions: Array<any> = []; // Local interactor subscriptions. These should be managed within this module and cleared upon destroy.

/**
 * Contains methods required for creating a 2d draw map & setting up all default interactions.
 * The setup methods are typically called behind the scenes, when i.e. enabling drawing interactions or tools.
 *
 * While active, it allows getting information, drawing & editing shapes on an instance of OpenLayers.
 * The drawing tools OpenLayer instance does not have a basemap (it is transparent).
 *
 * @note This component is an addon. It is not needed for the MikeVisualizer to function. It can be dynamically imported anywhere in the MikeVisualizer or outside of it.
 *
 * @module MikeVisualizer2DDrawCore
 * @version 1.0.0
 *
 * @internal
 */

/**
 * Moves the draw map to the position (center & zoom) that matches the 3D viewer's bounds (current corners of the canvas). * This method won't update the map if the draw map properties (bbox, height, width, etc) haven't changed.
 *
 * @private
 */
const _syncDrawMapTo3DViewerBounds = () => {
  const { drawMapProperties, drawMap } = getState();
  const viewerProperties = _getViewerProperties();

  if (!drawMap) {
    return false;
  }

  if (viewerProperties && !isEqual(viewerProperties, drawMapProperties)) {
    const view = drawMap.getView();
    _zoomAndCenterOpenLayersView(view, viewerProperties as IMapProperties);
    setState({
      drawMapProperties: { ...drawMapProperties, ...(viewerProperties as IMapProperties) },
    });
  }

  return true;
};

/**
 * Creates an OpenLayers map for the provided epsdg code.
 * Event listeners are also setup for interactions on the 3D viewer & resize.
 * Whenever the 3D viewer is interacted with, the map position sync accordingly.
 *
 * Note: the 3d viewer interactor is binded to the OpenLayers draw map container. That way, the OpenLayers map acts just as the 3d viewer would. This keeps things simple & allows syncing only from the 3D viewer => OpenLayers.
 * The listeners `unsubscribe` methods are stored so they can be called when destroying the map.
 */
const _setupOpenLayersDrawMap = async (): Promise<Map | false> => {
  if (!rendererReady()) {
    console.debug('Attempted to setup draw map before the renderer was ready.');
    return false;
  }

  const { container: vtkContainer, renderWindow, fullScreenRenderWindow, epsgCode } = getState();
  const epsgString = _getEpsgString(epsgCode);
  const mapContainer = document.createElement('div');

  // Verify given projection and try to fetch it if not defined.
  try {
    await _verifyProjection(epsgCode);
  } catch (error) {
    console.error('Failed to setup draw map.', error);
    return false;
  }

  // Create a view for the given projection. The view is what reprojects the tiles.
  const view = new View({
    projection: epsgString,
    center: [0, 0],
    zoom: 5,
  });

  // Create a HTMLElement container where OpenLayers will add canvas, controls, etc. The container should be removed when destroyed.
  mapContainer.style.cssText = `
    width: 100%;
    height: 100%;
    z-index: ${VIEWER_ZINDEX.DRAWMAP};
    opacity: 1;
  `;
  mapContainer.id = MAP_IDS.DRAWMAP_CONTAINER_ID;
  (vtkContainer as HTMLElement).appendChild(mapContainer);

  const source = new VectorSource({ wrapX: true });
  const vector = new VectorLayer({
    source,
    updateWhileAnimating: false,
    updateWhileInteracting: false,
    // renderMode: 'image', // not a valid option for VectorLayer
  });
  vector.setStyle(getOlStylePresets().DEFAULT.vectorLayer);

  const map = new Map({
    controls: [],
    layers: [vector],
    target: mapContainer,
    view,
    interactions: defaults({
      altShiftDragRotate: false,
      onFocusOnly: false,
      // constrainResolution: false, // not a valid option
      doubleClickZoom: false,
      keyboard: false,
      mouseWheelZoom: false,
      shiftDragZoom: false,
      dragPan: false,
      pinchRotate: false,
      pinchZoom: false,
      zoomDelta: undefined,
      zoomDuration: undefined,
    }),
  });

  _removeDrawMapInteractions(map);

  const interactor = renderWindow.getInteractor();
  const defaultSubscriptions = bindInteractorEvents(interactor, mapContainer);

  subscriptions = [
    ...subscriptions,
    interactor.onStartMouseWheel(_syncDrawMapTo3DViewerBounds),
    interactor.onEndMouseWheel(_syncDrawMapTo3DViewerBounds),
    interactor.onMouseWheel(_syncDrawMapTo3DViewerBounds),
    interactor.onPinch(_syncDrawMapTo3DViewerBounds),
    interactor.onPan(_syncDrawMapTo3DViewerBounds),
    interactor.onMouseMove(_syncDrawMapTo3DViewerBounds),
    ...defaultSubscriptions, // Also keep track of default bindings to unsubscribe later
  ];

  setState({ drawMap: map, drawMapProperties: { epsgCode } });

  // Make sure the map is updated when the window is resized.
  fullScreenRenderWindow.setResizeCallback(_syncDrawMapTo3DViewerBounds);

  // Do an initial sync with the 3D viewer
  self._syncDrawMapTo3DViewerBounds();

  return map;
};

/**
 * Removes the open layer drawmap and:
 *  - re-binds the interactor to the 3d viewer
 *  - unsubscribes from all map events
 *  - removes the HTMLElement used as a container
 *  - updates state and clears draw map properties
 *
 * @private
 */
const _destroyOpenLayersDrawMap = () => {
  if (!rendererReady()) {
    console.debug('Attempted to destroy draw map before the renderer was ready.');
    return false;
  }

  const { container: vtkContainer, drawMap, fullScreenRenderWindow, renderWindow } = getState();
  const mapContainer = (vtkContainer as HTMLElement).querySelector(
    `#${MAP_IDS.DRAWMAP_CONTAINER_ID}`
  );

  if (!mapContainer) {
    console.debug('Dropped removing draw map because the map container is not in the DOM anymore.');
    return false;
  }

  try {
    subscriptions = subscriptions.filter((subscription) => subscription.unsubscribe());

    /**
     * NB: it's important to re-bind events to the 'real viewer' aka the 3D renderer.
     * Keep in mind that the interactor can only be binded to one container at a time and that has to be either the open layers one while drawing or the 3D renderer otherwise.
     */
    bindInteractorEvents(renderWindow.getInteractor(), fullScreenRenderWindow.getContainer());

    if (drawMap){
      drawMap.setTarget(undefined);
    }
    (vtkContainer as HTMLElement).removeChild(mapContainer);

    setState({ drawMapProperties: {}, drawMap: null });
    // setState({ drawMapProperties: null, drawMap: null });

    return true;
  } catch (e) {
    console.error('Failed to remove draw map.', e);
    return false;
  }
};

/**
 * Gets the currently instantiated draw map.
 * If no map was instantiated yet, it sets it up by default.
 *
 * @private
 */
const _getOrSetupDrawMap = async () => {
  const { drawMap } = getState();

  if (!drawMap) {
    return self._setupOpenLayersDrawMap();
  }

  return drawMap;
};

/**
 * Sets the draw map vector layer style to the provided style function.
 *
 * @param vectorLayerStyle
 */
const setDrawMapVectorLayerStyle = (
  vectorLayerStyle: (feature) => Array<Style> = getOlStylePresets().DEFAULT.vectorLayer
): boolean => {
  const { drawMap } = getState();

  if (drawMap) {
    const vectorLayer = _getVectorLayer(drawMap);

    vectorLayer.setStyle(vectorLayerStyle);
    return true;
  }

  return false;
};

/**
 * This method is intended to be called from external modules.
 * For now, it triggers a map change callback, but in the future it might cancel or reset other map properties.
 *
 * @public
 */
const forceOpenLayersDrawMapUpdate = () => self._syncDrawMapTo3DViewerBounds();

const self = {
  _syncDrawMapTo3DViewerBounds,
  _setupOpenLayersDrawMap,
  _destroyOpenLayersDrawMap,
  _getOrSetupDrawMap,

  forceOpenLayersDrawMapUpdate,
  setDrawMapVectorLayerStyle,
};

export default self;
