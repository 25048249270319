/* eslint-disable @typescript-eslint/no-explicit-any */
import capitalize from 'lodash-es/capitalize';
import toLower from 'lodash-es/toLower';
import { Checkbox, TableCell } from '@mui/material';
import Folder from '../../icons/Folder.svg?react';
import { isDataset } from './support';
import { IGetDataset } from '../../model/IGetDataset';
import LongText from '../renders/LongText';
import DateTime from '../renders/DateTime';
import { IColumn } from '../TableComponents/support';

export const firstTableCellRender = (
  item: IGetDataset,
  isSelectable: boolean,
  isSelected: boolean,
  onClick: (
    event: any,
    item: IGetDataset
  ) => void
) => {
  if (isDataset(item)) {
    if (isSelectable) {
      return (
        <TableCell padding="checkbox" onClick={(event) => onClick(event, item)}>
          <Checkbox checked={isSelected} />
        </TableCell>
      );
    } else {
      return <TableCell padding="checkbox" />;
    }
  } else {
    return (
      <TableCell padding="checkbox">
        <Folder />
      </TableCell>
    );
  }
};

const typeRender = (value: string, item: IGetDataset) => {
  const properties = item.properties
  const geometryType = properties && properties['GeometryType'];
  return value
    ? capitalize(value) + (geometryType ? ' / ' + capitalize(geometryType) : '')
    : 'Folder';
};
const typeComparator = (item: IGetDataset) => {
  const properties = item.properties
  const geometryType = properties && properties['GeometryType'];
  const type =
    'datasetType' in item
      ? geometryType
        ? toLower(item.datasetType) + ' / ' + toLower(geometryType)
        : toLower(item.datasetType)
      : 'zzzz';
  return type;
};

const itemNameRender = (value: string) => <LongText text={value} />;
const nameComparator = (item:  IGetDataset) =>
  item.name.toLowerCase();

const dateTimeRender = (value: string) => <DateTime jsonTime={value} />;

export const projectContentColumns = [
  {
    field: 'name',
    label: 'Name',
    render: itemNameRender,
    comparator: nameComparator
  },
  {
    field: 'datasetType',
    label: 'Type',
    render: typeRender,
    comparator: typeComparator
  },
  {
    field: 'createdAt',
    label: 'Create on',
    render: dateTimeRender
  },
] as Array<IColumn>;
