export const IMPORT_TYPE = 'Import'

export const FILE_IMPORTER_ID = 'file'

export const SRID_PARAM = 'SRID'
export const ZCOLUMNINDEX_PARAM = 'ZColumnIndex'
export const CONNECTCOLUMNINDEX_PARAM = 'ConnectColumnIndex'
export const XYZCOLUMNSSEQUENCE = 'xyzColumnSequence'

interface IParameter {
  name: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any;  
}

const mapWriterParameters = data => {
  const writerParameters = Array<IParameter>()

  if (data.writerParameters) {
    const pairs = Object.entries(data.writerParameters);
    pairs.forEach((key, value) => {     
      if (key && value){        
        writerParameters.push({ name: key.toString(), value })
      }      
    })   
  }
  return writerParameters
}

const mapReaderParameters = data => {
  const readerParameters = Array<IParameter>()

  if (data.readerParameters) {
    const pairs = Object.entries(data.readerParameters);
    pairs.forEach((key, value) => {     
      if (key && value){        
        readerParameters.push({ name: key.toString(), value })
      }      
    })   
  }

  if (data.coordinateSystemId) {
    const srId = Array.isArray(data.coordinateSystemId)
      ? data.coordinateSystemId[0]
      : data.coordinateSystemId
    if (srId){
      readerParameters.push({ name: SRID_PARAM, value: srId })
    } 
  }

  if (data[XYZCOLUMNSSEQUENCE]) {
    switch (data[XYZCOLUMNSSEQUENCE]) {
      case 'xyzc':
        readerParameters.push({ name: ZCOLUMNINDEX_PARAM, value: 2 })
        readerParameters.push({ name: CONNECTCOLUMNINDEX_PARAM, value: 3 })
        break
      case 'xycz':
        readerParameters.push({ name: ZCOLUMNINDEX_PARAM, value: 3 })
        readerParameters.push({ name: CONNECTCOLUMNINDEX_PARAM, value: 2 })
        break
      case 'xyc':
        readerParameters.push({ name: CONNECTCOLUMNINDEX_PARAM, value: 2 })
        break
      case 'xyz':
        readerParameters.push({ name: ZCOLUMNINDEX_PARAM, value: 2 })
        break
      default:
        break
    }
  }
  return readerParameters
}

export const mapUserImportDataToConvertApiBody = (
  projectId,
  fileName,
  fileUrl,
  data
) => {
  const readerInputParameters = mapReaderParameters(data)
  const writerInputParameters = mapWriterParameters(data)

  const body = {
    originalFileName: fileName,
    uploadUrl: fileUrl,
    projectId,
    outputDatasetData: {
      name: data.name,
      description: data.description
    },
    readerName: data.reader,
    writerName: data.writer,
    readerParameters: readerInputParameters,
    writerParameters: writerInputParameters
  }

  const bodyTransf = data.transformations ? {...body, transformations: data.transformations} : body;

  return data.properties ? {...bodyTransf, outputDatasetData: {...bodyTransf.outputDatasetData, properties: data.properties}} : bodyTransf
}

export const mapUserConvertDataToConvertApiBody = (
  conversionData,
  projectId
) => {
  const data = conversionData

  const readerInputParameters = mapReaderParameters(data)
  const writerInputParameters = mapWriterParameters(data)

  const body = {
    outputProjectId: projectId,
    outputDatasetData: {
      name: data.name,
      description: data.description
    },
    readerName: data.reader,
    writerName: data.writer,
    readerParameters: readerInputParameters,
    writerParameters: writerInputParameters
  }

  return body
}

export const mapUserDownloadDataToConvertApiBody = conversionData => {
  const data = conversionData.importData

  const readerInputParameters = mapReaderParameters(data)
  const writerInputParameters = mapWriterParameters(data)

  const body = {
    targetFileName: data.name,
    readerName: data.reader,
    writerName: data.writer,
    readerParameters: readerInputParameters,
    writerParameters: writerInputParameters
  }

  return body
}
