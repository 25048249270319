import ActionType from "./ActionType";
import { IError } from "../model/IError"
import uniqid from 'uniqid'


interface IErr {
  id: string;
  timestamp: number; 
  message: string
}
const parseError = (error: IError | string): IErr => ({
  id: uniqid.time(), 
  message: typeof(error)=== "string" ? error : error.body && error.body.Detail ? error.body.Detail :  error.message,  
  timestamp: Date.now()
})

export const addError = (error: IError | string) => ({
  type: ActionType.ADD_ERROR,
  data: parseError(error)
})

export const addErrors = (error: Array<string>)=> ({
  type: ActionType.ADD_ERRORS,
  data: {
    id: uniqid.time(), 
    message: error.join(),  
    timestamp: Date.now(),
    messages: error
  }
})

export const removeError = (id: string) => ({
  type: ActionType.REMOVE_ERROR,
  data: id
})
