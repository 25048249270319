/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import mikeSharedTheme, { IMikeTheme } from '../../styles/mikeSharedTheme';
import React from 'react';
import Snackbar from '@mui/material/Snackbar';
import Typography from '@mui/material/Typography';
import { IMikeSnackbarProps, MikeSnackbarType } from './IMikeMessage';
import { PaletteColor } from '@mui/material';


const DEFAULT_AUTOHIDE_DURATION = 6000;

const getPColor = (mikeSnackbarType: MikeSnackbarType, theme: IMikeTheme): PaletteColor => {
  switch (mikeSnackbarType) {
    case 'regular':
      return theme.palette.primary;
    case 'success':
      return theme.palette.ultimate ? theme.palette.ultimate as PaletteColor : theme.palette.primary;
    case 'error':
      return theme.palette.error;
    default:
      throw new Error(`Invalid "props.mikeSnackbarType": ${mikeSnackbarType}`);
  }
};

const rootStyle = (mikeSnackbarType: MikeSnackbarType) => { 
  const pColor = getPColor(mikeSnackbarType, mikeSharedTheme);
  return css`  
    background-color: ${pColor.main};
    color: ${pColor.contrastText};
    padding: ${mikeSharedTheme.spacing(2)} ${mikeSharedTheme.spacing(3)};
    width: ${mikeSharedTheme.spacing(46)};
    border-radius: ${mikeSharedTheme.spacing(0.5)};
    min-height: ${mikeSharedTheme.spacing(3)};
    box-shadow: 0px 6px 6px 0px rgba(0,0,0,0.16);
    bottom: ${mikeSharedTheme.spacing(4)};
    box-sizing: content-box;      
  `;
}

const mikeContentStyle = css`
  display: flex;
  width: 100%;
  justify-content: space-between;
  overflow: hidden;
  align-items: center;  
  color: ${mikeSharedTheme.palette.primary.contrastText};  
`;

const colorStyle = css`
  color: ${mikeSharedTheme.palette.primary.contrastText};  
`;

const mikeActionBtnStyle = css`
  cursor: pointer;
  padding: ${mikeSharedTheme.spacing(0)} 0 ${mikeSharedTheme.spacing(0)} ${mikeSharedTheme.spacing(3)};
  & *: {
    font-weight: 500;
  };
`;



/**
 * @name MikeSnackbar
 * @summary Snackbars provide brief messages about app processes at the bottom of the screen.
 */
export const MikeSnackbar = (props: IMikeSnackbarProps) => {
  const {
    children,
    message,
    action,
    mikeSnackbarType,
    mikeOnAction,
    autoHideDuration = DEFAULT_AUTOHIDE_DURATION,
    ...rest
  } = props;
  
  const theMessage: React.ReactNode = message ? message : children;
  const theAction: React.ReactNode =
    typeof action === 'string' ? <ActionButton css={colorStyle} text={action} /> : action;

  return (
    <Snackbar {...rest} autoHideDuration={autoHideDuration} css={rootStyle(mikeSnackbarType)}>
      <div css={mikeContentStyle}>
        <div>
          {typeof theMessage === 'string' ? (
            <Typography css={colorStyle} variant="body1">{theMessage}</Typography>
          ) : (
            theMessage
          )}
        </div>
        <div onClick={mikeOnAction}>
          {theAction}
        </div>
      </div>
    </Snackbar>
  );
};

const ActionButton = ({ text }: { text: string }) => {  
  return (
    <div css={mikeActionBtnStyle}>
      <Typography css={colorStyle} variant="body1">{text}</Typography>
    </div>
  );
};

export default MikeSnackbar;
