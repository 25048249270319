/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import React, { useCallback, useMemo, useState} from 'react';
import Typography from '@mui/material/Typography';
import EyeVisible from '../../icons/EyeVisible.svg?react';
import EyeHidden from '../../icons/EyeHidden.svg?react';
import Info from '../../icons/Info.svg?react';
import Delete from '../../icons/Delete.svg?react';
import { useDispatch } from 'react-redux';
import { addHiddenId, deleteBackground, hideLayer, loadBackgroundLayer, showLayer, updateLayer } from '../../actions/legend';
import { backgroundLayers, ILayerGroup, ILegendLayer } from '../../reducers/legend';
import MikeVisualizerLib from '../../MikeVisualizer/lib/MikeVisualizer';
import AnnotationUtils from './annotation-utils';
import { ELEVATION } from '../Viewer/Viewer';
import CircularProgress from '@mui/material/CircularProgress';
import tinycolor from 'tinycolor2';
import { Dialog, DialogContent } from '@mui/material';
import { isEqual } from 'lodash-es';
import { LIGHTMAP_COLORS } from './utils';
import { HuePicker } from 'react-color';
import Button from '@mui/material/Button'
import mikeSharedTheme, { mikePalette } from '../../styles/mikeSharedTheme';
import MIKE_COLORS from '../../styles/mike-colors';
import { iconPrimaryStyle } from '../../styles/iconStyles';

const TitleContainerStyle = (visible: boolean) => {
  const backgroundColor = visible ? mikePalette.background.paper : mikePalette.lightGrey.main;  
  return css`
    display: flex;
    justify-content: space-between;
    align-items: center;  
    background-color: ${backgroundColor};
    border-top: 1px solid ${mikePalette.mediumGrey.main};
  `;
}

export const TitleStyle = (visible: boolean) => {  
  const color = visible ? mikePalette.primary.main : mikePalette.primary.light;
  return css`
  padding-left: ${mikeSharedTheme.spacing(6)};
  &.MuiTypography-root {
    color: ${color};
  }  
`;
}

const containerStyle = css`
  background-color: ${mikeSharedTheme.palette.background.default}; 
  padding-right: ${mikeSharedTheme.spacing(1)};
`;

const buttonContainerStyle = css`
  display: flex;
  align-items: center;
`;

const currentColorStyle = css`
  display: flex;
  align-items: center;
`;

const colorStyle = css`
  width: ${mikeSharedTheme.spacing(2)};
  height: ${mikeSharedTheme.spacing(2)};
`;

const spinnerContainerStyle = css`
  height: ${mikeSharedTheme.spacing(5)};
  padding-right: ${mikeSharedTheme.spacing(1.5)};
  display: flex;
  align-items: center;
`;

const legendListitemStyle = css`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
`;

const legendListitemColorStyle = css`
  width: ${mikeSharedTheme.spacing(2)};
  height: ${mikeSharedTheme.spacing(2)};
  padding: 0px ${mikeSharedTheme.spacing(1)} 0px ${mikeSharedTheme.spacing(1)};
  align-items: center;
  flex-shrink: 0;
  flex-grow: 0;
`;

const legendListitemTextStyle = css`
  padding: 0px ${mikeSharedTheme.spacing(1)} 0px ${mikeSharedTheme.spacing(1)};
  color: ${mikeSharedTheme.palette.primary.main};
  font-size: ${mikeSharedTheme.spacing(1.5)};
`;

const typographyH5Style = css`
  margin: 0;
  text-align: right;
`;

const topScrollPaperStyle = css`
  align-items: flex-end;
  background: transparent;
`;

const availableColorsStyle = css`
  position: relative;
  width: ${mikeSharedTheme.spacing(2.5)};
  height: ${mikeSharedTheme.spacing(2.5)};
  padding: ${mikeSharedTheme.spacing(0,5)};
`;

const activeColorStyle = css`
  position: relative;
  width: ${mikeSharedTheme.spacing(2.5)};
  height: ${mikeSharedTheme.spacing(2.5)};
  padding: ${mikeSharedTheme.spacing(0.5)};
  :before {
    content: '';
    display: inline-block;
    position: absolute;
    top: ${mikeSharedTheme.spacing(-0.5)};
    left: ${mikeSharedTheme.spacing(-0.5)};
    width: ${mikeSharedTheme.spacing(3)};
    height: ${mikeSharedTheme.spacing(3)};
    border-radius: 50%;
    border: ${mikeSharedTheme.spacing(0.25)} solid ${MIKE_COLORS.BRANDBLUE_DEFAULT};
  }
`;

interface IProps {
  layerGroup: ILayerGroup;
  layer: ILegendLayer; 
}

export interface IAnnotatedValue {
  value;
  annotation: string;
  rgba: Array<number>;
}

export interface IDrawnDataLegend {
  elementId: string;
  legendItems: Array<IAnnotatedValue>;
  title: string;
}
const MAX_NUMBER_OF_ITEMS = 10;

const { getState, setColorToElement } = MikeVisualizerLib;

export const Layer = (props: IProps) => {
  const dispatch = useDispatch();
  const {layer, layerGroup} = props;   

  const colorLegend = useMemo(() => {
    if (layer && !layer.isTwoD && layer.visible){   
      const { renderedElements } = getState();
      const renderedElement = renderedElements.find(({ id }) => id === layer.id);
      if (renderedElement && renderedElement.gradientApplied){
        const gradientSettings = renderedElement.gradientSettings;
        const gradientAttributeName = renderedElement.gradientAttributeName;
        const gradientDataArray = renderedElement.dataArrays.find(({ id }) => id === gradientAttributeName);
        const annotatedValues = AnnotationUtils.getAnnotatedValues(layer.id, gradientDataArray, gradientSettings);
        const legendData: IDrawnDataLegend = {
          elementId: layer.id,
          legendItems: annotatedValues,
          title: gradientAttributeName,
        };
        return legendData;
      }
    }
    return null
  }, [layer])

  const [colorMapOpen, setColorMapOpen] = useState(false);
  const [isColorPickerOpen, setIsColorPickerOpen] = React.useState(false);  

  const renderlegend = (legendData: IDrawnDataLegend) => {
    if (!legendData || !legendData.legendItems || !legendData.legendItems.length) {
      return null;
    }

    const getNiceTitle = (title: string) => {
      if (title.toLowerCase().includes(ELEVATION)){
        return "Elevation"
      }
      else{
        return title
      }
    }

    const { legendItems } = legendData;

    // If there are too many items, we pick every nTh.
    const nTh = legendItems.length > MAX_NUMBER_OF_ITEMS ? Math.ceil(legendItems.length / MAX_NUMBER_OF_ITEMS) : 1;

    return (
      <div css={containerStyle} key={legendData.elementId}>
        <Typography css={typographyH5Style} variant={'body2'}>
          <b>{getNiceTitle(legendData.title)}</b>
        </Typography>
        {legendItems.map((item, index) => {
          if (index % nTh !== 0) {
            return null;
          }

          const rgbaStyle = item.rgba ? item.rgba.join(',') : null;

          return (
            <div key={index} css={legendListitemStyle}>
              <div css={legendListitemTextStyle}>{item.annotation}</div>
              <div css={legendListitemColorStyle} style={{ backgroundColor: 'rgba(' + rgbaStyle + ')' }} />
            </div>
          );
        })}
      </div>
    );
  };

  const handleToggleVisibilty = useCallback(() => {
    const changeToHide = layer.visible
    if (changeToHide){
     dispatch(hideLayer(layerGroup, layer))
     if (layer.groupTitle === backgroundLayers){
      dispatch(addHiddenId(layer.id))
     }
    }
    else{ 
      if (layer.loaded){
        dispatch(showLayer(layerGroup, layer))
      }
      else if (layer.canBeLoadedByUser){
        dispatch(loadBackgroundLayer(layer.id, true))
      }      
    }
  }, [dispatch, layer, layerGroup])

  const handleToggleLegendVisibilty = () => {
    setColorMapOpen((previous) => !previous)
  }

  const convertColorToHex = (c) => {
    const l = tinycolor({ r: c[0] * 255, g: c[1] * 255, b: c[2] * 255 });
    return '#' + l.toHex();
  };

  const applyColor = useCallback((color: Array<number>) => {
    setColorToElement(layer.id, [...color, 1], [...color, 1]);   
    dispatch(updateLayer(layer.groupTitle, {...layer, color: color}))
  }, [dispatch, layer]);

  const applyPickerColor = useCallback((color) => {
    const c = color.rgb;
    const decRgb = [c.r / 255, c.g / 255, c.b / 255];
    setColorToElement(layer.id, [...decRgb, 1], [...decRgb, 1]);   
    dispatch(updateLayer(layer.groupTitle, {...layer, color: decRgb}))
  }, [dispatch, layer]);

  return (     
    <div>
      <div css={TitleContainerStyle(layer.visible)}> 
        <Typography css= {TitleStyle(layer.visible)}  variant="body2"><b>{layer.title}</b></Typography>
        <div css={buttonContainerStyle}>
          
          {colorLegend ? <Info onClick={handleToggleLegendVisibilty}/> : layer.color ?  <div css={currentColorStyle} onClick={() => {
              setIsColorPickerOpen(!isColorPickerOpen);
            }}><div
            css={colorStyle}
            style={{ background: convertColorToHex(layer.color) }}           
            
          >
            {isColorPickerOpen && (
              <Dialog
                style={{ left: '50%' }}
                open={isColorPickerOpen}
                css={topScrollPaperStyle}
              >
                <DialogContent>                 
                  {LIGHTMAP_COLORS.map((color, id) => (                     
                      <button
                        key={id}
                        css={isEqual(layer.color.slice(0, 3), color) ? activeColorStyle : availableColorsStyle}
                        onClick={() => applyColor(color)}
                        style={{ backgroundColor: `rgb(${color.map((val) => val * 255).join(',')})` }}
                      />
                  ))}
                  <HuePicker
                    color={convertColorToHex(layer.color)}
                    onChangeComplete={applyPickerColor}
                  />
                </DialogContent>
              </Dialog>
            )}
          </div>         
          </div> : null}
          {layer.canDelete && !layer.loading && <Button
            style={{ minWidth: 40, padding: 0 }}
            onClick={() => dispatch(deleteBackground(layer.id))}
          >
            <Delete />
          </Button>
          }
          {layer.loading ? <div css={spinnerContainerStyle}><CircularProgress size={20}/></div> : layer.visible ? <EyeVisible onClick={handleToggleVisibilty}/> : <EyeHidden css={iconPrimaryStyle(true)} onClick={handleToggleVisibilty} />}
        </div>
      </div>
      {colorMapOpen && colorLegend && <div>{renderlegend(colorLegend)}</div>}      
    </div>
    
  );
};

export default Layer

