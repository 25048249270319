/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import mikeSharedTheme from '../../styles/mikeSharedTheme';
import ButtonBase from '@mui/material/ButtonBase';
import NotificationAlert from '../../icons/NotificationAlert.svg?react';
import NotificationBell from './../../icons/NotificationBell.svg?react';

interface IProps {
  /**
   * To set the visibility of the blue dot from parent.
   */
  dotInvisible: boolean;
  /**
   * Click callback.
   */
  onClickCallBack?: () => void;
}

const style = css`
  display: flex;
  align-items: center;
  padding: 0 10px;
  postion: relative;
  min-height: 60px;
  &:hover: {
    background-color: ${mikeSharedTheme.palette.text.disabled};
  };
  margin-top: -4px;
  cursor: pointer;
`;

/**
 * @name MikeNotificationButton
 * @summary The notification bottom, showing if new notifications are available via a red dot.
 */
const MikeNotificationButton = (props: IProps) => {

  const { onClickCallBack, dotInvisible } = props;

  return (
    <ButtonBase css={style} onClick={onClickCallBack}>
      {dotInvisible ? <NotificationBell /> : <NotificationAlert />}
    </ButtonBase>
  );
}

export default MikeNotificationButton;
