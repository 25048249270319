/** @jsxImportSource @emotion/react */
/* eslint-disable react-refresh/only-export-components */
import { css } from '@emotion/react';
import { useCallback, useMemo, useState } from 'react';
import DataTable from '../TableComponents/DataTable';
import { AOI, ICreateMeshParameter, IEntity } from '../../reducers/createMesh';
import Delete from '../../icons/Delete.svg?react';
import Resolution from '../../icons/Resolution.svg?react';
import {  tableCellButtonStyle, tableRowButtonStyle } from '../TableComponents/renderers';
import MikeDialog from '../DialogComponents/MikeDialog';
import { useDispatch, useSelector } from 'react-redux';
import { deleteVtkItems } from '../../actions/createMesh';
import MeshOutlinePropertiesForm from '../MeshOutlinePropertiesForm';
import { DATASETS, DRAWING, OUTLINE, SHORELINE } from '../../shared/constants';
import { dateTimeRender } from '../Project/projectContentColumns';
import { useIntl } from 'react-intl';
import { IState } from '../../reducers';
import LongText from '../renders/LongText';
import { IMeshInputEntity } from '../../model/CreateMeshScenarioOptions';
import MeshUpload from './MeshUpload';
import Button from '@mui/material/Button';
import { iconSecondaryStyle } from '../EditPointsForm/iconStyles';
import Draw from '../../icons/Draw.svg?react';
import CircularProgress from '@mui/material/CircularProgress';
import Checkmark from '../../icons/Checkmark.svg?react';
import ContextHelp from '../ContextHelp';
import { EGeometryItemTypes, KeyboardEditHelp } from '../Viewer/keyboard-edit-help';
import mikeSharedTheme from '../../styles/mikeSharedTheme';

export const getDatasetType = (entityType: string) => {
  let filter = entityType
    switch (entityType){
      case OUTLINE: {
        filter = DATASETS.OUTLINE
        break;
      }
      case SHORELINE: {
        filter = DATASETS.OWN_SHORELINE
        break;
      }
      case AOI: {
        filter = DATASETS.AREAOFINTEREST
        break;
      }
    }
    return filter
}

export const getDrawingType = (entityType: string) => {
  let filter = entityType
    switch (entityType){
      case OUTLINE: {
        filter = DRAWING.MESHOUTLINE
        break;
      }
      case SHORELINE: {
        filter = DRAWING.OWN_SHORELINE
        break;
      }
      case AOI: {
        filter = DRAWING.AREAOFINTEREST
        break;
      }
    }
    return filter
}

interface IProps {   
  canUpdateContent: boolean;
  onUploadFromComputer: (files,entityType: string) => void;
  onUploadFromCLoud: (entityType: string) => void;
  onDraw: (entityType: string) => void;
}

interface IDeletion {
 message: string;
 itemsToDelete: Array<IEntity>;
}

export const ENTITY_TYPE = 'entityType'
export const LOADING = "loading"
export const MISSING = "missing"
export const VALID = "valid"

export const nameStyle = 
  css`
   display: flex;
   align-items: center;
   justify-content: space-between;
   width:  ${mikeSharedTheme.spacing(18)};
  `

const drawingActiveStyle = 
  css`
   background-color: ${mikeSharedTheme.palette.secondary.light};
  `
export const circleStyle =  css`
    height: ${mikeSharedTheme.spacing(3)};
    width: ${mikeSharedTheme.spacing(3)};
    background-color: ${mikeSharedTheme.palette.ultimate.main};
    border-radius: 50%;
    display: inline-block;
 `

 const drawActionStyle = 
  css`
   display: flex;
  `

export const checkMarkStyle = () => {
  const color = mikeSharedTheme.palette.background.default;
  return css`
    path {
      fill: ${color};
      stroke: ${color};
    }
  `
}

const MeshInputTable = (props: IProps) => {
  const { 
    canUpdateContent,
    onUploadFromComputer,
    onUploadFromCLoud,
    onDraw
  } = props;
  
  const dispatch = useDispatch();
  const intl = useIntl(); 


  const itemNameRender = (value: string) => {
    let name = "";
    let hint = "";
    switch (value){
      case OUTLINE: {
        name = intl.formatMessage({id: 'autoMesh.outline'});
        hint = intl.formatMessage({id: 'autoMesh.domain_infoText'});
        break;
      }
      case AOI: {
        name = intl.formatMessage({id: 'autoMesh.aoi'});
        hint = intl.formatMessage({id: 'autoMesh.aoi_infoText'});
        break;
      }
      case SHORELINE: {
        name = intl.formatMessage({id: 'autoMesh.shoreline'});
        hint = intl.formatMessage({id: 'autoMesh.shoreline_infoText'});
        break;
      }
    }
    return <div css={nameStyle}><LongText text={name} /><ContextHelp primary helpTexts={[hint]}/></div>;
   }

  const drawing = useSelector(
    (state: IState) => state.mapContent.drawing
  );  
  const {meshInputEntities, loadingShoreline, shorelines, 
    creatingMesh, loadingOutline, loadingAreasOfInterest, loadingGebco,
     meshOutline, meshAreasOfInterest, createMeshPayload } = useSelector(
    (state: IState) => state.createMesh
  ); 

  const supportedEntities = useMemo(() => {
    return meshInputEntities.map((entity: IMeshInputEntity) => entity.type.toLowerCase())
  }, [meshInputEntities]) 

  const entities = useMemo(() => {
    const hasProperties = (payload, type) => {
      if (payload){
        const parameters = createMeshPayload && createMeshPayload.parameterDescriptions ? createMeshPayload.parameterDescriptions.filter((p: ICreateMeshParameter) => p.entityType.toLowerCase() === type.toLowerCase()) : []  
        return parameters.length > 0 
      }
      return false
    }
    const meshInput = Array<IEntity>()
    if (supportedEntities.includes(OUTLINE.toLowerCase())){ 
      meshInput.push({ ...meshOutline,
        status: meshOutline ? VALID : loadingOutline ? LOADING : MISSING,
        canUpdate: !loadingOutline && !loadingGebco && !creatingMesh,
        hasProperties: hasProperties(createMeshPayload, OUTLINE),
        entityType: OUTLINE
      })
    }
    if (supportedEntities.includes(SHORELINE.toLowerCase())){
      const shoreline = shorelines && shorelines.length > 0 ? shorelines[0] : null;      
      meshInput.push({   ...shoreline,
        status: shoreline ? VALID : loadingShoreline ? LOADING : MISSING,
        canUpdate: !loadingShoreline && !creatingMesh,
        hasProperties: hasProperties(createMeshPayload, SHORELINE),
        entityType: SHORELINE   
      })
    }
    if (supportedEntities.includes(AOI.toLowerCase())){
      const aoi = meshAreasOfInterest && meshAreasOfInterest.length > 0 ? meshAreasOfInterest[0] : null;
      meshInput.push({   ...aoi,   
        status: aoi ? VALID : loadingAreasOfInterest ? LOADING : MISSING,
        canUpdate: !loadingAreasOfInterest && !creatingMesh,
        hasProperties: hasProperties(createMeshPayload, AOI),
        entityType: AOI   
      })      
    }
    return meshInput;
  }, [createMeshPayload, creatingMesh, loadingAreasOfInterest, loadingGebco, loadingOutline, loadingShoreline, meshAreasOfInterest, meshOutline, shorelines, supportedEntities])

  
  const [confirmDeletion, setConfirmDeletion] = useState<IDeletion | null>(null);
  const [vtkItemToUpdateProperties, setVtkItemToUpdateProperties] = useState<IEntity | null>(null);

  const renderStatus = useCallback((_value: boolean, row: IEntity) => {
    const isLoading = (entityType: string) => {
      let loading = false
      switch (entityType){
        case OUTLINE: {
          loading = loadingOutline
          break;
        }
        case SHORELINE: {
          loading = loadingShoreline
          break;
        }
        case AOI: {
          loading = loadingAreasOfInterest
          break;
        }
      }
      return loading
    }

    return row && row.id ? (
      <div css={tableRowButtonStyle()}><div css={circleStyle}><Checkmark css={checkMarkStyle()} width={24} height={24} viewBox={"0 0 40 40"}/></div></div>
    ) : (
      isLoading(row.entityType) ? <div css={tableRowButtonStyle()}><CircularProgress size={20}/> </div>: <div css={tableRowButtonStyle()}>{'NA'}</div>
    )
  }, [loadingAreasOfInterest, loadingOutline, loadingShoreline])

  const renderDeleteOrUploadAction = useCallback((_value: boolean, row: IEntity) => {
    const deleteActionClicked = (row: IEntity) => {  
      if (row.id && row.canUpdate){
        setConfirmDeletion({message: intl.formatMessage({id: 'warnings.deleteItem'}), itemsToDelete: [row]})
      }    
    }

    return row && row.id ? (
      <Button  
        variant="text"
        color="secondary"
        onClick={() => deleteActionClicked(row)}
      >
        <Delete css={iconSecondaryStyle(!canUpdateContent)} width={24} height={24} viewBox={"0 0 40 40"}/>   
        {intl.formatMessage({id: 'autoMesh.delete'})}   
      </Button>
    ) : (
      <MeshUpload disabled={!row.canUpdate} entityType={row.entityType} onUploadFromCLoud={onUploadFromCLoud} onUploadFromComputer={onUploadFromComputer}/>
    )
  }, [canUpdateContent, intl, onUploadFromCLoud, onUploadFromComputer])

  const renderPropertiesOrDrawAction = (_value: boolean, row: IEntity) => {   
    const propertiesActionClicked = (row: IEntity) => {  
      if (row.canUpdate){
        setVtkItemToUpdateProperties(row)
      }
    }
    return row && row.id ? row.hasProperties ?
      (<Button
        aria-controls="customized-menu"
        aria-haspopup="true"
        variant="text"
        color="secondary"
        onClick={() => propertiesActionClicked(row)}
      >
        <Resolution css={iconSecondaryStyle(!row.canUpdate)} width={24} height={24} viewBox={"0 0 40 40"}/>  
        {intl.formatMessage({id: 'autoMesh.parameter'})}      
        </Button>
      ) : null : 
        <div css={drawActionStyle}>
          <Button disabled={!row.canUpdate} variant="text" onClick={() => {onDraw(row.entityType)}} css={drawing === getDrawingType(row.entityType) ? drawingActiveStyle : ''}>
            <Draw css={iconSecondaryStyle(!row.canUpdate)} width={24} height={24} viewBox={"0 0 40 40"}/>
            {intl.formatMessage({id: 'draw.onMap'})}               
          </Button>
          <KeyboardEditHelp geometryType={EGeometryItemTypes.POLYGON}/>
        </div> 
           
  }



  const handleOnCancel = (_event?, reason?) => {
    if(!reason || (reason !== 'backdropClick' && reason !== 'escapeKeyDown')) {
      setConfirmDeletion(null);
    }
  }

  const deleteData = useCallback(() => {
    const itemToDelete: IEntity = confirmDeletion && confirmDeletion.itemsToDelete && confirmDeletion.itemsToDelete.length > 0 ? confirmDeletion.itemsToDelete[0] : null
    const datasetType = getDatasetType(itemToDelete.entityType)
    itemToDelete && dispatch(deleteVtkItems(confirmDeletion.itemsToDelete, datasetType))
    setConfirmDeletion(null);
  }, [confirmDeletion, dispatch])

  const columns = [
    {
      field: ENTITY_TYPE,
      label: 'Type',
      render: itemNameRender,
    },
    {
      field: 'updated',
      label: 'Edited on',
      render: dateTimeRender   
    },
    {
      field: 'id',
      label: 'Status',
      render: renderStatus
    },
    {
      field: 'properties',
      label: '',
      render: renderPropertiesOrDrawAction,  
      css: tableCellButtonStyle    
    },
    {
      field: 'delete',
      label: '',    
      render: renderDeleteOrUploadAction,
      css: tableCellButtonStyle
    }    
  ]
  const notSortableColumns = columns.map(({field}) => field)
  
  return (
    <>
     <MikeDialog 
        open={confirmDeletion !== null} 
        onCancel={handleOnCancel} 
        onOk={deleteData}
        dialogTitle={intl.formatMessage({id: 'warnings.pleaseConfirm'})}
        contentTitle={intl.formatMessage({id: 'warnings.resetMesh'})}
        message={confirmDeletion ? confirmDeletion.message : ''}    
      />
    
      <MeshOutlinePropertiesForm 
        createMeshDataset={vtkItemToUpdateProperties}         
        datasetType={vtkItemToUpdateProperties && vtkItemToUpdateProperties.entityType}
        onCancel={(_event?, reason?) => {
          if(!reason || (reason !== 'backdropClick' && reason !== 'escapeKeyDown')) {
            setVtkItemToUpdateProperties(null)
          }
        }}
        onOk={() => setVtkItemToUpdateProperties(null)}
      />     
    
      <DataTable
        loading={false}
        columns={columns}
        idField={ENTITY_TYPE}   
        data={entities}
        selectedRows={new Array<IEntity>()}
        onCellClick={() => {}}      
        onSelectionChange={() => {}}
        onHandleRequestSort={() => {}}
        orderBy={ENTITY_TYPE}
        order={'asc'}   
        selectable={false}
        topOffset={0}   
        notSortableColumns={notSortableColumns} 
      />
    </>
  );
};

export default MeshInputTable;
