import { isNumber } from 'lodash-es';
import { v4 as uuid } from 'uuid';

import MikeVisualizerStore from './store/MikeVisualizerStore';

import {
  IElementDataArray,
  IColorMap,
  IAnnotatedColorMap,
  IVtkAttributes,
  ISelectAllColorMap,
} from './IMikeVisualizerModels';
import { GeometryCollection, FeatureCollection } from 'geojson';
import { RGB_COLORS } from './MikeVisualizerConfiguration';
import { DATA_ARRAY_TYPES, EDataArrayTypes } from './MikeVisualizerConstants';

const { getState } = MikeVisualizerStore;

/**
 * Utilities for MikeVisualizer & co.
 *
 * @module MikeVisualizerUtil
 * @version 1.0.0
 */

/**
 * Checks if the renderer is ready to be used.
 * TODO: this can maybe be done as a proxy to public methods. It is a bit redundant to call this in every public method.
 *
 * @public
 */
const rendererReady = () => {
  const { renderer, renderWindow } = getState();

  if (!renderer && !renderWindow) {
    return false;
  }

  return true;
};

/**
 * Sets the viewer background to the provided rgba value.
 *
 * @param r Red value from 0 to 1.
 * @param g Green value from 0 to 1.
 * @param b Blue value from 0 to 1.
 * @param a Alpha value from 0 to 1.
 *
 * @public
 */
const setViewerBackground = (r: number, g: number, b: number, a = 1) => {
  if (!rendererReady()) {
    return false;
  }

  const { renderer } = getState();
  renderer.setBackground(r, g, b, a);
  return true;
};

/**
 * Check if data contains cells.
 * @param { vtkData } vtpDataPiece The data piece to map the gradient by.
 *
 * @public
 */
const containsCellData = (vtpDataPiece): boolean => {
  return (
    vtpDataPiece &&
    vtpDataPiece.getCellData() &&
    Boolean(vtpDataPiece.getCellData().getArrays().length)
  );
};

/**
 * Check if data contains points.
 * @param { vtkData } vtpDataPiece The data piece to map the gradient by.
 *
 * @public
 */
const containsPointData = (vtpDataPiece): boolean => {
  return (
    vtpDataPiece &&
    vtpDataPiece.getPointData() &&
    Boolean(vtpDataPiece.getPointData().getArrays().length)
  );
};

/**
 * Reads all field data.
 *
 * @param { vtkData } vtpDataPiece Target to get data array information for.
 *
 * @public
 */
const getFieldData = (vtpDataPiece): Array<{ data: Array<number>; name: string }> => {
  try {
    return vtpDataPiece
      .getFieldData()
      .getArrays()
      .map((arr) => {
        return { data: arr.getData(), id: arr.getName() };
      });
  } catch (e) {
    console.info('Failed to read field data', e);
    return [];
  }
};

/**
 * Looks into the provided data and gets the available data arrays.
 * Works with both points and cells.
 *
 * @param { vtkData } vtpDataPiece Target to get data array information for.
 *
 * @public
 */
const getDataArrays = (vtpDataPiece): Array<IElementDataArray> => {
  const containsCellDataArrays = containsCellData(vtpDataPiece);
  const containsPointDataArrays = containsPointData(vtpDataPiece);

  const formatDataArray = (type: string) => (dataArray) => {
    const id = dataArray.getName();
    const range = dataArray.getRange();
    const numberOfValues = dataArray.getNumberOfValues();
    const dataType = dataArray.getDataType();

    return {
      id,
      type,
      dataType,
      range,
      numberOfValues,
    };
  };

  let dataArrays: Array<IElementDataArray> = [];

  if (containsCellDataArrays) {
    dataArrays = vtpDataPiece
      .getCellData()
      .getArrays()
      .map(formatDataArray(DATA_ARRAY_TYPES.CELLDATA));
  }

  if (containsPointDataArrays) {
    dataArrays = dataArrays.concat(
      vtpDataPiece
        .getPointData()
        .getArrays()
        .map(formatDataArray(DATA_ARRAY_TYPES.POINTDATA))
    );
  }

  return dataArrays;
};

/**
 * Retrieves avaialble data array information for a rendered element.
 *
 * @param { string } elementId Target element id to get data array information for.
 *
 * @public
 */
const getDataArraysById = (elementId): Array<IElementDataArray> => {
  const { renderedElements } = getState();
  const element = renderedElements.find(({ id }) => id === elementId);

  if (element) {
    return element.dataArrays;
  }

  return [];
};

/**
 * Retrieves the data range for a given attribute of a rendered element
 *
 * @param elementId Target element id to get data for
 * @param attributeName Target attribute name to get data range for
 *
 * @returns An array with the min and max values. If element or attribute was not found, null will be returned.
 *
 * @public
 */
const getDataRange = (elementId, attributeName): Array<number> | null => {
  if (!elementId || !attributeName) {
    return null;
  }
  const dataArray = _getVtkDataArray(elementId, attributeName);

  if (!dataArray) {
    console.info(
      'Tried to get data range of an attribute  that does not exist.',
      elementId,
      attributeName
    );

    return null;
  }

  const range = dataArray.getRange();
  if (!isNumber(range[0]) || !isNumber(range[1])) {
    console.info(
      'Tried to get range for an attribute with non-number values',
      elementId,
      attributeName
    );
    return null;
  }
  return range;
};

/**
 * Retrieves the number of components for a given attribute of a rendered element
 *
 * @param elementId Target element id to get number of components for
 * @param attributeName Target attribute name to get number of components for
 *
 * @returns A number representing the number of components.
 *
 * @public
 */
const getDataNumberOfComponents = (elementId: string, attributeName: string): number | null => {
  if (!elementId || !attributeName) {
    return null;
  }
  const dataArray = _getVtkDataArray(elementId, attributeName);

  if (!dataArray) {
    console.info(
      'Tried to get number of components of an attribute that does not exist.',
      elementId,
      attributeName
    );

    return null;
  }

  return dataArray.getNumberOfComponents();
};

/**
 * Retrieves the data values for a given attribute of a rendered element
 *
 * @param elementId Target element id to get data for
 * @param attributeName Target attribute name to get data values for
 *
 * @returns An array with the values. If element or attribute was not found, an empty array will be returned.
 *
 * @public
 */
const getDataValues = (elementId, attributeName): Array<number | string> => {
  if (!elementId || !attributeName || !rendererReady()) {
    return [];
  }

  const dataArray = _getVtkDataArray(elementId, attributeName);

  if (!dataArray) {
    console.info(
      'Tried to get data values of an attribute  that does not exist.',
      elementId,
      attributeName
    );

    return [];
  }

  const data = dataArray.getData();

  return data;
};

/**
 * Checks if a given attribute of a rendered element is stored as cell data array
 *
 * @param elementId Target element id to get data for
 * @param attributeName Target attribute name to get data values for
 *
 * @returns if a given attribute of a rendered element is stored as cell data array.
 *
 * @public
 */
const isCellData = (elementId, attributeName): boolean => {
  if (!elementId || !attributeName || !rendererReady()) {
    return false;
  }

  const dataArray = _getVtkDataArray(elementId, attributeName, DATA_ARRAY_TYPES.CELLDATA);  

  if (!dataArray) {
    return false;
  }

  return true;  
};

/**
 * Checks if a given attribute of a rendered element is stored as point data array
 *
 * @param elementId Target element id to get data for
 * @param attributeName Target attribute name to get data values for
 *
 * @returns if a given attribute of a rendered element is stored as point data array.
 *
 * @public
 */
const isPointData = (elementId, attributeName): boolean => {
  if (!elementId || !attributeName || !rendererReady()) {
    return false;
  }

  const dataArray = _getVtkDataArray(elementId, attributeName, DATA_ARRAY_TYPES.POINTDATA);  

  if (!dataArray) {
    return false;
  }

  return true;  
};

/**
 * Captures a screenshot of the currently displayed canvas. The result resolution is the current viewport (sizes may vary based on display).
 *
 * @param type
 *
 * @public
 */
const captureScreenshot = async (type = 'image/jpeg') => {
  if (!rendererReady()) {
    return false;
  }

  try {
    const { container, renderWindow } = getState();
    const VIEWER_OPAQUE_BACKGROUND_COLOR = [...RGB_COLORS.MEDIUMGREY_LIGHT, 1]; // Viewer background color to be used when opaque, i.e. when taking a screenshot.

    const [r, g, b, a] = VIEWER_OPAQUE_BACKGROUND_COLOR;

    // Before the screenshot, apply a white background. Remove it afterwards.
    self.setViewerBackground(r, g, b, a);
    renderWindow.render();
    const containerCanvas = (container as HTMLElement).querySelector('canvas');
    const screenshot = (containerCanvas as HTMLCanvasElement).toDataURL(type);
    self.setViewerBackground(0, 0, 0, 0);
    renderWindow.render();

    return screenshot;
  } catch (e) {
    console.error('Failed to get canvas screenshot', e);
    return false;
  }
};

/**
 * Looks into the provided data and gets the available arrays of data.
 * Works with both points and cells.
 *
 * @param { vtkData } vtpDataPiece Target to get data array information for.
 *
 * @private
 */
const _getvtkDataArrays = (vtpDataPiece,  type?: EDataArrayTypes): Array<any> => {
  const containsCellDataArrays = containsCellData(vtpDataPiece);
  const containsPointDataArrays = containsPointData(vtpDataPiece);

  const getCellDataArraysOnly = type !== undefined && type ===  DATA_ARRAY_TYPES.CELLDATA 
  const getPointDataArraysOnly = type !== undefined && type ===  DATA_ARRAY_TYPES.POINTDATA 

  let vtkDataArrays = [];
  if (containsCellDataArrays && !getPointDataArraysOnly) {
    vtkDataArrays = vtpDataPiece.getCellData().getArrays();
    if (getCellDataArraysOnly){
      return vtkDataArrays
    }
  }

  if (containsPointDataArrays) {
    const pointDataArrays = vtpDataPiece.getPointData().getArrays()
    if (getPointDataArraysOnly){
      return pointDataArrays
    }
    vtkDataArrays = vtkDataArrays.concat(pointDataArrays);
  }

  return vtkDataArrays;
};

/**
 * Retrieves the full vtkDataArray for a given attribute of a rendered element
 *
 * @param elementId Target element id to get data for
 * @param attributeName Target attribute name to get data values for
 * @param type Array type to get data values for
 *
 * @returns An vtkDataArray
 *
 * @private
 */
const _getVtkDataArray = (elementId: string, attributeName: string, type?: EDataArrayTypes) => {
  if (!rendererReady()) {
    return false;
  }

  if (!elementId || !attributeName) {
    return null;
  }

  const { renderer } = getState();
  const actor = renderer.getActors().find((a) => a.getActorId() === elementId);

  if (!actor) {
    console.info('Tried to get vtkDataArrayof an element that does not exist.', elementId);

    return null;
  }

  const vtpData = actor.getMapper().getInputData();
  const dataArrays = _getvtkDataArrays(vtpData, type);

  if (!dataArrays) {
    return null;
  }

  return dataArrays.find((arr) => arr.getName() === attributeName);
};

/**
 * Gets all attribute values keyed by name for a XML node.
 *
 * @param xmlNode
 *
 * @private
 */
const getXmlAttributes = (xmlNode: Element): IVtkAttributes => {
  const attributes = xmlNode.attributes;
  const attributesWithValues = {};

  for (const attribute of Object.keys(attributes)) {
    attributesWithValues[attributes[attribute].nodeName] = attributes[attribute].nodeValue;
  }

  return attributesWithValues as IVtkAttributes;
};

/**
 * Generates a vtk rgb color map for the given constraints.
 *
 * @param valuePoints
 * @param rgbPoints
 * @param outOfRangeColor
 * @param name
 *
 * @public
 *
 * @example
 * valuePoints = [-1, 1]
 * rgbPoints = [[0, 0, 0], [1, 1, 1]] // rgb: white & black
 * outOfRangeColor = [1,0,0,1] //rgba for values below and above range
 * result = [-1, 0, 0, 0, 1, 1, 1, 1] // a color map that colors all values from white (-1/rgb 0 0 0) to grey (0/rgb 0.5 0.5 0.5) to black (1/rgb 1 1 1).
 */
const generateColorMap = (
  valuePoints: Array<number>,
  rgbPoints: Array<Array<number>>,
  outOfRangeColor?: Array<number>,
  name?: string
): IColorMap => {
  const colorMap: IColorMap = {
    ColorSpace: 'RGB',
    Name: name || uuid(),
    RGBPoints: valuePoints.reduce(
      (points: Array<number>, cur, index) => [...points, cur, ...rgbPoints[index]],
      []
    ),
  };

  if (outOfRangeColor) {
    colorMap.aboveRangeColor = outOfRangeColor;
    colorMap.belowRangeColor = outOfRangeColor;
    colorMap.colorMappingRange = [valuePoints[0], valuePoints[valuePoints.length - 1]];
  }

  return colorMap;
};

/**
 * Creates an annotated color map.
 * This is useful for highlighting strings.
 *
 * @param matchColor The color to apply to each annotation.
 * @param annotations A list of string values of annotations.
 * @param showIndexColorActiveValues
 * @param [name] Optional name of the color map.
 *
 * @public
 */
const generateAnnotatedColorMap = (
  matchColor: Array<number>,
  annotations: Array<string>,
  showIndexColorActiveValues = 1,
  name?: string
): IAnnotatedColorMap => {
  return {
    ShowIndexedColorActiveValues: showIndexColorActiveValues,
    IndexedColors: [...matchColor],
    Annotations: annotations.reduce((acc: Array<string>, cur) => [...acc, '0', cur], []),
    Name: name || uuid(),
  };
};

/**
 * Creates a color map for highligting when selecting all elements.
 *
 * @param matchColor The color to apply.
 *
 * @public
 */
const generateSelectAllColorMap = (matchColor: Array<number>): ISelectAllColorMap => {
  return {
    Name: 'select_all',
    selectColor: matchColor,
  };
};

/**
 * Retrieves the bounds for a given id of a rendered element
 *
 * @param elementId Target element id to get bounds for 
 *
 * @returns Bounds of an actor
 *
 * @private
 */
const getBounds = (elementId: string) => {
  if (!rendererReady()) {
    return false;
  }

  if (!elementId) {
    return null;
  }
 
  const { renderer } = getState(); 
  const actor = renderer.getActors().find((a) => a.getActorId() === elementId);

  if (!actor) {    
    return null;
  }

  const vtpData = actor.getMapper().getInputData();  
  const bounds = vtpData.getBounds();
  return bounds;
};

/**
 * Gets viewer relative zoom.
 */
const getRelativeZoom = (): number => {
  if (!rendererReady()) {
    return 1;
  }

  const { renderer } = getState();
  const activeCamera = renderer.getActiveCamera();
  const relativeZoom = activeCamera.getDistance() / activeCamera.getPhysicalScale();

  return relativeZoom;
};

/**
 * Converts a geometry collection to a feature colllection.
 *
 * @param geometryCollection
 *
 * @public
 */
const geometryCollectionToFeatureCollection = (
  geometryCollection: GeometryCollection
): FeatureCollection<any, any> => {
  if (!geometryCollection.geometries) {
    return {
      type: 'FeatureCollection',
      features: [],
    };
  }

  return {
    type: 'FeatureCollection',
    features: geometryCollection.geometries.map((geometry) => {
      return {
        type: 'Feature',
        geometry,
        properties: null,
      };
    }),
  };
};

const self = {
  rendererReady,
  setViewerBackground,
  captureScreenshot,
  containsCellData,
  containsPointData,
  getFieldData,
  getDataArrays,
  getDataArraysById,
  getDataRange,
  getDataValues,
  getDataNumberOfComponents,
  getXmlAttributes,
  generateColorMap,
  generateAnnotatedColorMap,
  generateSelectAllColorMap,
  getRelativeZoom,
  geometryCollectionToFeatureCollection,
  isCellData,
  isPointData,
  getBounds,
};
export default self;
