/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import React, { useMemo, useState } from 'react';
import MikeSubscribedAppsLinkItem from './MikeSubscribedAppsLinkItem';
import DownTriangle from '../../icons/DownTriangle.svg?react';
import { IApplication, IApplications, IAppLink } from './model/IApplications';
import { appName, path } from './configuration';
import { getAppIcon } from './configuration/AppIcon';
import Divider from '@mui/material/Divider';
import { ClickAwayListener, Popper, Typography } from '@mui/material';
import AppSkeleton from './AppSkeleton';
import { useIntl } from 'react-intl'
import mikeSharedTheme, { mikePalette } from '../../styles/mikeSharedTheme';

interface IProps {
  /**
   * Name of current application.
   */
  currentAppName:
    | 'Mesh Builder'
    | 'Data Admin'
    | 'Data Link'
    | 'Bathymetry Builder'
    | 'MIKE 3D World'
    | 'Admin Center'
    | 'Flood Screener'
    | 'MIKE Fast Wave Emulator'
    | 'Fast Wave Emulator'
    | 'Metocean Simulator' 
    | 'MetOcean Simulator'  
    | string;
  /**
   * Applications the user has access to returned from MIKE Cloud Home backend.
   */
  applications: IApplications;
  /**
   * ProjectId returned from url path.
   */
  projectId?: string;
  /**
   * Label of app list header.
   */
  appListHeaderLabel?: string;
  /**
   * Loading state.
   */
  loading?: boolean;
  /**
   * Popper z-index - defaults to 150.
   */
  popperZindex?: number;
  /**
   * TenantId - to extend href as query parameter.
   */
  tenantId?: string;
}

const ButtonStyle = css`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: ${mikeSharedTheme.spacing(-0.5)};
  min-height: 60px;
  cursor: pointer;
  color: ${mikePalette.primary.main};
  &:hover {
    background-color: ${mikePalette.text.disabled};
  }
`;

const ButtonIconTextContainerStyle = css`
  display: flex;
  align-items: center;
  padding-left: ${mikeSharedTheme.spacing(1)};
  padding-right: ${mikeSharedTheme.spacing(1)};
`;

const ButtonTextStyle = css`
  padding-left: ${mikeSharedTheme.spacing(2)};
  color: ${mikePalette.primary.main};
  font-size: 0.875rem;
  line-height: 1.286;
`;

const PopperStyle = (zIndex: number) => {
  return css`
    z-index: ${zIndex};
    box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.16);
    background-color: ${mikePalette.lightGrey?.main};
    padding-left: ${mikeSharedTheme.spacing(0)};
  `;
};

const HeaderContainerStyle = css`
  display: flex;
  height: ${mikeSharedTheme.spacing(7.5)};
  color: ${mikePalette.primary.main};
  background-color: ${mikePalette.lightGrey?.main};
  padding-left: ${mikeSharedTheme.spacing(1)};
  align-items: center;
`;

const HeaderStyle = css`
  font-size: 0.875rem;
  line-height: 1.286;
`;

/**
 * @name MikeSubscribedApps
 * @summary A list with links to apps the user has access to.
 */
export const MikeSubscribedApps = (props: IProps) => {
  const {
    currentAppName,
    projectId,
    applications,
    appListHeaderLabel = 'Open this project with:',
    loading = false,
    popperZindex = 150,
    tenantId = ""
  } = props;

  const intl = useIntl();
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);

  const currentAppIcon: React.ReactNode = useMemo(() => {
    if (applications) {
      const allApps = [...applications.appsInSubscription, ...applications.administrationApps];
      const currentApp = allApps.find((app: IApplication) => app.appName.endsWith(currentAppName));     
      if (currentApp !== undefined) {
        return getAppIcon(currentApp.iconName);
      }
      else{
        return getAppIcon(currentAppName)
      }    
    }    
  }, [applications, currentAppName])

  const links: Array<IAppLink> = useMemo(() => {
    if (applications) {
      const allApps = [...applications.appsInSubscription, ...applications.administrationApps];
      // If user is not an Admin we need to hide apps 'forAdministratorOnly'
      const availableApps =
        applications.isAdministrator === true
          ? allApps
          : allApps.filter((app: IApplication) => !app.forAdministratorOnly === true);
      // Link config shouldn't include current app
      const linkConfigs = availableApps
        .filter((app: IApplication) => !app.appName.endsWith(currentAppName))
        .map((app: IApplication) => {
          const appIcon = getAppIcon(app.iconName);
          return {
            label: appName(app.appName),
            href: app.appUrl,
            icon: appIcon,
            target: '_blank',
            appId: app.iconName,
          } as IAppLink;
        });
      return linkConfigs;
    }
    return new  Array<IAppLink>()
  }, [applications, currentAppName])

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <div>
        <div css={ButtonStyle} onClick={handleClick}>
          <Divider orientation="vertical" flexItem />
          <div css={ButtonIconTextContainerStyle}>
            {currentAppIcon}
            <Typography css={ButtonTextStyle}>
              <b>{intl.formatMessage({id: 'app.newRegisteredName'})}</b>
            </Typography>
          </div>
          <DownTriangle />
          <Divider orientation="vertical" flexItem />
        </div>

        <Popper
          placement="bottom-start"
          css={PopperStyle(popperZindex)}
          id="mike-subscribed-apps"
          anchorEl={anchorEl}
          open={open}
          onClick={handleClose}
        >
          <div css={HeaderContainerStyle} key={-1}>
            <Typography css={HeaderStyle}>
              <b>{appListHeaderLabel}</b>
            </Typography>
          </div>
          {loading ? (
            <AppSkeleton count={3} />
          ) : links ? (
            links.map((appLink: IAppLink, index: number) => {
              return (
                <div key={index}>
                  <Divider light />
                  <MikeSubscribedAppsLinkItem
                    config={{
                      icon: appLink.icon,
                      target: appLink.target,
                      label: appLink.label,
                      href: projectId
                        ? appLink.href + path(appLink.appId, projectId, tenantId)
                        : appLink.href + tenantId ? '?tenantId=' + tenantId : '',
                    }}
                    onClickCallBack={handleClose}
                  />
                </div>
              );
            })
          ) : null}
        </Popper>
      </div>
    </ClickAwayListener>
  );
};

export default MikeSubscribedApps;
