/** @jsxImportSource @emotion/react */
import React from 'react';import mikeSharedTheme from '../../styles/mikeSharedTheme';
import { css } from '@emotion/react'
import MIKE_COLORS from '../../styles/mike-colors';

interface IProps extends React.HTMLAttributes<HTMLElement> {
  dropDownMaxHeight?: number;
  dropDownWidth?: number;
}
const DROPDOWN_MAX_HEIGHT = 590;
const DROPDOWN_WIDTH = 352;

const mediumGrey = MIKE_COLORS.MEDIUMGREY_DEFAULT
const darkGrey = MIKE_COLORS.DARKGREY_DEFAULT

const dropdownWrapperStyle = css`
  position: relative;
  width: 352px;
  top: 0px;
  right: 294px;
  overflow-y: visible;
`;

const triangleUpStyle = css`
  position: absolute;
  right: 18px;
  top: 0px;
  width: 0px;
  height: 0px;
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;
  border-bottom: 12px solid ${mediumGrey};
`;

const dropdownContentStyle = (props: IProps) => {
  const w = typeof props.dropDownWidth !== 'undefined' ? props.dropDownWidth : DROPDOWN_WIDTH
  const mh = typeof props.dropDownMaxHeight !== 'undefined' ? props.dropDownMaxHeight : DROPDOWN_MAX_HEIGHT
  return css`
    background-color: ${mediumGrey};
    border-radius: 4px 4px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.16);
    position: absolute;
    top: 12px;
    right: 0px;
    width: ${w}px;
    max-height:${mh}px;
    padding-top: ${mikeSharedTheme.spacing(3)};
    padding-bottom: ${mikeSharedTheme.spacing(3)};
  `;
}

const notificationPerDayOuterContainerStyle =  (props: IProps) => {
  const mh = typeof props.dropDownMaxHeight !== 'undefined'
  ? props.dropDownMaxHeight - 48
  : DROPDOWN_MAX_HEIGHT - 48
  return css`
    background-color: ${mediumGrey};
    border-radius: 4px 4px;
    max-height:${mh}px;
    padding-left: ${mikeSharedTheme.spacing(2)};
    overflow-y: auto;
    scrollbar-width: thin;
    scrollbar-color: ${darkGrey} ${mediumGrey};
    &::-webkit-scrollbar: {
      width: 24px;
      border-radius: 4px 4px;
    };
    &::-webkit-scrollbar-track: {
      border-radius: 4px 4px;
      background-color: ${mediumGrey};
      border-right: 8px solid  + ${mediumGrey};
      border-left: 12px solid ' + ${mediumGrey};
    };
    &::-webkit-scrollbar-thumb: {
      border-radius: 4px 4px;
      background-color: ${darkGrey};
      border-right: 8px solid ' + ${mediumGrey};
      border-left: 12px solid ' + ${mediumGrey};
      &:hover: {
        border-right: 8px solid + ${mediumGrey};
        border-left: 12px solid + ${mediumGrey};
      };
    };
  `;
}


/**
 * @name MikeTopbarDropdown
 * @summary A dropdown meant to be used in the MikeTopbar.
 */
export const MikeTopbarDropdown = (props: IProps) => {
  const { children } = props; 
  const maxHeight = props.dropDownMaxHeight ? props.dropDownMaxHeight : DROPDOWN_MAX_HEIGHT;
  const containerRef = React.useRef<HTMLDivElement | null>(null);
  const [containerRightPadding, setContainerRightPadding] = React.useState(0);

  React.useEffect(() => {
    if (!containerRef.current) {
      return;
    }
    const height = containerRef.current.getBoundingClientRect().height;
    setContainerRightPadding(height < maxHeight ? 24 : 0);
  }, [maxHeight]);

  return (
    <div {...props} css={dropdownWrapperStyle}>
      <div css={triangleUpStyle} />
      <div
        css={dropdownContentStyle(props)}
        /* Function required by TS lint: */
        ref={(e) => (containerRef.current = e)}
      >
        <div
          css={(notificationPerDayOuterContainerStyle(props))}
          style={{ paddingRight: `${containerRightPadding}` + 'px' }}
        >
          {children}
        </div>
      </div>
    </div>
  );
};

export default MikeTopbarDropdown;
