

/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useMemo } from 'react';
import Dialog from '@mui/material/Dialog';
import Typography from '@mui/material/Typography'
import MuiDialogContent from '@mui/material/DialogContent';
import MuiDialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import { useDispatch, useSelector } from 'react-redux';
import { IState } from '../../reducers';
import { setOwnDataDialogType } from '../../actions/ownData';
import { useIntl } from 'react-intl';
import { OWNDATAHINTS } from '../../shared/constants';
import mikeSharedTheme from '../../styles/mikeSharedTheme';
import MikeDialogTitle from '../DialogComponents/MikeDialogTitle';
import MikeButton from '../mike-button';

const titleStyle = css`
  padding: ${mikeSharedTheme.spacing(1)}; 
  border-radius: 4px 4px 0px 0px; 
  height: ${mikeSharedTheme.spacing(8)};
  color: ${mikeSharedTheme.palette.background.paper};
  width: 100%;
`;

const dialogContentStyle = css`
  padding-top: 0;
  background-color: ${mikeSharedTheme.palette.background.paper};
`;

const contentContainerStyle = css`
   padding: ${mikeSharedTheme.spacing(1)};
   `
const dialogActionsStyle = css`
  background-color: ${mikeSharedTheme.palette.background.paper};
  color: ${mikeSharedTheme.palette.background.paper};
  padding-right: ${mikeSharedTheme.spacing(3)};
  width: 100%; 
  `

interface IProps {
  onCancel: () => void;
  onOk: () => void;
}

const OwnDataDialog = (props: IProps) => {
  const { onCancel, onOk } = props;
  const intl = useIntl();  
  const dispatch = useDispatch()

  const dialogType: string =  useSelector(
    (state: IState) => state.ownData.dialogType
  ); 

  const handleCancel = (_event?, reason?) => {
    if(!reason || (reason !== 'backdropClick' && reason !== 'escapeKeyDown')) {
      dispatch(setOwnDataDialogType(""))
    }
  }  

  const title = useMemo(() => {
    switch (dialogType){
      case OWNDATAHINTS.UPLOADBUTTON: 
        return intl.formatMessage({id: 'boundaryConditions.uploadDataPopup.title'})
      case OWNDATAHINTS.HOWTO:
        return intl.formatMessage({id: 'boundaryConditions.ownDataHintPopup.title'})
      case OWNDATAHINTS.LINK:
        return intl.formatMessage({id: 'boundaryConditions.uploadInstructions.title'})
      default:
        return ""
    }
  }, [dialogType, intl])

  const cancelButtonLabel = useMemo(() => {
    switch (dialogType){
      case OWNDATAHINTS.UPLOADBUTTON: 
        return intl.formatMessage({id: 'boundaryConditions.uploadDataPopup.cancelButton'})
      case OWNDATAHINTS.HOWTO:
        return intl.formatMessage({id: 'boundaryConditions.ownDataHintPopup.cancelButton'})
      case OWNDATAHINTS.LINK:
        return intl.formatMessage({id: 'boundaryConditions.uploadInstructions.cancelButton'})
      default:
        return ""
    }
  }, [dialogType, intl])

  const okButtonLabel = useMemo(() => {
    switch (dialogType){
      case OWNDATAHINTS.UPLOADBUTTON: 
        return intl.formatMessage({id: 'boundaryConditions.uploadDataPopup.okButton'})
      case OWNDATAHINTS.HOWTO:
        return intl.formatMessage({id: 'boundaryConditions.ownDataHintPopup.okButton'})
      case OWNDATAHINTS.LINK:
        return intl.formatMessage({id: 'boundaryConditions.uploadInstructions.okButton'})
      default:
        return ""
    }
  }, [dialogType, intl])

  const content = useMemo(() => {
    switch (dialogType){
      case OWNDATAHINTS.UPLOADBUTTON: 
        return <div css={contentContainerStyle}> 
          <Typography variant="body2">{intl.formatMessage({id: 'boundaryConditions.uploadDataPopup.line1'})}</Typography>
          <Typography variant="body2">{intl.formatMessage({id: 'boundaryConditions.uploadDataPopup.line2'})}</Typography>
          <Typography variant="body2">{intl.formatMessage({id: 'boundaryConditions.uploadDataPopup.line3'})}</Typography>
          <Typography variant="body2">{intl.formatMessage({id: 'boundaryConditions.uploadDataPopup.line4'})}</Typography>
        </div>
        
      case OWNDATAHINTS.HOWTO:
        return <div css={contentContainerStyle}> 
          <Typography variant="body2">{intl.formatMessage({id: 'boundaryConditions.ownDataHintPopup.line1'})}</Typography>
          <Typography variant="body2">
            {intl.formatMessage({id: 'boundaryConditions.ownDataHintPopup.see'})}{" "}
            <b>{intl.formatMessage({id: 'boundaryConditions.uploadInstructions.title'})}</b>
            {" "}{intl.formatMessage({id: 'boundaryConditions.ownDataHintPopup.madeAvailable'})}
          </Typography>     
        </div>
      
      case OWNDATAHINTS.LINK:
        return <div css={contentContainerStyle}> 
        <Typography variant="body2">{intl.formatMessage({id: 'boundaryConditions.uploadInstructions.line1'})}</Typography>
        <Typography variant="body2">{intl.formatMessage({id: 'boundaryConditions.uploadInstructions.line2'})}</Typography>
        <Typography variant="body2">{intl.formatMessage({id: 'boundaryConditions.uploadInstructions.line3'})}</Typography>
        <Typography variant="body2">{intl.formatMessage({id: 'boundaryConditions.uploadInstructions.line4'})}</Typography>
        <Typography variant="body2">{intl.formatMessage({id: 'boundaryConditions.uploadInstructions.line5'})}</Typography>
        <Typography variant="body2">{intl.formatMessage({id: 'boundaryConditions.uploadInstructions.line6'})}</Typography>
      </div>
      default:
        return ""
    }
  }, [dialogType, intl])
 
  return (
    <Dialog maxWidth={'sm'} onClose={handleCancel} open={dialogType !== ""} >
      <MuiDialogTitle css={titleStyle}>
        <MikeDialogTitle title={title} onClose={handleCancel} />
      </MuiDialogTitle>
      <MuiDialogContent css={dialogContentStyle}>
        {content}      
      </MuiDialogContent>
      <DialogActions css={dialogActionsStyle}>       
        <MikeButton onClick={onCancel} variant="text">
          {cancelButtonLabel}
        </MikeButton>
        <MikeButton onClick={onOk} color="primary">
          {okButtonLabel}
        </MikeButton>
      </DialogActions>
    </Dialog>
  );
};

export default OwnDataDialog;