/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React, { useCallback, useEffect, useMemo } from 'react'
import {  useDispatch, useSelector } from 'react-redux'
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import { fastWaveConfigSet, saveEvents, setupGet, uploadSetup } from '../../actions/mapContent'
import { IFastWaveConfig, IJob } from '../../model/IFastWaveConfig';
import { IState } from '../../reducers';
import { titleContainer, titleStyle, spinnerContainerStyle, chartContainerStyle, centerSpinnerStyle, subTitleStyle } from '../../shared/styles';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChartContainer from '../ChartContainer/ChartContainer';
import { getJob } from '../../helpers/fastwave';
import { JOBS_IN_PROGRESS } from '../../model/IJobStatus';
import { JOBS } from '../../shared/constants';
import { ISetup } from '../../model/ISetup';
import Download from '../../icons/Download.svg?react';
import IconButton from '@mui/material/IconButton'
import UploadButton from '../UploadButton/UploadButton';
import { useIntl } from 'react-intl';
import { getHasModelResults } from '../../actions/projectContent';
import { useParams } from 'react-router-dom';
import { IListItem } from '../../reducers/mapContent';
import mikeSharedTheme from '../../styles/mikeSharedTheme';

const radioStandardStyle = css`
  padding: 5px;
  margin: 0 5px;
  font-size: 100%;
`;
const radioColorStyle = css`    
  padding: 0;
  margin: 0;
  color: ${mikeSharedTheme.palette.primary.main};
  & .MuiTypography-root, .MuiTypography-root.Mui-disabled, .MuiFormControlLabel-root: {
    color: ${mikeSharedTheme.palette.primary.main};
  };
`;
const accordeonStyle = css`
  width: ${mikeSharedTheme.spacing(98)}; 
`;
const summaryStyle = css`
  display: flex;
  width: ${mikeSharedTheme.spacing(90)}; 
  justify-content: space-between;
  padding-right: ${mikeSharedTheme.spacing(1)}; 
  padding-left: ${mikeSharedTheme.spacing(1)}; 
  min-height: ${mikeSharedTheme.spacing(5)}; 
`;
const iconButtonStyle = css`
  padding: 0;
`;


const Setup = () => { 
  const { id } = useParams();  
  const dispatch = useDispatch();
  const intl = useIntl();

  const config: IFastWaveConfig = useSelector(
    (state: IState) => state.mapContent.fastWaveConfig
  ); 

  const loadingChartData: boolean  = useSelector(
    (state: IState) => state.mapContent.loadingChartData
  ); 

  const selectedBoundaryOrForcing: IListItem  = useSelector(
    (state: IState) => state.mapContent.selectedBoundaryOrForcing
  ); 
  
  const loadingCentralPoints: boolean  = useSelector(
    (state: IState) => state.mapContent.loadingCentralPoints
  ); 

  const setups: Array<ISetup> = useSelector(
    (state: IState) => state.mapContent.setups
  ); 

  const loadingSetups: boolean = useSelector(
    (state: IState) => state.mapContent.loadingSetups
  ); 

  const jobs: Array<IJob> = useSelector(
    (state: IState) => state.job.jobs
  );  

  const mdaJobIsProcssing = useMemo(() => {   
    const mdaJob = getJob(jobs, JOBS.MDA) 
    return mdaJob !== undefined && JOBS_IN_PROGRESS.includes(mdaJob.status.toLowerCase())    
  }, [jobs])

  useEffect(() => {   
    if (id){
      dispatch(saveEvents(id))
      dispatch(getHasModelResults(id))
    }
  }, [id, dispatch])

  const handleSetupChange = useCallback((event: { target: { value: React.SetStateAction<string>; }; }) => { 
    const setup = event.target.value.toString()
    const configIsUptodate = config && config.setup && config.setup === setup ? true : false
    if (!configIsUptodate){
      const updatedConfig = {...config, setup: setup }
      dispatch(fastWaveConfigSet(updatedConfig, true))
    }    
  }, [config, dispatch])

  const downloadSetup = (setup: string) => {    
    dispatch(setupGet(setup, true))
  }

  const onDropSetup = useCallback(
    (files) => {
      if (files.length > 0){
        const file = files[0]
        dispatch(uploadSetup(file, file.name))
      } 
    },[dispatch]);
  
  const setupFromConfig = useMemo(() => {
    return config.setup ? config.setup : ""
  }, [config])

  const getMissingDataUI = () => {
    return (
      <div>
        <Typography variant="h5" css={titleStyle}>{intl.formatMessage({id: 'setup.missingData.title'})}</Typography>
        <Typography variant="body2" css={subTitleStyle}>{intl.formatMessage({id: 'setup.missingData.subTitle'})}</Typography>
      </div>
    )
  }

  const getProcessingDataUI = () => {
    return (
      <div>
        <Typography variant="h5" css={titleStyle}>{intl.formatMessage({id: 'setup.processingData.title'})}</Typography>
        <Typography variant="body2" css={subTitleStyle}>{intl.formatMessage({id: 'setup.processingData.subTitle'})}</Typography>
        <div css={centerSpinnerStyle}><CircularProgress/></div>
      </div>
    )
  }

  return (
    <div>
      <div css={titleContainer}>
        <div>
          <Typography variant="h5" css={titleStyle}>{intl.formatMessage({id: 'setup.title'})}</Typography>
          <Typography variant="body2" css={subTitleStyle}>{intl.formatMessage({id: 'setup.subTitle'})}</Typography>
        </div>
        <UploadButton disabled={false} fileType={'.sw'} label={intl.formatMessage({id: 'desktop.upload'})} onDrop={onDropSetup}/> 
      </div>
      {loadingSetups ? <div css={spinnerContainerStyle}><CircularProgress/></div> :        
      <FormControl component="fieldset" css={radioColorStyle}>
        <RadioGroup aria-label="fileformat" name="fileformat" value={setupFromConfig} onChange={handleSetupChange}>
          {setups.map((setup, i) => {
            return (
              <Accordion key={i} css={accordeonStyle}>
                <AccordionSummary                  
                  expandIcon={!setup.isCustom && <ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <div css={summaryStyle}>
                    <FormControlLabel value={setup.name} control={<Radio color="primary" css={radioStandardStyle} />} label={setup.displayName} /> 
                    {!setup.isCustom && 
                    <IconButton css={iconButtonStyle} onClick={() => downloadSetup(setup.name)}>
                      <Download />
                    </IconButton>}
                  </div>  
                </AccordionSummary>
                <AccordionDetails>
                  <Typography variant="body2">{setup.description}</Typography>
                </AccordionDetails>
              </Accordion>            
              )
            })}           
        </RadioGroup>
      </FormControl>}
      <div css={titleContainer}>
        <div>
          <Typography variant="h5" css={titleStyle}>{intl.formatMessage({id: 'setup.chart.legend.title'})}</Typography>
          <Typography variant="body2" css={subTitleStyle}>{intl.formatMessage({id: 'setup.chart.legend.subTitle'})}</Typography>
        </div>
      </div>  
      <div> 
        <div css={chartContainerStyle}> 
          {mdaJobIsProcssing || loadingChartData || loadingCentralPoints ? getProcessingDataUI() : !selectedBoundaryOrForcing ? getMissingDataUI() : <ChartContainer selectForTestRun={true}/>}
        </div>        
      </div>
    </div>
  )
}

export default Setup

