/* eslint-disable @typescript-eslint/no-explicit-any */
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React from 'react';
import { TextField, InputAdornment } from '@mui/material';
import Calendar from '../../icons/Calendar.svg?react';

interface IProps {
  onClick?: (e: any) => void;
  id?: string;
  value?: string;
  placeholder?: string;
  onChange?: () => void;
  hideIcon?: boolean;
  innerRef?: any;
}

const textFieldStyle = css`
  width: 100%;
`;

const MikeDatePickerInput: React.FC<IProps> = ({
  onChange,
  placeholder,
  value,
  id,
  onClick,
  hideIcon,
  innerRef,
}) => {
  
  return (
    <TextField
      ref={innerRef}
      css={textFieldStyle}
      onChange={onChange}
      placeholder={placeholder}
      value={value}
      id={id}
      onClick={onClick}
      slotProps={{
        input:!hideIcon
        ? {
            readOnly: true,
            startAdornment: (
              <InputAdornment position="start">
                <Calendar />
              </InputAdornment>
            ),
          }
        : {}
      }}     
    />
  );
};

export default MikeDatePickerInput;
