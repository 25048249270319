import { IConfig } from "../model/IConfig";
import  ActionType from "../actions/ActionType";
import { IAction } from "../actions/Action";

import ITenant from "../model/ITenant";
import { IUser } from "../model/IUser";


export interface IAuthState {
  config: IConfig;
  user: IUser | null;
  tenants: ITenant[];
  isRootContributor: boolean
}

const initState = { config: null, user: null, tenants: new Array<ITenant>(), isRootContributor: false }

const auth = (state = initState, action: IAction) => {
  switch (action.type) {
    case ActionType.SET_ROOT_CONTRIBUTOR_USERROLE: 
      return { ...state, isRootContributor: action.data };
    case ActionType.SET_USER:
      return { ...state, user: action.data };
    case ActionType.SET_CONFIG:
      return {
          ...state,
          config: action.data
      }; 
    case ActionType.SET_MY_TENANTS:
      return {
        ...state,
        tenants: action.data
      }     
      default:
        return state;
  }
};

export default auth
