/** @jsxImportSource @emotion/react */
import LinearProgress from '@mui/material/LinearProgress';
import { styled } from "@mui/material/styles";

export const MikeProgressBar = styled(LinearProgress)(({theme}) => ({
  root: {
    height: 8,
    backgroundColor: theme.palette.secondary.light, // '#97DBF9' Action blue - Light
    borderRadius: 4,
  },
  bar: {
    borderRadius: 4,
    backgroundColor: theme.palette.secondary.main, // '#00A4EC' Action blue
  },
}));
