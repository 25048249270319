/* eslint-disable @typescript-eslint/no-explicit-any */

import { IConfig } from "../../model/IConfig";

import identity from 'lodash-es/identity'
import merge from 'lodash-es/merge'
export let coordinateSystem: IApi
export let metadata: IApi;
export let backend: IApi;
export let dataLink: IApi;
export let rawApi: IApi;
export let administration: IApi
export let mood: IApi;

export const isApiError = error => error instanceof ApiError

interface IApi {
  getJson: (resource: string, version?: string, headers?: object) => Promise<any>
  postJson: (resource: string, payload?: any, version?: string, headers?: object) => Promise<any>
  putJson: (resource: string, payload?: any, version?: string, headers?: object) => Promise<any>
  patchJson: (resource: string, payload?: any, version?: string, headers?: object) => Promise<any>
  delJson: (resource: string, version?: string, headers?: object) => Promise<any>
}

export class ApiError extends Error {
  httpStatus: string
  httpStatusText: string
  body: any
  constructor(httpStatus: string, httpStatusText: string, body: any) {
    super(`${httpStatusText} [${httpStatus}]`)
    this.httpStatus = httpStatus
    this.httpStatusText = httpStatusText
    this.body = body
  }
}


export default function init(config: IConfig) {
  const metadataUrl = config.metadataUrl
  if (!metadataUrl) {
    return
  }

  const backendUrl = config.backendUrl;
  if (!backendUrl) {
    return;
  }

  const dataLinkUrl = config.dataLinkBackendUrl
  if (!dataLinkUrl) {
    return;
  }

  const moodUrl = config.moodBackendUrl
  
  administration = {
    getJson: makeAdminServiceGet(metadataUrl),
    postJson: makeAdminServicePost(metadataUrl),
    putJson: makeAdminServicePut(metadataUrl),
    patchJson: makeAdminServicePatch(metadataUrl),
    delJson: makeAdminServiceDel(metadataUrl)
  }

  metadata = {
    getJson: makeMetadataGet(metadataUrl),
    postJson: makeMetadataPost(metadataUrl),
    putJson: makeMetadataPut(metadataUrl),
    patchJson: makeMetadataPatch(metadataUrl),
    delJson: makeMetadataDel(metadataUrl)
  }

  coordinateSystem = {
    getJson: makeGisServiceGet(metadataUrl),
    postJson: makeGisServicePost(metadataUrl),
    putJson: makeGisServicePut(metadataUrl),
    patchJson: makeGisServicePatch(metadataUrl),
    delJson: makeGisServiceDel(metadataUrl)
  }

  backend = {
    getJson: makeBackendGet(backendUrl),
    postJson: makeBackendPost(backendUrl),
    putJson: makeBackendPut(backendUrl),
    patchJson: makeBackendPatch(backendUrl),
    delJson: makeBackendDel(backendUrl)
  }

  dataLink = {
    getJson: makeDataLinkGet(dataLinkUrl),
    postJson: makeDataLinkPost(dataLinkUrl),
    putJson: makeDataLinkPut(dataLinkUrl),
    patchJson: makeDataLinkPatch(dataLinkUrl),
    delJson: makeDataLinkDel(dataLinkUrl)
  }

  mood = {
    getJson: makeMOODGet(moodUrl),
    postJson: makeMOODPost(moodUrl),
    putJson: makeMOODPut(moodUrl),
    patchJson: makeMOODPatch(moodUrl),
    delJson: makeMOODDel(moodUrl)
  }

  rawApi = {
    getJson: makeRawServiceGet(metadataUrl),
    postJson: makeRawServicePost(metadataUrl),
    putJson: makeRawServicePut(metadataUrl),
    patchJson: makeRawServicePatch(metadataUrl),
    delJson: makeRawServiceDel(metadataUrl)
  }
 
  const buildHeaders = (otherHeader = {}, version = "1") =>
  merge(
    {
      'api-version': version,    
      'Content-Type': 'application/json; charset=utf-8'
    },
    otherHeader
  )

const getResponseBody = async (resp: any) => {
  const contentType = resp.headers.get('Content-Type') || ''
  return contentType.includes('application/json')
    ? await resp.json()
    : await resp.text()
}

const apiError = (resp: any, body: any) => {
  const error = new ApiError(resp.status, resp.statusText, body)
  return error
}

const handleResponse = async (resp: any, okStatuses: any) => {
  const result = await getResponseBody(resp)

  if (!okStatuses.includes(resp.status)) {
    console.error(`Invalid status code ${resp.status}, expected ${okStatuses}`)
    return Promise.reject(apiError(resp, result))
  }

  return result
}

function makeAdminServiceGet(baseUrl, getOptions = identity) {
  return async (resource, version = "1", additionalheaders = {}) => {
    const headers = buildHeaders(
      { ...additionalheaders, 'dhi-service-id': 'iam' },
      version
    )
    const defaultOptions = { headers }
    const options = getOptions(defaultOptions)
    const url = `${baseUrl}/${resource}`
    const resp = await fetch(url, options)
    return handleResponse(resp, [200])
  }
}

function makeAdminServicePost(baseUrl, getOptions = identity) {
  return async (resource, payload, version = "1", additionalheaders = {}) => {
    const headers = buildHeaders(
      { ...additionalheaders, 'dhi-service-id': 'iam' },
      version
    )
    const defaultOptions = {
      method: 'POST',
      headers,
      body: payload && JSON.stringify(payload)
    }
    const options = getOptions(defaultOptions)
    const url = `${baseUrl}/${resource}`
    const resp = await fetch(url, options)
    return handleResponse(resp, [201, 200])
  }
}

function makeAdminServicePut(baseUrl, getOptions = identity) {
  return async (resource, payload, version = "1", additionalheaders = {}) => {
    const headers = buildHeaders(
      { ...additionalheaders, 'dhi-service-id': 'iam' },
      version
    )
    const defaultOptions = {
      method: 'PUT',
      headers,
      body: payload && JSON.stringify(payload)
    }
    const options = getOptions(defaultOptions)
    const url = `${baseUrl}/${resource}`
    const resp = await fetch(url, options)
    return handleResponse(resp, [200, 204])
  }
}

function makeAdminServicePatch(baseUrl, getOptions = identity) {
  return async (resource, payload, version = "1", additionalheaders = {}) => {
    const headers = buildHeaders(
      { ...additionalheaders, 'dhi-service-id': 'iam' },
      version
    )
    const defaultOptions = {
      method: 'PATCH',
      headers,
      body: payload && JSON.stringify(payload)
    }
    const options = getOptions(defaultOptions)
    const url = `${baseUrl}/${resource}`
    const resp = await fetch(url, options)
    return handleResponse(resp, [200])
  }
}

function makeAdminServiceDel(baseUrl, getOptions = identity) {
  return async (resource, version = "1", additionalheaders = {}) => {
    const headers = buildHeaders(
      { ...additionalheaders, 'dhi-service-id': 'iam' },
      version
    )
    const defaultOptions = {
      method: 'DELETE',
      headers
    }
    const options = getOptions(defaultOptions)
    const url = `${baseUrl}/${resource}`
    const resp = await fetch(url, options)
    return handleResponse(resp, [204])
  }
}

  function makeMetadataGet(baseUrl: string, getOptions = identity) {
    return async (resource: string, version = "1", additionalheaders = {}) => {   
      const headers = buildHeaders(additionalheaders, version)
      const defaultOptions = { headers }
      const options = getOptions(defaultOptions)
      const url = `${baseUrl}/${resource}`
      const resp = await fetch(url, options)
      return handleResponse(resp, [200])
    }
  }
  
  function makeMetadataPost(baseUrl: string, getOptions = identity) {
    return async (resource: string, payload = {}, version = "1", additionalheaders = {}) => { 
      const headers = buildHeaders(additionalheaders, version)
      const defaultOptions = {
        method: 'POST',
        headers,
        body: payload && JSON.stringify(payload)
      }
      const options = getOptions(defaultOptions)
      const url = `${baseUrl}/${resource}`
      const resp = await fetch(url, options)
      return handleResponse(resp, [201, 200, 204])
    }
  }
  
  function makeMetadataPut(baseUrl: string, getOptions = identity) {
    return async (resource: string, payload= {}, version = "1", additionalheaders = {}) => {  
      const headers = buildHeaders(additionalheaders, version)
      const defaultOptions = {
        method: 'PUT',
        headers,
        body: payload && JSON.stringify(payload)
      }
      const options = getOptions(defaultOptions)
      const url = `${baseUrl}/${resource}`
      const resp = await fetch(url, options)
      return handleResponse(resp, [200, 204])
    }
  }
  
  function makeMetadataPatch(baseUrl: string, getOptions = identity) {
    return async (resource: string, payload= {}, version = "1", additionalheaders = {}) => {
      const headers = buildHeaders(additionalheaders, version)
      const defaultOptions = {
        method: 'PATCH',
        headers,
        body: payload && JSON.stringify(payload)
      }
      const options = getOptions(defaultOptions)
      const url = `${baseUrl}/${resource}`
      const resp = await fetch(url, options)
      return handleResponse(resp, [200])
    }
  }
  
  function makeMetadataDel(baseUrl: string, getOptions = identity) {
    return async (resource: string, version = "1", additionalheaders = {}) => {
      const headers = buildHeaders(additionalheaders, version)
      const defaultOptions = {
        method: 'DELETE',
        headers
      }
      const options = getOptions(defaultOptions)
      const url = `${baseUrl}/${resource}`
      const resp = await fetch(url, options)
      return handleResponse(resp, [204])
    }
  }

  function makeGisServiceGet(baseUrl, getOptions = identity) {
    return async (resource, version = "1") => {   
      const headers = buildHeaders({ 'dhi-service-id': 'gis' }, version)
      const defaultOptions = { headers }
      const options = getOptions(defaultOptions)
      const url = `${baseUrl}/${resource}`
      const resp = await fetch(url, options)
      return handleResponse(resp, [200])
    }
  }
  
  function makeGisServicePost(baseUrl, getOptions = identity) {
    return async (resource, payload, version = "1") => {    
      const headers = buildHeaders({ 'dhi-service-id': 'gis' }, version)
      const defaultOptions = {
        method: 'POST',
        headers,
        body: payload && JSON.stringify(payload)
      }
      const options = getOptions(defaultOptions)
      const url = `${baseUrl}/${resource}`
      const resp = await fetch(url, options)
      return handleResponse(resp, [201, 200])
    }
  }
  
  function makeGisServicePut(baseUrl, getOptions = identity) {
    return async (resource, payload, version = "1") => {   
      const headers = buildHeaders({ 'dhi-service-id': 'gis' }, version)
      const defaultOptions = {
        method: 'PUT',
        headers,
        body: payload && JSON.stringify(payload)
      }
      const options = getOptions(defaultOptions)
      const url = `${baseUrl}/${resource}`
      const resp = await fetch(url, options)
      return handleResponse(resp, [200, 204])
    }
  }
  
  function makeGisServicePatch(baseUrl, getOptions = identity) {
    return async (resource, payload, version = "1") => {   
      const headers = buildHeaders({ 'dhi-service-id': 'gis' }, version)
      const defaultOptions = {
        method: 'PATCH',
        headers,
        body: payload && JSON.stringify(payload)
      }
      const options = getOptions(defaultOptions)
      const url = `${baseUrl}/${resource}`
      const resp = await fetch(url, options)
      return handleResponse(resp, [200])
    }
  }
  
  function makeGisServiceDel(baseUrl, getOptions = identity) {
    return async (resource, version = "1") => {     
      const headers = buildHeaders({ 'dhi-service-id': 'gis' }, version)
      const defaultOptions = {
        method: 'DELETE',
        headers
      }
      const options = getOptions(defaultOptions)
      const url = `${baseUrl}/${resource}`
      const resp = await fetch(url, options)
      return handleResponse(resp, [204])
    }
  }

  function makeBackendGet(baseUrl: string, getOptions = identity) {
    return async (resource: string, version = "1", additionalheaders = {}) => {
      const headers = buildHeaders(additionalheaders, version)
      const defaultOptions = { headers }
      const options = getOptions(defaultOptions)
      const url = `${baseUrl}/${resource}`
      const resp = await fetch(url, options)
      return handleResponse(resp, [200, 204])
    }
  }
  
  function makeBackendPost(baseUrl: string, getOptions = identity) {
    return async (resource: string, payload = {}, version = "1", additionalheaders = {}) => {   
      const headers = buildHeaders(additionalheaders, version)
      const defaultOptions = {
        method: 'POST',
        headers,
        body: payload && JSON.stringify(payload)
      }
      const options = getOptions(defaultOptions)
      const url = `${baseUrl}/${resource}`
      const resp = await fetch(url, options)
      return handleResponse(resp, [201, 200, 204])
    }
  }
  
  function makeBackendPut(baseUrl: string, getOptions = identity) {
    return async (resource: string, payload= {}, version = "1", additionalheaders = {}) => {   
      const headers = buildHeaders(additionalheaders, version)
      const defaultOptions = {
        method: 'PUT',
        headers,
        body: payload && JSON.stringify(payload)
      }
      const options = getOptions(defaultOptions)
      const url = `${baseUrl}/${resource}`
      const resp = await fetch(url, options)
      return handleResponse(resp, [200, 204])
    }
  }
  
  function makeBackendPatch(baseUrl: string, getOptions = identity) {
    return async (resource: string, payload= {}, version = "1", additionalheaders = {}) => {  
      const headers = buildHeaders(additionalheaders, version)
      const defaultOptions = {
        method: 'PATCH',
        headers,
        body: payload && JSON.stringify(payload)
      }
      const options = getOptions(defaultOptions)
      const url = `${baseUrl}/${resource}`
      const resp = await fetch(url, options)
      return handleResponse(resp, [200])
    }
  }
  
  function makeBackendDel(baseUrl: string, getOptions = identity) {
    return async (resource: string, version = "1", additionalheaders = {}) => {     
      const headers = buildHeaders(additionalheaders, version)
      const defaultOptions = {
        method: 'DELETE',
        headers
      }
      const options = getOptions(defaultOptions)
      const url = `${baseUrl}/${resource}`
      const resp = await fetch(url, options)
      return handleResponse(resp, [200, 204])
    }
  }

  function makeDataLinkGet(baseUrl: string, getOptions = identity) {
    return async (resource: string, version = "1", additionalheaders = {}) => {   
      const headers = buildHeaders(additionalheaders, version)
      const defaultOptions = { headers }
      const options = getOptions(defaultOptions)
      const url = `${baseUrl}/${resource}`
      const resp = await fetch(url, options)
      return handleResponse(resp, [200])
    }
  }
  
  function makeDataLinkPost(baseUrl: string, getOptions = identity) {
    return async (resource: string, payload = {}, version = "1", additionalheaders = {}) => {  
      const headers = buildHeaders(additionalheaders, version)
      const defaultOptions = {
        method: 'POST',
        headers,
        body: payload && JSON.stringify(payload)
      }
      const options = getOptions(defaultOptions)
      const url = `${baseUrl}/${resource}`
      const resp = await fetch(url, options)
      return handleResponse(resp, [201, 200, 204])
    }
  }
  
  function makeDataLinkPut(baseUrl: string, getOptions = identity) {
    return async (resource: string, payload= {}, version = "1", additionalheaders = {}) => {   
      const headers = buildHeaders(additionalheaders, version)
      const defaultOptions = {
        method: 'PUT',
        headers,
        body: payload && JSON.stringify(payload)
      }
      const options = getOptions(defaultOptions)
      const url = `${baseUrl}/${resource}`
      const resp = await fetch(url, options)
      return handleResponse(resp, [200, 204])
    }
  }
  
  function makeDataLinkPatch(baseUrl: string, getOptions = identity) {
    return async (resource: string, payload= {}, version = "1", additionalheaders = {}) => {  
      const headers = buildHeaders(additionalheaders, version)
      const defaultOptions = {
        method: 'PATCH',
        headers,
        body: payload && JSON.stringify(payload)
      }
      const options = getOptions(defaultOptions)
      const url = `${baseUrl}/${resource}`
      const resp = await fetch(url, options)
      return handleResponse(resp, [200])
    }
  }
  
  function makeDataLinkDel(baseUrl: string, getOptions = identity) {
    return async (resource: string, version = "1", additionalheaders = {}) => {     
      const headers = buildHeaders(additionalheaders, version)
      const defaultOptions = {
        method: 'DELETE',
        headers
      }
      const options = getOptions(defaultOptions)
      const url = `${baseUrl}/${resource}`
      const resp = await fetch(url, options)
      return handleResponse(resp, [204])
    }
  }

  function makeMOODGet(baseUrl: string, getOptions = identity) {
    return async (resource: string, version = "1", additionalheaders = {}) => {   
      const headers = buildHeaders(additionalheaders, version)
      const defaultOptions = { headers }
      const options = getOptions(defaultOptions)
      const url = `${baseUrl}/${resource}`
      const resp = await fetch(url, options)
      return handleResponse(resp, [200])
    }
  }
  
  function makeMOODPost(baseUrl: string, getOptions = identity) {
    return async (resource: string, payload = {}, version = "1", additionalheaders = {}) => {  
      const headers = buildHeaders(additionalheaders, version)
      const defaultOptions = {
        method: 'POST',
        headers,
        body: payload && JSON.stringify(payload)
      }
      const options = getOptions(defaultOptions)
      const url = `${baseUrl}/${resource}`
      const resp = await fetch(url, options)
      return handleResponse(resp, [201, 200, 204])
    }
  }
  
  function makeMOODPut(baseUrl: string, getOptions = identity) {
    return async (resource: string, payload= {}, version = "1", additionalheaders = {}) => {   
      const headers = buildHeaders(additionalheaders, version)
      const defaultOptions = {
        method: 'PUT',
        headers,
        body: payload && JSON.stringify(payload)
      }
      const options = getOptions(defaultOptions)
      const url = `${baseUrl}/${resource}`
      const resp = await fetch(url, options)
      return handleResponse(resp, [200, 204])
    }
  }
  
  function makeMOODPatch(baseUrl: string, getOptions = identity) {
    return async (resource: string, payload= {}, version = "1", additionalheaders = {}) => {  
      const headers = buildHeaders(additionalheaders, version)
      const defaultOptions = {
        method: 'PATCH',
        headers,
        body: payload && JSON.stringify(payload)
      }
      const options = getOptions(defaultOptions)
      const url = `${baseUrl}/${resource}`
      const resp = await fetch(url, options)
      return handleResponse(resp, [200])
    }
  }
  
  function makeMOODDel(baseUrl: string, getOptions = identity) {
    return async (resource: string, version = "1", additionalheaders = {}) => {     
      const headers = buildHeaders(additionalheaders, version)
      const defaultOptions = {
        method: 'DELETE',
        headers
      }
      const options = getOptions(defaultOptions)
      const url = `${baseUrl}/${resource}`
      const resp = await fetch(url, options)
      return handleResponse(resp, [204])
    }
  }

  function makeRawServiceGet(baseUrl, getOptions = identity) {
    return async (resource, version = "1") => {    
      const headers = buildHeaders(      
        { 'dhi-service-id': 'raw' },
        version
      )
      const defaultOptions = { headers }
      const options = getOptions(defaultOptions)
      const url = `${baseUrl}/${resource}`
      const resp = await fetch(url, options)
      return handleResponse(resp, [200])
    }
  }
  
  function makeRawServicePost(baseUrl, getOptions = identity) {
    return async (resource, payload, version = "1") => {     
      const headers = buildHeaders(      
        { 'dhi-service-id': 'raw' },
        version
      )
      const defaultOptions = {
        method: 'POST',
        headers,
        body: payload && JSON.stringify(payload)
      }
      const options = getOptions(defaultOptions)
      const url = `${baseUrl}/${resource}`
      const resp = await fetch(url, options)
      return handleResponse(resp, [201, 200])
    }
  }
  
  function makeRawServicePut(baseUrl, getOptions = identity) {
    return async (resource, payload, version = "1", additionalheaders = {}) => {      
      const headers = buildHeaders(       
        {...additionalheaders, 'dhi-service-id': 'raw' },
        version
      )
      const defaultOptions = {
        method: 'PUT',
        headers,
        body: payload && JSON.stringify(payload)
      }
      const options = getOptions(defaultOptions)
      const url = `${baseUrl}/${resource}`
      const resp = await fetch(url, options)
      return handleResponse(resp, [200, 204])
    }
  }
  
  function makeRawServicePatch(baseUrl, getOptions = identity) {
    return async (resource, payload, version = "1") => {     
      const headers = buildHeaders(       
        { 'dhi-service-id': 'raw' },
        version
      )
      const defaultOptions = {
        method: 'PATCH',
        headers,
        body: payload && JSON.stringify(payload)
      }
      const options = getOptions(defaultOptions)
      const url = `${baseUrl}/${resource}`
      const resp = await fetch(url, options)
      return handleResponse(resp, [200])
    }
  }
  
  function makeRawServiceDel(baseUrl, getOptions = identity) {
    return async (resource, version = "1") => {
      const headers = buildHeaders(     
        { 'dhi-service-id': 'raw' },
        version
      )
      const defaultOptions = {
        method: 'DELETE',
        headers
      }
      const options = getOptions(defaultOptions)
      const url = `${baseUrl}/${resource}`
      const resp = await fetch(url, options)
      return handleResponse(resp, [204])
    }
  }
  
}
