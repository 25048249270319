/* eslint-disable @typescript-eslint/no-explicit-any */
import { v4 as uuid } from 'uuid';
import Map from 'ol/Map';
import proj4 from 'proj4';
import { register } from 'ol/proj/proj4.js';
import View from 'ol/View';
import { Fill, Stroke, RegularShape, Style } from 'ol/style';
import { Options as OlStyleOptions } from 'ol/style/Style';
import OlFeature, { FeatureLike } from 'ol/Feature';
import MikeVisualizerStore from '../store/MikeVisualizerStore';
import MikeVisualizerViewManager from '../MikeVisualizerViewManager';
import { IMapProperties } from '../IMikeVisualizerModels';
import { getEmitters } from '../MikeVisualizerEvents';
import { IEpsgIoApiResponse, IEpsgIoResult } from '../models/IEpsgIOResponse';
import { Extent } from 'ol/extent';
import VectorLayer from 'ol/layer/Vector';
const { getState,  } = MikeVisualizerStore;
const { _getViewerBbox } = MikeVisualizerViewManager;
const { emitBaseMapProjectionFetchFailed } = getEmitters();

/**
 * Utilities used by map-related modules, i.e. base map, draw map, etc.
 *
 * @module MikeVisualizer2dMapUtil
 * @version 1.0.0
 */




 export const getDataForMap = async (query: any) => {
  const queryNumber = (String(query).match(/[0-9].+/gi) || [''])[0];
  // console.log(`query, queryNumber`, query, queryNumber);
  fetch('https://epsg.io/?format=json&q=' + queryNumber)
    .then(function (response) {
      return   response.json();
    })
/*     .then(function (json) {
      const results = json['results'];
      let test = {code: null, name: null, proj4def: null, bbox: null}
      if (results && results.length > 0) {
        // window.history.replaceState(null, query, `${window.location.origin}${window.location.pathname}?projQuery=${query}`);
        // window.history.replaceState(null, query, `${window.location.origin}/?projQuery=${query}`);
        for (var i = 0, ii = results.length; i < ii; i++) {
          const result = results[i];
          if (result) {
            const code = result['code'];
            const name = result['name'];
            const proj4def = result['proj4'];
            const bbox = result['bbox'];
            if (
              code &&
              code.length > 0 &&
              proj4def &&
              proj4def.length > 0 &&
              bbox &&
              bbox.length == 4
            ) {
              return {code, name, proj4def, bbox}  
            }
          }
        }
      }
    }); */
};

/**
 * Given an EPSG code, fetches the proj4js definition from epsg.io and registeres it with OpenLayers.
 * A definition is considered valid if it contains a bbox of length = 4.
 *
 * @param epsgCode
 */
const fetchProjectionFromEpsg = (epsgCode: number): Promise<string> => {
  return fetch(`https://epsg.io/?format=json&q=${epsgCode}`)
    .then((response) => response.json())
    .then((json: IEpsgIoApiResponse) => {
      const results = json.results;
      if (results && results.length > 0) {
        const firstValidProjection = self._getFirstValidProjection(results);
        if (firstValidProjection) {
          const { proj4: proj4def } = firstValidProjection;
          proj4.defs(self._getEpsgString(epsgCode), proj4def);
          register(proj4);
          return proj4def;
        }
      }
      throw new Error(
        'Failed to get projection from epsg.io, no results returned for epsgCode search.'
      );
    });
};
/**
 * Given an EPSG code, fetches the entire metadata available from epsg.io.
 * A definition is considered valid if it contains a bbox of length = 4.
 *
 * @param epsgCode
 */
const fetchProjectionMetadataFromEpsg = (epsgCode: number): Promise<IEpsgIoResult> =>
  fetch(`https://epsg.io/?format=json&q=${epsgCode}`)
    .then((response) => response.json())
    .then((json: IEpsgIoApiResponse) => {
      const results = json.results;
      if (results && results.length > 0) {
        const firstValidProjection = self._getFirstValidProjection(results);
        if (firstValidProjection) {
          return firstValidProjection;
        }
      }
      throw new Error(
        'Failed to get projection from epsg.io, no results returned for epsgCode search.'
      );
    });

/**
 * Simple API wrapper for epsg.io; use it in the other methods in this module.
 * @param query
 * @returns
 */
export const getEpgsIoApi = async (query: number | string) => {
  const resp = await fetch(`https://epsg.io/?format=json&q=${query}`);
  const json: IEpsgIoApiResponse = await resp.json();
  return json;
};

/**
 * Return the first valid projection from epsg.io, if any - otherwise null.
 * @param query
 * @returns
 */
export const getFirstProjFromEpsgIo = async (query: number | string) => {
  const apiResp = await getEpgsIoApi(query);
  const results = apiResp.results;
  if (results && results.length > 0) {
    const firstValidProjection = self._getFirstValidProjection(results);
    if (firstValidProjection) {
      return firstValidProjection;
    }
  }
  return null;
};

/**
 * Given a .prj shapefile, tries to figure out epsg code.
 *
 * @param prjFile
 */
const getEpsgCodeFromPrjFile = async (
  prjFile: ArrayBuffer
): Promise<{ epsgCode: number; crs: string }> => {
  const defaultEpsg = -1;
  const defaultCrs = '';

  if (!prjFile) {
    return {
      epsgCode: defaultEpsg,
      crs: defaultCrs,
    };
  }

  const decoder = new TextDecoder('utf-8');
  const crs = decoder.decode(prjFile);

  const projection = proj4.Proj(crs);
  // Hacky `any` handling of errors after @types/ol install:
  const projectionName = (projection as any).name
    ? (projection as any).name.replace(/_/g, ' ')
    : '';

  return fetch(`https://epsg.io/?format=json&q=${projectionName}`)
    .then((response) => response.json())
    .then((json: IEpsgIoApiResponse) => {
      const results = json.results;
      if (results && results.length > 0) {
        const firstValidProjection = self._getFirstValidProjection(results);
        if (firstValidProjection) {
          return {
            epsgCode: parseInt(firstValidProjection.code, 10),
            crs,
          };
        }
      }
      console.error('Failed to get epsgCode from epsg.io, no results returned for crs search.');
      return {
        epsgCode: defaultEpsg,
        crs,
      };
    });
};

/**
 * Given an array of epsg.io results, finds the first projection that is well-defined:
 * - has a code > 0
 * - has a proj4 definition
 * - has a bbox array with 4 corners
 *
 * @param results
 */
const _getFirstValidProjection = (results: Array<IEpsgIoResult>) => {
  return results.find(
    ({ code, proj4: proj4def, bbox: proj4bbox }) =>
      code &&
      code.length > 0 &&
      proj4def &&
      proj4def.length > 0 &&
      proj4bbox &&
      proj4bbox.length === 4
  );
};

/**
 * Creates and EPSG string given a code. This is typically used for getting/setting proj4js definitions.
 *
 * @param epsgCode
 *
 * @private
 */
const _getEpsgString = (epsgCode: number): string => `EPSG:${epsgCode}`;

/**
 * Given a EPSG code, verifies if a definition exists and is registered with proj4js.
 * If a projection is not found, it attempts to get it remotely.
 *
 * Why some reprojections might not work:
 * proj4js does not support grid transformations - @see http *codesandbox.io/embed/gracious-shamir-98knt
 *
 * This might happen when converting a projected coordinate system. i.e. Web Mercator (meters/feet) to a geographical projection like EPSG:4267 (degrees). A server-side transformation of input data is required in that case.
 *
 * https://github.com/proj4js/proj4js/issues/62
 * https://github.com/proj4js/proj4js/issues/212
 * https://github.com/proj4js/proj4js/issues/296
 *
 * @param epsgCode
 *
 * @private
 */
const _verifyProjection = async (epsgCode: number) => {
  
  const { crs } = getState();  

  if (!epsgCode) {
    throw new Error('No epsg code provided');
  }

  try {
    await proj4(self._getEpsgString(epsgCode)); // It fails if it doesn't exist.

    console.debug('Using local pre-registered projection', epsgCode);

    return true;
  } catch (e) {
    console.info('Projection not found. Trying to retrieve from epsg.io', e);
  }

  if (crs){
    return true // projection has been registered with OpenLayers
  }

  try {
    await self.fetchProjectionFromEpsg(epsgCode);
    console.debug('Using fetched projection', epsgCode);

    return true;
  } catch (e) {
    emitBaseMapProjectionFetchFailed(e as Error);

    console.error('Failed to fetch projection from epsg.io', e);
    throw new Error(e as any);
  }
};

/**
 * Based on the current height/width, gets the 3D viewer corners and translates them to properties that 2D maps can use, such as: bbox, center, etc
 */
const _getViewerProperties = (): IMapProperties | boolean => {
  const { container: vtkContainer } = getState();
  if (!vtkContainer) {
    return false;
  }
  const viewerCanvas = (vtkContainer as HTMLElement).querySelector('canvas');
  const { height, width } = (viewerCanvas as HTMLElement).getBoundingClientRect();
  const { viewerBbox, viewerCenter } = _getViewerBbox();
  return {
    bbox: viewerBbox,
    center: viewerCenter,
    width,
    height,
  };
};

/**
 * Given an OpenLayers view and a set of map properties this method calculates the zoom and center of the view and updates it accordingly.
 * This method is called typically as a result of a 3D map change, updating a map to match its position & zoom.
 *
 * @param view
 * @param mapProperties
 *
 * @private
 */
const _zoomAndCenterOpenLayersView = (view: View, mapProperties: IMapProperties) => {
  const { bbox: mapBbox, center: mapCenter, width, height } = mapProperties;
  if (!mapBbox) {
    console.error(`No mapBbox`);
  }
  const resolutionForExtent = view.getResolutionForExtent(mapBbox as Extent, [width, height]);
  const zoomForResolution = view.getZoomForResolution(resolutionForExtent);
  if (zoomForResolution){
    view.setZoom(zoomForResolution);
  } 
  view.setCenter(mapCenter);
};

/**
 * Gets the vector source from a map.
 *
 * @param map
 *
 * @private
 */
const _getVectorLayerSource = (map: Map) => {
  return self._getVectorLayer(map).getSource();
};

/**
 * Gets the vector layers from a map.
 *
 * @param map
 *
 * @private
 */
const _getVectorLayer = (map: Map) => {
  return map.getLayers().getArray()[0] as VectorLayer;
};

/**
 * Generic method that removes interactions from an ol map instance.
 *
 * @param map The map to remove all interactions from.
 *
 * @private
 */
const _removeAllMapInteractions = (map: Map) => {
  const interactions = [...map.getInteractions().getArray()];
  interactions.forEach((interaction) => {
    map.removeInteraction(interaction);
  });
};

/**
 * Makes a default vector layer style, using the provided surface & edge colors.
 *
 * @param surfaceColor Any valid css color.
 * @param edgeColor Any valid css color.
 * @param [olStyleOptions] OpenLayers Style options, can overrule the previous.
 */
const _makeDefaultVectorStyle = (
  surfaceColor: string = 'lime',
  edgeColor: string = 'pink',
  olStyleOptions?: OlStyleOptions
) => {
  const style = new Style({
    image: new RegularShape({
      fill: new Fill({
        color: edgeColor,
      }),
      stroke: new Stroke({
        color: surfaceColor,
        width: 2,
      }),
      points: 4,
      radius: 2,
      angle: Math.PI / 4,
    }),
    fill: new Fill({
      color: surfaceColor,
    }),
    stroke: new Stroke({
      color: edgeColor,
      width: 2,
    }),
    ...olStyleOptions,
  });
  return style;
};

/**
 * For a given ol feature attempts to find the feature id, either on the feature itself or inside its properties.
 *
 * @param feature
 */
const _getOlFeatureId = (feature: OlFeature | FeatureLike): string => {
  if (!feature) {
    return '';
  }

  const featureId = feature.getId ? feature.getId() : '';
  const featurePropertiesId = feature.getProperties ? (feature.getProperties() || {}).id : '';

  return featurePropertiesId || featureId;
};

/**
 * Sets an ol feature id, adding an id prop to both feature and feature properties, as both approaches are used interchangeably by various libraries / parsers.
 *
 * @param feature
 * @param id
 */
const _setOlFeatureId = (feature: OlFeature, id = uuid()): boolean => {
  try {
    // Sets id on both feature and property, as both approaches are used interchangeably by various libraries / parsers.
    feature.setId(id);
    feature.setProperties({ id }, true);
    return true;
  } catch (error) {
    console.error('failed to set ol feature id', error);
    return false;
  }
};

const self = {
  _getViewerProperties,
  _getEpsgString,
  _getFirstValidProjection,
  _zoomAndCenterOpenLayersView,
  _verifyProjection,
  _getVectorLayerSource,
  _getVectorLayer,
  _removeAllMapInteractions,
  _makeDefaultVectorStyle,
  _getOlFeatureId,
  _setOlFeatureId,

  fetchProjectionFromEpsg,
  fetchProjectionMetadataFromEpsg,
  getEpsgCodeFromPrjFile,
};

export default self;
