/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import MoreVert from '@mui/icons-material/MoreVert';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import MIKE_COLORS from '../../styles/mike-colors';
import { IGetProject } from '../../model/IGetProject';

export interface IAction {
  name: string;
  callBack: (row: any, name?: string) => void;
  capabilities?: any;
  disableGutters?: boolean;
  icon?: React.ReactNode;
  render?: (row: IGetProject, capabilities?: any) => React.ReactNode;
}

interface IProps {
  row: any;
  actions: Array<IAction>;
  actionsPopupStateChange?: (open: boolean, row: any) => void;
}

const Actions = (props: IProps) => {
  const { row, actions, actionsPopupStateChange } = props;

  return (
    <PopupState variant="popover" popupId="action-popup-menu">
      {(popupState) => {      
        actionsPopupStateChange && actionsPopupStateChange(popupState.isOpen, row)
        return (
        <React.Fragment>
          <MoreVert {...bindTrigger(popupState)} />
          <Menu
            {...bindMenu(popupState)}
            // getContentAnchorEl={null}
            anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
            transformOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            MenuListProps={{
              style: {
                padding: 0,
                backgroundColor: MIKE_COLORS.XLIGHTGREY,
              },
            }}
          >
            {actions.map((a, index) => {
              return (
                <MenuItem
                  key={index}
                  onClick={(e) => {
                    e.preventDefault();
                    popupState.close();
                    a.callBack(row, a.name);
                  }}
                  disableGutters={a.disableGutters}
                >
                  {a.icon ? a.icon : ''}
                  {a.render ? a.render(row, a.capabilities) : a.name}
                </MenuItem>
              );
            })}
          </Menu>
        </React.Fragment>
      )
      }}
    </PopupState>
  );
};

export default Actions;
