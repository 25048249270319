import { FeatureCollection } from 'geojson';
import { Style } from 'ol/style';
import MikeVisualizerStore from '../../store/MikeVisualizerStore';
import MikeVisualizer2DDrawCore from './MikeVisualizer2DDrawCore';
import MikeVisualizer2DInteractions, {
  IDrawInteractionOptions,
} from './MikeVisualizer2DInteractions';
import MikeVisualizer2DMapUtil from '../MikeVisualizer2DMapUtil';
import { getOlStylePresets } from '../MikeVisualizer2DDrawConstants';
// import {GeometryType} from 'ol/geom/GeometryType';
import { createBox } from 'ol/interaction/Draw';

const { getState } = MikeVisualizerStore;
const { _getVectorLayer } = MikeVisualizer2DMapUtil;
const { _getOrSetupDrawMap } = MikeVisualizer2DDrawCore;
const { _enableDrawInteraction, _removeDrawMapInteractions } = MikeVisualizer2DInteractions;

/**
 * Contains methods that allows enabling/disabling 2d drawing tools.
 *
 * @module MikeVisualizer2DDrawingTools
 * @version 1.0.0
 */

/**
 * Enable polygon drawing tool.
 *
 * @param [singleItem] Only allow one drawn item at a time.
 * @param [instantUpdates] Emit drawing changes instantly, without debouncing them. This can result in bad performance, because changes will potentially be emitted for each mouse move.
 * @param [vectorLayerStyle]
 * @param [drawInteractionStyle]
 * @param [selectInteractionStyle]
 */
export const enable2DPolygonDrawing = async (
  singleItem = false,
  instantUpdates = false,
  vectorLayerStyle?: (feature) => Array<Style>,
  drawInteractionStyle?: (feature) => Array<Style>,
  selectInteractionStyle?: (feature) => Array<Style>,
  allowFreehand = true
) => {
  const options: IDrawOptions = {
    singleItem,
    instantUpdates,
    vectorLayerStyle,
    drawInteractionStyle,
    selectInteractionStyle,
    allowFreehand,
  };
  enable2DPolygonDrawingV2(options);
};

/**
 * Interface for version 2 drawing functions
 */
// eslint-disable-next-line @typescript-eslint/no-empty-object-type
export interface IDrawOptions extends Omit<IDrawInteractionOptions, 'drawMap' | 'type'> {}

/**
 * Same as enable2DPolygonDrawing but with more options.
 * @param {IDrawOptions} options
 * @returns void
 */
export const enable2DPolygonDrawingV2 = async (options?: IDrawOptions) => {
  const drawMap = await _getOrSetupDrawMap();
  if (!drawMap) {
    console.error('No draw map');
    return false;
  }
  _enableDrawInteraction({
    drawMap,
    type: 'Polygon',
    ...options,
  });
};

/**
 * Draw a circle.
 * @param {IDrawOptions} options
 * @returns void
 */
export const enable2DCircleDrawing = async (options?: IDrawOptions) => {
  const drawMap = await _getOrSetupDrawMap();
  if (!drawMap) {
    console.error('No draw map');
    return false;
  }
  _enableDrawInteraction({
    drawMap,
    type: 'Circle', // GeometryType.CIRCLE,
    ...options,
  });
};

/**
 * Draw a box / rectangle.
 * @param {IDrawOptions} options
 * @returns void
 */
export const enable2DBoxDrawing = async (options?: IDrawOptions) => {
  const drawMap = await _getOrSetupDrawMap();
  if (!drawMap) {
    console.error('No draw map');
    return false;
  }
  _enableDrawInteraction({
    drawMap,
    type: 'Circle', //GeometryType.CIRCLE,
    ...options,
    olDrawOptions: {
      geometryFunction: createBox(),
      ...(options || {}).olDrawOptions,
    },
  });
};

/**
 * Enable polyline drawing tool.
 *
 * @param [singleItem] Only allow one drawn item at a time.
 * @param [instantUpdates] Emit drawing changes instantly, without debouncing them. This can result in bad performance, because changes will potentially be emitted for each mouse move.
 * @param [vectorLayerStyle]
 * @param [drawInteractionStyle]
 * @param [selectInteractionStyle]
 */
export const enable2DPolylineDrawing = async (
  singleItem = false,
  instantUpdates = false,
  vectorLayerStyle?: (feature) => Array<Style>,
  drawInteractionStyle?: (feature) => Array<Style>,
  selectInteractionStyle?: (feature) => Array<Style>,
  allowFreehand = true
) => {
  const options: IDrawOptions = {
    singleItem,
    instantUpdates,
    vectorLayerStyle,
    drawInteractionStyle,
    selectInteractionStyle,
    allowFreehand,
  };
  enable2DPolylineDrawingV2(options);
};

/**
 * Same as enable2DPolylineDrawing but with more options.
 * @param {IDrawOptions} options
 * @returns void
 */
export const enable2DPolylineDrawingV2 = async (options?: IDrawOptions) => {
  const drawMap = await _getOrSetupDrawMap();
  if (!drawMap) {
    console.error('No draw map');
    return false;
  }
  _enableDrawInteraction({
    drawMap,
    type: 'LineString', // GeometryType.LINE_STRING,
    ...options,
  });
};

/**
 * Enable point drawing tool.
 *
 * @param [singleItem] Only allow one drawn item at a time.
 * @param [instantUpdates] Emit drawing changes instantly, without debouncing them. This can result in bad performance, because changes will potentially be emitted for each mouse move.
 * @param [vectorLayerStyle]
 * @param [drawInteractionStyle]
 * @param [selectInteractionStyle]
 */
export const enable2DPointDrawing = async (
  singleItem = false,
  instantUpdates = false,
  vectorLayerStyle?: (feature) => Array<Style>,
  drawInteractionStyle?: (feature) => Array<Style>,
  selectInteractionStyle?: (feature) => Array<Style>
) => {
  const options: IDrawOptions = {
    singleItem,
    instantUpdates,
    vectorLayerStyle,
    drawInteractionStyle,
    selectInteractionStyle,
  };
  enable2DPointDrawingV2(options);
};

/**
 * Same as enable2DPointDrawing but with more options.
 * @param {IDrawOptions} options
 * @returns void
 */
export const enable2DPointDrawingV2 = async (options?: IDrawOptions) => {
  const drawMap = await _getOrSetupDrawMap();
  if (!drawMap) {
    console.error('No draw map');
    return false;
  }
  _enableDrawInteraction({
    drawMap,
    type: 'Point', // GeometryType.POINT,
    ...options,
  });
};

/**
 * Disables all drawing tools and removes all drawn content.
 * Sets currentGeojson to initial state.
 */
export const disable2DPolygonDrawing = () => {
  const { drawMap } = getState();
  if (drawMap) {
    _removeDrawMapInteractions(drawMap);
  }
};

/**
 * Enables 2D Polygon selection drawing.
 * NB: this is not a selection per say, but another type of polygon drawing that is meant to be used as a spatial selection. It still draws a polygon like the polygon drawing tool, just with a different style.
 *
 * @param singleSelection Only allows 1 polygon selection to be drawn at a time; Only keeps the latest drawn polygon, all others are removed.
 */
export const enable2DPolygonSelection = async (singleSelection = false) => {
  const drawMap = await _getOrSetupDrawMap();
  if (!drawMap) {
    console.error('No draw map');
    return false;
  }
  return _enableDrawInteraction({
    drawMap,
    type: 'Polygon', // GeometryType.POLYGON,
    vectorLayerStyle: getOlStylePresets().DRAW_SELECTION.vectorLayer,
    drawInteractionStyle: getOlStylePresets().DRAW_SELECTION.drawInteraction,
    selectInteractionStyle: getOlStylePresets().DRAW_SELECTION.selectInteraction,
    singleItem: singleSelection,
  });
};

export const enable2DPointSelection = async (singleSelection = false) => {
  const drawMap = await _getOrSetupDrawMap();
  if (!drawMap) {
    console.error('No draw map');
    return false;
  }
  _enableDrawInteraction({
    drawMap,
    type: 'Point', // GeometryType.POINT,
    vectorLayerStyle: getOlStylePresets().DRAW_SELECTION.vectorLayer,
    drawInteractionStyle: getOlStylePresets().DRAW_SELECTION.drawInteraction,
    selectInteractionStyle: getOlStylePresets().DRAW_SELECTION.selectInteraction,
    singleItem: singleSelection,
  });
}

/**
 * Enables 2D Box selection drawing.
 * NB: this is not a selection per say, but another type of box drawing that is meant to be used as a spatial selection. It still draws a box like the box drawing tool, just with a different style.
 *
 * @param singleSelection Only allows 1 box selection to be drawn at a time; Only keeps the latest drawn box, all others are removed.
 */
export const enable2DBoxSelection = async (singleSelection = false) => {
  const drawMap = await _getOrSetupDrawMap();
  if (!drawMap) {
    console.error('No draw map');
    return false;
  }
  _enableDrawInteraction({
    drawMap,
    type: 'Circle', // GeometryType.CIRCLE,
    vectorLayerStyle: getOlStylePresets().DRAW_SELECTION.vectorLayer,
    drawInteractionStyle: getOlStylePresets().DRAW_SELECTION.drawInteraction,
    selectInteractionStyle: getOlStylePresets().DRAW_SELECTION.selectInteraction,
    singleItem: singleSelection,  
    olDrawOptions: {
      geometryFunction: createBox(),
    },
  });
}

/**
 * Disables the polyline drawing tool and removes all drawn content.
 */
export const disable2DPolylineDrawing = () => {
  const { drawMap } = getState();
  if (drawMap) {
    _removeDrawMapInteractions(drawMap);
  }
};

/**
 * Disables the point drawing tool and removes all drawn content.
 */
export const disable2DPointDrawing = () => {
  const { drawMap } = getState();
  if (drawMap) {
    _removeDrawMapInteractions(drawMap);
  }
};

/**
 * Disables the polygon selection tool and removes all drawn content.
 */
export const disable2DPolygonSelection = () => {
  const { drawMap } = getState();
  if (drawMap) {
    // Restore vector layer style
    const vectorLayer = _getVectorLayer(drawMap);
    vectorLayer.setStyle(getOlStylePresets().DEFAULT.vectorLayer);
    _removeDrawMapInteractions(drawMap);
  }
};

/**
 * Disables all drawing tools. Also calls disableAnyDrawingTools.
 */
export const disableAllDrawingTools = () => {
  self.disable2DPolygonDrawing();
  self.disable2DPolylineDrawing();
  self.disable2DPointDrawing();
  // Make sure to disable those that don't have their own method:
  self.disableAnyDrawingTools();
};

/**
 * Use this to disable drawing tools that don't have their own
 * disable method. It disables all drawing tools without calling
 * their respective disable methods.
 */
export const disableAnyDrawingTools = () => {
  const { drawMap } = getState();
  if (drawMap) {
    _removeDrawMapInteractions(drawMap);
  }
};

/**
 * Gets the current drawing geojson.
 * Depending on the enabled tools, it could return a feature collections of polygons, polylines or points.
 */
const getCurrentlyDrawnGeojson = (): FeatureCollection<any, any> =>
  getState().drawMapCurrentGeojson;

const self = {
  enable2DPolygonDrawing,
  enable2DPolygonDrawingV2,
  enable2DPolylineDrawing,
  enable2DPolylineDrawingV2,
  enable2DPointDrawing,
  enable2DPointDrawingV2,
  enable2DCircleDrawing,
  enable2DBoxDrawing,
  enable2DPolygonSelection,
  enable2DPointSelection,
  disable2DPolygonDrawing,
  disable2DPolylineDrawing,
  disable2DPointDrawing,
  disableAllDrawingTools,
  disable2DPolygonSelection,
  disableAnyDrawingTools,
  getCurrentlyDrawnGeojson,
  enable2DBoxSelection
};

export default self;
