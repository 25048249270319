/* eslint-disable @typescript-eslint/no-explicit-any */
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import theme, { mikePalette } from './../../styles/mikeSharedTheme';
import Plus from '../../icons/Plus.svg?react';
import React, { useEffect, useCallback, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Typography from '@mui/material/Typography';
import { IState } from '../../reducers'
import { IGetProject } from '../../model/IGetProject';
import AllProjectsTable from './RootProjectsTable';
import { useNavigate } from 'react-router-dom'
import { getProjects, setProjectsPagination, setProjectsRowsPerPage, setProjectsNamePrefix, setProjectsSort, setCreateRootProjectPanelOpen, deleteRootProjects } from '../../actions/projects';
import SearchInput from '../SearchInput'
import CreateFolderForm from '../CreateFolderForm';
import { useIntl } from 'react-intl';
import { iconWhiteStyle } from './../../styles/iconStyles';
import MikeDialog from '../DialogComponents/MikeDialog';
import MikeButton from '../mike-button';
import MikeSlidingPanel from '../mike-sliding-panel';

const appBarSpacerStyle = css`
  height: ${theme.spacing(4)};
  background-color: ${theme.palette.lightGrey?.main};
`;

const tableTopHeaderStyle = css`
  position: sticky;
  top: 0;    
  z-index: 1;
  background-color: ${theme.palette.lightGrey?.main};
`;

const titleStyle = css`
  padding-left: ${theme.spacing(4)};
  padding-top: ${theme.spacing(0)};
  padding-bottom: ${theme.spacing(2)};
`;

const flexStyle = css`
  display: flex;
  justify-content: space-between;
  align-items: center;  
  width: 99vw; 
  padding-left: ${theme.spacing(4)};
  padding-right: ${theme.spacing(4)};
  padding-bottom: ${theme.spacing(5)};
`;
const buttonStyle = css`
  border: 2px solid  + ${mikePalette.secondary.main};
  color: ${mikePalette.background.default};
  &[disabled]: {
    color: ${mikePalette.background.paper};
    opacity: 0.5;
    cursor: not-allowed;
    border: 2px solid  + ${mikePalette.secondary.light};
  };
`;

const RootProjects = () => {    
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const intl = useIntl();

  useEffect(() => {
    dispatch(setProjectsNamePrefix(''))    
    dispatch(getProjects(0, 25, 'CreatedAt', 'desc', ''))
   }, [dispatch]);

  const isRootContributor: boolean = useSelector((state: IState) => state.auth.isRootContributor); 

   const projects: IGetProject[] = useSelector((state: IState) => state.projects.projects); 
  
  const sortBy: string = useSelector(
    (state: IState) => state.projects.sortBy
  );  

  const sortOrder: 'asc' | 'desc' = useSelector(
    (state: IState) => state.projects.sortOrder
  );  

  const rowsPerPage: number = useSelector(
    (state: IState) => state.projects.rowsPerPage
  );

  const namePrefix: string = useSelector(
    (state: IState) => state.projects.namePrefix
  );

  const loading: boolean = useSelector(
    (state: IState) => state.projects.loading
  ); 

  const page: number = useSelector(
    (state: IState) => state.projects.page
  ); 

  const totalCount: number = useSelector(
    (state: IState) => state.projects.totalCount
  ); 

  const createRootProjectPanelIsOpen = useSelector(
    (state: IState) => state.projects.createPanelIsOpen
  ); 

  const [selectedRows, setSelectedRows] = useState(new Array<IGetProject>());
  const [projectsToBeDeleted, setProjectsToBeDeleted] = useState(new Array<IGetProject>());
  const [projectToBeEdited, setProjectToBeEdited] = useState<IGetProject | null>(null);
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);

  const onDeleteOneProject =  (deleteProject: IGetProject) => {   
    if (deleteProject.capabilities.canDelete) {
      setProjectsToBeDeleted([deleteProject])
      setDeleteConfirmationOpen(true)
    }
  }

  const onEditProject = useCallback((editProject: IGetProject) => {   
    if (editProject.capabilities && editProject.capabilities.canEdit) {
      setProjectToBeEdited(editProject)
      dispatch(setCreateRootProjectPanelOpen(true))   
    }
  }, [dispatch])

/*   const onDeleteProjects = React.useCallback(() => {
    setProjectsToBeDeleted(selectedRows)
    setDeleteConfirmationOpen(true)
  }, [selectedRows]) */

  const onCancelDeleteProjects = () => {
    setProjectsToBeDeleted(new Array<IGetProject>())
    setDeleteConfirmationOpen(false)
  }

  const onOkDeleteProjects = React.useCallback(() => {
    const ids = projectsToBeDeleted.filter((project: IGetProject) => project.capabilities.canDelete).map((project: IGetProject) => project.id)
    dispatch(deleteRootProjects(ids))
    setProjectsToBeDeleted(new Array<IGetProject>())
    setDeleteConfirmationOpen(false)
  }, [dispatch, projectsToBeDeleted])

  const onSelectionChange = (selectedItems: Array<IGetProject>) => {
    setSelectedRows(selectedItems)
  };
  
  const handleSelect = useCallback((selectedProject: IGetProject) => {
    navigate('/project/' + selectedProject.id);
  }, [navigate])

  const handleRequestSort = useCallback((orderBy: string | ((item: any) => string | number), order: 'asc' | 'desc') => {   
    dispatch(setProjectsSort(orderBy.toString(), order)) 
    dispatch(setProjectsPagination(0))   
  }, [dispatch])

  const handleChangePage = useCallback((newPage: number) => { 
     dispatch(setProjectsPagination(newPage))
  }, [dispatch])

  const handleChangeRowsPerPage = useCallback((newRowsPerPage: number) => {  
    dispatch(setProjectsRowsPerPage(newRowsPerPage))
  }, [dispatch])

  const [topOffset, setTopOffset] = useState(0)
  const measuredRef = useCallback(node => {
    if (node !== null) {
      setTopOffset(node.getBoundingClientRect().height)
    }
  }, [])

  const handleSetFilter = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {   
    dispatch(setProjectsNamePrefix(e.target.value))     
    dispatch(setProjectsPagination(0))
  }, [dispatch]);

  const handleClearFilter = useCallback(() => { 
    dispatch(setProjectsNamePrefix('')) 
    dispatch(setProjectsPagination(0))
  }, [dispatch]);

  const onShowCreateRootProject = useCallback((show?: boolean) => {
    setProjectToBeEdited(null)
    dispatch(setCreateRootProjectPanelOpen(show))    
  }, [dispatch]);

  return (
   <>
    <div css={tableTopHeaderStyle} ref={measuredRef}>
      <div css={appBarSpacerStyle} />  
      <Typography css={titleStyle} variant={'h1'}>{"Folders"}</Typography> 
      <div css={flexStyle}>
        <SearchInput        
          value={namePrefix}
          onChangeValue={handleSetFilter}
          onResetValue={handleClearFilter}
        />  
        <MikeButton variant="contained"  css={buttonStyle} onClick={() => onShowCreateRootProject()} disabled={!isRootContributor}>
          <Plus css={iconWhiteStyle(!isRootContributor)} />
          {intl.formatMessage({ id: 'createFolder.title'})}
        </MikeButton>
      </div>
    </div>
    <AllProjectsTable     
      data={projects}
      loading={loading}
      onChangePage={handleChangePage}
      onChangeRowsPerPage={handleChangeRowsPerPage}
      onItemClick={handleSelect}
      onHandleRequestSort={handleRequestSort}
      order={sortOrder}
      orderBy={sortBy}
      page={page}
      rowsPerPage={rowsPerPage}
      topOffset={topOffset}
      selectable={false}
      totalCount={totalCount}
      pagination={totalCount > 10}
      notSortableColumns={["description"]}
      onSelectionChange={onSelectionChange}
      selectedItems={selectedRows}
      onDeleteOneProject={onDeleteOneProject}
      onEdit={onEditProject}
    />

      <MikeDialog 
        open={deleteConfirmationOpen} 
        onCancel={onCancelDeleteProjects} 
        onOk={onOkDeleteProjects}
        dialogTitle={intl.formatMessage({id: 'warnings.pleaseConfirm'})}
        contentTitle={intl.formatMessage({id: projectsToBeDeleted.length === 1 ? 'projects.deleteOneConfirmation' : 'projects.deleteManyConfirmation'})}
        message={''}    
        okButtonLabel={intl.formatMessage({id: 'projects.delete'})}
      />

    <MikeSlidingPanel
      position="right"
      isOpen={createRootProjectPanelIsOpen}
      onClose={() => onShowCreateRootProject(false)}
      titleArea={intl.formatMessage({ id: projectToBeEdited ? 'editFolder.title' : 'createFolder.title'})}
      contentArea={<CreateFolderForm project={projectToBeEdited} />}
      actionsArea={null}
      noGrayOverlay={false}
    /> 
  </>
  )
}

export default RootProjects