/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-return */
import vtk from '@kitware/vtk.js/vtk';
import readPolyDataArrayBuffer from 'itk/readPolyDataArrayBuffer';
import itkConfig from "itk/itkConfig";
import vtkXMLReader from '@kitware/vtk.js/IO/XML/XMLReader';
import MikeVisualizerUtil from '../MikeVisualizer/lib/MikeVisualizerUtil';

const { getXmlAttributes } = MikeVisualizerUtil;

/**
 * This method replaces all string Arrays with Int8 DataArrays.
 * Does not feel great, but it's maybe the most performant option we have available (considering both back/front-end).
 *
 * Some background: the vtkXmlReader (wasm) cannot parse a vtu file if it contains such data.
 * It is unclear if there is a problem in the parser, a configuration option missing or the format itself is not accepted.
 *
 * NB: if this causes problems, try completely stripping out string Array.
 * NB2: in an ideal future, cell data would be split during import, and the UI could conditionally load data on demand. In that case, we could skip string Arrays at that level.
 * NB3: it makes a lot of sense for the back-end to not do this at the moment. That is, because the back-end can literally retrieve the file as-is from the database, without doing any processing and loading it in memory in order to strip out parts. This is an ideal scenario from the back-end perspective.
 *
 * @param data
 */
const __dangerouslyReplaceStringArrays__ = (data: string) =>
  data.replace(/<Array type="String"/gi, '<DataArray type="Int8"').replace(/<\/Array>/gi, '</DataArray>');

/**
 * Reads vtu PolyData from a string.
 *
 * @param data
 *
 * @return { Promise<vtkData> } A promise, resolving to a vtk() object instance.
 */
export const readVtuPolyDataAsString = (data: string): Promise<any> => {
  const encoder = new TextEncoder();
  const patchedData = __dangerouslyReplaceStringArrays__(data);
  const dataArrayBuffer = encoder.encode(patchedData);
  // this configures ITK library to load the webworkers from 
  // the corect path (relative to `/public`)
  itkConfig.itkModulesPath = "/itk";
  return readPolyDataArrayBuffer(null, dataArrayBuffer, `file.vtu`)
    .then(({ polyData, webWorker }) => {     
        webWorker.terminate();      

      const vtkDataOject = vtk(polyData);

      // itk does not read the root fielddata, so we need to parse the xml ourself to include these
      try {
        const domParser = new DOMParser();

        // HEVO: For parsing fielddata we use the original data, not the patchedData.
        // Otherwise processFieldData will fail if data contains fielddata of type string
        const vtkXmlData = domParser.parseFromString(data, 'application/xml');
        const vtkXmlNode = vtkXmlData.documentElement;

        if (vtkXmlNode.nodeName === 'parsererror') {
          console.error('Failed to parse XML', vtkXmlNode);
          return vtkDataOject; // we return the object as parsed by itk, without the fielddata
        }

        const fieldDataElements = vtkXmlData.getElementsByTagName('FieldData');
        const fieldNumber = fieldDataElements.length;

        if (fieldNumber) {
          // Fill in fieldData if possible.
          const fieldDataElement: any = fieldDataElements[0];

          const fieldData = (vtkDataOject as any).getFieldData();

          const vtkRootXmlAttributes = getXmlAttributes(vtkXmlNode);
          const { byte_order, header_type, compressor } = vtkRootXmlAttributes;

          vtkXMLReader.processFieldData(
            fieldNumber,
            fieldDataElement,
            fieldData,
            compressor,
            byte_order,
            header_type,
            dataArrayBuffer,
          );
          return vtkDataOject; // we return the object as parsed by itk, without the fielddata
        }
      } catch (error) {
        console.error('Failed to parse fielddata', error);
        return vtkDataOject; // we return the object as parsed by itk, without the fielddata
      }

      return vtkDataOject;
    })
    .catch((error) => {     

      if (localStorage.getItem('DEBUG_SOCKET_ON') === 'true') {
        console.error('Failed to read vtu data', data);
      }
      throw error;
    });
};

const self = {
  readVtuPolyDataAsString,
};

export default self;
