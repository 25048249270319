import { put, takeEvery, all, delay } from 'redux-saga/effects'
import ActionType from '../actions/ActionType'
//import { IProgressItem } from '@mike/mike-shared-frontend/mike-topbar-progress-spinner';
import { ITrackProgressItem } from '../model/ITrackProgressItem'
import {
  removeUpload
} from '../actions/uploads'
import {
  removeImport
} from '../actions/imports'
import {
  removeExport
} from '../actions/exports'


import {
  updateOrAddProgressItem,
  removeProgressItem
} from '../actions/progressItems'
import { addError } from '../actions/errors'
import { PROGRESS_ITEM_TIMEOUT } from '../components/mike-topbar-progress-spinner'

export default function* watchProgress() {
  yield takeEvery(ActionType.UPLOAD_FILE, handleUploadStart)
  yield takeEvery(ActionType.UPLOAD_FAIL, handleProcessFailed)
  yield takeEvery(ActionType.UPDATE_PROGRESS, handleUpdateUploadProgress)

  yield takeEvery(ActionType.IMPORT_START, handleImportStart)
  yield takeEvery(ActionType.IMPORT_FAIL, handleProcessFailed)
  yield takeEvery(ActionType.IMPORT_COMPLETE, handleImportComplete)

  yield takeEvery(ActionType.COPY_FAIL, handleProcessFailed)
  yield takeEvery(ActionType.COPY_COMPLETE, handleCopyComplete)

  yield takeEvery(ActionType.EXPORT_START, handleExportStart)
  yield takeEvery(ActionType.UPDATE_EXPORT_PROGRESS, handleUpdateProgress)
  yield takeEvery(ActionType.EXPORT_FAIL, handleProcessFailed)
  yield takeEvery(ActionType.EXPORT_COMPLETE, handleExportComplete)
}

export function* handleProcessFailed(action) {
  const { id, error } = action.data
  const progressItem: ITrackProgressItem = {
    id: id,
    title: 'Error',
    progressValue: 75
  }
  progressItem.error = true
  yield put(updateOrAddProgressItem(progressItem))
  yield put(addError(error && error.message ? error.message : error))
  yield delay(PROGRESS_ITEM_TIMEOUT)
  yield put(removeProgressItem(progressItem))
  yield all([
    put(removeImport(id)),
    put(removeUpload(id)),
    put(removeExport(id))
  ])
}

export function* handleUpdateUploadProgress(action) {
  const { id, progress, fileName } = action.data
  const progressItem: ITrackProgressItem = {
    id: id,
    title: 'Step 1 of 2: Uploading (' + fileName + ')',
    progressValue: progress / 2
  }
  yield put(updateOrAddProgressItem(progressItem))
}

export function* handleUpdateProgress(action) {
  const { id, progress, title } = action.data
  const progressItem: ITrackProgressItem = {
    id: id,
    title: title && title,
    progressValue: progress
  }
  yield put(updateOrAddProgressItem(progressItem))
}

export function* handleUploadStart(action) {
  const { id, fileName } = action.data
  const progressItem: ITrackProgressItem = {
    id: id,
    title: 'Step 1 of 2: Uploading (' + fileName + ')',
    progressValue: 0
  }
  yield put(updateOrAddProgressItem(progressItem))
}

export function* handleExportStart(action) {
  const { id, title } = action.data
  const progressItem: ITrackProgressItem = {
    id: id,
    title: title,
    progressValue: 0
  }
  yield put(updateOrAddProgressItem(progressItem))
}

export function* handleConvertStart(action) {
  const { id, title } = action.data
  const progressItem: ITrackProgressItem = {
    id: id,
    title: title,
    progressValue: 0
  }
  yield put(updateOrAddProgressItem(progressItem))
}

function* handleImportStart(action) {
  const { id, data, initialId } = action.data
  if (initialId) {
    const name =
      data && data.name
        ? ' (' + data.name + ')'
        : data && data.fileName
        ? ' (' + data.fileName + ')'
        : ''

    const progressItem: ITrackProgressItem = {
      id: initialId,
      title: 'Step 2 of 2: Converting' + name,
      progressValue: 75,
      importId: id
    }
    yield put(updateOrAddProgressItem(progressItem))
  }
}

export function* handleExportComplete(action) {
  const progressItem: ITrackProgressItem = {
    id: action.data,
    title: 'Loading completed',
    progressValue: 100,
    done: true
  }
  yield put(updateOrAddProgressItem(progressItem))
  // yield delay(PROGRESS_ITEM_TIMEOUT)
  yield put(removeProgressItem(progressItem))
  yield put(removeExport(action.id))
}

function* handleImportComplete(action) {
  const progressItem: ITrackProgressItem = {
    id: action.data.id,
    title: 'Saving completed',
    progressValue: 100,
    done: true
  }
  yield put(updateOrAddProgressItem(progressItem))
  // yield delay(PROGRESS_ITEM_TIMEOUT)
  yield put(removeProgressItem(progressItem))
  yield all([put(removeImport(action.id)), put(removeUpload(action.id))])
}

function* handleCopyComplete(action) {
  const progressItem: ITrackProgressItem = {
    id: action.data.id,
    title: 'Copying completed',
    progressValue: 100,
    done: true
  }
  yield put(updateOrAddProgressItem(progressItem))
  // yield delay(PROGRESS_ITEM_TIMEOUT)
  yield put(removeProgressItem(progressItem)) 
}
