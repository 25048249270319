/** @jsxImportSource @emotion/react */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { css } from '@emotion/react';
import {
  Typography,
  Breadcrumbs,
  Link,
  Tooltip,
} from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { IGetProjectPath } from '../../model/IGetProject';
import mikeSharedTheme from '../../styles/mikeSharedTheme';

export type MikeProjectBreadcrumpsNavClassKey =
  | 'root'
  | 'mikeBreadcrumpsLinkLabel'
  | 'mikeBreadcrumpsLastLabel'
  | 'mikeBreadcrumpsEllipsis';

interface IProps {
  breadcrumpsContent: Array<IGetProjectPath>; 
  currentProjectName?: string;
  onClickCallBack: (project: IGetProjectPath) => void;
}


const rootStyle = css`
      background-color: ${mikeSharedTheme.palette.background.default};
      padding-top: ${mikeSharedTheme.spacing(1)};
    `;
    const mikeBreadcrumpsLinkLabelStyle = css`
      cursor: pointer;
      color: ${mikeSharedTheme.palette.text.primary};
      &:hover: {
        color: ${mikeSharedTheme.palette.text.primary};
      };
      &:active: {
        color: ${mikeSharedTheme.palette.text.primary};
      };
      &:focus: {
        color: ${mikeSharedTheme.palette.text.primary};
      };
   `;
    const mikeBreadcrumpsLastLabelStyle = css`
      font-weight: bold;
   `;
    const mikeBreadcrumpsEllipsisStyle = css`
      max-width: 25vw;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: block;
 `;


/**
 * @name MikeProjectBreadcrumpsNav
 * @summary Renders a list of links. Meant to be used as breadcrumbs in the MikeProjectExplorer.
 */
export const MikeProjectBreadcrumpsNav = (props: IProps) => {
  const { breadcrumpsContent, currentProjectName, onClickCallBack, ...rest } = props;


  const handleClick = (project: IGetProjectPath) => (e: any) => {
    e.preventDefault();
    onClickCallBack(project);
  };

  return (
    <Breadcrumbs
      css={rootStyle}
      maxItems={3}
      separator={<NavigateNextIcon color="primary" fontSize="small" />}
      aria-label="mikebreadcrumb"
      {...rest}
    >
      {breadcrumpsContent.map((breadcrump: IGetProjectPath, index: number) => {
        return (
          <Tooltip key={breadcrump.id} title={breadcrump.name}>
            <Link
              key={index}
              css={[
                mikeBreadcrumpsLinkLabelStyle,
                mikeBreadcrumpsEllipsisStyle 
               ].join(' )')
              }
              onClick={handleClick(breadcrump)}
            >
              <Typography
                css={[
                  mikeBreadcrumpsLinkLabelStyle,
                  mikeBreadcrumpsEllipsisStyle 
                 ].join(' )')
                }
                variant="body2"
              >
                {breadcrump.name}
              </Typography>
            </Link>
          </Tooltip>
        );
      })}
      {currentProjectName && (
        <Typography     
          css={[
            mikeBreadcrumpsEllipsisStyle,
            mikeBreadcrumpsLastLabelStyle 
           ].join(' )')
          }
          variant="body2"
        >
          {currentProjectName}
        </Typography>
      )}
    </Breadcrumbs>
  );
};

export default MikeProjectBreadcrumpsNav;
