import MikeVisualizerStore from '../../store/MikeVisualizerStore';
import MikeVisualizer2DMapUtil from '../MikeVisualizer2DMapUtil';
import { IMikeOlBaseLayer, IMikeOlVectorLayer } from './MikeVisualizer2DIO';
import { Options as OlStyleOptions, StyleLike } from 'ol/style/Style';
const { getState, setState } = MikeVisualizerStore;
const { _makeDefaultVectorStyle } = MikeVisualizer2DMapUtil;

/**
 * Module containing methods that can modify the appearance of a 2D geometry:
 * color, opacity, visibility, etc.
 *
 * @module MikeVisualizerCosmetic
 * @version 1.0.0
 */

/**
 * Sets a new color for a 2d element and updates state.
 *
 * @param elementId The 2d element id to set color to.
 * @param edgeColor Any valid css color.
 * @param surfaceColor Any valid css color.
 *
 * @public
 */
const setColorTo2DElement = (elementId: string, edgeColor: string, surfaceColor: string) => {
  return setStylesTo2DElement({ elementId, edgeColor, surfaceColor });
};

export interface ISetColorOptions {
  elementId: string;
  edgeColor?: string;
  surfaceColor?: string;
  olStyleOptions?: OlStyleOptions;
  olStyle?: StyleLike;
}
/**
 * Same as setColorTo2DElement but with more options.
 * @param options {ISetColorOptions}
 * @returns
 */
export const setStylesTo2DElement = (options: ISetColorOptions) => {
  const { dataMap } = getState();
  if (!dataMap) {
    return false;
  }
  const { elementId, edgeColor, surfaceColor, olStyleOptions, olStyle } = options;
  try {
    dataMap.getLayers().forEach((layer) => {
      const layr = layer as IMikeOlVectorLayer;
      if (layr && layr.elementId === elementId) {
        if (olStyle) {
          layr.setStyle(olStyle);
        } else {
          layr.setStyle(_makeDefaultVectorStyle(surfaceColor, edgeColor, olStyleOptions));
        }
      }
    });
    return true;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  } catch (error) {
    return false;
  }
};

/**
 * Makes a list of 2d elements hidden.
 * NB: replaces previous hidden elements.
 *
 * @param elementIds The ids of elements to hide.
 *
 * @public
 */
const hide2DElements = (elementIds: Array<string>) => {
  const { dataMap } = getState();

  if (!dataMap) {
    return false;
  }

  setState({ hidden2DElementIds: elementIds });
  elementIds.forEach((pId) => self._hide(pId));
  getState().renderWindow.render();

  return true;
};

/**
 * Makes a list of 2d elements shown.
 *
 * @param elementIds The ids of elements to show.
 *
 * @public
 */
const show2DElements = (elementIds: Array<string>) => {
  const { dataMap, hidden2DElementIds } = getState();

  if (!dataMap) {
    return false;
  }
 
  setState({
    hidden2DElementIds: hidden2DElementIds.filter((elId) => !elementIds.includes(elId)),
  });
  elementIds.forEach((pId) => self._show(pId));
  return true;
};

/**
 * Makes the 2d element with the provided id visible.
 *
 * @param elementId
 *
 * @private
 */
const _show = (elementId: string) => {
  self._setVisibility(elementId, true);
};

/**
 * Makes the 2d element with the provided id hidden.
 *
 * @param elementId
 *
 * @private
 */
const _hide = (elementId: string) => {
  self._setVisibility(elementId, false);
};

/**
 * Changes the visibility of a 2d element.
 *
 * @param elementId The id of the element to set visibility to.
 * @param visibility
 *
 * @private
 */
const _setVisibility = (elementId: string, visibility: boolean) => {
  const { dataMap } = getState();
  if (!dataMap) {
    console.error('No data map');
    return false;
  }
  try {
    dataMap.getLayers().forEach((layer) => {
      const layr = layer as IMikeOlBaseLayer;
      if (layr && layr.elementId === elementId) {
        layer.setVisible(visibility);
      }
    });
  } catch (error) {
    console.error('Failed to set visibility', error);
  }

  return true;
};

const self = {
  _show,
  _hide,
  _setVisibility,

  show2DElements,
  hide2DElements,
  setColorTo2DElement,
  setStylesTo2DElement,
};

export default self;
