import { takeEvery, put, call, select, all } from 'redux-saga/effects'
import ActionType from '../actions/ActionType'
import { IAction } from '../actions/Action'
import { setProjects, setLoadingProjects, getProjects, setCreatingRootProject, setCreateRootProjectPanelOpen, setUpdatingProject } from '../actions/projects'
import { addError } from '../actions/errors'
import { IGetProjectResponse } from '../model/IGetProjectResponse'
import { IState } from "../reducers";
import { delProject, getRootProjects, postProject, putProject } from '../apis/metadataApi'
import { setDeletingRootProjects } from '../actions/projects'

/* interface IGetProjects{
  offset: number;
  rowsPerPage: number;
  sortBy: string
  sortOrder: 'asc' | 'desc';
  namePrefix: string
} */

export const getPage = (state: IState) => state.projects.page;
export const getRowsPerPage = (state: IState) => state.projects.rowsPerPage;
export const getSortBy = (state: IState) => state.projects.sortBy;
export const getSortOrder = (state: IState) => state.projects.sortOrder;
export const getNamePrefix = (state: IState) => state.projects.namePrefix;

export default function* watchGetProjects() {
  yield takeEvery(ActionType.GET_PROJECTS, handleGetProjects)
  yield takeEvery(ActionType.SET_PROJECTS_PAGINATION, handleSetProjectsPagination)
  yield takeEvery(ActionType.SET_PROJECTS_ROWSPERPAGE, handleSetProjectsRowsPerPage)
  yield takeEvery(ActionType.CREATE_ROOTPROJECT, handleCreateRootProject)
  yield takeEvery(ActionType.DELETE_ROOTPROJECTS, deleteRootProjects)
  yield takeEvery(ActionType.UPDATE_ROOTPROJECT, handleUpdateProject)
}

function* deleteRootProjects(action) {
  const ids = action.data
  const page = yield select(getPage)
  const rowsPerPage = yield select(getRowsPerPage)
  const namePrefix = yield select(getNamePrefix)
  const sortBy = yield select(getSortBy)
  const sortOrder = yield select(getSortOrder)

  try {
    yield put(setDeletingRootProjects())
    yield all(ids.map(id => call(delProject, id)))
    yield put(getProjects(page, rowsPerPage, sortBy, sortOrder, namePrefix)) 
  } catch (error) {
    yield put(addError(error)); 
  } finally {
    yield put(setDeletingRootProjects(false))
  }
}

function* handleUpdateProject(action){
  const data  = action.data
  try{
    yield put(setCreateRootProjectPanelOpen(false))
    yield put(setUpdatingProject())
    yield call(putProject, data)    
    const page: number = yield select(getPage);
    const rowsPerPage: number = yield select(getRowsPerPage);
    const sortBy: string = yield select(getSortBy);
    const sortOrder: 'asc' | 'desc' = yield select(getSortOrder);
    const namePrefix: string = yield select(getNamePrefix);    
    yield put(getProjects(page, rowsPerPage, sortBy, sortOrder, namePrefix))
  }
  catch (error){
    yield put(addError(error)); 
  } 
  finally{
    yield put(setUpdatingProject(false))    
  }
}

function* handleCreateRootProject(action){
  const data = action.data
  try{
    yield put(setCreateRootProjectPanelOpen(false))
    yield put(setCreatingRootProject())
    yield call(postProject, data)
    const page: number = yield select(getPage);
    const rowsPerPage: number = yield select(getRowsPerPage);
    const sortBy: string = yield select(getSortBy);
    const sortOrder: 'asc' | 'desc' = yield select(getSortOrder);
    const namePrefix: string = yield select(getNamePrefix);    
    yield put(getProjects(page, rowsPerPage, sortBy, sortOrder, namePrefix)) 
  }
  catch (error){
    yield put(addError(error)); 
  } 
  finally{
    yield put(setCreatingRootProject(false))    
  }
}

function* handleGetProjects(action: IAction) {
  try{
    yield put(setLoadingProjects())
    const { rowsPerPage, sortBy, sortOrder, namePrefix} = action.data
    const response: IGetProjectResponse = yield call(getRootProjects, action.data.offset, rowsPerPage, sortBy, sortOrder ==='asc' ? 'Asc': 'Desc', namePrefix)
    
      const { data, totalCount, offset, limit } = response
      yield put(setProjects(data, totalCount, limit !== undefined ? limit : rowsPerPage, offset !== undefined ? offset : 0 ))
    
  }
  catch (error){
    yield put(addError(error)); 
  } 
  yield put(setLoadingProjects(false))
}

function* handleSetProjectsPagination(action: IAction) {
  try{
    yield put(setLoadingProjects())
    const rowsPerPage: number = yield select(getRowsPerPage);
    const sortBy: string = yield select(getSortBy);
    const sortOrder: 'asc' | 'desc' = yield select(getSortOrder);
    const namePrefix: string = yield select(getNamePrefix);

    const page = action.data
    yield put(getProjects(page * rowsPerPage, rowsPerPage, sortBy, sortOrder, namePrefix))  
    
  }
  catch (error){
    yield put(addError(error)); 
  } 
  yield put(setLoadingProjects(false))
}

function* handleSetProjectsRowsPerPage(action: IAction) {
  try{
    yield put(setLoadingProjects())
    const page: number = yield select(getPage);
    const sortBy: string = yield select(getSortBy);
    const sortOrder: 'asc' | 'desc' = yield select(getSortOrder);
    const namePrefix: string = yield select(getNamePrefix);

    const rowsPerPage = action.data
    yield put(getProjects(page * rowsPerPage, rowsPerPage, sortBy, sortOrder, namePrefix))    
  }
  catch (error){
    yield put(addError(error)); 
  } 
  yield put(setLoadingProjects(false))
}
