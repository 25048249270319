/* eslint-disable @typescript-eslint/no-explicit-any */
import { call, put, select, takeLatest } from 'redux-saga/effects';
import {
  getTenantFeatures,
  getUserTenants, 
} from '../apis/metadataApi'
import { addError } from '../actions/errors'
import ActionType from '../actions/ActionType';
import { getConfiguration } from '../reducers/state';
import { loadingTenants, setMyTenants } from '../actions/iam';
import { setApps, setLoadingApps } from '../actions/applications';
import { IApplication, IApplications } from '../components/mike-subscribed-apps/model/IApplications';

export default function* watchIAM() {
  yield takeLatest(ActionType.GET_APPS, fetchApplications);
  yield takeLatest(ActionType.GET_MY_TENANTS, fetchMyTenants);
}

interface IFeature {
  enabled: boolean;
  featureId: string;
  name: string;
  responseUrl: string;
  type: string;
  url: string;
}

function* fetchMyTenants() {
  try {
    yield put(loadingTenants());

    const myTenants = yield call(getUserTenants)
      yield put(setMyTenants(myTenants));
    
  } catch (error: any) {
    yield put(addError(error))
  } finally {
    yield put(loadingTenants(false));
  }
}

function* fetchApplications() {
  try {
    yield put(setLoadingApps());

    const features: IFeature[] = (yield call(getTenantFeatures)).data

    if (features) {      
      let apps = new Array<IApplication>();
      if (features && features.length > 0) {
        let ownFeatureId = 'fb6387cf-481d-ed11-b83d-000d3abd6960';
        if (!process.env.VITE_BACKEND_URL) {
          const configuration = yield select(getConfiguration);
          if (configuration.oAuth2.clientName) {
            ownFeatureId = configuration.oAuth2.clientName;
          }
        }
        apps = features
          .filter(
            (app: IFeature) => app.enabled && app.url && app.type === 'Application' && app.featureId !== ownFeatureId,
          )
          .map((app: IFeature) => {
            return {
              appName: app.name,
              forAdministratorOnly: false,
              // ToDo: Fix this in mike-shared once Data Link is migrated
              appUrl: app.name === 'Data Link' || app.url.endsWith('/') ? app.url : app.url + '/',
              iconName: app.name,
            };
          });
      }

      const applications: IApplications = {
        administrationApps: new Array<IApplication>(),
        appsInSubscription: apps,
        appsOutsideSubscription: new Array<IApplication>(),
      };
      yield put(setApps(applications));
    }
  } catch (error: any) {
    yield put(addError(error))
  } finally {
    yield put(setLoadingApps(false));
  }
}
