
import ActionType from "../actions/ActionType"
import { IAction } from "../actions/Action"
import { IGetProject, IGetProjectPath } from '../model/IGetProject';

export interface IFoldersState {
  projectPath: Array<IGetProjectPath>;
  projectId: string;
  loading: boolean;
  folders: IGetProject[];
  namePrefix: string;
  sortBy: string;
  sortOrder: 'asc' | 'desc';
  totalCount: number;
  offset: number;
  page: number;
  rowsPerPage: number;
  limit: number;
  creatingFolder: boolean;
  createFolderPanelIsOpen: boolean;
  deleting: boolean;
}

const initState = {
  projectPath: new Array<IGetProjectPath>(),
  projectId: '',
  loading: false,  
  folders: Array<IGetProject>(),
  namePrefix: '',
  sortBy: 'createdAt',
  sortOrder: 'desc',
  totalCount: 0,
  offset: 0,
  page: 0,
  rowsPerPage: 25,
  limit: 25,
  creatingFolder: false,
  createFolderPanelIsOpen: false,
  deleting: false
}

const folders = (state = initState, action: IAction) => {
  switch (action.type) {
    case ActionType.SET_FOLDER_PATH: {
      return {...state, projectPath: action.data}
    }
    case ActionType.SET_FOLDER_PANEL_OPEN: {
      return {...state, createFolderPanelIsOpen: action.data}
    }
    case ActionType.CREATING_FOLDER:
      return {...state, creatingFolder: action.data}
    case ActionType.DELETING_FOLDERS:
      return { ...state, deleting: action.data };
    case ActionType.LOADING_FOLDERS:
      return { ...state, loading: action.data };
    case ActionType.SET_FOLDERS_PAGINATION:
      return { ...state, page: action.data }
    case ActionType.SET_FOLDERS_ROWSPERPAGE:
      return { ...state, rowsPerPage: action.data }
    case ActionType.SET_FOLDERS_SORT:
      return { ...state, 
        sortOrder: action.data.sortOrder,
        sortBy: action.data.sortBy 
      }
    case ActionType.SET_FOLDERS_NAMEPREFIX:
      return { ...state, namePrefix: action.data }
    case ActionType.SET_FOLDERS: {     
      return {
        ...state,
        projectPath: action.data.path,
        projectId: action.data.projectId,
        folders: action.data.folders,
        loading: false,
        offset: action.data.offset,
        limit: action.data.limit,    
        totalCount: action.data.totalCount
      }; 
    }
          
    default:
        return state;
  }
};

export default folders