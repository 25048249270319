import { takeEvery, put, call, delay, select, all, takeLatest } from 'redux-saga/effects'
import ActionType from '../actions/ActionType'
import { addError } from '../actions/errors'
import { downloadSetupToProject, getJobLogs, getStoredJobStatus, processContainer } from '../apis/backendApi'
import { IAction } from '../actions/Action'
import { jobsSave, jobsSet, setDataLinkJobId, setDataLinkJobStatus, setJobId, setJobStatus } from '../actions/job'
import { removeProgressItem, updateOrAddProgressItem } from '../actions/progressItems'
import { defineMessages } from 'react-intl'
import { cancelJob, getJobStatus, getProjectDatasets, getProjectSubprojects } from '../apis/metadataApi'
import { DATA_LINK_JOBS_IN_PROGRESS, IJobStatus, JOBS_IN_PROGRESS, JOB_STATUSES } from '../model/IJobStatus'
import { DATALINKJOBS, DATASETS, EXTRACTION_ARCHIVE_EXTENSION, EXTRACTION_ARCHIVE_FIXED_NAME_PART, EXTRACTION_INFO_FILE_EXTENSION, EXTRACTION_INFO_FIXED_NAME_PART, GET_JOB_INFO_DELAY, JOBS, OUTPUTFOLDER, OUTPUTLOG } from '../shared/constants'
import {  getExtractionPeriodEnd, getExtractionPeriodStart, getFastWaveConfig, getJobs, getProject, getSelectedBoundaryOrForcing, getStoredJobs } from '../reducers/state'
import { centralPointsGet, dataLinkExtractionBuilderDataGet,  fastWaveConfigSet, setBoundaryCondition, showSuccessDialog, testRunOutputGet } from '../actions/mapContent'
import { IGetProject } from '../model/IGetProject'
import { IJob } from '../model/IFastWaveConfig'
import { dateTimeFormat } from '../helpers/fixTime'
import { cancelExtraction, getExtractionBuilderJobs } from '../apis/dataLinkApi'
import { IGetDataset } from '../model/IGetDataset'
import { getDataLinkExtractionArchive, getDataLinkExtractionInfo } from '../helpers/fastwave'
import { getHasModelResults } from '../actions/projectContent'
import { exportAndDownloadDataset } from '../actions/exportAndDownload'
import { getNiceDataLinkJobName, getNiceJobName } from '../helpers/jobs'
import { intl } from '../main'
import { IProgressItem, PROGRESS_ITEM_TIMEOUT } from '../components/mike-topbar-progress-spinner'

const messages = defineMessages({ 
  jobProgressStarting: { id: 'jobProgress.starting'},
  jobProgressPending: { id: 'jobProgress.pending'},
  jobProgressRunning: { id: 'jobProgress.running'},
  jobProgressFinished: { id: 'jobProgress.finished'},
  jobErrorsGetStatus: { id: 'jobErrors.getStatus'},
  jobProgressIsStarting: { id: 'jobProgress.isStarting'},
  jobProgressIsPending: { id: 'jobProgress.isPending'},
  jobProgressIsRunning: { id: 'jobProgress.isRunning'},
  jobProgressIsFinished: { id: 'jobProgress.isFinished'},
  jobProgressIsCancelled: {id: 'jobProgress.isCancelled'},
  jobApprox: {id: 'jobProgress.approx'},
  jobMinutes:  {id: 'jobProgress.min'},
  jobCanTakeSeveralMinutes:  {id: 'jobProgress.canTakeSeveralMinutes'},
  jobCanTakeHours:  {id: 'jobProgress.canTakeHours'}
})

export default function* watchJobs() {
  yield takeLatest(ActionType.JOBS_GET, handleGetJobs)
  yield takeEvery(ActionType.JOBS_SET, handleStoredJobs)
  yield takeEvery(ActionType.CREATE_JOB, handleProcessContainer)
  yield takeEvery(ActionType.SET_JOB, handleSetJob) 
  yield takeEvery(ActionType.SET_JOB_STATUS, updateConfigForContainer)
  yield takeEvery(ActionType.SET_DATA_LINK_JOB, handleSetDataLinkJob)   
  yield takeEvery(ActionType.SET_DATA_LINK_JOB_STATUS, updateConfigForDataLinkJob)  
  yield takeEvery(ActionType.CANCEL_JOBS, handleCancelJobs) 
  yield takeEvery(ActionType.CANCEL_JOB, handleCancelJob) 
}

function* handleCancelJobs(action){
  const jobIds = action.data
  const project: IGetProject | null = yield select(getProject)  
  if (project && jobIds && jobIds.length > 0){
    try{
      yield all(
        jobIds.map(jobId => call(cancelJob, jobId, project.id))
      )
    }
    catch (error) {
      console.log(error)
    }
  }
}

function* handleCancelJob(action){
  const jobId = action.data
  if (jobId){
    const jobs = yield select(getJobs)
    const dataLinkExtractionJobIds = jobs.filter((job: IJob) => job.rowKey === DATALINKJOBS.DATAEXTRACTION).map((job: IJob) => job.jobId)
    if (dataLinkExtractionJobIds.includes(jobId)){
      try{
        yield call(cancelExtraction, jobId)
      }
      catch (error) {
        console.log(error)
      }
    }
    else{
      const project: IGetProject | null = yield select(getProject)  
      if (project){
        try{
          yield call(cancelJob, jobId, project.id)
        }
        catch (error) {
          console.log(error)
        }
      }
    }
  }
}

function* handleGetJobs(action){   
  const project: IGetProject | null = yield select(getProject)
  const projectId = project ? project.id : action.data  
  if (projectId){  
    try{
      const jobs = yield call(getStoredJobStatus, projectId)
      if (jobs && jobs.length > 0){
        yield put(jobsSet(jobs, projectId))  
      } 
    }
    catch (error) {
      console.log(error)
    }    
  }  
}

export function* handleStoredJobs(action){
  const {jobs, projectId} = action.data
  const pendingOrRunningJobs = jobs.filter((job: IJob) => job.status && JOBS_IN_PROGRESS.includes(job.status.toLowerCase()))
  yield all(
    pendingOrRunningJobs.map((job: IJob) =>
      put(setJobId(job.rowKey, job.jobId, job.start, getNiceJobName(job.rowKey), projectId))
    )
  )
  const pendingOrRunningDataLInkJobs = jobs.filter((job: IJob) => job.status && DATA_LINK_JOBS_IN_PROGRESS.includes(job.status.toLowerCase()))
  yield all(
    pendingOrRunningDataLInkJobs.map((job: IJob) =>
      put(setDataLinkJobId(job.rowKey, job.jobId, job.start, getNiceDataLinkJobName(job.rowKey), projectId))
    )
  )
}

export function* handleProcessContainer(action: IAction) {
  const {jobIndex, configToBeUpdated, skipCheckIfContainerNeedsToRerun} = action.data
  const project = yield select(getProject)
  const config = yield select(getFastWaveConfig)
  let runContainer = skipCheckIfContainerNeedsToRerun 
  if (!skipCheckIfContainerNeedsToRerun){    
    const selectedBoundaryOrForcing = yield select(getSelectedBoundaryOrForcing)
    switch (jobIndex) {
      case JOBS.MDA:
        runContainer = !selectedBoundaryOrForcing || !config.data_link_output_file.name.startsWith(selectedBoundaryOrForcing.source)
        break;
      default:
        runContainer = true
        break;
    }
  }
  if (runContainer){
    if (configToBeUpdated){   
      yield put(fastWaveConfigSet(configToBeUpdated, true, jobIndex))
    } 
    else{
      try{
        if (jobIndex === JOBS.TESTSETUP ||  jobIndex === JOBS.AUTOSETUP){
          const datasets = yield call(getProjectDatasets, project.id) 
          const dataset = datasets.find((ds: IGetDataset) => ds.name === config.setup)
          if (dataset === undefined){
            yield call(downloadSetupToProject, project.id, config.setup)
          }
        }
        const response = yield call(processContainer, project.id, jobIndex)
        if (response) {
          const name = getNiceJobName(jobIndex)          
          yield put(setJobId(jobIndex, response, dateTimeFormat(Date.now()), name, project.id))
        }
      }
      catch (error){
        yield put(addError(error)); 
      }  
    } 
  }
  
}

function* updateConfigForContainer(action: IAction){    
  const {jobId, jobIndex, jobStatus, start} = action.data
  const storedJobs = yield select(getStoredJobs)
  const job = storedJobs.find((j: IJob) => j.rowKey === jobIndex && j.jobId === jobId) 
  const configIsUptodate = job !== undefined  
    && job.status === jobStatus
  if (!configIsUptodate){
    const jobs = yield select(getJobs)
    const otherJobs = jobs.filter((j: IJob) => j.rowKey !== jobIndex)
    const updatedConfig = [...otherJobs, {rowKey: jobIndex,
      jobId: jobId, status: jobStatus, start: start}]
    yield put(jobsSave(updatedConfig))
  }
  if (jobStatus.toLowerCase() === JOB_STATUSES.FINISHED){
    const project = yield select(getProject)
    switch (jobIndex){
      case JOBS.MDA:
        yield put(centralPointsGet())//MDA job saves chart data for quality control and test model run 
        break;
      case JOBS.TESTSETUP:          
        yield put(testRunOutputGet())// Test model run creates a csv file in the output folder that we display in a table
        break;  
      case JOBS.AUTOSETUP: 
        yield put(showSuccessDialog())// We finally can notify the user that output can be downloaded
        if (project && project.id){
           yield put(getHasModelResults(project.id)) 
        }
        break;
      case JOBS.ENVIRONMENTALDATA:
        if (project && project.id){
          const folders = yield call(getProjectSubprojects, project.id)
          const outputFolder = folders.find((f: IGetProject) => f.name.toLowerCase() === OUTPUTFOLDER)
          if (outputFolder && outputFolder.id){
            const datasets = yield call(getProjectDatasets, outputFolder.id)
            const logDataset = datasets.find((d: IGetDataset) => d.name === OUTPUTLOG)
            if (logDataset !== undefined){
              try{
                yield put(exportAndDownloadDataset(logDataset.name,logDataset.id,
                  {importData: {name: logDataset.name, reader: "FileReader",writer: "FileWriter"}}, DATASETS.TRANFORM_USER_DATA_LOG))        
              }
              catch (error){
                console.log(error)
              }   
            }
          }      
       }
       break;
    }    
  }
}

const addEstimatedJobTime = (jobid: string, start: Date, end: Date) => {
  if (jobid && start && end){
    const endYear = end.getUTCFullYear();
    const startYear = start.getUTCFullYear();
    const years = endYear - startYear
    switch (jobid){
      case DATALINKJOBS.DATAEXTRACTION: {
        // 20s per year extracted (Round up to a full minute)
        const seconds = 20 * years;
        const minutes = seconds / 60
        return "(" + intl.formatMessage(messages.jobApprox) + " " +  Math.ceil(minutes) + " " + intl.formatMessage(messages.jobMinutes) + ")"        
      }
      case JOBS.MDA: {
        // 12s per year extracted (Round up to a full minute)
        const seconds = 12 * years;
        const minutes = seconds / 60
        return "(" + intl.formatMessage(messages.jobApprox) + " " +  Math.ceil(minutes) + " " + intl.formatMessage(messages.jobMinutes) + ")"        
      }
      case JOBS.TESTSETUP: {
        return intl.formatMessage(messages.jobCanTakeSeveralMinutes)
      }
      case JOBS.AUTOSETUP: {
        return intl.formatMessage(messages.jobCanTakeHours)
      }
    }
  }
  return ""  
}

function* handleSetJob(action: IAction) {
  const {jobId, jobIndex, start, jobName, projectId}  = action.data
 
  
  if (jobId && projectId){   
    const progressItem: IProgressItem = {  
      id: jobId,  
      title: jobName ? intl.formatMessage(messages.jobProgressIsStarting) + jobName :  intl.formatMessage(messages.jobProgressStarting),
      progressValue: 25
    }
    yield put(updateOrAddProgressItem(progressItem))
    let jobStatus: IJobStatus     
    
    while (true) {
      const project : IGetProject | null = yield select(getProject);
      if (project && project.id && project.id !== projectId){
        yield put(removeProgressItem(progressItem)) 
        break;
      }
      try{
        jobStatus = yield call(getJobStatus, jobId, project.id)
      }
      catch (error) {
        if (error && error.body && error.body.Type === 'Object not found'){
          progressItem.title = jobName ? jobName + " cancelled" : 'Cancelled'
          yield put(updateOrAddProgressItem(progressItem))       
          yield delay(PROGRESS_ITEM_TIMEOUT)     
          yield put(removeProgressItem(progressItem)) 
          yield put(setJobStatus(jobId, jobIndex, JOB_STATUSES.CANCELLED, start))
        }
        // Stop fetching job status when it starts to fail - happens e.g. when browser got inactive or job has been canceled by the user
        yield delay(PROGRESS_ITEM_TIMEOUT)     
        yield put(removeProgressItem(progressItem)) 
        yield put(addError(error));
        break
      }

      if (jobStatus && jobStatus.jobState) {  
        if (jobStatus.jobState.toLowerCase() === JOB_STATUSES.PENDING.toLowerCase()) { 
          progressItem.title = jobName ? jobName + intl.formatMessage(messages.jobProgressIsPending) : intl.formatMessage(messages.jobProgressPending) 
          progressItem.progressValue = 50
          yield put(updateOrAddProgressItem(progressItem))
          yield put(setJobStatus(jobId, jobIndex, jobStatus.jobState, start))
        } else if (jobStatus.jobState.toLowerCase() === JOB_STATUSES.RUNNING.toLowerCase()) {   
          const startDate =  yield select(getExtractionPeriodStart)
          const endDate =  yield select(getExtractionPeriodEnd)
          const title = jobName ? jobName + intl.formatMessage(messages.jobProgressIsRunning) : intl.formatMessage(messages.jobProgressRunning)    
          progressItem.title = title + addEstimatedJobTime(jobId, new Date(startDate), new Date(endDate))
          progressItem.progressValue = 75
          progressItem.canBeDeleted = true
          yield put(updateOrAddProgressItem(progressItem))
          yield put(setJobStatus(jobId, jobIndex, jobStatus.jobState, start))
        }
      
        else if (jobStatus.hasError) {    
          if (jobStatus.statusMessage.toLowerCase() === JOB_STATUSES.CANCELLED.toLowerCase()){
            progressItem.title = jobName ? jobName + " cancelled" : 'Cancelled'
          }
          else{
            progressItem.title = jobName ? jobName + " failed" : 'Error'   
            progressItem.error = true
          }
          
          yield put(updateOrAddProgressItem(progressItem))    
          if (jobStatus.statusMessage.toLowerCase() !== JOB_STATUSES.CANCELLED.toLowerCase()){    
            yield put(addError(intl.formatMessage(messages.jobErrorsGetStatus)));
          }
          yield delay(PROGRESS_ITEM_TIMEOUT)     
          yield put(removeProgressItem(progressItem)) 
          
          if (jobStatus.statusMessage.toLowerCase() === JOB_STATUSES.CANCELLED.toLowerCase()){
            yield put(setJobStatus(jobId, jobIndex, 'Cancelled', start))
          }
          else{
            yield put(setJobStatus(jobId, jobIndex, 'Error', start))
          }         
          
          break
        } else if (jobStatus.jobState.toLowerCase() === JOB_STATUSES.FINISHED.toLowerCase()) {             
          progressItem.title = jobName ? jobName + intl.formatMessage(messages.jobProgressIsFinished) : intl.formatMessage(messages.jobProgressFinished)
          progressItem.progressValue = 100
          progressItem.done = true
          const response = yield call(getJobLogs, project.id, jobId)
          if (response){
            console.log("Logs of job " + jobId + " in project " + project.id)
            console.log(response)
          }        
          yield put(updateOrAddProgressItem(progressItem))        
          yield put(setJobStatus(jobId, jobIndex, jobStatus.jobState, start)) 
          yield delay(GET_JOB_INFO_DELAY)       
          yield put(removeProgressItem(progressItem)) 
          break
        }

        yield delay(GET_JOB_INFO_DELAY)
      }     
      else {
        progressItem.title = jobName ? jobName + " failed" : 'Error'
        progressItem.error = true
        yield put(updateOrAddProgressItem(progressItem))       
        yield put(addError(jobStatus.statusMessage ? jobStatus.statusMessage  : intl.formatMessage(messages.jobErrorsGetStatus)));
        yield delay(PROGRESS_ITEM_TIMEOUT)     
        yield put(removeProgressItem(progressItem)) 
        break
      }
    }
  }
}

function* handleSetDataLinkJob(action: IAction) {
  const {jobId, jobIndex, start, jobName, projectId}  = action.data 
  
  if (jobId && projectId){   
    const progressItem: IProgressItem = {  
      id: jobId,  
      title: jobName ? intl.formatMessage(messages.jobProgressIsStarting) + jobName :  intl.formatMessage(messages.jobProgressStarting),
      progressValue: 20      
    }
    yield put(updateOrAddProgressItem(progressItem))
    let jobStatus     
    
    while (true) {
      const project : IGetProject | null = yield select(getProject);
      if (project && project.id && project.id !== projectId){
        yield put(removeProgressItem(progressItem)) 
        break;
      }
      try{
        const jobStatuses = yield call(getExtractionBuilderJobs, [jobId])
        const status = JSON.parse(jobStatuses)
        if (status.length > 0){
          jobStatus = status[0]
        }
      }
      catch {  
        // Stop fetching job status when it starts to fail - happens e.g. when browser got inactive         
        yield delay(PROGRESS_ITEM_TIMEOUT)     
        yield put(removeProgressItem(progressItem))
        break
      }

      if (jobStatus && jobStatus.State) { 
        if (jobStatus.State.toLowerCase() === JOB_STATUSES.SCHEDULED.toLowerCase()) { 
          progressItem.title = jobName ? jobName + intl.formatMessage(messages.jobProgressIsPending) : intl.formatMessage(messages.jobProgressPending)      
          progressItem.progressValue = 40
          yield put(updateOrAddProgressItem(progressItem))
          yield put(setDataLinkJobStatus(jobId, jobIndex, jobStatus.State, start, jobStatus))
        } else if (jobStatus.State.toLowerCase() === JOB_STATUSES.STARTED.toLowerCase()) {   
          const title = jobName ? jobName + intl.formatMessage(messages.jobProgressIsRunning) : intl.formatMessage(messages.jobProgressRunning)    
          const startDate =  yield select(getExtractionPeriodStart)
          const endDate =  yield select(getExtractionPeriodEnd)
          progressItem.title = title + addEstimatedJobTime(jobId, new Date(startDate), new Date(endDate))
          progressItem.progressValue = 60
          progressItem.canBeDeleted = jobIndex === DATALINKJOBS.DATAEXTRACTION
          yield put(updateOrAddProgressItem(progressItem))
          yield put(setDataLinkJobStatus(jobId, jobIndex, jobStatus.State, start, jobStatus))
        } else if (jobStatus.State.toLowerCase() === JOB_STATUSES.PROCESSING.toLowerCase()) {   
          progressItem.title = jobName ? jobName + intl.formatMessage(messages.jobProgressIsRunning) : intl.formatMessage(messages.jobProgressRunning)    
          progressItem.progressValue = 80
          progressItem.canBeDeleted = jobIndex === DATALINKJOBS.DATAEXTRACTION
          yield put(updateOrAddProgressItem(progressItem))
          yield put(setDataLinkJobStatus(jobId, jobIndex, jobStatus.State, start, jobStatus))
        } else if (jobStatus.State.toLowerCase() === JOB_STATUSES.FAILED.toLowerCase()) {    
          progressItem.title = jobName ? jobName + " failed" : 'Error'   
          progressItem.error = true
          yield put(updateOrAddProgressItem(progressItem)) 
          yield put(addError(jobStatus.Status ?  jobStatus.Status :intl.formatMessage(messages.jobErrorsGetStatus)));
          yield delay(PROGRESS_ITEM_TIMEOUT)     
          yield put(removeProgressItem(progressItem)) 
          yield put(setDataLinkJobStatus(jobId, jobIndex, 'Error', start, jobStatus))
          break
        } else if (jobStatus.State.toLowerCase() === JOB_STATUSES.COMPLETED.toLowerCase()) {             
          progressItem.title = jobName ? jobName + intl.formatMessage(messages.jobProgressIsFinished) : intl.formatMessage(messages.jobProgressFinished)
          progressItem.progressValue = 100
          progressItem.done = true              
          yield put(updateOrAddProgressItem(progressItem)) 
          yield put(setDataLinkJobStatus(jobId, jobIndex, jobStatus.State, start, jobStatus))
          yield delay(GET_JOB_INFO_DELAY)       
          yield put(removeProgressItem(progressItem)) 
          break
        } else if (jobStatus.State.toLowerCase() === JOB_STATUSES.CANCELLED.toLowerCase()) {             
          progressItem.title = jobName ? jobName + intl.formatMessage(messages.jobProgressIsCancelled) : intl.formatMessage(messages.jobProgressIsCancelled)
          progressItem.progressValue = 0                  
          yield put(updateOrAddProgressItem(progressItem)) 
          yield put(setDataLinkJobStatus(jobId, jobIndex, jobStatus.State, start, jobStatus))
          yield delay(GET_JOB_INFO_DELAY)       
          yield put(removeProgressItem(progressItem)) 
          break
        }
         
        yield delay(GET_JOB_INFO_DELAY)       
      }            
    }
  }
}

function* updateConfigForDataLinkJob(action: IAction){    
  const {jobId, jobIndex, jobStatus, start, status} = action.data 
  const storedJobs = yield select(getStoredJobs)
  const job = storedJobs.find((j: IJob) => j.rowKey === jobIndex && j.jobId === jobId) 
  const configIsUptodate = job !== undefined  
    && job.status === jobStatus
  if (!configIsUptodate){   
    const jobs = yield select(getJobs)
    const otherJobs = jobs.filter((j: IJob) => j.rowKey !== jobIndex)
    const updatedConfig = [...otherJobs, {rowKey: jobIndex,
      jobId: jobId, status: jobStatus, start: start}]
    yield put(jobsSave(updatedConfig))       
  }
  if (jobStatus.toLowerCase() === JOB_STATUSES.COMPLETED.toLowerCase()){
    switch (jobIndex){
      case DATALINKJOBS.BOUNDARYEXTRACTION:               
        yield put(dataLinkExtractionBuilderDataGet()) // Now providers can be fetched as mesh boundaries have been uploaded to Data Link storage
        break;
      case DATALINKJOBS.DATAEXTRACTION:
        if (status && status.ZipUri){
          const zipUriParts = status.ZipUri.split("?sv")
          if (zipUriParts.length === 2){
            const path = zipUriParts[0].split("%20")
            if (path.length > 0){             
              const project: IGetProject | null = yield select(getProject) 
              const startDate = yield select(getExtractionPeriodStart)
              const endDate = yield select(getExtractionPeriodEnd)
              const dynamicNamePart = path[path.length -1]
              const archiveName = EXTRACTION_ARCHIVE_FIXED_NAME_PART + dynamicNamePart
              const infoFileName = EXTRACTION_INFO_FIXED_NAME_PART + dynamicNamePart.replace(EXTRACTION_ARCHIVE_EXTENSION, EXTRACTION_INFO_FILE_EXTENSION)
              const datasets = yield call(getProjectDatasets, project.id)
              const extractionInfoDataset: IGetDataset | undefined = getDataLinkExtractionInfo(datasets, infoFileName) 
              const extractionArchiveDataset: IGetDataset | undefined = getDataLinkExtractionArchive(datasets, archiveName) 
              if (extractionInfoDataset && extractionArchiveDataset){
                const config = yield select(getFastWaveConfig)
                const updatedConfig = {...config, 
                  start_time: startDate, end_time: endDate, test_event_time: startDate,                  
                  data_link_output_file: {dataset_id: extractionArchiveDataset.id, name: extractionArchiveDataset.name},
                  data_link_output_info_file: {dataset_id: extractionInfoDataset.id, name: extractionInfoDataset.name} 
                }
                yield put(fastWaveConfigSet(updatedConfig, true))
                yield put(setBoundaryCondition(extractionArchiveDataset))              
              }
            } 
          } 
        }          
        break;  
    }    
  }
}