/** @jsxImportSource @emotion/react */
import { useRef } from 'react';
import { Tooltip, TooltipProps, Fade } from '@mui/material';
import { v4 as uuid } from 'uuid';
import { SPACING } from '../../styles/mikeSharedTheme';
import { css } from '@emotion/react'


export interface IMikeTooltipProps extends TooltipProps {
  /**
   * How to position the tooltip in relation to its target/anchor.
   * Structure is `{ top?: number, left?: number }`.
   */
  mikeposition?: IMikeTooltipPosition;
  /**
   * After what duration (ms) of hovering do you want to hide the tooltip.
   * Default is false. If true, duration is 1500ms.
   */
  mikeHoverHideDelay?: number | boolean;
  /**
   * Whether to hide the tooltip when you click its target/anchor.
   * Default is true.
   */
  mikeHideOnClick?: boolean;
}
export interface IMikeTooltipPosition {
  /**
   * How many pixels from the top to displace the tooltip.
   * Negative values are possible.
   */
  top?: number;
  /**
   * How many pixels from the left to displace the tooltip.
   * Negative values are possible.
   */
  left?: number;
}

const popperStyle = (props: IMikeTooltipProps) => {
  const top =
  props.mikeposition && typeof props.mikeposition.top !== 'undefined'
    ? props.mikeposition.top
    : 0;
  return css`
    margin-top: ${top}px
`;
}

const popperArrowStyle = (props: IMikeTooltipProps) => {
  const left =
      props.mikeposition && typeof props.mikeposition.left !== 'undefined'
        ? props.mikeposition.left
        : undefined;
  return css`
    &[x-placement*="bottom"] span[class*="MuiTooltip-arrow"]: {
      &::before: {
        margin-left: ${left}px;
      };
      &::after: {
        margin-left: ${left} ? ${left} + 1 + px  : undefined;
      };
    };
`;
}

// So far not successful mimicking the grow/shrink animation used by MUI:
const hideStyle = css`
  /* transition: visibility 0s, opacity 0.2s linear; */
  /* opacity: 0; */
  visibility: hidden;
`;
// Hiding overflow on the MUI tooltip container makes the arrows go away, which is why this is required:
const titleStyle = css`
  overflow: hidden;
  max-height: ${4.5 * SPACING}px;
`;

/**
 * @name MikeTooltip
 * @summary Tooltips display informative text when users hover over, focus on, or tap an element.
 *
 * MikeTooltip fixes some problems with MUI Tooltip:
 *
 * 1. Adjust position with prop mikeposition members `top` and `left`.
 * 2. Avoid forwardRef errors by wrapping a span.
 * 3. Provide options to hide-on-click and hide-on-delay-during-hover;
 *    control those through new props.
 *
 * NB: The span wrapper causes issues from time to time with layout
 * and with positioning of the tooltip.
 * You will have to make various fixes e.g. in your styling or by
 * wrapping an element around the tooltip. You can target the span
 * by its CSS class "MikeTooltip--span".
 *
 * NB: Only for short text; don't use more than 2 lines
 * of text, it will be cut off.
 */
export const MikeTooltip = (props: IMikeTooltipProps) => {
  const {
    children,   
    mikeHoverHideDelay = false,
    mikeHideOnClick = true,
    title = "",
    ...rest
  } = props;
 
  let theDelay;
  if (mikeHoverHideDelay) {
    theDelay = typeof mikeHoverHideDelay === 'number' ? mikeHoverHideDelay : 1500;
  }

  const idRef = useRef(props.id || uuid());
  // Hide the tooltip (remove causes crash):
  const hideTooltip = () => {
    const el = document.getElementById(idRef.current);
    if (el){
      el.className += ` ${hideStyle}`
    }    
  };
  // If mikeHoverHideDelay is truthy, hide tooltip a while after opening it,
  // - but don't if it's controlled by the prop 'open':
  const onOpen = () => {
    if (typeof props.open === 'boolean' || !theDelay) {
      return;
    }
    setTimeout(hideTooltip, theDelay);
  };
  // Hide on click - if mikeHideOnClick is truthy:
  const onClick = () => {
    if (mikeHideOnClick){
      hideTooltip();
    }
  };
  const theTitle = title ? <div css={titleStyle}>{title}</div> : '';
  return (
    <Tooltip
      classes={{popper: popperStyle(props) + "", popperArrow: popperArrowStyle(props) + ""}}
      TransitionComponent={Fade}
      TransitionProps={{ timeout: 1 }}    
      id={idRef.current}
      onOpen={onOpen}
      title={theTitle}
      {...rest}
    >
      <span onMouseDown={onClick} className="MikeTooltip--span">
        {children}
      </span>
    </Tooltip>
  );
};

export default MikeTooltip;
