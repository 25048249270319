/* eslint-disable @typescript-eslint/no-explicit-any */
import { polygon } from '@turf/helpers';
import bbox from '@turf/bbox';
import center from '@turf/center';
import vtkPointPicker from '@kitware/vtk.js/Rendering/Core/PointPicker';
import { resetInteractor } from './vtk-extensions/vtkBasicFullScreenRenderWindow';
import InteractorStyleManipulator from '@kitware/vtk.js/Interaction/Style/InteractorStyleManipulator';
import MikeVisualizerStore from './store/MikeVisualizerStore';
import MikeVisualizerUtil from './MikeVisualizerUtil';
import { IViewerBounds, VIEWER_MODES } from './IMikeVisualizerModels';
import { reduceBounds } from './MikeVisualizerUtils';
import * as vtkMath from '@kitware/vtk.js/Common/Core/Math';

import { mat4, vec3 } from 'gl-matrix';

const { getState, setState } = MikeVisualizerStore;
const { rendererReady } = MikeVisualizerUtil;

/**
 * Provides methods to manage view types, i.e. switch between 2D/3D or manipulate cameras.
 *
 * @module MikeVisualizerManager
 * @version 1.0.0
 */

/**
 * Gets the 3D viewer's bounds (current corners of the canvas).
 * The canvas origin (0, 0) is bottom left.
 *
 * This method is typically used to sync between 2D & 3D views.
 *
 * @private
 */
const _getViewerBbox = () => {
  const viewBounds = self.getCurrentViewBounds();
  const viewerPolygon = polygon([[...viewBounds, viewBounds[0]]]);
  const viewerBbox = bbox(viewerPolygon);
  return {
    viewerPolygon,
    viewerBbox,
    viewerCenter: center(viewerPolygon).geometry.coordinates,
  };
};

/**
 * Gets the current visualization bounds.
 * These do not necessarily match initial bounds, which are set implicitly to the viewer (usually the inital bounds are calculated by the back-end).
 * This is the computed value of the renderer for the current visualization.
 * The bounds are constant and only depend on visualized items; they are not affected by pan or zoom.
 *
 * @public
 */
const getVisualisationBounds = (): boolean | IViewerBounds => {
  if (!rendererReady()) {
    return false;
  }
  const { renderer } = getState();
  return renderer.computeVisiblePropBounds();
};

/**
 * Gets the current view (camera) bounds.
 * Contrary to `getVisualizationBounds`, these bounds are dependent on the current position & zoom of the camera and are not constant even if the visualized items stay the same.
 * In essence, for any camera view, this method gets each corner coordinate and creates an array of bounds from them.
 *
 * @public
 */
const getCurrentViewBounds = (): Array<Array<number>> => {
  const { container: vtkContainer, renderWindow } = getState();
  const picker = vtkPointPicker.newInstance();
  const pixelRatio = window.devicePixelRatio || 1;
  const viewerCanvas = (vtkContainer as HTMLElement).querySelector('canvas');
  const { height, width } = (viewerCanvas as HTMLElement).getBoundingClientRect();
  const renderer = renderWindow.getRenderers()[0];

  picker.pick([0, 0, 0], renderer);
  const bottomLeft = picker.getPickPosition();
  picker.pick([0, height * pixelRatio, 0], renderer);
  const topLeft = picker.getPickPosition();
  picker.pick([width * pixelRatio, height * pixelRatio, 0], renderer);
  const topRight = picker.getPickPosition();
  picker.pick([width * pixelRatio, 0, 0], renderer);
  const bottomRight = picker.getPickPosition();
  picker.delete();
  return [bottomLeft, topLeft, topRight, bottomRight];
};

/**
 * Resets camera to initial bounds ('home' position).
 * If there are no initial bounds set, then it will reset the camera based on the displayed geometry.
 * Essentially, it will fit all geometry in the viewer.
 * Re-renders by default, so changes can be visualized.
 * Also updated 2D layers.
 *
 * @public
 */
const resetCameraToInitialBounds = () => {
  const { initialBounds } = getState();
  return self.resetCameraToBounds(initialBounds);
};

/**
 * Resets camera to current basemap bounds. Defaults to initial bounds
 * if `baseMapProperties.bbox` is not set.
 */
const resetCameraToBaseMapBounds = () => {
  const { initialBounds, baseMapProperties } = getState();
  let bounds: IViewerBounds | void = void 0;
  if (baseMapProperties && baseMapProperties.bbox) {
    const [xMin, yMin, xMax, yMax] = baseMapProperties.bbox as Array<number>;
    bounds = [xMin, xMax, yMin, yMax, 0, 0] as IViewerBounds;
  } else {
    bounds = initialBounds;
  }
  if (!bounds) {
    return console.warn(`No bounds in resetCameraToBaseMapBounds`);
  }
  const newBounds = reduceBounds({ bounds });
  return self.resetCameraToBounds(newBounds);
};

/**
 * Resets camera to given bounds.
 * @param bounds
 */
const resetCameraToBounds = (bounds: IViewerBounds | void) => {
  const { renderWindow } = getState();
  if (!bounds) {
    return self.resetCamera();
  }
  self._makeCamera(bounds as IViewerBounds);
  return renderWindow.render();
};

/**
 * Resets camera to fit all visible data.
 * Also updates 2D layers.
 *
 * @public
 */
const resetCamera = () => {
  const { renderWindow, renderer } = getState();
  renderer.resetCamera();
  // Seems redundant as `resetCameraClippingRange` is called within `resetCamera`:
  renderer.resetCameraClippingRange(); // Recompute clipping range to make sure all props are visible.
  self.resetOrientation(false);
  self.updateViewer2DLayers();
  return renderWindow.render();
};

/**
 * Resets orientation to default.
 * Re-renders by default, so changes can be visualized.
 * @public
 */
const resetOrientation = (reRender = true) => {
  const { renderWindow, renderer } = getState();
  // Inspired by https://public.kitware.com/pipermail/vtkusers/2010-May/059972.html
  const camera = renderer.getActiveCamera();
  // Move the camera position to align the vector focalPoint->position with the z axis while maintaning same distance
  const fp = camera.getFocalPoint();
  const dist = camera.getDistance();
  camera.setPosition(fp[0], fp[1], fp[2] + dist);
  // set the up-direction of the camera to default direction
  camera.setViewUp(0, 1, 0);
  if (reRender) {
    renderWindow.render();
  }
};

/**
 * Given a set of xyz bounds, updates the current camera position without creating a new instance.
 * Re-renders by default, so changes can be visualized.
 *
 * @param bounds
 *
 * @public
 */
const setActiveCameraBounds = (bounds: IViewerBounds) => {
  const { renderer, renderWindow } = getState();
  renderer.resetCamera(bounds);
  // Seems redundant as `resetCameraClippingRange` is called within `resetCamera`:
  renderer.resetCameraClippingRange(); // Recompute clipping range to make sure all props are visible.
  renderWindow.render();
};

/**
 * Makes a camera and moves it to xyz bounds.
 * Re-renders by default, so changes can be visualized.
 *
 * @param bounds
 *
 * @private
 */
const _makeCamera = (bounds: IViewerBounds) => {
  const { renderer, renderWindow } = getState();
  const camera = renderer.makeCamera();
  camera.setParallelProjection(true);
  renderer.setActiveCamera(camera);
  renderer.resetCamera(bounds);
  // Seems redundant as `resetCameraClippingRange` is called within `resetCamera`:
  renderer.resetCameraClippingRange(); // Recompute clipping range to make sure all props are visible.
  self.updateViewer2DLayers();
  renderWindow.render();
  return camera;
};

/**
 * Get active camera properties.
 */
const _getActiveCameraProperties = () => {
  const { renderer } = getState();
  const camera = renderer.getActiveCamera();
  return {
    position: camera.getPosition(),
    viewUp: camera.getViewUp(),
    focalPoint: camera.getFocalPoint(),
  };
};

/**
 * Sets the viewer mode to 2D, doing the following:
 * - dynamically imports the 2DBaseMaps module
 *  + the base map sets up using the provided url & epsg code.
 * - resets interactor to 2D mode
 *
 * @param url The x/y/z URL to request basemap tiles from.
 * @param attributions The attributions for the basemap
 *
 * @public
 */
const setViewModeTo2D = async (
  url: string,
  attributions: Array<string>
): Promise<string | boolean> => {
  // todo hevo attributions?
  if (!rendererReady()) {
    return Promise.resolve(false);
  }
  const { viewMode, epsgCode } = getState();
  if (viewMode === VIEWER_MODES.TWO_D) {
    return Promise.resolve(true);
  }
  // do not setup a basemap if no epsg code is provided
  if (!epsgCode) {
    setState({ viewMode: VIEWER_MODES.TWO_D });
    resetInteractor(VIEWER_MODES.TWO_D);
    return Promise.resolve(true);
  }
  // Load base map by default.
  try {
    await import('./2d/MikeVisualizer2DBaseMaps').then((module) =>
      module.default.setupOpenLayersBaseMap(url, attributions)
    );
    return Promise.resolve(`"setViewModeTo2D" done using basemap url=${url}`);
  } catch (e) {
    const msg = `Failed to create base map. ${String(e)}`;
    console.debug(msg);
    return Promise.reject(msg);
  } finally {
    self.resetCameraToBaseMapBounds();
    setState({ viewMode: VIEWER_MODES.TWO_D });
    resetInteractor(VIEWER_MODES.TWO_D);
  }
};

/**
 * Sets the viewer mode to 3D, doing the following:
 * - attempts to destroy 2D maps if instantiated. This will dynamically import the 2DBaseMaps module
 * - attempts to destroy 3D maps if instantiated. This will dynamically import the 2DDrawMaps module
 * - resets interactor to 3D mode
 *
 * @public
 */
const setViewModeTo3D = () => {
  if (!rendererReady()) {
    return Promise.resolve(false);
  }
  const { viewMode } = getState();
  if (viewMode === VIEWER_MODES.THREE_D) {
    return Promise.resolve(true);
  }
  return import('./2d/MikeVisualizer2DBaseMaps')
    .then((module) => {
      module.default.destroyOpenLayersBaseMap();
      return import('./2d/draw/MikeVisualizer2DDrawCore');
    })
    .then((module) => module.default._destroyOpenLayersDrawMap())
    .then(() => import('./2d/data/MikeVisualizer2DDataCore'))
    .then((module) => module.default._destroyOpenLayersDataMap())
    .then(() => {
      setState({ viewMode: VIEWER_MODES.THREE_D });
      resetInteractor(VIEWER_MODES.THREE_D);
      return Promise.resolve(true);
    });
};

/**
 * Updates the viewer base maps with a new URL.
 * This can be used to switch between vector / sat maps / etc.
 *
 * @param url The new x/y/z URL to request basemap tiles from.
 * @param attributions The attributions for the basemap
 *
 * @public
 */
const setViewerBaseMapUrl = (url: string, attributions?: Array<string>) => {
  return import('./2d/MikeVisualizer2DBaseMaps').then((module) => {
    module.default.updateOpenLayersBaseMap(url, attributions);
  });
};

/**
 * Turn the basemap off and on again by setting it to an empty value
 * and back again. This forces OpenLayers to re-render the basemap as
 * a fix for render errors, e.g. blank tiles.
 *
 * @public
 */
const turnBaseMapOffAndOn = () => {
  const { baseMap, baseMapUrl, baseMapAttributions: attr } = getState();
  if (!baseMap) {
    return Promise.resolve(false);
  }
  return import('./2d/MikeVisualizer2DBaseMaps').then((module) => {
    module.default.updateOpenLayersBaseMap('', []);
    const _attr = attr instanceof Array ? attr : undefined;
    module.default.updateOpenLayersBaseMap(String(baseMapUrl), _attr);
  });
};

/**
 * Requests an update of the 2D base maps.
 * This method can be used to make the base map update because of certain user interactions; i.e. position changes or camera reset.
 *
 * @public
 */
const updateViewer2DLayers = async () => {
  const { baseMap, dataMap, drawMap, measureMap } = getState();
  if (baseMap) {
    const baseMapModule = await import('./2d/MikeVisualizer2DBaseMaps');
    baseMapModule.default.forceOpenLayersBaseMapUpdate();
  }
  if (dataMap) {
    const dataMapModule = await import('./2d/data/MikeVisualizer2DDataCore');
    dataMapModule.default.forceOpenLayersDataMapUpdate();
  }
  if (drawMap) {
    const drawMapModule = await import('./2d/draw/MikeVisualizer2DDrawCore');
    drawMapModule.default.forceOpenLayersDrawMapUpdate();
  }
  if (measureMap) {
    const measureMapModule = await import('./2d/measure/MikeVisualizer2DMeasureCore');
    measureMapModule.default.forceOpenLayersMeasureMapUpdate();
  }
};

/**
 * Zoom by factor, where a factor > 1 zooms in and < 1 zooms out.
 * In this case, zoom refers to 'dolly' which literally moves the camera and not magnification of the camera.
 *
 * For example, a factor of 1.1 would zoom in 10%.
 *
 * @param factor
 */
const zoom = (factor: number) => {
  const { renderer, renderWindow } = getState();
  (InteractorStyleManipulator as any).dollyByFactor(renderWindow.getInteractor(), renderer, factor);
  self.updateViewer2DLayers();
  renderWindow.render();
};

/**
 * Zoom in by 10%.
 */
const zoomIn = () => {
  zoom(1.1);
};

/**
 * Zoom out by 10%.
 */
const zoomOut = () => {
  zoom(0.9);
};

/**
 * PanHorizontally 
 *  @param angle,  where a angle > 0 pans  to the right and < 0 pans to the left
 *
 */
 const panHorizontally = (angle: number) => {
  const { renderer, renderWindow } = getState(); 
  const camera = renderer.getActiveCamera();
  camera.yaw(angle)
  renderWindow.render();
 }

/**
 * panVertically 
 *  @param angle,  where a angle > 0 pans  to the top and < 0 pans to the bottom
 *
 */
const panVertically = (angle: number) => {
  const { renderer, renderWindow } = getState(); 
  const camera = renderer.getActiveCamera();
  camera.pitch(angle)
  renderWindow.render();
}

/**
 * rotate 
 *  @param angle,  where a angle > 0 rotates  to the right and < 0 rotates to the left
 *
 */
const rotateCamera = (angle: number) => {
  const { renderer, renderWindow } = getState(); 
  const camera = renderer.getActiveCamera();
  camera.roll(angle)
  renderWindow.render();
}

/**
 * tiltVertically 
 *  @param angle,  where a angle > 0 tilts  to the top and < 0 tilts to the bottom
 *
 */
const tiltVertically = (angle: number) => {
  const { renderer, renderWindow } = getState(); 
  const camera = renderer.getActiveCamera();
 
  const focalPoint = camera.getFocalPoint();
  const position = camera.getPosition();
  const trans = mat4.identity(new Float64Array(16) as any);
  const newPosition = new Float64Array(3);
  const newFocalPoint = new Float64Array(3);  

  const vt = camera.getViewMatrix();
  const axis = [vt[0], vt[1], vt[2]];
  mat4.identity(trans);

  // translate the camera to the focal point, 
  mat4.translate(trans, trans, focalPoint);
  // Rotates a mat4 by the given angle around the given axis
  mat4.rotate(trans, trans, vtkMath.radiansFromDegrees(angle), axis as any);
  // translate back again
  mat4.translate(trans, trans, [-focalPoint[0], -focalPoint[1], -focalPoint[2]]);

  // apply the transform to the position
  vec3.transformMat4(newFocalPoint as any, focalPoint, trans);
  camera.setFocalPoint(...newFocalPoint as any);

  // apply the transform to the focal point
  vec3.transformMat4(newPosition as any, position, trans);
  camera.setPosition(...newPosition as any);

  camera.orthogonalizeViewUp();
  renderer.resetCameraClippingRange(); 
  renderer.resetCamera(); 
  renderWindow.render();   
}

/**
 * tiltHorizontally 
 *  @param angle,  where a angle > 0 tilts  to the right and < 0 tilts to the left
 *
 */
 const tiltHorizontally= (angle: number) => {
  const { renderer, renderWindow } = getState(); 
  const camera = renderer.getActiveCamera();
 
  const focalPoint = camera.getFocalPoint();
  const position = camera.getPosition();
  const viewUp = camera.getViewUp();
  const trans = mat4.identity(new Float64Array(16) as any);
  const newPosition = new Float64Array(3);
  const newFocalPoint = new Float64Array(3);  
  mat4.identity(trans);
 
  // translate the camera to the focal point,
  mat4.translate(trans, trans, focalPoint);
  // Rotates a mat4 by the given angle around the given axis
  mat4.rotate(trans, trans, vtkMath.radiansFromDegrees(angle), viewUp);
   // translate back again  
  mat4.translate(trans, trans, [-focalPoint[0], -focalPoint[1], -focalPoint[2]]);

  // apply the transform to the focal point
  vec3.transformMat4(newFocalPoint as any, focalPoint, trans);
  camera.setFocalPoint(...newFocalPoint as any);

  // apply the transform to the position
  vec3.transformMat4(newPosition as any, position, trans);
  camera.setPosition(...newPosition as any);

  camera.orthogonalizeViewUp();
  renderer.resetCameraClippingRange(); 
  renderer.resetCamera(); 
  renderWindow.render();   
}

/**
 * Focus camera to a point.
 * By default, it will move the camera so that point is in the center of the current view.
 *
 * @param x
 * @param y
 */
const focusTo2DPoint = (x, y) => {
  const { renderWindow, renderer, viewMode } = getState();
  if (viewMode === VIEWER_MODES.THREE_D) {
    console.info('Setting focus to a 2D point cannot be used in 3D mode.');

    return false;
  }
  // Change the focal point of the active camera; this will mess up interactions.
  const activeCamera = renderer.getActiveCamera();
  const [currentFocalX, currentFocalY, currentFocalZ] = activeCamera.getFocalPoint();
  const [currentPostionX, currentPostionY, currentPostionZ] = activeCamera.getPosition();
  const dX = currentFocalX - x;
  const dY = currentFocalY - y;
  // translate focal point and camera position the same distance in x,y plane to keep current zoom level
  activeCamera.setFocalPoint(x, y, currentFocalZ);
  activeCamera.setPosition(currentPostionX - dX, currentPostionY - dY, currentPostionZ);
  self.updateViewer2DLayers();
  renderWindow.render();
};

const self = {
  _getViewerBbox,
  _makeCamera,
  _getActiveCameraProperties,

  getVisualisationBounds,
  getCurrentViewBounds,
  resetCameraToBounds,
  resetCameraToInitialBounds,
  resetCameraToBaseMapBounds,
  resetCamera,
  resetOrientation,
  setActiveCameraBounds,
  setViewModeTo2D,
  setViewModeTo3D,
  setViewerBaseMapUrl,
  turnBaseMapOffAndOn,
  updateViewer2DLayers,
  zoom,
  zoomIn,
  zoomOut,
  focusTo2DPoint,
  panVertically,
  panHorizontally,
  rotateCamera,
  tiltVertically,
  tiltHorizontally
};

export default self;
