/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import theme from './../../styles/mikeSharedTheme';
import React from 'react'
import Tooltip from '@mui/material/Tooltip'

const rootStyle = css`
  display: flex;
  align-items: center;
`;

const iconStyle = css`
  padding-top: ${theme.spacing(0.5)};
  margin-right: ${theme.spacing(1)};
`;

const longTextStyle = css`
  max-width: 25vw;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: table-cell;
  padding-right: ${theme.spacing(1)};
`;

interface IProps {
  text: string
  icon?: React.ReactNode
}

const IconAndLongText = (props: IProps) => {
  const { text, icon } = props 
  return (
    <div css={rootStyle}>
      {icon && (
        <div css={iconStyle}>
          {icon}
        </div>
      )}
      <Tooltip title={text}>
        <div css={longTextStyle}>{text}</div>
      </Tooltip>
    </div>
  )
}

export default React.memo(IconAndLongText)