import uniqid from 'uniqid'
import isObject from 'lodash-es/isObject'
import { isApiError } from '../apis/support'

export interface IError {
  name: string;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const parseError = (error: IError | string, custom: any = {}) => {
  return {
    id: custom.id || uniqid.time(),
    name: custom.name || (isObject(error) ? error?.name : ''),
    message: isApiError(error) ? "Server error" : error,
    isApiError: isApiError(error),
    source: error,
    timestamp: Date.now()
  }
} 
