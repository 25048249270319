/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { ReactNode } from 'react';
import { Grow, ThemeProvider, Typography } from '@mui/material';
import Logo from '../../../icons/LandingPage.svg?react';
import MeshGenerator from '../../../icons/MeshGenerator.svg?react';
import { useIntl } from 'react-intl'
import './landingpage.css';
import mikeSharedTheme from '../../../styles/mikeSharedTheme';
import splash from './splashscreen.jpg';

const containerStyle = 
  css`
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 16px;
  `;

const wrapperStyle = 
  css`
    padding: 50px 40px 25px 40px;
  `;

const headerStyle = 
  css`
    margin-bottom: 0px;
  `;


type Props = {
  children?: ReactNode;
};

const LandingLayout = ({ children }: Props) => {
  const intl = useIntl(); 

  const background = {
    backgroundImage: `url(${splash})`,
  };

  return (
    <ThemeProvider theme={mikeSharedTheme}>      
      <div className="LandingPage" style={background}>
        <div className="LandingPage-logo">
          <Logo />
        </div>

        <div className="LandingPage-content">
          <Grow in={true}>
            <div css={wrapperStyle}>             
              <div css={containerStyle}>
                <MeshGenerator />
                <Typography color="primary" gutterBottom={true} variant="h1" css={headerStyle}>
                  {intl.formatMessage({id: 'app.newRegisteredName'})}
                </Typography>
              </div>
              {children}             
            </div>
          </Grow>
        </div>
      </div>
    </ThemeProvider>
  );
};

export default LandingLayout;
