/** @jsxImportSource @emotion/react */
import RemoveIcon from '@mui/icons-material/Remove';
import Tooltip from '@mui/material/Tooltip';
import { ViewerToolsButtonStyle } from '../ViewerStyles';
import MikeVisualizer from '../../../MikeVisualizer/lib/MikeVisualizer';

const { zoomOut } = MikeVisualizer;

/**
 * @name ViewerToolZoomOut
 * @summary Allows zooming out.
 */
export const ViewerToolZoomOut = () => {
  return (
    <Tooltip title={'Zoom out'} placement="right">
      <button css={ViewerToolsButtonStyle} onClick={zoomOut}>
        <RemoveIcon />
      </button>
    </Tooltip>
  );
};

export default ViewerToolZoomOut