import vtkPolyLineWidget from '@kitware/vtk.js/Widgets/Widgets3D/PolyLineWidget';

import MikeVisualizerStore from './store/MikeVisualizerStore';
import MikeVisualizerUtil from './MikeVisualizerUtil';
import MikeVisualizerViewManager from './MikeVisualizerViewManager';

const { resetCameraToInitialBounds } = MikeVisualizerViewManager;
const { getState } = MikeVisualizerStore;
const { rendererReady } = MikeVisualizerUtil;

/**
 * Enables / disables drawing tools.
 *
 * @module MikeVisualizerDraw
 * @version 1.0.0
 */

/**
 * Enables a widget to draw polygons.
 *
 * @public
 */
const enablePolygonDrawing = () => {
  if (!rendererReady()) {
    return false;
  }

  const { initialBounds, widgetManager } = getState(); 

  if (!initialBounds) {
    console.info('Attempted to enable polygon drawing without bounds');
    // TODO: fails if not initial bounds. How to get current bounds in a generic way? Mapper?
    return false;
  }

  // Start by replacing camera. This is needed to force drawing on z=0.
  resetCameraToInitialBounds();

  const widget = vtkPolyLineWidget.newInstance();
  widget.placeWidget(initialBounds);

  const widgetInstance = widgetManager.addWidget(widget);

  // Grab vis focus; effectively, this means each mouse event draws instead of moving the camera.
  widgetManager.grabFocus(widget);

  if (widgetInstance){
    widgetInstance.setActiveScaleFactor(0.1);
    widgetInstance.setGlyphResolution(1);
    widgetInstance.setClosePolyLine(true);

    widgetInstance.onWidgetChange((/* newWidget */) => {
      // TODO: should be some sort of callback with the widget geometries (or bounds). Either in here or in 'disablePolygonDrawing'.
    });
  }


  return true;
};

/**
 * Disables polygon drawing widget(s).
 *
 * @public
 */
const disablePolygonDrawing = () => {
  const { widgetManager } = getState();

  // Release focus so camera can be moved again.
  widgetManager.releaseFocus();

  const activeDrawingWidgets = widgetManager
    .getWidgets()
    .filter((widget) => widget.isA('vtkPolyLineWidgetProp'));
  activeDrawingWidgets.forEach((widget) => widgetManager.removeWidget(widget));
};

const self = {
  enablePolygonDrawing,
  disablePolygonDrawing,
};
export default self;
