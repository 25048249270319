/* eslint-disable @typescript-eslint/no-explicit-any */
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React, { useCallback } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Checkbox from '@mui/material/Checkbox';
import TableContainer from '@mui/material/TableContainer';
import IconButton from '@mui/material/IconButton';
import EnhancedTableHead from './EnhancedTableHead';
import TableBodySkeleton from './TableBodySkeleton';
import Actions from './Actions';
import { IColumn, getRender, getPadding } from './support';
import Paper from '@mui/material/Paper';
import mikeSharedTheme from '../../styles/mikeSharedTheme';

const tableStyle = css`
  min-width: 300;
  background-color: ${mikeSharedTheme.palette.background.paper};
`;

const actionButtonStyle = css`
  width: 40px;
  padding-right: ${mikeSharedTheme.spacing(4)};
`;


export interface IProps {
  actions?: [];
  loading: boolean;
  columns: Array<IColumn>;
  idField: string;
  rowsPerPage?: number;
  data: Array<any>;
  firstTableCellRender?: (
    item: any,
    isSelectable: boolean,
    isSelected: boolean,
    onClick: (event: any, item: any) => void
  ) => React.ReactNode;
  page?: number;
  totalCount?: number;
  notSortableColumns?: [];
  topOffset?: number;
  onChangePage?: (page: number) => void;
  onChangeRowsPerPage?: (rowsPerPage: number) => void;
  onCellClick: (item: any) => void;
  onHandleRequestSort?: (
    orderBy: string | ((item: any) => string | number),
    order: 'asc' | 'desc'
  ) => void;
  onSelectionChange: (selectedItems: Array<any>) => void;
  order: 'asc' | 'desc';
  orderBy: string | ((item: any) => string | number);
  rowsPerPageOptions?: Array<number> | Array<{ value: number; label: string }>;
  selectedRows: Array<any>;
  pagination?: boolean;
  selectable: boolean;
  skeletonRowCount?: number;
}

const ScrollableTable = (props: IProps) => { 
  const {
    actions,
    loading,
    columns,
    idField,
    firstTableCellRender,
    rowsPerPage = 10,
    data = [],
    page = 0,
    totalCount,
    notSortableColumns,
    topOffset = 0,
    onChangePage,
    onChangeRowsPerPage,
    onCellClick,
    onHandleRequestSort,
    onSelectionChange,
    order,
    orderBy,
    rowsPerPageOptions = [10, 20, 25],
    selectedRows = [],
    pagination = false,
    selectable,
    skeletonRowCount = 10,
  } = props;

  const _handleRequestSort = useCallback(
    (sortBy: string | ((item: any) => string | number), sortDir: 'asc' | 'desc') => {
      if (onHandleRequestSort) {
        onHandleRequestSort(sortBy, sortDir);
      }
    },
    [onHandleRequestSort]
  );

  const _handleChangePage = useCallback(
    (_event, thePage) => {
      if (onChangePage) {
        onChangePage(thePage);
      }
    },
    [onChangePage]
  );

  const _handleChangeRowsPerPage = useCallback(
    (event) => {
      if (onChangeRowsPerPage) {
        onChangeRowsPerPage(event.target.value);
      }
    },
    [onChangeRowsPerPage]
  );

  const _handleCellClick = useCallback(
    (event, item) => {
      event.stopPropagation();
      onCellClick(item);
    },
    [onCellClick]
  );

  const _handleActionClick = (event: any) => {
    event.stopPropagation();
  };

  const _handleSelect = useCallback(
    (event, item) => {
      event.stopPropagation();
      const selectedIndex = selectedRows.findIndex(
        (selectedRow) => selectedRow[idField] === item[idField]
      );
      let newSelected = Array<any>();
      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selectedRows, item);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selectedRows.slice(1));
      } else if (selectedIndex === selectedRows.length - 1) {
        newSelected = newSelected.concat(selectedRows.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          selectedRows.slice(0, selectedIndex),
          selectedRows.slice(selectedIndex + 1)
        );
      }
      onSelectionChange(newSelected);
    },
    [onSelectionChange, selectedRows, idField]
  );

  const _isSelected = (item: any) =>
    selectedRows.filter((selectedRow) => selectedRow[idField] === item[idField]).length > 0;

  return (
    <TableContainer component={Paper}>
      <Table css={tableStyle}>
        <EnhancedTableHead
          actions={actions}
          topOffset={topOffset}
          columns={columns}
          notSortableColumns={notSortableColumns}
          onRequestSort={_handleRequestSort}
          sortDirection={order}
          sortBy={orderBy}
        />

        {loading ? (
          <TableBodySkeleton
            skeletonColumnCount={actions ? columns.length + 1 : columns.length}
            skeletonRowCount={skeletonRowCount}
          />
        ) : (
          <TableBody>
            {data.map((item: any) => {
              const rowKey = item[idField];
              const isSelected = _isSelected(item);

              return (
                <TableRow
                  hover
                  role="checkbox"
                  aria-checked={isSelected}
                  tabIndex={-1}
                  key={rowKey}
                  selected={isSelected}
                >
                  {firstTableCellRender ? (
                    firstTableCellRender(item, selectable, isSelected, _handleSelect)
                  ) : selectable ? (
                    <TableCell padding="checkbox" onClick={(event) => _handleSelect(event, item)}>
                      <Checkbox checked={isSelected} />
                    </TableCell>
                  ) : (
                    null //<TableCell padding="checkbox" />
                  )}

                  {columns.map((col) => {
                    const value = item[col.field];
                    const render = getRender(col);

                    return (
                      <TableCell
                        css={col.className ? col.className(item) : ''}
                        key={col.field}
                        padding={getPadding(col)}
                        align={col.numeric ? 'right' : 'left'}
                        onClick={(event) => _handleCellClick(event, item)}
                      >
                        {render(value, item, data)}
                      </TableCell>
                    );
                  })}
                  {actions ? (
                    <TableCell
                      align="left"
                      css={actionButtonStyle}
                      onClick={(event) => _handleActionClick(event)}
                    >
                      <IconButton>
                        <Actions actions={actions} row={item} />
                      </IconButton>
                    </TableCell>
                  ) : null}
                </TableRow>
              );
            })}
          </TableBody>
        )}
      </Table>
      {pagination && totalCount && (
        <TablePagination
          component="div"
          count={totalCount}
          rowsPerPage={rowsPerPage}
          page={page}
          rowsPerPageOptions={rowsPerPageOptions}
          onPageChange={_handleChangePage}
          onRowsPerPageChange={_handleChangeRowsPerPage}
        />
      )}
    </TableContainer>
  );
};

export default ScrollableTable;
