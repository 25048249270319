/* eslint-disable @typescript-eslint/no-explicit-any */
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import {  pointUpdate,  renamePoints,  replacePoints,  setExistingPointNames,  uploadPoints, uploadPointsUrlSet } from '../../actions/mapContent' 
import { IState } from '../../reducers';
import { IGetProject } from '../../model/IGetProject'
import EditPointsForm from '../EditPointsForm';
import { IValue } from '../EditPointsForm/EditPointsForm';
import UploadButton from '../UploadButton/UploadButton';
import { titleContainer, titleStyle, subTitleStyle } from '../../shared/styles';
import { useIntl } from 'react-intl';
import MikeDialog, { contentTitleStyle, dialogActionsStyle, dialogContentStyle } from '../DialogComponents/MikeDialog';
import MuiDialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import MikeButton from '../mike-button/MikeButton';
import ContextHelp from '../ContextHelp';
import { EGeometryItemTypes, KeyboardEditHelp } from '../Viewer/keyboard-edit-help';
import mikeSharedTheme from '../../styles/mikeSharedTheme';

const drawActionStyle = 
  css`
    display: flex;
    align-items: center;
`  

const geometryButtonsContainerStyle = css`
  display: flex;
  align-items: center;
  justify-content: flex-end;     
`
const spinnerRowStyle = css`
  display: flex;
  align-items: center;
  justify-content: center;
`
const uploadingPointsLabelStyle = css`
  color: ${mikeSharedTheme.palette.secondary.main};
  padding-left: ${mikeSharedTheme.spacing(1)};
`

const Points = () => {  
  const dispatch = useDispatch();
  const intl = useIntl();

  const loadingPoints: boolean = useSelector((state: IState) => state.mapContent.loadingConfig)  

  const project: IGetProject | null = useSelector(
    (state: IState) => state.projectContent.project
  );

  const { points, existingPointNames, uploadingPoints }  = useSelector(
    (state: IState) => state.mapContent
  );  

  const { uploadesPointNameLabel, uploadesPointNameHint, showDuplicatePointNamesDialog } = useMemo(() => {
    let names = existingPointNames.join()
    if (existingPointNames.length > 3){
      const firstThreeNames = existingPointNames.filter((_name: string, index: number) => index < 3)
      names = firstThreeNames.join() + ",..."
    }
    return { showDuplicatePointNamesDialog: existingPointNames && existingPointNames.length > 0 ? true : false, uploadesPointNameLabel: names, uploadesPointNameHint: existingPointNames.length === 1 ?  intl.formatMessage({id: 'points.oneDuplicatedPointName'}) : intl.formatMessage({id: 'points.manyDuplicatedPointNames'})}
  }, [existingPointNames, intl])

  const [features, setFeatures ] = useState([])

  useEffect(() => {
      setFeatures(points) 
   }, [points]); 

  const onDropPoints = useCallback(
    (files) => {
      if (files.length > 0){
        dispatch(uploadPointsUrlSet(""))
        const file = files[0]   
        dispatch(uploadPoints(file, file.name, project.id)) 
      }
    },
    [dispatch, project])

  const canCreateContent = project && project.capabilities && project.capabilities.canCreateContent

  const onSubmit = (values: IValue, resetElevationIndex = -1) => {
    let vals
    if (resetElevationIndex > -1){
      const editedFeature = values.features[resetElevationIndex]
      const coords = editedFeature.geometry.coordinates
      const editedFeatureWithoutElevation = {...editedFeature, geometry: {...editedFeature.geometry, coordinates: [coords[0], coords[1]]}}
      vals = {...values, features: values.features.map((feature, i) => i === resetElevationIndex ? editedFeatureWithoutElevation : feature)} 
    }
    else{
      vals = values  
    }
    const fixFeatures = vals.features.map((f: any) => {return { ...f, geometry: {type: "Point",
      coordinates: [...f.geometry.coordinates.map((c: string) => parseFloat(c))] } }})
      dispatch(pointUpdate(fixFeatures))
  }

  const handleOnCancel = () => {
    dispatch(setExistingPointNames([],[]))
  }

  const handleRenamePoints = () => {
    dispatch(renamePoints())
  }

  const handleReplacePoints = () => {
    dispatch(replacePoints())
  }

  return (  
    <div>
      <MikeDialog 
        open={showDuplicatePointNamesDialog} 
        onCancel={handleOnCancel} 
        dialogTitle={intl.formatMessage({id: 'warnings.pleaseConfirm'})}
        dialogActions={
          <DialogActions css={dialogActionsStyle}>       
          <MikeButton size="small" onClick={handleOnCancel} variant='text'>
            {intl.formatMessage({id: 'points.cancel'})}
          </MikeButton>
          <MikeButton size="small" onClick={handleRenamePoints} variant='outlined'>
            {intl.formatMessage({id: 'points.rename'})}
          </MikeButton>
          <MikeButton size="small" onClick={handleReplacePoints} variant='contained'>
            {intl.formatMessage({id: 'points.replace'})}
          </MikeButton>
        </DialogActions>
        }
      >
        <MuiDialogContent
          css={dialogContentStyle}
        >
          <Typography
            variant="h3"
            css={contentTitleStyle}
          >
            {uploadesPointNameLabel}
          </Typography>
          <Typography variant="body2">{uploadesPointNameHint}</Typography>
        </MuiDialogContent>

      </MikeDialog>
      <div css={titleContainer}>
        <div>
          <Typography variant="h5" css={titleStyle}>{intl.formatMessage({id: 'points.outputPoints'})}</Typography>
          <div css={drawActionStyle}>
            <Typography variant="body2" css={subTitleStyle}>{intl.formatMessage({id: 'points.clickOnMapToPlaceAPoint'})}</Typography>
            <KeyboardEditHelp primary geometryType={EGeometryItemTypes.POINT}/>
          </div>
        </div>         
        <div css={geometryButtonsContainerStyle}>
          {uploadingPoints ? <div css={spinnerRowStyle}><CircularProgress size={20}/><div css={uploadingPointsLabelStyle}>{intl.formatMessage({id: 'points.uploading'})}</div></div> :
          <UploadButton disabled={!canCreateContent} fileType={['.csv', '.txt', '.xy', '.xyz']} label={intl.formatMessage({id: 'points.uploadXYZ'})} onDrop={onDropPoints}/> }
          <ContextHelp helpTexts={[
            intl.formatMessage({id: 'points.infoUpload'}),
            "-------------------------------------------------------------------------------------",
            intl.formatMessage({id: 'points.infoUpload4'}),
            intl.formatMessage({id: 'points.infoUpload5'}),
            "-------------------------------------------------------------------------------------",          
            intl.formatMessage({id: 'points.infoUpload2'}),
            intl.formatMessage({id: 'points.infoUpload1'}),
           
          ]}/>
        </div>
      </div>
      
      
      <div>
        {loadingPoints ? <CircularProgress/> :         
          <EditPointsForm
            initialValues={{newName: "", newLong: "", newLat: "", features: features}}
            onSubmit={onSubmit}        
            />
          } 
        </div> 
    </div>
  )
}

export default Points