import { assign } from 'lodash-es';
import { IMikeVisualizerState } from './IMikeVisualizerStore';
import { VIEWER_MODES } from '../IMikeVisualizerModels';
import { getConfiguration } from '../MikeVisualizerConfiguration';
import { DeepPartial } from '../../utils/ts-utils';
import { FeatureCollection } from 'geojson';

/**
 * Global store that holds the viewer state.
 * The central means of communication between viewer components (glue).
 *
 * The store cannot be modified directly, properties can only be changed via `setState`.
 * @note could be a good idea to move the store to redux.
 *
 * @module MikeVisualizerStore
 * @version 1.0.0
 */

const DEBUG_MODE = localStorage.getItem('DEBUG_ON');

// Make all properties from IMikeVisualizerState optional:
export type SetStateArg = DeepPartial<IMikeVisualizerState>;

/**
 * Basic store factory wrapper that holds all MikeVisualizer state.
 * This can be later replaced with other types of stores if necessary.
 *
 * @param initialState
 */
const store = (initialState: IMikeVisualizerState) => {
  let state = { ...initialState };

  return {
    getState: (): IMikeVisualizerState => {
      return state;
    },
    setState: (partialState: SetStateArg) => {
      state = assign(state, partialState);
      if (DEBUG_MODE) {
        logState(state, partialState);
      }
    },
    resetState: () => {
      state = { ...initialState };
    },
  };
};

const logState = (state, prevState) => {
  console.groupCollapsed('MikeVisualizer view state changed');
  console.count('State changed');
  console.groupCollapsed('State');
  console.info(Object.keys(state).map((k) => ({ key: k, value: state[k] })));
  console.groupEnd();
  console.groupCollapsed('Partial state (change to be merged)');
  console.info(prevState);
  console.groupEnd();
  console.groupEnd();
};

// Initial geojson state.
const initialGeojson: FeatureCollection<any, any> = {
  features: [],
  type: 'FeatureCollection',
};

// Properties are documented in the interface definition.
const viewerInitialstate: IMikeVisualizerState = {
  container: undefined,
  renderer: null,
  renderWindow: null,
  fullScreenRenderWindow: null,

  epsgCode: getConfiguration().epsgCode,
  crs: undefined,
  initialBounds: undefined,
  viewMode: VIEWER_MODES.THREE_D,
  viewerZScale: 1,

  hiddenElementIds: [],
  highlightedElementId: undefined,
  renderedElements: [],
  selectedElementIds: [],

  hidden2DElementIds: [],
  rendered2DElements: [],

  workingItems: [],

  widgetManager: null,

  // maps & their properties
  baseMap: null,
  baseMapPromise: undefined,
  baseMapProperties: {
    bbox: undefined,
    center: undefined,
    width: 0,
    height: 0,
  },
  baseMapUrl: undefined,
  baseMapAttributions: [],

  dataMap: null,
  dataMapPromise: undefined,
  dataMapProperties: {
    bbox: undefined,
    center: undefined,
    width: 0,
    height: 0,
  },
  dataMapUrl: null,

  measureMap: null,
  measureMapPromise: undefined,
  measureMapProperties: {
    bbox: undefined,
    center: undefined,
    width: 0,
    height: 0,
  },
  measureUnit: "metric",
  measureHelpTooltipElement: undefined,
  measureHelpTooltipOverlay: undefined,
  measureTooltipElement: undefined,
  measureTooltipOverlay: undefined,
  measureMapDocumentListeners: [],

  drawMap: null,
  drawMapProperties: {
    bbox: undefined,
    center: undefined,
    width: 0,
    height: 0,
  },
  drawMapUpdatesPaused: false,
  drawMapDocumentListeners: [],
  drawMapCurrentGeojson: { ...initialGeojson },
  drawMapInspectionSelectInteraction: undefined,
  drawnMapInspectionSelectedFeatureProperties: [],

  cellIndexActorIds: [],
  pointIndexActorIds: []
};

/**
 * This effectively means that the store is global and shared between all viewer instances.
 * If a different behaviour is needed, the state should probably be extended to allow multiple viewers at the same time.
 */
const GeometryStore = store(viewerInitialstate);
export default GeometryStore;
