import { useState } from 'react';
import DataTable from '../TableComponents/DataTable';
import { IGetDataset } from '../../model/IGetDataset';
import { projectContentColumns } from './meshColumns';

interface IProps { 
  data: Array<IGetDataset >;
  loading: boolean;
  selectedItems: Array<IGetDataset>;  
  onItemClick?: (item:  IGetDataset ) => void;
  onSelectionChange?: (selectedItems: Array<IGetDataset>) => void;
}

const MeshTable = (props: IProps) => {
  const { 
    data,   
    loading,
    selectedItems, 
    onItemClick,
    onSelectionChange
  } = props;

  const [order, setOrder] = useState<{order: 'asc' | 'desc', orderBy: string | ((item: IGetDataset) => string | number)}>({order: 'asc', orderBy: 'name'})

  const onHandleRequestSort =  (
    orderBy: string | ((item: IGetDataset) => string | number),
    order: 'asc' | 'desc'
  ) => {setOrder({order: order, orderBy: orderBy})}

  return (
    <DataTable
      loading={loading}
      columns={projectContentColumns}
      idField="id"    
      data={data}
      selectedRows={selectedItems}
      onCellClick={onItemClick ? onItemClick : () => {}}      
      onSelectionChange={onSelectionChange ? onSelectionChange : () => {}}
      onHandleRequestSort={onHandleRequestSort}
      orderBy={order.orderBy}
      order={order.order}   
      selectable={false}
      topOffset={0}    
    />
  );
};

export default MeshTable;
