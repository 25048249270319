/**
 * Extends vtk Widget manager to tailor it to our needs, i.e.:
 * - completely disable picking
 *
 * TODO: this module might not be needed after https://github.com/Kitware/vtk-js/releases/tag/v8.3.0 => Seems like picking has been fixed.
 *
 * @module vtkEnhancedWidgetManager
 * @version 1.0.0
 */
import macro from '@kitware/vtk.js/macro.js';
import vtkWidgetManager from '@kitware/vtk.js/Widgets/Core/WidgetManager';

// vtkEnhancedWidgetManager methods
function vtkEnhancedWidgetManager(publicAPI, model) {
  // Set our className
  model.classHierarchy.push('vtkEnhancedWidgetManager');

  // Disable picking. This seems to cause performance issues.
  publicAPI.enablePicking = () => {
    return false;
  };
}

// Object factory
const DEFAULT_VALUES = {
  actorId: null,
};

export function extend(publicAPI, model, initialValues = {}) {
  Object.assign(model, DEFAULT_VALUES, initialValues);

  // Inheritance
  vtkWidgetManager.extend(publicAPI, model, initialValues);

  // Create get-set macros
  macro.setGet(publicAPI, model, ['actorId']);

  // Object specific methods
  vtkEnhancedWidgetManager(publicAPI, model);
}

export const newInstance = macro.newInstance(extend, 'vtkEnhancedWidgetManager');
export default Object.assign({ newInstance, extend });
