import BathymetryBuilder from '../../../icons/BathymetryBuilder.svg?react';
import DataAdmin from '../../../icons/DataAdmin.svg?react';
import DataLink from '../../../icons/DataLink.svg?react';
import FloodScreener from '../../../icons/FloodScreener.svg?react';
import GenericApp from '../../../icons/GenericApp.svg?react';
import MeshGenerator from '../../../icons/MeshGenerator.svg?react';
import FastWaveEmulator from '../../../icons/FastWaveEmulator.svg?react';

export const getAppIcon = (appId: string) => {
  let appIcon;
  switch (appId) {
    case 'dataAdmin':
    case 'Cloud Admin':
      appIcon = <DataAdmin />;
      break;    
    case 'dataLink':
    case 'Data Link':
      appIcon = <DataLink />;
      break;
    case 'floodScreener':
    case 'Flood Screener':
      appIcon = <FloodScreener />;
      break;
    case 'meshBuilder':
    case 'Mesh Builder':
      appIcon = <MeshGenerator />;
      break;      
    case 'bathymetryBuilder':
    case 'Bathymetry Builder':
      appIcon = <BathymetryBuilder />;
      break;
    case 'MIKE Fast Wave Emulator':
    case 'Fast Wave Emulator':
    case 'MetOcean Simulator':
    case 'Metocean Simulator':
      appIcon = <FastWaveEmulator/>;
      break;
    default:
      appIcon = <GenericApp />;
      break;
  }
  return appIcon;
};
/* 
export default AppIcon */
