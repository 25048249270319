import React, { useRef } from 'react';
import { IViewerBounds } from './lib/IMikeVisualizerModels';
import MikeVisualizerLib from './lib/MikeVisualizer';
import useMount from 'react-use/lib/useMount';

import './MikeVisualizer.css';

const { init, destroy, setInitialBounds } = MikeVisualizerLib;
const DEFAULT_VIEWER_SIZE = 128;

type VisualizerProps = {
  bounds?: IViewerBounds;
  onReady?: () => void;
};

/**
 * @name MikeVisualizer
 * @summary Sets up the visualizer at 100% width & 100% height within a container.
 * Fits the provided bounds into the view.
 * If no bounds will be passed, will fit the data into view.
 *
 * @param props
 */
export const MikeVisualizer = (props: VisualizerProps) => {
  // const {bounds, onReady} = props;
  const map = useRef<HTMLDivElement>(null);
  // const [initialized, setInitialized] = useState(false)
  const [bounds] = React.useState(props.bounds as IViewerBounds);

 /* React.useEffect(()=> {
  if (!initialized){
    destroy();
    const currentMap = map.current;
    if (!currentMap) {
      console.error(`No 'div' mounted for 'MikeVisualizer'`);
      return;
    }
    init(currentMap);
    if (bounds) {
      setInitialBounds(bounds);
    }
    if (onReady) {
      onReady();
    }
    if (
      currentMap.clientHeight === DEFAULT_VIEWER_SIZE &&
      currentMap.clientWidth &&
      DEFAULT_VIEWER_SIZE
    ) {
      console.warn(
        'MikeVisualizer was used without a specific height/width. By default, it is set to take 100% of the height/width of its container. To set dimensions, try setting height/width on the element that contains MikeVisualizer, i.e. `.container-class { height: 100vh, width: 100vw }` for a "full screen" viewer.'
      );
    }
    setInitialized(true)
  }
  }, [bounds, initialized, onReady]) */

 useMount(() => {
    destroy();
    const currentMap = map.current;
    if (!currentMap) {
      console.error(`No 'div' mounted for 'MikeVisualizer'`);
      return;
    }
    init(currentMap);
    if (bounds) {
      setInitialBounds(bounds);
    }
    if (props.onReady) {
      props.onReady();
    }
    if (
      currentMap.clientHeight === DEFAULT_VIEWER_SIZE &&
      currentMap.clientWidth &&
      DEFAULT_VIEWER_SIZE
    ) {
      console.warn(
        'MikeVisualizer was used without a specific height/width. By default, it is set to take 100% of the height/width of its container. To set dimensions, try setting height/width on the element that contains MikeVisualizer, i.e. `.container-class { height: 100vh, width: 100vw }` for a "full screen" viewer.'
      );
    }
  }); 
 
  return (
    <div
      className="viewer"
      ref={map}
      style={{ minHeight: DEFAULT_VIEWER_SIZE, minWidth: DEFAULT_VIEWER_SIZE }}
    />
  );
};
