/* eslint-disable @typescript-eslint/no-explicit-any */
import vtkBasicFullScreenRenderWindow from './vtk-extensions/vtkBasicFullScreenRenderWindow';
import vtkEnhancedWidgetManager from './vtk-extensions/vtkEnhancedWidgetManager';
import { IViewerBounds } from './IMikeVisualizerModels';
import MikeVisualizerConfiguration from './MikeVisualizerConfiguration';
import MikeVisualizerStore from './store/MikeVisualizerStore';
import MikeVisualizerCore from './MikeVisualizerCore';
import MikeVisualizerIO from './MikeVisualizerIO';
import MikeVisualizerUtil from './MikeVisualizerUtil';
import MikeVisualizerCosmetic from './MikeVisualizerCosmetic';
import MikeVisualizerDraw from './MikeVisualizerDraw';
import MikeVisualizerNav from './MikeVisualizerNav';
import MikeVisualizerSelect from './MikeVisualizerSelect';
import MikeVisualizerTileManager from './MikeVisualizerTileManager';
import MikeVisualizerPickers from './MikeVisualizerPickers';
import MikeVisualizerViewManager from './MikeVisualizerViewManager';
import MikeVisualizer2DDrawCore from './2d/draw/MikeVisualizer2DDrawCore';
import MikeVisualizer2DDrawingTools from './2d/draw/MikeVisualizer2DDrawingTools';
import MikeVisualizer2DDrawIO from './2d/draw/MikeVisualizer2DDrawIO';
import MikeVisualizer2DInspectionTools from './2d/draw/MikeVisualizer2DInspectionTools';
import MikeVisualizer2DIO from './2d/data/MikeVisualizer2DIO';
import MikeVisualizer2DCosmetic from './2d/data/MikeVisualizer2DCosmetic';
import MikeVisualizer2DControls from './2d/MikeVisualizer2DControls';
import MikeVisualizerProjections from './2d/MikeVisualizerProjections';
import MikeVisualizer2DLineMeasure from './2d/measure/MikeVisualizer2DLineMeasure';
import MikeVisualizer2DAreaMeasure from './2d/measure/MikeVisualizer2DAreaMeasure';
import MikeVisualizer2DMeasureIO from './2d/measure/MikeVisualizer2DMeasureIO';
import MikeVisualizer2DMeasureCore from './2d/measure/MikeVisualizer2DMeasureCore';
import {
  onAllWorkEnded,
  onWorkStarted,
  onWorkEnded,
  onDrawnDataUpdated,
  onDrawingInProgressChanged,
  onMouseMove,
  onCellIndexChanged,
  onPointIndexChanged,
  onPress,
  onDrawMapInspectionChanged,
  onBaseMapChanged,
  onBaseMapDestroyed,
  onBaseMapProjectionNotSupported,
  onBaseMapProjectionFetchFailed,
  onBoundsChanged,
} from './MikeVisualizerEvents';
import { VIEWER_ZINDEX, MAP_IDS } from './MikeVisualizerConstants';
import enableMikeOrientationMarkerWidget from './MikeOrientationMarkerWidget';
import { addGraticule } from './2d/MikeVisualizerGraticule';
import { NO_Z_ATTRIBUTE_NAME } from './converters/MikeVisualizerConverter';

const { getConfiguration, setConfiguration } = MikeVisualizerConfiguration;
const { getState, setState } = MikeVisualizerStore;
const { getInitialBounds, setInitialBounds, setViewerZScale, destroy } = MikeVisualizerCore;
const {
  appendStlData,
  appendObjData,
  appendData,
  appendDataV2,
  updateData,
  updateDataV2,
  deleteData,
  appendDataArray,
  updateDataArray,
  removeDataArray, 
} = MikeVisualizerIO;
const {
  captureScreenshot,
  getDataArraysById,
  getDataRange,
  getDataValues,
  generateColorMap,
  generateAnnotatedColorMap,
  generateSelectAllColorMap,
  setViewerBackground,
  getBounds,
} = MikeVisualizerUtil;
const {
  highlight,
  unhighlight,
  hideElement,
  showElement,
  showElements,
  hideElements,
  updateElementGradient,
  updateElementsGradient,
  getGradientAttributeName,
  getValueColors,
  setRepresentation,
  setPointSize,
  setPointSizeToElements,
  setColorToElement,
  setColorToElements,
  setRepresentationToElements,
  setColorAndRepresentationToElements,
} = MikeVisualizerCosmetic;
const { enablePolygonDrawing, disablePolygonDrawing } = MikeVisualizerDraw;
const { enableOrientationBox } = MikeVisualizerNav;
const { selectElements, deselectElements } = MikeVisualizerSelect;
const { drawTile } = MikeVisualizerTileManager;
const { enableMouseMovePointPicker, enablePressPointPicker, enableMouseMoveCellIdPicker, enableMouseMovePointIdPicker,  setCellIndexActorIds, setPointIndexActorIds } = MikeVisualizerPickers;
const {
  getVisualisationBounds,
  resetCameraToBounds,
  resetCameraToInitialBounds,
  resetCameraToBaseMapBounds,
  resetCamera,
  resetOrientation,
  setViewModeTo2D,
  setViewModeTo3D,
  setViewerBaseMapUrl,
  turnBaseMapOffAndOn,
  updateViewer2DLayers,
  zoomIn,
  zoomOut,
  focusTo2DPoint,
  panVertically,
  panHorizontally,
  rotateCamera,
  tiltVertically,
  tiltHorizontally,
} = MikeVisualizerViewManager;
const { forceOpenLayersDrawMapUpdate, setDrawMapVectorLayerStyle } = MikeVisualizer2DDrawCore;
const {
  enable2DPolygonDrawing,
  enable2DPolygonDrawingV2,
  disable2DPolygonDrawing,
  enable2DPolylineDrawing,
  enable2DPolylineDrawingV2,
  disable2DPolylineDrawing,
  enable2DPointDrawing,
  enable2DPointDrawingV2,
  enable2DCircleDrawing,
  enable2DBoxDrawing,
  disable2DPointDrawing,
  enable2DPolygonSelection,
  disable2DPolygonSelection,
  disableAllDrawingTools,
  getCurrentlyDrawnGeojson,
  enable2DBoxSelection,
  enable2DPointSelection
} = MikeVisualizer2DDrawingTools;
const {
  enable2DDrawInspection,
  disable2DInspection,
  set2DInspectedFeatures,
} = MikeVisualizer2DInspectionTools;
const {
  setDrawVectorLayerGeojson,
  appendDrawVectorLayerGeojson,
  clearDrawnVectorLayerData,
} = MikeVisualizer2DDrawIO;
const { append2DData, update2DData, delete2DData, get2DData, getAll2DData } = MikeVisualizer2DIO;
const {
  setColorTo2DElement,
  setStylesTo2DElement,
  show2DElements,
  hide2DElements,
} = MikeVisualizer2DCosmetic;
const { setupMapScaleControl, setupMapAttributionControl } = MikeVisualizer2DControls;
const { getEpsgCode, setEpsgCode, setCrs, getCrs } = MikeVisualizerProjections;
const { enable2DLineMeasure, setLineMeasurementUnit } = MikeVisualizer2DLineMeasure;
const { enable2DAreaMeasure, setAreaMeasurementUnit } = MikeVisualizer2DAreaMeasure;
const { clearMeasurements } = MikeVisualizer2DMeasureIO;
const { disableMeasureTools } = MikeVisualizer2DMeasureCore;

/**
 * Exposes methods to render & control the visual aspects of vtpXML strings with vtk.js.
 * This is the entry point for the 3D visualizer.
 *
 * @module MikeVisualizer
 * @version 1.0.0
 */

/**
 * Intitializes the full screen viewer.
 *
 * @param container A DOM Node where the vtk.js will create a canvas & do its rendering.
 * @param bounds Optional bounds to help place the initial camera.
 * @param epsgCode The visualization's epsg code. Required for 2D; in 3D, there might not be one.
 */
const init = (container: HTMLElement | ChildNode, bounds?: IViewerBounds, epsgCode?: number) => {
  // Create a full screen render window, using our custom class.
  const fullScreenRenderWindow = vtkBasicFullScreenRenderWindow.newInstance({
    rootContainer: container,
    container: (() => {
      const div = document.createElement('div');
      div.id = MAP_IDS.VTK_CONTAINER_ID;
      return div;
    })(),
    containerStyle: {
      height: '100%',
      width: '100%',
      'z-index': VIEWER_ZINDEX.THREED_VIEWER,
    },
  });

  const renderer = fullScreenRenderWindow.getRenderer();
  const renderWindow = fullScreenRenderWindow.getRenderWindow();

  // Configure renderer default appearance.
  renderer.setUseDepthPeeling(1);

  // Set desired update rate.
  renderWindow.getInteractor().setDesiredUpdateRate(15);

  // Try to set initial bounds if available.
  if (bounds) {
    setInitialBounds(bounds);
  }

  // Instantiate a global widget manager for all widgets.  
  const widgetManager = vtkEnhancedWidgetManager.newInstance();
  widgetManager.setRenderer(renderer);

  // Update state & append to DOM.
  setState({
    container: container as any,
    renderer,
    renderWindow,
    fullScreenRenderWindow,
    widgetManager,
    epsgCode,
  });

  // Instantiate a global mouse move & press point pickers.
  enableMouseMovePointPicker();
  enablePressPointPicker();  
};

const self = {
  /* Initialization */
  init,

  /* Constants */
  NO_Z_ATTRIBUTE_NAME,

  /* Configuration exports */
  getConfiguration,
  setConfiguration,

  /* State exports */
  getState,
  setState,

  /* IO exports */
  appendStlData,
  appendObjData,
  appendData,
  appendDataV2,
  updateData,
  updateDataV2,
  deleteData,
  appendDataArray,
  updateDataArray,
  removeDataArray,
  getBounds,

  /* Core exports */
  destroy,
  setViewerZScale,
  setInitialBounds,
  getInitialBounds,
  getVisualisationBounds,

  /* Select exports */
  selectElements,
  deselectElements,

  /* Util exports */
  captureScreenshot,
  getDataArraysById,
  getDataRange,
  getDataValues,
  generateColorMap,
  generateAnnotatedColorMap,
  generateSelectAllColorMap,

  /* Cosmetic exports */
  highlight,
  unhighlight,
  hideElement,
  showElement,
  showElements,
  hideElements,
  updateElementGradient,
  updateElementsGradient,
  getGradientAttributeName,
  getValueColors,
  setRepresentation,
  setRepresentationToElements,
  setPointSize,
  setPointSizeToElements,
  setColorToElement,
  setColorToElements, 
  setColorAndRepresentationToElements,
  setViewerBackground,

  /* Draw exports */
  enablePolygonDrawing,
  disablePolygonDrawing,

  /* Nav exports */
  enableOrientationBox,
  enableMikeOrientationMarkerWidget,

  /* Tile manager exports */
  drawTile,

  /* View manager exports */
  resetCameraToBounds,
  resetCameraToInitialBounds,
  resetCameraToBaseMapBounds,
  resetCamera,
  resetOrientation,
  setViewModeTo2D,
  setViewModeTo3D,
  setViewerBaseMapUrl,
  turnBaseMapOffAndOn,
  updateViewer2DLayers,
  zoomIn,
  zoomOut,
  focusTo2DPoint,
  addGraticule, 
  panVertically,
  panHorizontally,
  rotateCamera,
  tiltVertically,
  tiltHorizontally,

  /* 2D Draw exports */
  enable2DPolygonDrawing,
  enable2DPolygonDrawingV2,
  disable2DPolygonDrawing,
  enable2DPolylineDrawing,
  enable2DPolylineDrawingV2,
  disable2DPolylineDrawing,
  enable2DPointDrawing,
  enable2DPointDrawingV2,
  disable2DPointDrawing,
  enable2DPolygonSelection,
  enable2DBoxSelection,
  enable2DPointSelection,
  disable2DPolygonSelection,
  enable2DCircleDrawing,
  enable2DBoxDrawing,
  enable2DDrawInspection,
  disable2DInspection,
  set2DInspectedFeatures,
  getCurrentlyDrawnGeojson,
  disableAllDrawingTools,
  setDrawVectorLayerGeojson,
  appendDrawVectorLayerGeojson,
  clearDrawnVectorLayerData,

  /* 2D Draw core exports */
  forceOpenLayersDrawMapUpdate,
  setDrawMapVectorLayerStyle,

  /* 2D data exports */
  append2DData,
  update2DData,
  delete2DData,
  get2DData,
  getAll2DData,

  /* 2D data cosmetic exports */
  setColorTo2DElement,
  setStylesTo2DElement,
  show2DElements,
  hide2DElements,

  /* 2D controls exports */
  setupMapScaleControl,
  setupMapAttributionControl,

  /* 2D measure exports */
  enable2DLineMeasure,
  enable2DAreaMeasure,
  clearMeasurements,
  disableMeasureTools,
  setAreaMeasurementUnit,
  setLineMeasurementUnit,

  /* Event registration */
  onBoundsChanged,
  onAllWorkEnded,
  onWorkStarted,
  onWorkEnded,
  onDrawnDataUpdated,
  onDrawingInProgressChanged,
  onMouseMove,
  onCellIndexChanged,
  onPointIndexChanged,
  onPress,
  onDrawMapInspectionChanged,
  onBaseMapChanged,
  onBaseMapDestroyed,
  onBaseMapProjectionNotSupported,
  onBaseMapProjectionFetchFailed,
  setCellIndexActorIds,
  setPointIndexActorIds,
  enableMouseMoveCellIdPicker,
  enableMouseMovePointIdPicker,

  /* Projections */
  getEpsgCode,
  setEpsgCode,
  setCrs,
  getCrs,
};

export default self;
