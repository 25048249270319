/* eslint-disable @typescript-eslint/no-explicit-any */
import Map from 'ol/Map';
import Draw, { DrawEvent } from 'ol/interaction/Draw';
import { unByKey } from 'ol/Observable';
import { Style } from 'ol/style';
import { Coordinate } from 'ol/coordinate';
import LineString from 'ol/geom/LineString';
import Polygon from 'ol/geom/Polygon';

import MikeVisualizer2DMapUtil from '../MikeVisualizer2DMapUtil';
import MikeVisualizer2DMeasureUtil from './MikeVisualizer2DMeasureUtil';
import MikeVisualizer2DMeasureIO from './MikeVisualizer2DMeasureIO';
import MikeVisualizerStore from '../../store/MikeVisualizerStore';
import { getOlStylePresets } from '../MikeVisualizer2DDrawConstants';
import { getConfiguration } from '../../MikeVisualizerConfiguration';
// import GeometryType from 'ol/geom/GeometryType';
import Geometry from 'ol/geom/Geometry';
import { IMikeDrawMapEvent } from '../../store/IMikeVisualizerStore';

const { getState, setState } = MikeVisualizerStore;
const {
  _getVectorLayerSource,
  _getVectorLayer,
  _removeAllMapInteractions,
} = MikeVisualizer2DMapUtil;
const {
  _showHelpTooltip,
  _hideHelpTooltip,
  _createHelpTooltip,
  _createMeasureTooltip,
  _destroyMeasureTooltip,
} = MikeVisualizer2DMeasureUtil;
const { clearMeasurements } = MikeVisualizer2DMeasureIO;

/**
 * Contains methods to enable 2d measure interactions.
 *
 * @module MikeVisualizer2DMeasureInteractions
 * @version 1.0.0
 */

/**
 * Enables measure interactions on a map (generally - the measure map).
 *
 * @param measureMap
 * @param type
 * @param [clearFeaturesOnStart] Remove previous measurements when starting to make a new measurement.
 * @param [drawInteractionStyle]
 * @param [vectorLayerStyle]
 * @param [onUpdateHelpTooltip] Callback for update help tooltip events.
 * @param [onUpdateMeasureTooltip] Callback for update measure tooltip events.
 * @param [onCreateStaticMeasureTooltip] Callback for create static measure tooltip events.
 */
const _enableMeasureInteraction = async (
  measureMap: Map,
  // TODO: type: GeometryType.LINE_STRING | GeometryType.POLYGON,
  type: 'LineString' | 'Polygon',
  drawInteractionStyle: (feature) => Array<Style> = getOlStylePresets().MEASUREMENT.drawInteraction,
  vectorLayerStyle: (feature) => Array<Style> = getOlStylePresets().MEASUREMENT.vectorLayer,
  clearFeaturesOnStart = false,
  onUpdateHelpTooltip: (position: Coordinate, isSomethingSketched: boolean) => void,
  onUpdateMeasureTooltip: (geometry: LineString | Polygon) => void,
  onCreateStaticMeasureTooltip: (geometry: LineString | Polygon) => void
) => {
  if (measureMap) {
    self._removeMeasureMapInteractions(measureMap);

    const source1 = _getVectorLayerSource(measureMap);
    const source = source1 ? source1 : undefined
    const vectorLayer = _getVectorLayer(measureMap);
    const drawInteraction = new Draw({
      freehandCondition: () => false,
      source,
      type: type, // as GeometryType,
      style: drawInteractionStyle,
    });

    vectorLayer.setStyle(vectorLayerStyle);
    measureMap.addInteraction(drawInteraction);
    _createHelpTooltip();

    self._handleMeasureSketchUpdates(
      drawInteraction,
      clearFeaturesOnStart,
      onUpdateMeasureTooltip,
      onCreateStaticMeasureTooltip
    );
    self._enableEditInteractions(drawInteraction);
    self._enableMeasurePointerInteraction(measureMap, drawInteraction, onUpdateHelpTooltip);

    return measureMap;
  }

  return false;
};

/**
 * Enables the mouse pointer interaction, which will display a help tooltip as the pointer moves.
 * i.e. instructions on how to measure / finish measurement.
 *
 * @param measureMap
 * @param sketchedFeature
 * @param [onUpdateHelpTooltip] Callback for help tooltip update events.
 */
const _enableMeasurePointerInteraction = (
  measureMap: Map,
  drawInteraction: Draw,
  onUpdateHelpTooltip: (position: Coordinate, isSomethingSketched: boolean) => void
) => {
  const pointerMoveHandler = (event: any) => {
    if (event.dragging) {
      return;
    }
    // TODO: Find a more robust way that doesn't rely on a undocumented temporary property:
    const isSomethingSketched = drawInteraction && (drawInteraction as any).sketchFeature_;
    onUpdateHelpTooltip(event.coordinate, isSomethingSketched);
    _showHelpTooltip();
  };

  measureMap.on('pointermove', pointerMoveHandler);

  const mouseOutHandler = () => {
    _hideHelpTooltip();
  };

  measureMap.getViewport().addEventListener('mouseout', mouseOutHandler);
};

/**
 * Handles measurement sketch updates (before a measurement is finished / draw ended).
 *
 * @param draw
 * @param clearFeaturesOnStart Remove previous measurements when starting to make a new measurement.
 * @param [onUpdateMeasureTooltip] Callback for update measure tooltip events.
 * @param [onCreateStaticMeasureTooltip] Callback for create static measure tooltip events.
 */
const _handleMeasureSketchUpdates = (
  draw: Draw,
  clearFeaturesOnStart: boolean,
  onUpdateMeasureTooltip: (geometry: LineString | Polygon) => void,
  onCreateStaticMeasureTooltip: (geometry: LineString | Polygon) => void
) => {
  let sketchedFeatureChangeListener;

  const drawStartCb = (event: DrawEvent) => {
    if (clearFeaturesOnStart) {
      clearMeasurements();
    }

    const sketchedFeature = event.feature;
    _createMeasureTooltip();

    const sketchedGeometry = sketchedFeature.getGeometry() as Geometry;
    sketchedFeatureChangeListener = sketchedGeometry.on('change', (geometryChangeEvent) => {
      const geometry: LineString | Polygon = geometryChangeEvent.target;

      onUpdateMeasureTooltip(geometry);
    });
  };

  draw.on('drawstart', drawStartCb);

  const drawEndCb = (event) => {
    const geometry = event.feature ? event.feature.getGeometry() : null;

    if (geometry) {
      _destroyMeasureTooltip();
      onCreateStaticMeasureTooltip(geometry);
    }
    unByKey(sketchedFeatureChangeListener);
  };

  draw.on('drawend', drawEndCb);
};

/**
 * Enables edit interactions on a instance of 'ol/Draw'.
 *
 * This allows
 * - moving points
 * - handling keyboard shortcuts to i.e. cancel a measurement
 *
 * @param drawInteraction
 *
 * @private
 */
const _enableEditInteractions = (drawInteraction: Draw) => {
  const measureMap = drawInteraction.getMap();
  const { keyboardShortcuts } = getConfiguration();

  if (!measureMap) {
    console.info('dropped enabling measure map interactions; no measure map is present');
    return;
  }

  const onDrawEnded = () => {
    // Remove 'events when sketch in progress'.
    document.removeEventListener('keydown', handleKeyBoardEventsWhenSketchInProgress);

    setState({
      measureMapDocumentListeners: getState().measureMapDocumentListeners.filter((event) => {
        const { name, cb } = event as IMikeDrawMapEvent;
        return name === 'keydown' && cb === handleKeyBoardEventsWhenSketchInProgress;
      }),
    });
  };

  // Setup event listeners (keyboard shortcuts) and callbacks for drawing.
  // Keyboard events for sketch in progress.
  const handleKeyBoardEventsWhenSketchInProgress = (keyEvt) => {
    const key = keyEvt.key;

    if (key === keyboardShortcuts.removeLastPoint) {
      drawInteraction.removeLastPoint();
      drawInteraction.changed();
    }

    if (key === keyboardShortcuts.cancelSketch) {
      drawInteraction.abortDrawing();
      // drawInteraction.abortDrawing_(); // Why did they use underscore previously?
      onDrawEnded();
      _destroyMeasureTooltip();
    }

    if (key === keyboardShortcuts.finishSketch) {
      drawInteraction.finishDrawing();
    }
  };

  const drawStartCb = () => {
    document.addEventListener('keydown', handleKeyBoardEventsWhenSketchInProgress, false);

    // Setup 'events when sketch in progress'.
    setState({
      measureMapDocumentListeners: [
        ...getState().measureMapDocumentListeners,
        {
          name: 'keydown',
          cb: handleKeyBoardEventsWhenSketchInProgress,
        },
      ],
    });
  };

  const drawEndCb = () => {
    onDrawEnded();
  };

  drawInteraction.on('drawstart', drawStartCb);
  drawInteraction.on('drawend', drawEndCb);
};

/**
 * Removes all previous measure interactions.
 *
 * @param measureMap
 *
 * @private
 */
const _removeMeasureMapInteractions = (measureMap: Map) => {
  if (measureMap) {
    _removeAllMapInteractions(measureMap);
    setState({
      measureMapDocumentListeners: getState().measureMapDocumentListeners.filter((event) => {
        const { name, cb } = event as IMikeDrawMapEvent;
        document.removeEventListener(name, cb);
      }),
    });
  }
};

const self = {
  _enableMeasureInteraction,
  _enableMeasurePointerInteraction,
  _enableEditInteractions,
  _handleMeasureSketchUpdates,
  _removeMeasureMapInteractions,
};

export default self;
