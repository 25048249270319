/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import Skeleton from '@mui/material/Skeleton';
import mikeSharedTheme from '../../styles/mikeSharedTheme';

const SkeletonStyle = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: ${mikeSharedTheme.spacing(7.5)};
  padding-left: ${mikeSharedTheme.spacing(1)};
  padding-right: ${mikeSharedTheme.spacing(1)};
`;

interface IProps {
  count: number;
}

const AppSkeleton = (props: IProps) => {
  const { count } = props;

  const skeletonRows = Array.from({ length: count }, (_v, k) => k + 1);

  return (
    <>
      {skeletonRows.map((_row, ind) => {
        return (
          <div css={SkeletonStyle} key={ind}>
            <Skeleton variant="circular" width={40} height={40} />
            <Skeleton width="70%" />
          </div>
        );
      })}
    </>
  );
};

export default AppSkeleton;
