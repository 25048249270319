/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { useCallback, useState, useMemo } from 'react';
import MikeVisualizerLib from '../../MikeVisualizer/lib/MikeVisualizer';
import Down from '../../icons/Down.svg?react';
import Up from '../../icons/Up.svg?react';
import Typography from '@mui/material/Typography';
import EyeVisible from '../../icons/EyeVisible.svg?react';
import EyeHidden from '../../icons/EyeHidden.svg?react';
import { useDispatch, useSelector } from 'react-redux';
import { legendGroupHideAll, legendGroupShowAll } from '../../actions/legend';
import { ILayerGroup, ILegendLayer } from '../../reducers/legend';
import { IRenderElement, IThreeDRenderElement } from '../../MikeVisualizer/lib/IMikeVisualizerModels';
import { Layer } from './Layer';
import { IGetProject } from '../../model/IGetProject';
import { IState } from '../../reducers';
import { useIntl } from 'react-intl';
import { uploadBackground } from '../../actions/mapContent';
import UploadButton from '../UploadButton/UploadButton';
import { BACKGROUND } from '../../shared/constants';
import mikeSharedTheme, { mikePalette } from '../../styles/mikeSharedTheme';
import { iconPrimaryStyle } from '../../styles/iconStyles';


const TitleContainerStyle = (visible: boolean) => {
  const backgroundColor = visible ? mikePalette.mediumGrey.main : mikePalette.mediumGrey.light;  
  return css`
  display: flex;
  justify-content: space-between;
  align-items: center;  
  background-color: ${backgroundColor};
  border-top: 1px solid ${mikePalette.mediumGrey.dark};
`;
}

const TitleAndButtonStyle = css`
  display: flex;
  align-items: center;
`;

export const TitleStyle = (visible: boolean) => {  
  const color = visible ? mikePalette.primary.main : mikePalette.primary.light;
  return css`
  padding-left: ${mikeSharedTheme.spacing(1)};
  &.MuiTypography-root {
    color: ${color};
  }  
`;
}


interface IProps {
  layerGroup: ILayerGroup; 
}
export const LayerGroup = (props: IProps) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const {layerGroup} = props;

  const project: IGetProject | null = useSelector(
    (state: IState) => state.projectContent.project
  );

  const { getState } = MikeVisualizerLib;
  const { renderedElements, rendered2DElements } = getState();

  const canUpdate = useMemo(() => {    
    return project && project.capabilities && project.capabilities.canUpdateContent
  }, [project])

  const layersToBeLoadedByUser = useMemo(() => {
    const layers = layerGroup.layer;
    return layers.filter((l: ILegendLayer) => l.canBeLoadedByUser)
  },[layerGroup])

  const loadingLayers = useMemo(() => {
    const layers = layerGroup.layer;
    return layers.filter((l: ILegendLayer) => l.loading)
  }, [layerGroup])

  const loadedLayers = useMemo(() => {
    const layers = layerGroup.layer;
  
    const two2DLayerIds = rendered2DElements.map((re: IRenderElement) => re.id)
    const two3DLayerIds = renderedElements.map((re: IThreeDRenderElement) => re.id)
   
    const loadedLayers = layers.filter((l: ILegendLayer) => 
      l.isTwoD ? two2DLayerIds.includes(l.id) : two3DLayerIds.includes(l.id))
    const sortedLayers = loadedLayers.sort((a: ILegendLayer, b: ILegendLayer) => {return b.order - a.order})
    return sortedLayers
  }, [layerGroup, renderedElements, rendered2DElements])

  const allLayers =  useMemo(() => {
    const all = Array<ILegendLayer>()
    return all.concat(loadedLayers).concat(loadingLayers).concat(layersToBeLoadedByUser);
  }, [layersToBeLoadedByUser, loadedLayers, loadingLayers])

  const allLayersHidden = useMemo(() => {
    const hiddenLayers = allLayers.filter((l: ILegendLayer) => l.visible === false); 
    return hiddenLayers.length === allLayers.length
  }, [allLayers])
 
  const [layerGroupOpen, setLayerGroupOpen] = useState(false);

  const handleToggleVisibiltyAllLayers = useCallback(() => {
    const showAllLayers = allLayersHidden
    if (showAllLayers){
      dispatch(legendGroupShowAll(layerGroup))
    }
    else{
      dispatch(legendGroupHideAll(layerGroup))
    }
  }, [layerGroup, allLayersHidden, dispatch])

  const onUploadFromComputer = (files) => {     
    if (files.length > 0){       
      const file = files[0] 
      dispatch(uploadBackground(file, file.name, BACKGROUND))     
    }
  };

  return (     
    <div>
      <div css={TitleContainerStyle(!allLayersHidden)} key="layergroup">
        <div css={TitleAndButtonStyle}>
          {layerGroupOpen ?  <Up css={iconPrimaryStyle(allLayersHidden)}  onClick={() => setLayerGroupOpen(!layerGroupOpen)}/>: 
          <Down css={iconPrimaryStyle(allLayersHidden)} onClick={() => setLayerGroupOpen(!layerGroupOpen)} />}
          <Typography css= {TitleStyle(!allLayersHidden)}  variant="body2"><b>{layerGroup.title}</b></Typography>
        </div>
        {layerGroup && layerGroup.uploadType && <UploadButton 
          disabled={!canUpdate} 
          fileType={'.zip'} 
          label={intl.formatMessage({id:'desktop.upload'})} 
          onDrop={onUploadFromComputer}
          info={[intl.formatMessage({id: 'autoMesh.uploadShapefile_hint'})]}
        />}
        {allLayersHidden ? <EyeHidden  css={iconPrimaryStyle(true)} onClick={handleToggleVisibiltyAllLayers}/> : <EyeVisible onClick={handleToggleVisibiltyAllLayers}/>}
      </div>
      {layerGroupOpen && allLayers.map((l: ILegendLayer, index) => <Layer key={index} layer={l} layerGroup={layerGroup}/>)}
    </div>
  );
};

export default LayerGroup