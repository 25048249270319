import { isNumber } from 'lodash-es';
import vtk from '@kitware/vtk.js/vtk';
import vtkDataArray from '@kitware/vtk.js/Common/Core/DataArray';
import MikeVisualizerVtkWriter from './MikeVisualizerVtkWriter';
const { createVtkObject, createVtkXmlObject } = MikeVisualizerVtkWriter;

/**
 * Conversion for vtk data formats.
 * Data is typically converted from xml to vtk.js's internal json data fromat.
 * This module can be used for displaying gridded vti data as vtp 3D surfaces by connecting the points (triangles).
 *
 * For example:
 * - convert vti to vtp points
 * - convert vtp points to vtp triangles
 *
 * @module MikeVisualizerConverter
 * @version 2.0.0
 */

const NO_DATA_VALUE_KEY = 'NoDataValue';
const DEFAULT_NO_DATA_VALUE = -9999; // NoDataValue is used to filter out data 'padding'. Because vti is a grid format, it pads with data even where no values are present. It could be 0, NaN, -9999 or whatever the source grid (i.e. ESRI grid) defines as 'no data value'. -9999 is used as a fallback in case the vti file does not set a value.
export const NO_Z_ATTRIBUTE_NAME = '< NO Z ATTRIBUTE >';
export enum EElevationDataArrayNames {
  Elevation = 'Elevation',
  band1 = 'band1',
}
const ElevationDataArrayLCNames = Object.values(EElevationDataArrayNames).map((str) =>
  str.toLowerCase()
);

/**
 * Get the dataArray from an array of dataArrays that matches one of
 * the elevation names (returns the first match).
 *
 * @param vtkDataArrays An array of dataArrays
 * @param [zAttributeName] Name of the data array to use for z values
 * @returns key value pairs { vtkZIndexDataArray, appliedZAttributeName }
 */
const _findZIndexDataArray = (vtkDataArrays: Array<any>, zAttributeName = '') => {
  let appliedZAttributeName: string | undefined = zAttributeName || undefined;
  let vtkZIndexDataArray: any;
  if (zAttributeName && zAttributeName !== NO_Z_ATTRIBUTE_NAME) {
    vtkZIndexDataArray = vtkDataArrays.find((dataArray) => {
      const daName = dataArray.getName().toLowerCase();
      return daName === zAttributeName.toLowerCase();
    });
    if (!vtkZIndexDataArray) {
      console.error(`No match for data array '${zAttributeName}'`);
    }
  } else {
    vtkZIndexDataArray = vtkDataArrays.find((dataArray) => {
      const daName = dataArray.getName().toLowerCase();
      const match = ElevationDataArrayLCNames.indexOf(daName) > -1;
      if (match){
        appliedZAttributeName = daName;
      } 
      return match;
    });
  }
  const result = { vtkZIndexDataArray, appliedZAttributeName };
  return result;
};

/**
 * Gets the NoDataValue of a vti xml, if present.
 *
 * @param { vtkObject } parsedVtiData A parsed xml object containing all vti data.
 *
 * @protected
 */
const _getNoDataValue = (parsedVtiData): number | string => {
  if (parsedVtiData.getFieldData()) {
    const NoDataValueArray = parsedVtiData
      .getFieldData()
      .getArrays()
      .find((dataArray) => dataArray.getName() === NO_DATA_VALUE_KEY);
    if (NoDataValueArray) {
      return NoDataValueArray.getData()[0];
    }
  }
  return DEFAULT_NO_DATA_VALUE;
};

/**
 * Get vti noDataValue, origin, spacing & extent.
 *
 * @param { vtkObject } parsedVtiData A parsed xml object containing all vti data.
 */
const _readVtiMetadata = (parsedVtiData) => {
  const noDataValue = self._getNoDataValue(parsedVtiData);

  const vtiExtent = parsedVtiData.getExtent();
  const EXTNET_X_MIN = vtiExtent[0];
  const EXTENT_X_MAX = vtiExtent[1];
  const EXTENT_Y_MIN = vtiExtent[2];
  const EXTENT_Y_MAX = vtiExtent[3];
  const EXTENT_Z_MIN = vtiExtent[4];
  const EXTENT_Z_MAX = vtiExtent[5];
  const totalNumberOfPoints = EXTENT_X_MAX * EXTENT_Y_MAX; // todo hevo Is this ever correct?

  const vtiOrigin = parsedVtiData.getOrigin();
  const ORIGIN_Z = vtiOrigin[2];
  let ORIGIN_X = vtiOrigin[0];
  let ORIGIN_Y = vtiOrigin[1];

  const vtiSpacing = parsedVtiData.getSpacing();
  let SPACING_X = vtiSpacing[0];
  let SPACING_Y = vtiSpacing[1];

  if (SPACING_X < 0) {
    SPACING_X = -SPACING_X;
    ORIGIN_X = ORIGIN_X + EXTENT_X_MAX * SPACING_X;
  }

  if (SPACING_Y < 0) {
    SPACING_Y = -SPACING_Y;
    ORIGIN_Y = ORIGIN_Y + EXTENT_Y_MAX * SPACING_Y;
  }

  return {
    vtiOrigin,
    vtiSpacing,
    vtiExtent,
    noDataValue,
    totalNumberOfPoints,

    ORIGIN_X,
    ORIGIN_Y,
    ORIGIN_Z,
    SPACING_X,
    SPACING_Y,
    EXTNET_X_MIN,
    EXTENT_X_MAX,
    EXTENT_Y_MIN,
    EXTENT_Y_MAX,
    EXTENT_Z_MIN,
    EXTENT_Z_MAX,
  };
};

/**
 * Converts a data array from cell data to point data.
 * Will add extra data, since there is one more point than cells in each direction.
 * Duplicates last row and last column data (not exact, but assumed to be ok for visualization purposes)
 *
 * @param vtkDataArray
 * @param NO_X_CELLS
 * @param NO_Y_CELLS
 */
const convertVtiCellDataToVtpTrianglePointData = (
  dataArray,
  NO_X_CELLS: number,
  NO_Y_CELLS: number
) => {
  const vtiData = dataArray.getData();
  const vtpData: Array<number | string> = [];

  for (let yIndex = 0; yIndex < NO_Y_CELLS; yIndex++) {
    const row = vtiData.slice(yIndex * NO_X_CELLS, (yIndex + 1) * NO_X_CELLS);
    const newRow = [...row, row[row.length - 1]];
    // add an extra column with same value as last in the original row.
    vtpData.push(...newRow);
    // add an extra row with the same data as the last row
    if ((yIndex + 1) % NO_Y_CELLS === 0) {
      vtpData.push(...newRow);
    }
  }

  const vtpDataArray = vtkDataArray.newInstance({
    numberOfComponents: 1,
    name: dataArray.getName(),
    dataType: dataArray.getDataType(),
    values: vtpData,
  });

  return vtpDataArray;
};

/**
 * Converts a vti to vtp point data.
 * - Vti Elevation data array points can be used are Z coordinates in the result vti.
 * - Assumes Z extent & Z spacing is 0 in the vti.
 *
 * @param { vtkObject } parsedVtiData A parsed xml object containing all vti data.
 * @param usePointsAsZIndex Will assign points to zIndex instead of '0'.
 * @param generateXml Flag to generate the actual vtp XML string. Useful for debugging, but shouldn't be used to create actors (requires parsing data again; data is already parsed at this point).
 * @param zValueReplacement A constant to be used instead of z values should `usePointsAsZIndex` be false. NB: Can be refactored into a function later for extra flexibility.
 *
 * @public
 */
const convertVtiToVtpPoints = (
  parsedVtiData,
  usePointsAsZIndex: boolean,
  generateXml = false,
  zValueReplacement = 0,
  zAttributeName = ''
) => {
  // This method is only used in a unit test `MikeVisualizerConverter.test.ts`,
  // ie can't make a story to see it action.
  let vtpXml;

  const {
    vtiOrigin,
    vtiSpacing,
    vtiExtent,
    noDataValue,

    ORIGIN_X,
    ORIGIN_Y,
    ORIGIN_Z,
    SPACING_X,
    SPACING_Y,
    EXTENT_X_MAX,
    EXTENT_Y_MAX,
  } = self._readVtiMetadata(parsedVtiData);

  const totalNumberOfPoints = EXTENT_X_MAX * EXTENT_Y_MAX; // todo hevo Might need to be aligned with convertVtiToVtpTriangles

  /**
   * Read data. If usePointsAsZIndex is provided, look for the `Elevation` data array. Otherwise don't apply a Z index.
   */
  const vtiDataArrays = [
    ...parsedVtiData.getCellData().getArrays(),
    ...parsedVtiData.getPointData().getArrays(),
  ];
  const vtiFieldData = parsedVtiData
    .getFieldData()
    .getArrays()
    .filter((fieldDataArray) => fieldDataArray.getName() !== NO_DATA_VALUE_KEY);
  const { vtkZIndexDataArray: vtiZIndexDataArray } = _findZIndexDataArray(
    vtiDataArrays,
    zAttributeName
  );
  const vtiZIndexData = vtiZIndexDataArray ? vtiZIndexDataArray.getData() : [];

  const vtpPoints: Array<number | string> = [];
  const vertConnectivity = Array.from(Array(totalNumberOfPoints).keys());
  const vertOffsets = [totalNumberOfPoints];

  /**
   * Starting top-left, read each row of points and generate a xyz point from it, using the provided X/Y spacing.
   */
  let zIndex = 0;
  for (let yIndex = 0; yIndex < EXTENT_Y_MAX; yIndex++) {
    for (let xIndex = 0; xIndex < EXTENT_X_MAX; xIndex++) {
      const pointX = ORIGIN_X + SPACING_X * xIndex;
      const pointY = ORIGIN_Y + SPACING_Y * yIndex;
      const pointZValueRaw = vtiZIndexData[zIndex++];
      const pointZValue = isNumber(pointZValueRaw) ? pointZValueRaw : zValueReplacement;
      const pointZ =
        pointZValue === noDataValue
          ? 'NaN'
          : usePointsAsZIndex
          ? ORIGIN_Z + pointZValue
          : zValueReplacement;
      vtpPoints.push(pointX, pointY, pointZ);
    }
  }

  const vtpObject = createVtkObject(
    'vtkPolyData',
    noDataValue,
    vtiFieldData,
    vtiDataArrays,
    vtpPoints,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    vertOffsets,
    vertConnectivity
  );

  const vtpPolyData = vtk(vtpObject);

  if (generateXml) {
    vtpXml = createVtkXmlObject(
      totalNumberOfPoints,
      0,
      noDataValue,
      vtiFieldData,
      vtiDataArrays,
      vtpPoints,
      undefined,
      undefined,
      vertOffsets,
      vertConnectivity
    );
  }

  return {
    // Parsed data
    vtpPoints,
    vtiZIndexData,
    vtiOrigin,
    vtiSpacing,
    vtiExtent,
    vtiFieldData,
    noDataValue,

    // Generated data
    vertConnectivity,
    vertOffsets,
    vtpObject,
    vtpPolyData,
    vtpXml,
  };
};

/**
 * Converts a vti to vtp triangle data.
 * - Vti Elevation data array points can be used are Z coordinates in the result vti.
 * - Assumes Z extent & Z spacing is 0 in the vti.
 *
 * @param { vtkObject } parsedVtiData A parsed xml object containing all vti data.
 * @param usePointsAsZIndex Will assign points to zIndex instead of '0'.
 * @param generateXml Flag to generate the actual vtp XML string. Useful for debugging, but shouldn't be used to create actors (requires parsing data again; data is already parsed at this point).
 * @param zValueReplacement A constant to be used instead of z values should `usePointsAsZIndex` be false. NB: Can be refactored into a function later for extra flexibility.
 *
 * @public
 */
const convertVtiToVtpTriangles = (
  parsedVtiData,
  usePointsAsZIndex: boolean,
  generateXml = false,
  zValueReplacement = 0,
  zAttributeName?: string
) => {
  let vtpXml;
  const {
    vtiOrigin,
    vtiSpacing,
    vtiExtent,
    noDataValue,
    ORIGIN_X,
    ORIGIN_Y,
    ORIGIN_Z,
    SPACING_X,
    SPACING_Y,
    EXTENT_X_MAX,
    EXTENT_Y_MAX,
  } = self._readVtiMetadata(parsedVtiData);

  const NO_X_POINTS = EXTENT_X_MAX + 1;
  const NO_Y_POINTS = EXTENT_Y_MAX + 1;
  const totalNumberOfPoints = NO_X_POINTS * NO_Y_POINTS;

  /**
   * Read data. If usePointsAsZIndex is provided, look for the `Elevation` data array. Otherwise don't apply a Z index. It's safe to do that because the name (`Elevation`) is hardcoded in the back-end.
   */

  const vtiCellDataArrays = [...parsedVtiData.getCellData().getArrays()];

  // The cell data will be added as point data later on, so we need to convert them
  const vtpCellDataArrays = vtiCellDataArrays.map((dataArray) => {
    return self.convertVtiCellDataToVtpTrianglePointData(dataArray, EXTENT_X_MAX, EXTENT_Y_MAX);
  });

  // point data are kept as is, as we do not make any changes to the number of points
  const vtpDataArrays = [...vtpCellDataArrays, ...parsedVtiData.getPointData().getArrays()];

  // remove the 'no data value' field
  const vtpFieldData = parsedVtiData
    .getFieldData()
    .getArrays()
    .filter((fieldDataArray) => fieldDataArray.getName() !== NO_DATA_VALUE_KEY);

  const { vtkZIndexDataArray: vtpZIndexDataArray, appliedZAttributeName } = _findZIndexDataArray(
    vtpDataArrays,
    zAttributeName
  );
  const vtpZIndexData = vtpZIndexDataArray ? vtpZIndexDataArray.getData() : [];

  const vtpPoints: Array<number | string> = [];
  const noOfPolys = EXTENT_X_MAX * EXTENT_Y_MAX * 2; // two triangles per original cell
  const polyOffsets = Array.from(Array(noOfPolys).keys()).map((item) => (item + 1) * 3);

  const isNoData = (value: number) => {
    if (value === noDataValue || isNaN(value) || value === undefined) {
      return true;
    }
    return false;
  };

  /**
   * Starting top-left, read each row of points and generate a triangle from it, using the provided X/Y spacing.
   */
  for (let yIndex = 0; yIndex < NO_Y_POINTS; yIndex++) {
    for (let xIndex = 0; xIndex < NO_X_POINTS; xIndex++) {
      const pointX = ORIGIN_X + SPACING_X * xIndex;
      const pointY = ORIGIN_Y + SPACING_Y * yIndex;
      const pointZValueRaw = vtpZIndexData[xIndex + yIndex * NO_X_POINTS];
      const pointZValue = isNumber(pointZValueRaw) ? pointZValueRaw : zValueReplacement;
      let pointZ: number | string;
      if (zAttributeName !== NO_Z_ATTRIBUTE_NAME) {
        // prettier-ignore
        pointZ = isNoData(pointZValue) ? 'NaN' : (usePointsAsZIndex ? ORIGIN_Z + pointZValue : zValueReplacement);
      } else {
        pointZ = isNoData(pointZValue) ? 'NaN' : zValueReplacement;
      }
      vtpPoints.push(pointX, pointY, pointZ);
    }
  }

  const polyConnectivity: Array<number> = [];
  for (let i = 0; i < totalNumberOfPoints - NO_X_POINTS; i++) {
    if ((i + 1) % NO_X_POINTS === 0) {
      continue;
    }

    polyConnectivity.push(i);
    polyConnectivity.push(i + 1);
    polyConnectivity.push(i + NO_X_POINTS);

    polyConnectivity.push(i + 1);
    polyConnectivity.push(i + NO_X_POINTS + 1);
    polyConnectivity.push(i + NO_X_POINTS);
  }

  const vtpObject = createVtkObject(
    'vtkPolyData',
    noDataValue,
    vtpFieldData,
    vtpDataArrays,
    vtpPoints,
    undefined,
    undefined,
    undefined,
    polyOffsets,
    polyConnectivity
  );

  const vtpPolyData = vtk(vtpObject);

  if (generateXml) {
    vtpXml = createVtkXmlObject(
      totalNumberOfPoints,
      noOfPolys,
      noDataValue,
      vtpFieldData,
      vtpDataArrays,
      vtpPoints,
      polyOffsets,
      polyConnectivity
    );
  }

  return {
    // Parsed data
    vtpPoints,
    vtpZIndexData,
    vtiOrigin,
    vtiSpacing,
    vtiExtent,
    vtiFieldData: vtpFieldData,
    noDataValue,

    // Generated data
    noOfPolys,
    polyConnectivity,
    polyOffsets,
    vtpObject,
    vtpPolyData,
    vtpXml,
    appliedZAttributeName,
  };
}; // convertVtiToVtpTriangles

/**
 * Converts a vtp containing only points to a vtp with points and triangles.
 * The vtp can then be visualized as a surface.
 *
 * @param { vtkObject } parsedVtpData A parsed xml object containing points and/or CellData, PointData.
 * @param options.elevationDataArrayName A name for the DataArray that contains elevation data. Default is 'Elevation'.
 *
 * @public
 */
const convertVtpPointsToVtpTriangles = (
  parsedVtpData,
  options?: { elevationAttributeName?: EElevationDataArrayNames | string }
) => {
  // This method is only used in drawTile - which is only used in TileManager
  // - which in turn only works with the value `Elevation` - although the files
  const { elevationAttributeName = EElevationDataArrayNames.Elevation } = options || {};
  const noDataValue = self._getNoDataValue(parsedVtpData);
  const totalNumberOfPoints = parsedVtpData.getPoints().getNumberOfPoints();
  const EXTENT_X_MAX = Math.sqrt(totalNumberOfPoints);
  const EXTENT_Y_MAX = EXTENT_X_MAX;
  const vtiExtent = [0, EXTENT_X_MAX, 0, EXTENT_Y_MAX, 0, 0];

  /**
   * Read data. If usePointsAsZIndex is provided, look for the `Elevation` data array. Otherwise don't apply a Z index. It's safe to do that because the name (`Elevation`) is hardcoded in the back-end.
   */
  const vtiDataArrays = [
    ...parsedVtpData.getCellData().getArrays(),
    ...parsedVtpData.getPointData().getArrays(),
  ];

  const vtiFieldData = parsedVtpData
    .getFieldData()
    .getArrays()
    .filter((fieldDataArray) => fieldDataArray.getName() !== NO_DATA_VALUE_KEY);

  const vtpPoints: Array<number | string> = parsedVtpData.getPoints().getData();
  const noOfPolys = (EXTENT_X_MAX - 1) * (EXTENT_Y_MAX - 1) * 2;
  const polyOffsets = Array.from(Array(noOfPolys).keys()).map((item) => (item + 1) * 3);

  // This is pretty much the same as connecting points in a vti at this point. Might be worth to reuse.
  const polyConnectivity: Array<number> = [];
  for (let i = 0; i < totalNumberOfPoints - EXTENT_X_MAX; i++) {
    if ((i + 1) % EXTENT_X_MAX === 0) {
      continue;
    }

    polyConnectivity.push(i);
    polyConnectivity.push(i + 1);
    polyConnectivity.push(i + EXTENT_X_MAX);

    polyConnectivity.push(i + 1);
    polyConnectivity.push(i + EXTENT_X_MAX);
    polyConnectivity.push(i + EXTENT_X_MAX + 1);
  }

  const vtpObject = createVtkObject(
    'vtkPolyData',
    noDataValue,
    vtiFieldData,
    vtiDataArrays.concat({
      getName: () => elevationAttributeName,
      getData: () => vtpPoints.filter((_p, index) => (index + 1) % 3 === 0),
    }), // Treat the 3rd value of point data as Elevation data by default. This allows visualizing elevation or appling a gradient on the elevation attribute.
    vtpPoints,
    undefined,
    undefined,
    undefined,
    polyOffsets,
    polyConnectivity
  );

  const vtpPolyData = vtk(vtpObject);

  return {
    // Parsed data
    vtpPoints,
    vtiExtent,
    vtiFieldData,
    noDataValue,

    // Generated data
    noOfPolys,
    polyConnectivity,
    polyOffsets,
    vtpObject,
    vtpPolyData,
  };
};

const self = {
  _getNoDataValue,
  _readVtiMetadata,

  convertVtiToVtpTriangles,
  convertVtiToVtpPoints,
  convertVtpPointsToVtpTriangles,
  convertVtiCellDataToVtpTrianglePointData,
};
export default self;
