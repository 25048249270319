/**
 * Custom interactor with predefined keyboard shortcuts.
 *
 * @module vtkEnhancedInteractor
 * @version 1.0.0
 */
import macro from '@kitware/vtk.js/macro';
import vtkRenderWindowInteractor from '@kitware/vtk.js/Rendering/Core/RenderWindowInteractor';
import Constants from '@kitware/vtk.js/Rendering/Core/InteractorStyle/Constants';
import { REPRESENTATION } from '../MikeVisualizerConstants';

const setRepresentationToSurface = (actor) => {
  actor.getProperty().setRepresentationToSurface();
  actor.getProperty().setEdgeVisibility(false);
};

const setRepresentationToWireframe = (actor) => actor.getProperty().setRepresentationToWireframe();

const setRepresentationToSurfaceWithEdges = (actor) => {
  actor.getProperty().setRepresentationToSurface();
  actor.getProperty().setEdgeVisibility(true);
};

const setRepresentationToPoints = (actor) => actor.getProperty().setRepresentationToPoints();

// vtkEnhancedInteractor methods
function vtkEnhancedInteractor(publicAPI, model) {
  // Set our className
  model.classHierarchy.push('vtkEnhancedInteractor');

  publicAPI.handleKeyPress = () => {};

  publicAPI.handleKeyUp = (callData) => {
    if (!model.currentRenderer || !callData) {
      return false;
    }

    const renderer = model.currentRenderer;
    const renderWindow = renderer.getRenderWindow();
    const actors = renderer.getActors();

    if (callData.altKey && callData.shiftKey) {
      switch (callData.key) {
        case 'w':
        case 'W':
          actors.forEach(setRepresentationToWireframe);
          renderWindow.render();
          break;

        case 's':
        case 'S':
          actors.forEach(setRepresentationToSurface);
          renderWindow.render();
          break;

        case 'e':
        case 'E':
          actors.forEach(setRepresentationToSurfaceWithEdges);
          renderWindow.render();
          break;

        case 'p':
        case 'P':
          actors.forEach(setRepresentationToPoints);
          renderWindow.render();
          break;

        case 'r':
        case 'R':
          renderer.resetCamera();
          renderWindow.render();
          break;

        case 'v':
        case 'V': {
          // Based on the first actor's representation, rotate through representations using this key.
          const currentRepresentation = actors[0]
            ? actors[0].getProperty().getRepresentation()
            : REPRESENTATION.SURFACE_WITH_EDGES;
          const currentEdgeVisibility = actors[0]
            ? actors[0].getProperty().getEdgeVisibility()
            : true;

          const isCurrentSurfaceWithEdges =
            currentRepresentation === REPRESENTATION.SURFACE_WITH_EDGES.representation &&
            currentEdgeVisibility === true;
          const isCurrentSurface =
            currentRepresentation === REPRESENTATION.SURFACE.representation &&
            currentEdgeVisibility === false;
          const isCurrentWireframe =
            currentRepresentation === REPRESENTATION.WIREFRAME.representation;
          const isCurrentPoints = currentRepresentation === REPRESENTATION.POINTS.representation;

          if (isCurrentSurfaceWithEdges) {
            actors.forEach(setRepresentationToWireframe);
          }
          if (isCurrentWireframe) {
            actors.forEach(setRepresentationToSurface);
          }
          if (isCurrentSurface) {
            actors.forEach(setRepresentationToPoints);
          }
          if (isCurrentPoints) {
            actors.forEach(setRepresentationToSurfaceWithEdges);
          }

          renderWindow.render();

          break;
        }

        default:
          break;
      }
    }

    return true;
  };
}

const DEFAULT_VALUES = {};

export function extend(publicAPI, model, initialValues = {}) {
  Object.assign(model, DEFAULT_VALUES, initialValues);

  // Inheritance
  vtkRenderWindowInteractor.extend(publicAPI, model, initialValues);

  // Object specific methods
  vtkEnhancedInteractor(publicAPI, model);
}

export const newInstance = macro.newInstance(extend, 'vtkEnhancedInteractor');
export default Object.assign({ newInstance, extend }, Constants);
