/** @jsxImportSource @emotion/react */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { css } from '@emotion/react';
import React, { useState } from 'react';
import { Typography } from '@mui/material';
import {
  projectContentColumns,
  firstTableCellRender,
} from '../MikeProjectContentTable/projectContentColumns';
import { IColumn } from '../TableComponents/support';
import { IGetProject, IGetProjectPath } from '../../model/IGetProject';
import { IGetDataset } from '../../model/IGetDataset';
import MikeProjectBreadcrumpsNav from '../MikeProjectBreadcrumpsNav';
import { ICreateMeshDataset } from '../../reducers/createMesh';
import mikeSharedTheme, { mikePalette } from '../../styles/mikeSharedTheme';
import MikeProjectContentTable from '../MikeProjectContentTable';

export type MikeProjectExplorerClassKey = 'root' | 'placeHolder' | 'noContent';

const headerContainerStyle = (topOffset = 0) => {
  const top = topOffset;
  const color = mikePalette.background.default;
  return css`
    position: sticky;
    top: ${top};
    z-index: 1;
    backgroundcolor: ${color};
  `;
};
const tableContainerStyle = (topOffset = 0) => {
  const top = topOffset;
  const color = mikePalette.background.paper;
  return css`
    position: sticky;
    top: ${top};
    backgroundcolor: ${color};
  `;
};


const rootStyle = css`
      padding-top: ${mikeSharedTheme.spacing(0)};
      padding-left: ${mikeSharedTheme.spacing(0)};
      padding-right: ${mikeSharedTheme.spacing(0)};
      width: 100%;
      background-color: ${mikeSharedTheme.palette.background.default};
`;
    const placeHolderStyle = css`
      height: ${mikeSharedTheme.spacing(2)};
      z-index: 1;
      background-color: ${mikeSharedTheme.palette.background.default};
`;
    const noContentStyle = css`
      background-color: ${mikeSharedTheme.palette.background.paper};
      height: 10vh;
      display: flex;
      justify-content: center;
      padding-top: ${mikeSharedTheme.spacing(2)};
`;


interface IProps {
  /**
   * Column configuration.
   * @default projectContentColumns imported by MikeProjectContentTable
   */
  columns?: Array<IColumn>;
  /**
   * Column first column renderer.
   * @default customFirstTableCellRender imported by MikeProjectContentTable
   */
  customFirstTableCellRender?: (
    item: IGetProject | IGetDataset,
    isSelectable: boolean,
    isSelected: boolean,
    onClick: (
      event: any,
      item: IGetProject | IGetDataset
    ) => void
  ) => React.ReactNode;
  /**
   * If true, table rows will be shown as a skeleton.
   * @default false
   */
  loading?: boolean;
  /**
   * Label indicating empty project.
   * @default 'No content'
   */
  noContentLabel?: string;
  /**
   * Callback to paginate project's content (required when pagination is set to true).
   *
   */
  onChangePage?: (page: number) => void;
  /**
   * Callback to change number of items in paginated project content (required when pagination is set to true).
   *
   */
  onChangeRowsPerPage?: (rowsPerPage: number) => void;
  /**
   * Callback to sort project's content (required when pagination is set to true).
   *
   */
  onHandleRequestSort: (
    orderBy: string | ((item: IGetProject | IGetDataset) => string | number),
    order: 'asc' | 'desc'
  ) => void;
  /**
   * Callback to fetch nested or parent project content.
   *
   */
  onItemClick: (item: IGetProject | IGetDataset | IGetProjectPath) => void;
  /**
   * Callback with selected items.
   *
   */
  onSelectionChange: (selectedItems: Array<IGetProject | IGetDataset>) => void;
  /**
   * Order direction.
   *
   */
  order: 'asc' | 'desc';
  /**
   * Order field or function.
   *
   */
  orderBy: string | ((item: IGetProject | IGetDataset) => string | number);
  /**
   * If true, table includes pagination component - requires totalCount to be set
   * @default false
   */
  pagination?: boolean;
  /**
   * Current page (required when pagination is set to true).
   *
   */
  page?: number;
  /**
   * Current project.
   *
   */
  project?: IGetProject;
  /**
   * Content of current project.
   *
   */
  projectContent: Array<IGetProject | IGetDataset>;
  /**
   * Path of current project.
   *
   */
  projectPath: Array<IGetProjectPath>;
  /**
   * Rows per page (required when pagination is set to true).
   *
   */
  rowsPerPage?: number;
  /**
   * If true, first table row includes a checkbox to select datasets
   * @default false
   */
  selectable: boolean;
  /**
   * Callback to set selected datasets (required when selectable is set to true)
   *
   */
  selectedItems: Array<IGetProject | IGetDataset |  ICreateMeshDataset>;
  /**
   * Total count of project's content (required when pagination is set to true).
   *
   */
  totalCount?: number;
}

/**
 * @name MikeProjectExplorer
 * @summary MikeProjectExplorer enables to navigate inside a project and optionally to select datasets.
 * @summary MikeProjectExplorer is ment to be placed into a wrapper, e.g. MikeProjectExplorerDialog.
 * @summary Current project content has to be managed by the consuming application.
 *
 * @param props
 */
const MikeProjectExplorer = (props: IProps) => {
  const {
    columns,
    customFirstTableCellRender,
    loading = false,
    noContentLabel = 'No content yet',
    onChangePage,
    onChangeRowsPerPage,
    onHandleRequestSort,
    onItemClick,
    onSelectionChange,
    order,
    orderBy,
    page,
    pagination,
    project,
    projectContent,
    projectPath,
    rowsPerPage,
    selectable,
    selectedItems,
    totalCount,
  } = props;

  const [topOffset, setTopOffset] = useState(0);
  const measuredRef = React.useCallback((node) => {
    if (node !== null) {
      setTopOffset(node.getBoundingClientRect().height + 16);
    }
  }, []);

  const projContentColumns = columns ? columns : projectContentColumns;
  const firstCellRenderer = customFirstTableCellRender ? customFirstTableCellRender : firstTableCellRender
  const pathWithoutRecentProject =
    project && projectPath && projectPath.length > 1
      ? projectPath.filter((path: IGetProjectPath) => path.id !== project.id)
      : Array<IGetProjectPath>();

  return (
    <div css={rootStyle}>
      <div css={headerContainerStyle(0)} ref={measuredRef}>
        <MikeProjectBreadcrumpsNav
          breadcrumpsContent={pathWithoutRecentProject}         
          currentProjectName={project ? project.name : undefined}
          onClickCallBack={onItemClick}
        />
      </div>
      <div css={placeHolderStyle} />
      {!loading && projectContent.length === 0 ? (
        <div css={noContentStyle}>
          <Typography>{noContentLabel}</Typography>
        </div>
      ) : (
        <div css={tableContainerStyle(topOffset)}>
          <MikeProjectContentTable
            firstTableCellRender={firstCellRenderer}
            selectedItems={selectedItems}
            onSelectionChange={onSelectionChange}
            onItemClick={onItemClick}
            data={projectContent}
            loading={loading}
            topOffset={topOffset}
            columns={projContentColumns as any}
            onChangePage={onChangePage}
            onChangeRowsPerPage={onChangeRowsPerPage}
            onHandleRequestSort={onHandleRequestSort}
            order={order}
            orderBy={orderBy}
            pagination={pagination}
            page={page}
            rowsPerPage={rowsPerPage}
            selectable={selectable}
            totalCount={totalCount}
          />
        </div>
      )}
    </div>
  );
};

export default MikeProjectExplorer;
