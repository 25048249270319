/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import theme from './../../styles/mikeSharedTheme';
import React from 'react'
import {
  Popover,
  Typography
} from '@mui/material'
import ButtonBase from '@mui/material/ButtonBase'
import Info from '../../icons/Info.svg?react';
import { iconPrimaryStyle, iconSecondaryStyle } from '../../styles/iconStyles'

const buttonStyle = (disabled: boolean) => {
  return css`
    cursor: ${disabled} ? 'default' : 'pointer';
  `;
} 

const popoverStyle = css`
  max-width: 75vw;
`;

const contentStyle = css`
  padding: ${theme.spacing(2)};
`;

interface IProps {
  helpTexts?: string[];
  disabled?: boolean;
  primary?: boolean;
  content?: React.ReactNode;
  small?: boolean;
}

const ContextHelp = (props: IProps) => {
 
  const { content, helpTexts, disabled = false, primary = false, small = false } = props

  const [anchorEl, setAnchorEl] = React.useState(null)

  const handleClick = event => {
    if (!disabled){
      setAnchorEl(event.currentTarget)
    }
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'context-help' : undefined

  return (
    <>
      <ButtonBase
        css={buttonStyle(disabled)}
        aria-describedby={id}
        onClick={handleClick}
      >
        {small ? <Info width={30} height={30} viewBox={"0 0 40 40"} css={primary ? iconPrimaryStyle(disabled) : iconSecondaryStyle(disabled)}  /> :
        <Info css={primary ? iconPrimaryStyle(disabled) : iconSecondaryStyle(disabled)}  />}
        
      </ButtonBase>
  
      <Popover     
        css={popoverStyle}
        id={id} 
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
      >
        <div css={contentStyle}>
          {helpTexts
            ? helpTexts.map((helpText, index) => {
            return <Typography key={index}>{helpText}</Typography>
          }) : content}
        </div>
      </Popover>
    </> 
  )
}

export default ContextHelp
