/**
 * Extends vtkRenderer to allow moving individual actors to the start of the props array.
 *
 * @module vtkEnhancedRenderer
 * @version 1.0.0
 */
import macro from '@kitware/vtk.js/macro.js';
import vtkRenderer from '@kitware/vtk.js/Rendering/Core/Renderer';

const { vtkErrorMacro } = macro;

/**
 * Checks if an actor has an id and if the id matches the provided value.
 *
 * @param { string } actorId
 *
 * @return { Function } A function that given an actor, checks if its id matches the provided actorId.
 */
const doesActorHaveId = (actorId) => (actor) =>
  actor.getActorId ? actor.getActorId() === actorId : false;

// vtkEnhancedRenderer methods
function vtkEnhancedRenderer(publicAPI, model) {
  // Set our className
  model.classHierarchy.push('vtkEnhancedRenderer');

  /**
   * Moves an ehnanced actor to the top.
   * Essentially, removes the actor and adds it back to the renderer, on top of all other actors.
   * Requires a render() from the method that calls it, otherwise the visualization won't be updated.
   *
   * @todo this will fail if there are props that are NOT enhanced actors.
   *
   * @param {string} actorId The actor id to move to top.
   */
  publicAPI.moveEnhancedActorToTop = (actorId) => {
    try {
      // Find the actor in question
      const actorToMoveIndex = model.props.findIndex(doesActorHaveId(actorId));

      // Only move actors if they aren't already the first in the props array.
      if (actorToMoveIndex !== 0) {
        // This is the most efficient way I could come up with in order to move an actor to the top.
        // In essence, the actor in question is first removed then added back to model.props.
        // To achieve this, a copy is required, otherwise the renderer won't see a difference between removing & adding, even with a forced render.
        const actorToMove = model.props.find(doesActorHaveId(actorId));
        const actorCopy = {
          ...model.props.find(doesActorHaveId(actorId)),
        };

        // Remove actor from props & render.
        // NB: This still requires a render from the method that calls moveEnhancedActorToTop().
        publicAPI.removeActor(actorToMove);
        model.props.unshift(actorCopy);
      }
    } catch (e) {
      vtkErrorMacro('failed to move actor', e);
    }
  };

  /**
   * Adds an actor as the bottom layer of the renderer.
   * Note: the default addActor() behaviour is to add actors to the top.
   *
   * @param { vtkEnhancedActor } actor
   */
  publicAPI.addEnhancedActorToBottom = (actor) => {
    if (actor && !publicAPI.hasViewProp(actor)) {
      model.props = [actor, ...model.props];
    }
  };

  /**
   * Removes an actor by id instead of making a direct comparison (alternative to vtkViewport.removeViewProp()).
   * This is useful when actors get copied around and are not equal (===).
   * Requires a render() from the method that calls it, otherwise the visualization won't be updated.
   *
   * @todo this will fail if there are props that are NOT enhanced actors.
   *
   * @param {string} actorId The actor id to remove.
   */
  publicAPI.removeEnhancedActorById = (actorId) => {
    model.props = model.props.filter((actor) =>
      actor.getActorId ? actor.getActorId() !== actorId : false
    );
  };
}

// Object factory
const DEFAULT_VALUES = {};

export function extend(publicAPI, model, initialValues = {}) {
  Object.assign(model, DEFAULT_VALUES, initialValues);

  // Inheritance
  vtkRenderer.extend(publicAPI, model, initialValues);

  // Object specific methods
  vtkEnhancedRenderer(publicAPI, model);
}

export const newInstance = macro.newInstance(extend, 'vtkEnhancedRenderer');
export default Object.assign({ newInstance, extend });
