import { useEffect, useState, useCallback } from 'react'
import { Route, Routes  } from 'react-router-dom'
import RootProjects from '../RootProjects'
// import Project from '../Project'
import AppLayout from '../AppLayout'
import { IState } from '../../reducers'
import { setIsAuthenticated, setUser } from '../../actions/auth'
import { IError } from '../../model/IError'
import { useDispatch, useSelector } from 'react-redux'
import { removeError } from '../../actions/errors'
import { removeMessage } from '../../actions/message'
import './app.css';
import AuthenticationCallback from './login/AuthenticationCallback';
import { useOidc, useOidcIdToken } from '@axa-fr/react-oidc';
import { IUser } from '../../model/IUser'
import LandingPage from './login/LandingPage'
import { useLocation } from 'react-router-dom'
import qs from 'qs'
import Typography from '@mui/material/Typography';
import Folders from '../Folder'
import MikeSnackbar from '../mike-snackbar'
import Project from '../Project'

const App = () => {
  const { isAuthenticated, login } = useOidc();
  const { idToken, idTokenPayload } = useOidcIdToken();
  const dispatch = useDispatch(); 
  const { search, pathname } = useLocation()

  const errors: IError[] = useSelector(
    (state: IState) => state.errors.errors
  );

  const message: string = useSelector(
    (state: IState) => state.message.message
  );

  const user: IUser | null = useSelector(
    (state: IState) => state.auth.user
  );

  const [lastError, setLastError] = useState<IError | null>(null)

  useEffect(() => {
    if (document.location.hostname.endsWith('mike-cloud.com')) {
      const _paq = (window._paq = window._paq || []);
      /* tracker methods like "setCustomDimension" should be called before "trackPageView" */
      _paq.push(['setDocumentTitle', document.location.hostname + '/' + document.title]);
      _paq.push(['setCookieDomain', document.location.hostname]);
      _paq.push(['setDomains', [document.location.hostname]]);
      _paq.push(['trackPageView']);
      _paq.push(['enableLinkTracking']);
      const u = 'https://dhigroup.matomo.cloud/';
      _paq.push(['setTrackerUrl', u + 'matomo.php']);
      _paq.push(['setSiteId', '8']);
      const d = document;
      const g = d.createElement('script');
      const s = d.getElementsByTagName('script')[0];
      g.async = true;
      g.src = '//cdn.matomo.cloud/dhigroup.matomo.cloud/matomo.js';
      if (s.parentNode){
        s.parentNode.insertBefore(g, s);
      }      
    }
  }, []);
 
  useEffect(
    () => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      function getUserProfile(userData: any) {
        const user: IUser = {
          id: userData.oid,
          tenantId: userData.TenantId,
          tenantName: userData.TenantName,
          name: userData.sub,
          initials: userData.sub
            .split(' ')
            .map((n) => n[0])
            .join(''),
          email: userData.email,
          roles: userData['http://schemas.microsoft.com/ws/2008/06/identity/claims/role']
            ? [userData['http://schemas.microsoft.com/ws/2008/06/identity/claims/role']]
            : [],
        };
        return user;
      }
      if (idToken) {
        const userProfile = getUserProfile(idTokenPayload);
        dispatch(setUser(userProfile));
      }
    },
    [dispatch, idToken, idTokenPayload],
  );

  useEffect(() => {
    if (user) {
      if (search) {
        const pathAndSearch = search.length > 1 ? pathname + search : pathname
        const parameters = qs.parse(search.replace('?', '')) || {}
        if (parameters.tenantId && parameters.tenantId !== user.tenantId) {
          const extras = {
            prompt: 'none',
            tenantId: parameters.tenantId.toString()
          }
          login(pathAndSearch, extras)
        }
      }
      const _paq = (window._paq = window._paq || []);
      _paq.push(['setUserId', user.email]);
      _paq.push(['trackPageView']);
      _paq.push(['enableHeartBeatTimer']);
    }
  }, [user, login, search, pathname])

  // When logged in we can fetch data categories and
  // the applications the user has access to
  useEffect(() => {
    if (isAuthenticated) {
      dispatch(setIsAuthenticated(isAuthenticated))          
    } 
   }, [dispatch, isAuthenticated]);

  useEffect(() => {
    const count = errors ? errors.length : 0
    setLastError(count > 0 ? errors[count - 1] : null)
  }, [errors])

  const onRemoveError = useCallback(() => {
    if (lastError && lastError.id){
      dispatch(removeError(lastError.id))
    }  
  }, [lastError,  dispatch])

  const onCloseMessage = useCallback(() => {
    dispatch(removeMessage())
  }, [dispatch])

  const showErrors = (errors: Array<string>) => {
    return (
      <div>
        {errors.map((error: string, i: number) => (
          <Typography variant="body2" key={i}>
            {error}
          </Typography>
        ))}
      </div>
    )
  }
 
  return isAuthenticated ? (   
    < >
      <MikeSnackbar
        open={!!lastError}
        mikeSnackbarType={'error'}
        message={lastError && lastError.messages && lastError.messages.length > 1 ? showErrors(lastError.messages) : lastError && lastError.message}
        action={'OK'}
        mikeOnAction={onRemoveError}
        onClose={onRemoveError}
        autoHideDuration={null}
    />

    <MikeSnackbar
      open={!!message}
      mikeSnackbarType={'regular'}
      message={message}
      action={'OK'}
      mikeOnAction={onCloseMessage}
      onClose={onCloseMessage}
    />

    <AppLayout>               
      <Routes>
        <Route path='/authentication/callback' element={<AuthenticationCallback/>} />
        <Route path='/project/:id/initialselection' element={<Project/>} />
        <Route path='/project/:id/points' element={<Project/>} /> 
        <Route path='/project/:id/createmesh' element={<Project/>} /> 
        <Route path='/project/:id/boundaryconditions' element={<Project/>} />  
        <Route path='/project/:id/control' element={<Project/>} />  
        <Route path='/project/:id/setup' element={<Project/>} /> 
        <Route path='/project/:id' element={<Folders/>} />
        <Route path='/' element={<RootProjects/>} />  
      </Routes>
    </AppLayout>  
    </>) : ( 
      <LandingPage/> 
  )
}

export default App
