/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import MIKE_COLORS from '../../styles/mike-colors';

// TO(masm): investigate why this does not overrider Mui theme, and inject the css on the picker
export const selectStyle = css`
   {
    color: ${MIKE_COLORS.BRANDBLUE_DEFAULT} !important;
    background-color: ${MIKE_COLORS.MEDIUMGREY_LIGHT} !important;
    height: 33px !important;
    width: 113px !important;
    font-weight: bold !important;
    font-size: 14px !important;
    margin-left: 12px !important;
  }
`;

export const MikeDatePickerStyles = css`
  .date-pickers-container {
    z-index: 9999, .react-datepicker {
      font-family: Roboto;
      background-color: white;
      color: ${MIKE_COLORS.BRANDBLUE_DEFAULT};
      display: inline-block;
      position: relative;
      width: 280px;
      height: 400px;
      border: none;
      box-shadow: 5px 0 5px -5px #333, 0 5px 5px -5px #333;
    }

    .react-datepicker__time-name {
      color: ${MIKE_COLORS.BRANDBLUE_DEFAULT};
      display: inline-block;
      line-height: 1.7rem;
      text-align: center;
      width: 2rem;
      height: 2rem;
      font-size: 16px;
    }

    .react-datepicker__day-name {
      color: black;
      display: table-cell;
      vertical-align: middle;
      line-height: 1.7rem;
      text-align: center;
      width: 40px;
      padding: 0;
      font-size: 12px;
      font-family: Roboto;
    }

    .react-datepicker__day-names {
      margin-top: 20px;
    }

    .react-datepicker__day {
      color: ${MIKE_COLORS.BRANDBLUE_DEFAULT};
      line-height: 22px;
      text-align: center;
      width: 40px;
      height: 40px;
      margin: 0;
      padding: 0;
      font-size: 16px;
      font-family: Roboto;
      display: table-cell;
      vertical-align: middle;
    }

    .react-datepicker__day--outside-month {
      color: ${MIKE_COLORS.MEDIUMGREY_DEFAULT};
    }
    .react-datepicker__month-container {
      width: 100%;
      height: 100%;
    }

    .react-datepicker__month {
      margin: 0;
    }

    .react-datepicker-popper {
      z-index: 12;
    }

    .react-datepicker__header {
      background-color: white;
    }

    .react-datepicker__day--disabled {
      color: lightgrey !important;
    }

    .react-datepicker__day--selected,
    .react-datepicker__day--in-selecting-range,
    .react-datepicker__day--in-range,
    .react-datepicker__month-text--selected,
    .react-datepicker__month-text--in-selecting-range,
    .react-datepicker__month-text--in-range,
    .react-datepicker__quarter-text--selected,
    .react-datepicker__quarter-text--in-selecting-range,
    .react-datepicker__quarter-text--in-range {
      border-radius: 0.3rem;
      background-color: ${MIKE_COLORS.BRANDBLUE_DEFAULT};
      color: #fff;
      border: none;
    }
  }
`;
