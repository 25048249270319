/** @jsxImportSource @emotion/react */
import mikeSharedTheme from './mikeSharedTheme';
import { css } from '@emotion/react'

export const formControlStyle = 
  css`
    min-width: ${mikeSharedTheme.spacing(15)}; 
  `;

export const getInputStyle = (noLabel = true) => {
  if (noLabel){
    return {
      '& .MuiInputBase-input': {
        paddingTop: mikeSharedTheme.spacing(1.5),
        paddingBottom: mikeSharedTheme.spacing(0.75)
      }
   
    }
  }
  else{
    return {
      '& .MuiInputBase-input': {
      paddingTop: mikeSharedTheme.spacing(2.25),
      paddingBottom: mikeSharedTheme.spacing(0)
    }
  }
    
  }
}

export const inputStyle = 
  css`
    padding-top: ${mikeSharedTheme.spacing(2.25)}; 
    padding-bottom: ${mikeSharedTheme.spacing(0)}; 
  `;

export const inputNoLabelStyle = 
  css`
    padding-top: ${mikeSharedTheme.spacing(1.5)}; 
    padding-bottom: ${mikeSharedTheme.spacing(0.75)}; 
  `;

export const iconDisabledStyle = 
  css`
    fill: ${mikeSharedTheme.palette.mediumGrey?.main}; 
  `;

export const iconErrorStyle = 
  css`
    fill: ${mikeSharedTheme.palette.error.main}; 
  `;

export const iconHiddenStyle = 
  css`
    opacity: 0; 
  `;

export const menuListStyle =  
  css`
    background-color: ${mikeSharedTheme.palette.mediumGrey?.light}; 
  `;

export const primaryDisabledStyle = {
  color: mikeSharedTheme.palette.mediumGrey?.main 
} 

 export const primarySelectedStyle = {
  color: mikeSharedTheme.palette.primary.main, 
  fontWeight: mikeSharedTheme.typography.fontWeightBold
 }
 

export const helperTextPlaceHolderStyle = 
  css`
    min-height: ${mikeSharedTheme.spacing(2)}; 
    background-color: transparent;
  `;

  export const menuItemStyle = 
    css`
      display: flex; 
      align-items: center;
    `;
