import { IAction } from '../actions/Action';
import ActionType from '../actions/ActionType';

import { TRANSFER_STATUSES } from '../apis/transfer'

export interface IImportItem{
  id: string;
  projectId: string;
  createdBy: string;
  status: TRANSFER_STATUSES;
  data: object
  error: null,
  progress: number,
  initialId: string;
}

export interface IImportState { 
  importItems: Array<IImportItem>;
}

const initState = {  
  importItems: new Array<IImportItem>()
}


const updateImportItem = (items: IImportItem[], item: IImportItem, id: string) => {
  const remainingItems = items.filter((item: IImportItem) => item.id !== id)  
  return [...remainingItems, item];
};

const geItem = (items: IImportItem[], id: string) => {
  return items.find((item: IImportItem) => item.id === id)
};

const imports = (state = initState, action: IAction) => {
  
  switch (action.type) {
    case ActionType.IMPORT_START: {
      const item = {
        id: action.data.id,
        projectId: action.data.projectId,
        createdBy: action.data.createdBy,
        status: action.data.state,
        data: action.data.data,
        error: null,
        progress: 0,
        initialId: action.data.initialId
      }

      return {
        ...state, importItems: [...state.importItems, item]
     }
    }     
      
    case ActionType.UPDATE_PROGRESS: {     
      const item = geItem(state.importItems, action.data.id) 
      if (item !== undefined){
        return {
          ...state,        
          importItems: updateImportItem(state.importItems,{...item, progress: action.data.progress}, action.data.id)
        }; 
      }
      return {
        ...state
      }; 
    }
    case ActionType.IMPORT_COMPLETE: {     
      const item = geItem(state.importItems, action.data.id) 
      if (item !== undefined){
        return {
          ...state,        
          importItems: updateImportItem(state.importItems,{...item, status: TRANSFER_STATUSES.COMPLETED}, action.data.id)
        }; 
      }
      return {
        ...state
      };   
    }
    case ActionType.IMPORT_FAIL: {
      const item = geItem(state.importItems, action.data.id) 
      if (item !== undefined){
        return {
          ...state,        
          importItems: updateImportItem(state.importItems,{...item, status: TRANSFER_STATUSES.ERROR,  error: action.data.error}, action.data.id)
        }; 
      }
      return {
        ...state
      }; 
    }     
          
    default:
        return state;
  }
};

export default imports

