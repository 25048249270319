const REPRESENTATION_MODEL = {
  POINTS: 0,
  WIREFRAME: 1,
  SURFACE: 2,
};

export const REPRESENTATION = {
  SURFACE: {
    edgeVisibility: false,
    representation: REPRESENTATION_MODEL.SURFACE,
  },
  WIREFRAME: {
    edgeVisibility: false,
    representation: REPRESENTATION_MODEL.WIREFRAME,
  },
  SURFACE_WITH_EDGES: {
    edgeVisibility: true,
    representation: REPRESENTATION_MODEL.SURFACE,
  },
  POINTS: {
    edgeVisibility: false,
    representation: REPRESENTATION_MODEL.POINTS,
  },
};

export enum EDataArrayDataTypes {
  CHAR = 'Int8Array',
  SIGNED_CHAR = CHAR,
  UNSIGNED_CHAR = 'Uint8Array',
  SHORT = 'Int16Array',
  UNSIGNED_SHORT = 'Uint16Array',
  INT = 'Int32Array',
  UNSIGNED_INT = 'Uint32Array',
  FLOAT = 'Float32Array',
  DOUBLE = 'Float64Array',
}

/**
 * A plain object is also exported with the data types in order to be used as literal values.
 * The EDataArrayDataTypes enum can only be used for type definitions.
 */
export const DATA_ARRAY_DATA_TYPES = {
  CHAR: EDataArrayDataTypes.CHAR,
  SIGNED_CHAR: EDataArrayDataTypes.SIGNED_CHAR,
  UNSIGNED_CHAR: EDataArrayDataTypes.UNSIGNED_CHAR,
  SHORT: EDataArrayDataTypes.SHORT,
  UNSIGNED_SHORT: EDataArrayDataTypes.UNSIGNED_SHORT,
  INT: EDataArrayDataTypes.INT,
  UNSIGNED_INT: EDataArrayDataTypes.UNSIGNED_INT,
  FLOAT: EDataArrayDataTypes.FLOAT,
  DOUBLE: EDataArrayDataTypes.DOUBLE,
};

export enum EDataArrayTypes {
  CELLDATA = 'CellData',
  POINTDATA = 'PointData',
}

/**
 * A plain object is also exported with the data array types in order to be used as literal values.
 * The EDataArrayTypes enum can only be used for type definitions.
 */
export const DATA_ARRAY_TYPES = {
  CELLDATA: EDataArrayTypes.CELLDATA,
  POINTDATA: EDataArrayTypes.POINTDATA,
};

export const SUPPORTED_VTK_FORMATS = {
  VTP: 'PolyData',
  VTI: 'ImageData',
};

export const SUPPORTED_VTK_CLASSES = {
  VTP: 'vtkPolyData',
  VTI: 'vtkImageData',
};

export const MAP_IDS = {
  BASEMAP_CONTAINER_ID: 'viewerBaseMap',
  DRAWMAP_CONTAINER_ID: 'viewerDrawMap',
  DATAMAP_CONTAINER_ID: 'viewerDataMap',
  MEASUREMAP_CONTAINER_ID: 'viewerMeasureMap',
  VTK_CONTAINER_ID: 'viewerVtk',
};

export const VIEWER_ZINDEX = {
  MEASUREMAP: 4,
  DRAWMAP: 3,
  DATAMAP: 2,
  THREED_VIEWER: 1,
  BASEMAP: -1,
};
