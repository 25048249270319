
import ActionType from "../actions/ActionType"
import { IAction } from "../actions/Action"
import { IApplications } from "../components/mike-subscribed-apps/model/IApplications";

export interface IAppsState {
  loading: boolean;
  applications: IApplications;
}

const initState = {
  loading: false,  
  applications: null
}

const applications = (state = initState, action: IAction) => {
  switch (action.type) {
    case ActionType.LOADING_APPS:
      return { ...state, loading: action.data };
    case ActionType.SET_APPS: {     
      return {
        ...state,
        applications: action.data,
        loading: false
      }; 
    }
          
    default:
        return state;
  }
};

export default applications