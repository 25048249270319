/***
 * Like FullScreenRenderWindow, but without some of the 'control' methods.
 * This module makes use of the vtkEnhancedRenderer.
 *
 * @module vtkBasicFullScreenRenderWindow
 * @version 1.0.0
 */
import { debounce } from 'lodash-es';
// Load the rendering pieces we want to use (for both WebGL and WebGPU)
import '@kitware/vtk.js/Rendering/Profiles/Geometry';
import macro from '@kitware/vtk.js/macro';
import vtkOpenGLRenderWindow from '@kitware/vtk.js/Rendering/OpenGL/RenderWindow';
import vtkEnhancedRenderer from './vtkEnhancedRenderer';
import vtkEnhancedInteractor from './vtkEnhancedInteractor';
import vtkRenderWindow from '@kitware/vtk.js/Rendering/Core/RenderWindow';

import vtkInteractorStyleTrackballCamera from '@kitware/vtk.js/Interaction/Style/InteractorStyleTrackballCamera';
import vtkInteractorStyleManipulator from '@kitware/vtk.js/Interaction/Style/InteractorStyleManipulator';
import vtkInteractionPresets from '@kitware/vtk.js/Interaction/Style/InteractorStyleManipulator/Presets';

// Load basic classes for vtk() factory
import '@kitware/vtk.js/Common/Core/Points';
import '@kitware/vtk.js/Common/Core/DataArray';
import '@kitware/vtk.js/Common/DataModel/PolyData';
import '@kitware/vtk.js/Rendering/Core/Actor';
import '@kitware/vtk.js/Rendering/Core/Mapper';

import MikeVisualizerStore from '../store/MikeVisualizerStore';
import MikeVisualizerUtil from '../MikeVisualizerUtil';
import { getEmitters } from '../MikeVisualizerEvents';
import { REPRESENTATION } from '../MikeVisualizerConstants';
import { VIEWER_MODES } from '../IMikeVisualizerModels';

const { vtkErrorMacro } = macro;
const { getState } = MikeVisualizerStore;
const { rendererReady, getRelativeZoom } = MikeVisualizerUtil;

const initialActorRepresentations = {};

const CONTAINER_STYLE = {
  margin: '0',
  padding: '0',
  position: 'absolute',
  top: '0',
  left: '0',
  width: '100vw',
  height: '100vh',
  overflow: 'hidden',
};

const BIG_ELEMENT_COUNT = 500000;
const END_INTERACTION_DEBOUNCE = 1500;
const RELATIVE_ZOOM_LOWER_TRESHOLD = 0.25;

/**
 * Switches between interactor modes by removing the interactor style & re-binding defaults.
 * NB: there seems to only be one immutable instance of an interactor. Replacing an interactor with a new instance does not seem to complete reset interactions. It could also be that this behaviour is do to hanging event listeners (should be investiaged, see TODO below).
 *
 * TODO dan: check if events are stacking. Previous events might still be subscribed because `bindInteractionEvents` does not keep track of them.
 *
 * @param { string } mode
 */
export const resetInteractor = (mode = VIEWER_MODES.THREE_D) => {
  const { fullScreenRenderWindow } = getState();
  const renderWindow = fullScreenRenderWindow.getRenderWindow();
  const container = fullScreenRenderWindow.getContainer();
  const interactor = renderWindow.getInteractor();

  interactor.setInteractorStyle(null);
  bindInteractorEvents(interactor, container);

  if (mode === VIEWER_MODES.THREE_D) {
    setInteractorTo3D(interactor);
  } else {
    setInteractorToSlippyMap(interactor);
  }  
};

/**
 * Bind default interactor events.
 *
 * NB: binding an event returns an `unsubscribe` method for each of the definitions below.
 * Modules consuming this method can use the return value to unbind events when done.
 *
 * @param { vtkEnhancedInteractor } interactor
 * @param { HTMLElement | ChildNode } container A DOM Node that should capture interactions.
 *
 * @return { Array<Function> } An array of unsubscribe functions.
 *
 * @public
 */
export const bindInteractorEvents = (interactor, container) => {
  const debouncedEndInteraction = debounce(endInteraction, END_INTERACTION_DEBOUNCE); // NB: it's important to debounce interaction end because there can be small pauses (< 1000ms) between user scroll / press. It's too expensive to apply the callback in between user interactions.

  interactor.bindEvents(container);

  // Listen to events and fire start or end interaction callbacks for both mouse & touch.
  return [
    interactor.onStartMouseWheel(startInteraction),
    interactor.onMouseWheel(toggleRepresentationBasedOnZoom),
    interactor.onEndMouseWheel(debouncedEndInteraction),

    interactor.onStartPan(startInteraction),
    interactor.onEndPan(debouncedEndInteraction),

    interactor.onStartPinch(startInteraction),
    interactor.onEndPinch(debouncedEndInteraction),

    interactor.onStartRotate(startInteraction),
    interactor.onEndRotate(debouncedEndInteraction),

    interactor.onLeftButtonPress(startInteraction),
    interactor.onLeftButtonRelease(debouncedEndInteraction),

    interactor.onMiddleButtonPress(startInteraction),
    interactor.onMiddleButtonRelease(debouncedEndInteraction),

    interactor.onRightButtonPress(startInteraction),
    interactor.onRightButtonRelease(debouncedEndInteraction),
  ];
};

/**
 * Given an interactor (usually current renderWindow interactor), it sets the interaction style to a custom instance that:
 * - disabled all current manipulators.
 * - adds a custom manipulator for panning.
 * - adds a custom manipulator for zoom.
 *
 * With this in effect, the viewer behaves more like a 'traditional' map view.
 *
 * > TODO dan: some gesture (touch screen) manipulators should probably be deactivated.
 *
 * @param { vtkEnhancedInteractor } interactor
 */
const setInteractorToSlippyMap = (interactor) => {
  const interactorStyle = vtkInteractorStyleManipulator.newInstance();

  const slippyMapManipulators = [
    // pan on left mouse and shift+left mouse
    { type: 'pan', options: { button: 1 } },
    { type: 'pan', options: { button: 1, shift: true } },

    // In 2D mode we always want to zoomToMouse using  on middle mouse or scroll
    { type: 'zoomToMouse', options: { button: 2, scrollEnabled: true } },

    // Support gestures
    { type: 'gestureCamera' },

    // todo hevo could consider supporting VR
    // { type: 'vrPan' },

    // todo hevo could consider supporting keyboard - not sure how it works yet.
    //{ type: 'movement' },
  ];

  interactor.setInteractorStyle(interactorStyle);
  vtkInteractionPresets.applyDefinitions(slippyMapManipulators, interactorStyle);
};

/**
 * Given an interactor (usually current renderWindow interactor), it sets the interacton style to trackball camera.
 * This is a custom core style, used by default by vtk.js for 3D views.
 *
 * @param { vtkEnhancedInteractor } interactor
 */
const setInteractorTo3D = (interactor) => {
  interactor.setInteractorStyle(vtkInteractorStyleTrackballCamera.newInstance());
};

/**
 * Applies a style to a DOM element.
 *
 * @param { HTMLElement } el
 * @param { Object } style
 */
function applyStyle(el, style) {
  Object.keys(style).forEach((key) => {
    el.style[key] = style[key];
  });
}

/**
 * Callback for interaction start.
 * Do not do heavy tasks in here, it will be called often.
 * Prevent re-doing the same task if possible.
 */
function startInteraction() {
  if (!rendererReady()) {
    return false;
  }

  const { renderer, renderWindow, workingItems } = getState();
  const { emitWorkStarted } = getEmitters();

  const setInitialActorRepresentation = (actor, actorId) => {
    initialActorRepresentations[actorId] = {
      representation: actor.getProperty().getRepresentation(),
      edgeVisibility: actor.getProperty().getEdgeVisibility(),
      opacity: actor.getProperty().getOpacity(),
    };
  };

  const representationsChanged = renderer.getActors().map((actor) => {
    const actorId = actor.getActorId();
    const primitiveCount = actor.getMapper().getPrimitiveCount();
    const pointCount = primitiveCount.points;
    const triangleCount = primitiveCount.triangles;

    const isBigElement = pointCount > BIG_ELEMENT_COUNT || triangleCount > BIG_ELEMENT_COUNT; // Only reduce visualization cost for larger elements. For UX reasons, it's better to keep smaller elements as they are.

    if (
      !initialActorRepresentations[actorId] &&
      isBigElement &&
      getRelativeZoom() > RELATIVE_ZOOM_LOWER_TRESHOLD // Don't do any tricks lower than this zoom level, it should work fine from here on out.
    ) {
      setInitialActorRepresentation(actor, actorId);

      // Set representation to surface
      const { representation, edgeVisibility } = REPRESENTATION.SURFACE;
      const alreadyWorking = workingItems.indexOf(actorId) !== -1;

      if (!alreadyWorking) {
        // Register work started, once per actor. Registering multiple times can happen due to cluncky event handling. It's correct to only register work once per actorId.
        emitWorkStarted(actorId);
      }

      actor.getProperty().setRepresentation(representation);
      actor.getProperty().setEdgeVisibility(edgeVisibility);

      return true;
    }

    return false;
  });

  const anyRepresentationsChanged = representationsChanged.some((item) => item);

  if (anyRepresentationsChanged) {
    renderWindow.render();
  }

  return true;
}

/**
 * Callback for interaction end.
 * Do not do heavy tasks in here, it will be called often.
 * Prevent re-doing the same task if possible.
 */
function endInteraction(callData) {
  if (!rendererReady()) {
    return false;
  }

  const { renderer, renderWindow } = getState();
  const { emitWorkEnded, mouseOrTouchInteractionEnded } = getEmitters();

  mouseOrTouchInteractionEnded(callData)

  const representationsChanged = renderer.getActors().map((actor) => {
    const actorId = actor.getActorId();

    if (initialActorRepresentations[actorId]) {
      const { representation, edgeVisibility, opacity } = initialActorRepresentations[actorId];

      actor.getProperty().setRepresentation(representation);
      actor.getProperty().setEdgeVisibility(edgeVisibility);
      actor.getProperty().setOpacity(opacity);

      delete initialActorRepresentations[actorId];

      return actorId;
    }

    return false;
  });

  const anyRepresentationsChanged = representationsChanged.some((item) => item);

  if (anyRepresentationsChanged) {
    renderWindow.render();

    representationsChanged
      .filter((actorId) => actorId)
      .forEach((actorId) => emitWorkEnded(actorId));
  }

  return true;
}

/**
 * Toggles detailed representation right away if zoom exceeds the provided treshold.
 */
const toggleRepresentationBasedOnZoom = () => {
  if (getRelativeZoom() <= RELATIVE_ZOOM_LOWER_TRESHOLD) {
    endInteraction();
  }
};

/**
 * Adds a container to the model root container.
 *
 * @param { Object } model
 */
const setupContainer = (model) => {
  try {
    applyStyle(model.container, model.containerStyle || CONTAINER_STYLE);
    model.rootContainer.appendChild(model.container);
    return true;
  } catch (e) {
    vtkErrorMacro(e);
    return false;
  }
};

function vtkBasicFullScreenRenderWindow(publicAPI, model) {
  // Full screen DOM handler
  if (!model.rootContainer) {
    return vtkErrorMacro('Failed to instantiate vtk renderWindow, no root container provided.');
  }

  if (!model.container) {
    return vtkErrorMacro('Failed to instantiate vtk renderWindow, no container provided.');
  }

  if (!setupContainer(model)) {
    return vtkErrorMacro(
      'Failed to instantiate vtk renderWindow, could not attach viewer container to rootContainer.'
    );
  }

  // VTK renderWindow/renderer
  model.renderWindow = vtkRenderWindow.newInstance();
  const renderer = vtkEnhancedRenderer.newInstance();
  renderer.setBackground(0, 0, 0, 0);
  model.renderer = renderer
  model.renderWindow.addRenderer(renderer);

  // OpenGlRenderWindow
  model.openGLRenderWindow = vtkOpenGLRenderWindow.newInstance();
  model.openGLRenderWindow.setContainer(model.container);
  model.renderWindow.addView(model.openGLRenderWindow);

  // Setup interactor for 3D
  const interactor = vtkEnhancedInteractor.newInstance();
  setInteractorTo3D(interactor);
  interactor.setView(model.renderWindow.getViews()[0]);
  interactor.initialize();
  bindInteractorEvents(interactor, model.container);

  // Handle window resize
  publicAPI.resize = () => {
    const views = model.renderWindow.getViews();
    if (views){ // Fix for #40492
      const dims = model.container.getBoundingClientRect();
      const devicePixelRatio = window.devicePixelRatio || 1;
      model.openGLRenderWindow.setSize(
        Math.floor(dims.width * devicePixelRatio),
        Math.floor(dims.height * devicePixelRatio)
      );
      if (model.resizeCallback) {
        model.resizeCallback(dims);
      }
      model.renderWindow.render();
    }
  };

  publicAPI.setResizeCallback = (cb) => {
    model.resizeCallback = cb;
    publicAPI.resize();
  };

  if (model.listenWindowResize) {
    // TODO dan: this should probably be cleaned up when the viewer is destroyed.
    window.addEventListener('resize', publicAPI.resize);
  }

  publicAPI.resize();

  return true;
}

// Object factory
const DEFAULT_VALUES = {
  containerStyle: null,
  listenWindowResize: true,
  resizeCallback: null,
  container: null,
  rootContainer: null,
};

export function extend(publicAPI, model, initialValues = {}) {
  Object.assign(model, DEFAULT_VALUES, initialValues);

  // Object methods
  macro.obj(publicAPI, model);
  macro.get(publicAPI, model, [
    'renderWindow',
    'renderer',
    'openGLRenderWindow',
    'interactor',
    'rootContainer',
    'container',
  ]);

  // Object specific methods
  vtkBasicFullScreenRenderWindow(publicAPI, model);
}

export const newInstance = macro.newInstance(extend);
export default { newInstance, extend };
