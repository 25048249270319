/** @jsxImportSource @emotion/react */
import AddIcon from '@mui/icons-material/Add';
import Tooltip from '@mui/material/Tooltip';
import { ViewerToolsButtonStyle } from '../ViewerStyles';
import MikeVisualizer from '../../../MikeVisualizer/lib/MikeVisualizer';

const { zoomIn } = MikeVisualizer;

/**
 * @name ViewerToolZoomIn
 * @summary Allows zooming in.
 */
export const ViewerToolZoomIn = () => {
  return (
    <Tooltip title={'Zoom in'} placement="right">
      <button css={ViewerToolsButtonStyle} onClick={zoomIn}>
        <AddIcon />
      </button>
    </Tooltip>
  );
};

export default ViewerToolZoomIn