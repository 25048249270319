/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import mikeSharedTheme from '../../styles/mikeSharedTheme';
import React from 'react';
import { INotifications, INotificationPerDate } from './INotifications';
import MikeNotificationItemGroup from './MikeNotificationItemGroup';
import { INotification } from './INotification';
import { formatDate } from '../../mike-shared-helpers/date';
import { Typography } from '@mui/material';

interface IProps extends React.HTMLAttributes<HTMLElement> {
  /**
   * Notifications.
   */
  notifications: Array<INotification>;
  /**
   * href.
   */
  href?: string;
  /**
   * Link callback.
   */
  onLinkCallBack?: (arg0: INotification) => void;
  /**
   * Custom title.
   */
  title?: string;
  /**
   * Custom 'no content' label.
   */
  noContentLabel?: string;
  /**
   * Custom render function.
   * To display the content of a notification item.
   */
  renderContent?: (notification: INotification) => React.ReactNode;
  /**
   * Custom render function.
   * To display the title of a notification item.
   */
  renderTitle?: (notification: INotification) => React.ReactNode;
}

const notificationsTitleStyle = css`
  padding-top: 0px;
  padding-bottom: ${mikeSharedTheme.spacing(1)};
  font-weight: bold;
`;

/**
 * @name MikeNotificationGroups
 * @summary A component for grouping notification items per day.
 */
export const MikeNotificationGroups: React.FC<IProps> = ({
  notifications,
  href,
  onLinkCallBack,
  title = 'Notifications',
  noContentLabel = 'No notifications to show yet',
  renderContent,
  renderTitle,
}: IProps) => {  
  const sortedNotifications: Array<INotification> =
    notifications &&
    notifications.sort((a: INotification, b: INotification) => {
      return b.time - a.time;
    });

  // this gives an object with dates as keys
  const dailyNotifications: INotificationPerDate =
    sortedNotifications &&
    sortedNotifications.reduce((notificationsByDate, notification) => {
      const date = formatDate(notification.time);
      if (!notificationsByDate[date]) {
        notificationsByDate[date] = new Array<INotification>();
      }
      notificationsByDate[date].push(notification);
      return notificationsByDate;
    }, {});

  const notificationPerDay: Array<INotifications> =
    dailyNotifications &&
    Object.keys(dailyNotifications).map((date: string) => {
      return {
        date,
        notifications: dailyNotifications[date],
      };
    });

  return (
    <>
      <Typography css={notificationsTitleStyle} variant="h4">
        {title}
      </Typography>
      {!notificationPerDay || notificationPerDay.length === 0 ? (
        <Typography variant="body2">{noContentLabel}</Typography>
      ) : (
        notificationPerDay.map((notificationsOfTheDay: INotifications, index: number) => (
          <MikeNotificationItemGroup
            key={index}
            notificationsPerDate={notificationsOfTheDay}
            href={href}
            isFirst={index === 0}
            onLinkCallBack={onLinkCallBack}
            renderContent={renderContent}
            renderTitle={renderTitle}
          />
        ))
      )}
    </>
  );
};

export default MikeNotificationGroups;
