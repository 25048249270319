import { INotification } from "../components/mike-notification-list";
import ActionType from "./ActionType";

export const hideBlueDot = (hide = true) => ({
  type: ActionType.HIDE_BLUE_DOT,
  data: hide
})

export const setNotification = (notifications: INotification[]) => ({
  type: ActionType.SET_NOTIFICATION,
  data: notifications
})
