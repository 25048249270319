/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { mikePalette } from '../../styles/mikeSharedTheme';

export const ViewerToolsDisabledStyle  = css`
  opacity: 0.5;
  cursor: not-allowed;
`;

export const ViewerToolsButtonStyle = css`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${mikePalette.primary.main};
  width: 40px;
  height: 40px;
  border: 0;
  padding: 0;
  background: 0;
  background-color: ${mikePalette.lightGrey.main};
  transition: opacity 0.3s;
  font-size: 1.05em;
  font-weight: bold;
  position: relative;
  z-index: 1; /* Needs to be just on top of the submenu so it can be toggled off without having to choose a value. */

  &:focus,
  &:hover {
    outline: 0;
    opacity: 0.7;
    transition: opacity 0.3s;
  }

  &[disabled] {
    opacity: 0.5;
    cursor: not-allowed;
  }

  &[hidden] {
    display: none;
  }
`;

export const ViewerToolsWrapperStyle = css`
  position: absolute;
  top: 0;
  left: 0;
  z-index:9999;
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 60px);
`;

export const ViewerToolsContainerStyle = css`
  display: flex;
  flex-direction: column;
  box-shadow: 4px 0px 4px rgba(0, 0, 0, 0.16);
  padding: 0;
  list-style-type: none;
  background-color: ${mikePalette.lightGrey.main};
`;