/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import React, { useMemo } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux';
import CssBaseline from '@mui/material/CssBaseline'
import Fade from '@mui/material/Fade'
import FastWaveEmulator from '../../icons/FastWaveEmulator.svg?react';
import MikeSubscribedApps from '../mike-subscribed-apps';
import { IConfig } from "../../model/IConfig";
import { IState } from "../../reducers";
import {
    hideBlueDot as hideBlueDotAction
  } from '../../actions/notification'
import { useMatch } from 'react-router-dom';
import MikeLogin from '../App/login';
import { IUser } from '../../model/IUser';
import { cancelJob } from '../../actions/job';
import mikeSharedTheme from '../../styles/mikeSharedTheme';
import MikeProgressSpinner, { IProgressItem } from '../mike-topbar-progress-spinner';
import MikeNotificationContainer, { INotification } from '../mike-notification-list';
import MikeTopbar from '../mike-topbar';
import MikeSupportMenuContainer from '../mike-support';
import MikeHomeButton from '../mike-home-button';
import { IApplications } from '../mike-subscribed-apps/model/IApplications';
import AppBreadcrumpsNav from '../AppBreadcrumpsNav';

export interface AuditCompareRouteParams {
  id: string;
}

const messages = defineMessages({
  appTitle: { id: 'app.title' },
  supportEmailSubject: { id: 'supportEmail.subject' },
  supportEmailBody: { id: 'supportEmail.body' }
})

const rootStyle = 
  css`
    display: flex;
  `;

const contentStyle = 
  css`
    flex-grow: 1;
    height: 100vh;
    padding-bottom: ${mikeSharedTheme.spacing(0)}; 
    padding-left: ${mikeSharedTheme.spacing(0)}; 
    padding-right: ${mikeSharedTheme.spacing(0)}; 
    padding-top: ${mikeSharedTheme.spacing(7.5)}; 
    background-color: ${mikeSharedTheme.palette.lightGrey?.main};  
    overflow: auto;
  `;

const contentHideVerticalScrollStyle = 
  css`
    flex-grow: 1;
    height: 100vh;
    padding-bottom: ${mikeSharedTheme.spacing(0)}; 
    padding-left: ${mikeSharedTheme.spacing(0)}; 
    padding-right: ${mikeSharedTheme.spacing(0)}; 
    padding-top: ${mikeSharedTheme.spacing(7.5)}; 
    background-color: ${mikeSharedTheme.palette.lightGrey?.main};  
    overflow-y: hidden;
`;

interface IProps 
{
  children: React.ReactNode
}

const AppLayout = (props: IProps) => {  
  const match = useMatch('/project/:id') 
  const matchBC = useMatch('/project/:id/boundaryconditions')
  const matchControl = useMatch('/project/:id/control')
  const matchSetup = useMatch('/project/:id/setup')  
  const matchPoints = useMatch('/project/:id/points')  
  const matchCreateMesh = useMatch('/project/:id/createmesh')   
  const matchInitialSelection = useMatch('/project/:id/initialSelection')    

  const id =  match && match.params.id ? match.params.id : 
  matchBC && matchBC.params.id ? matchBC.params.id : 
  matchControl && matchControl.params.id ? matchControl.params.id : 
  matchSetup && matchSetup.params.id ? matchSetup.params.id : 
  matchCreateMesh && matchCreateMesh.params.id ? matchCreateMesh.params.id : 
  matchInitialSelection && matchInitialSelection.params.id ? matchInitialSelection.params.id : 
  matchPoints && matchPoints.params.id ? matchPoints.params.id :null

  const { children } = props

  const intl = useIntl()

  const dispatch = useDispatch();

  const config: IConfig = useSelector((state: IState) => state.auth.config);

  const apps: IApplications = useSelector((state: IState) => state.applications.applications);

  const loadingApps: boolean = useSelector((state: IState) => state.applications.loading);

  const progressItems: IProgressItem[] = useSelector((state: IState) => state.progressItems);

  const hideBlueDot = useSelector((state: IState) => state.notification.hideBlueDot);

  const user: IUser | null = useSelector((state: IState) => state.auth.user);

  const notifications: INotification[] = useSelector(
    (state: IState) => state.notification.notification
  );

  const isMapRoute = useMemo(() => {
    return matchInitialSelection || matchCreateMesh || matchPoints || matchBC || matchControl || matchSetup
  }, [matchBC, matchControl, matchCreateMesh, matchInitialSelection, matchPoints, matchSetup])

  const mailConfig = {
    to: config && config.supportEmail,
    subject: intl.formatMessage(messages.supportEmailSubject),
    body: intl.formatMessage(messages.supportEmailBody)
  }

  const ProgressActions = () => {
    return (
      <MikeProgressSpinner progressItems={progressItems} onClickItemButton={(id: string) => dispatch(cancelJob(id)) }/>
  )};

  const onHandleNotificationClickAway = React.useCallback(() => {
    dispatch(hideBlueDotAction(true))
  }, [dispatch])

  const NotificationsDropdown = () => {
    return (
      <MikeNotificationContainer
        showDot={!hideBlueDot}
        notifications={notifications}
        tooltipPosition={{ top: -60, left: -20 }}
        clickAwayCallback={onHandleNotificationClickAway}
      />
  )}
    

  const TopBar = () => {
    return (
      <MikeTopbar
        actionsArea={<ProgressActions/>}
        alertArea={<NotificationsDropdown/>}
        appIcon={<TopbarIcon/>}
        appTitle={intl.formatMessage(messages.appTitle)} 
        breadcrump={id && <AppBreadcrumpsNav />}           
        homeButton={<TopbarHomeButton />}              
        loginButton={<TopbarLoginButton />}
        supportButton={ <MikeSupportMenuContainer
          documentationConfig={[
            { label: "MIKE MS Documentation", href: "https://doc.mikepoweredbydhi.help/webhelp/mike-cloud/metoceansimulator/index.htm", target: '_blank'},
            { label: "MIKE MS Documentation (as pdf-file)", href: "https://doc.mikepoweredbydhi.help/webhelp/mike-cloud/PDF-documentation/DHICloud_MetOcean_Simulator.pdf", target: '_blank'},
            { label: "MIKE Cloud Documentation", href: "https://doc.mikepoweredbydhi.help/webhelp/mike-cloud/MIKE_CLOUD_Documentation_Overview.htm", target: '_blank'}
          ]}
          mailConfig={mailConfig}             
          tooltipPosition={{ top: -60, left: -20 }}            
        />}
        appsDropdown={id ? getSubscribedApps() : null}
      />
    )}

  const TopbarLoginButton = () => {
    return (
      <MikeLogin />
    )}

  const getSubscribedApps = () => {
    return (
      <MikeSubscribedApps
        currentAppName={intl.formatMessage({id: 'app.newRegisteredName'})}
        applications={apps}
        projectId={id ? id : undefined}
        loading={loadingApps}
        tenantId={user?.tenantId}
      />
    )}

  const TopbarHomeButton = () => {
    return (
      <MikeHomeButton
        loggedIn={true}
        hrefLink={config && config.cloudHomeUri + "?tenantId=" + user?.tenantId}
      />
    )}

  const TopbarIcon = () => {
    return (
      <FastWaveEmulator />
    )}

    return (
      <>
        <CssBaseline />
        <Fade in>
        <div css={rootStyle}>
        <TopBar />      
        <main css={isMapRoute ? contentHideVerticalScrollStyle : contentStyle}>  
          {children}
        </main>
        </div>
        </Fade>
      </>
    )
  }
  
  export default AppLayout