import { call, put } from "redux-saga/effects";
import getConfig from "../apis/config";
import { IConfig } from "../model/IConfig";
import { setConfig } from "../../src/actions/auth";

export default function* bootstrap() {
  try {  
    const config: IConfig = yield call(getConfig); 
    yield put(setConfig(config));
  } catch (error) {
    // tslint:disable-next-line:no-console
    console.log("Failed to read app config", error);
  }
}
