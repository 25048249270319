export const ViewerWrapper = (props) => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'start',
        alignItems: 'start',  
        boxSizing: 'border-box',
        overflow:'hidden'
      }}
    >
      <div
        style={{
          position: 'relative',
          zIndex: 3,
          width: 'calc(100vw - 800px)',
          height: 'calc(100vh - 92px)',
          margin: '0px',
          boxSizing: 'border-box',
          boxShadow: 'rgba(0, 0, 0, 0.16) -8px 1px 10px, rgba(0, 0, 0, 0.1) -8px 1px 20px',
        }}
      >
        {props.children}       
      </div>      
    </div>
  );
};
