/* eslint-disable @typescript-eslint/no-explicit-any */
/** @jsxImportSource @emotion/react */
/* eslint-disable react-refresh/only-export-components */
import { css } from '@emotion/react';
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Button } from '@mui/material';
import {  clearMapContent, fastWaveConfigSet, setProj4String, setSelectedMesh, uploadMesh } from '../../actions/mapContent' 
import { IState } from '../../reducers';
import { deleteOutputFolder, getProjectContent, setFilter, setProjectContentDialogOpen } from '../../actions/projectContent'
import { addMessage } from '../../actions/message'
import { IGetProject } from '../../model/IGetProject'
import { MESH, MESHTYPE, SCENARIO_COASTAL, SCENARIO_OFFSHORE } from '../../shared/constants';
import { IGetDataset } from '../../model/IGetDataset';
import MeshTable from '../MeshTable';
import UploadButton from '../UploadButton/UploadButton';
import { IFastWaveConfig } from '../../model/IFastWaveConfig';
import { titleContainer, titleStyle, subTitleStyle, spinnerContainerStyle } from '../../shared/styles';
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormControl from '@mui/material/FormControl'
import { filterProjectionSystems, getProjectionSystemsById } from '../../actions/mapContent';
import { radioStandardStyle } from '../../shared/styles';
import MikeProjectionSelect from '../mike-projection-select';
import IProjection from '../mike-projection-select/model/IProjection';
import { isNumeric } from '../../helpers/common';
import MikeDialog from '../DialogComponents/MikeDialog';
import { setMeshType, setWorkspaceScenario } from '../../actions/createMesh';
import Duplicate from '../../icons/Duplicate.svg?react';
import { useIntl } from 'react-intl';
import MikeButton from '../mike-button/MikeButton';
import Link from '@mui/material/Link';
import { IConfig } from '../../model/IConfig';
import mikeSharedTheme from '../../styles/mikeSharedTheme';
import { iconSecondaryStyle } from '../../styles/iconStyles';

export enum WARNINGTYPE {
  MESHTYPE = 'meshtype',
  UPLOADMESH = 'uploadmesh',
  COPYPLATFORMMESH = 'copyplatformmesh',
  PROJECTION = 'projection',
  ENVIRONMENTALDATA = 'environmentaldata',
  UPLOADENVIRONMENTALDATA = 'uploadenvironmentaldata',
  EXTRACTPROVIDERDATA = 'extractproviderdata',
  USEPREVIOUSEXTRACTION = 'usepreviousextraction',
  UPLOADMESHINPUT = 'uploadmeshinput',
  COPYMESHINPUT = 'copymeshinput',
  DRAWMESHINPUT = 'drawmeshinput',
  MESHNOTINGWM = 'meshnotingwm',
  PENDINGGWMCHECK = 'pendinggwmcheck'
}

export interface IWarning {
  warning: string;
  type: string;
  filter?: string;
}
const scenarioOptionsStyle = css`  
  padding-left: ${mikeSharedTheme.spacing(2)};
`
const noMeshStyle = css`  
  padding: ${mikeSharedTheme.spacing(2)};
`
const flexStyle = css`  
  display: flex;
  align-items: center;  
`
const linkStyle = css` 
  display: contents;
  text-decoration: none;
  white-space: nowrap; 
`

const linkButtonStyle = css`
  &.MuiButton-root {
    font-size: 1rem;
    padding: 6px;  
    height: 1.286rem;
    white-space: nowrap;
    min-width: 0;
    &:disabled {
      height: 1.286rem;
      min-width: 0;
    }
  }   
`;

const useOwnMeshSubnoteTextStyle  = css`
  padding-top: 0;
  white-space: nowrap;  
`;

const accordionDetailsContainerStyle  = css`
  width: ${mikeSharedTheme.spacing(98)};

`;

/* const radioGroupStyle  = css`
  display: block;
  color: ${mikeSharedTheme.palette.primary.main};
  & .MuiTypography-root, .MuiTypography-root.Mui-disabled, .MuiFormControlLabel-root: {
    color: ${mikeSharedTheme.palette.primary.main};
    font-size: 16px;
  }
`; */

const geometryButtonsContainerStyle = css`
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;     
`;

const geometryButtonStyle = css`
 padding-right: ${mikeSharedTheme.spacing(1)};
`;

const radioGroupSX = {
  display: 'block',
  color: mikeSharedTheme.palette.primary.main,
  '& .MuiTypography-root, .MuiTypography-root.Mui-disabled, .MuiFormControlLabel-root': {
    color: mikeSharedTheme.palette.primary.main,
    fontSize: 16
  }
}
    
const scenarioLabelStyle = css`
  padding-top: ${mikeSharedTheme.spacing(2)};
  padding-bottom: ${mikeSharedTheme.spacing(0.5)};
`;

const InitialSelection = () => {     
  const dispatch = useDispatch();
  const intl = useIntl();
 
  const { meshType, scenarios, workspaceScenario, loadingScenarios }  = useSelector((state: IState) => state.createMesh);

  const coordSystems: Array<IProjection> = useSelector(
    (state: IState) => state.mapContent.selectedProjectionSystems
  );  

  const allCoordSystems: Array<IProjection> = useSelector(
    (state: IState) => state.mapContent.coordinateSystems
  );  
 
  const searchProjectionSystemsById: boolean = useSelector(
    (state: IState) => state.mapContent.searchProjectionSystemsById
  );  

  const loadingCoordSystems = useSelector(
    (state: IState) => state.mapContent.loadingCoordinateSystems
  );  

  const selectedMesh: IGetDataset | null = useSelector(
    (state: IState) => state.mapContent.selectedMesh
  ); 

  const project: IGetProject | null = useSelector(
    (state: IState) => state.projectContent.project
  );


  const loadingMesh: boolean = useSelector(
    (state: IState) => state.mapContent.loadingMesh
  ); 

  const fastWaveConfig: IFastWaveConfig = useSelector(
    (state: IState) => state.mapContent.fastWaveConfig
  );  

  const config: IConfig = useSelector((state: IState) => state.auth.config);

  const scenarioItems = useMemo(() => {
    const getScenarioDescription = (name: string) => {
      switch (name){
        case SCENARIO_OFFSHORE:
          return intl.formatMessage({id: 'initialSelection.scenarioOffshoreDesc'});
        case SCENARIO_COASTAL:
          return intl.formatMessage({id: 'initialSelection.scenarioCoastalDesc'});
      }
    }
    if (scenarios && scenarios.length > 0){
      return scenarios.map((sc: string) => {return { name: sc, label: getScenarioDescription(sc)}})  
    }
    else{
      return []
    }
  }, [intl, scenarios])

  const [ fileTemp, setFileTemp ] = useState<any>();
  const [warning, setWarning] = React.useState<IWarning | null>(null);
  const [expanded, setExpanded] = React.useState('');
  const [ meshTypeTemp, setMeshTypeTemp ] = useState('');
  const [ epsgTemp, setEpsgTemp ] = useState<number | null>(null);  

  useEffect(() => {
    setExpanded(meshType)
  }, [meshType])

  const reset = useCallback(() => {
    dispatch(setSelectedMesh(null, project.id)); 
    dispatch(fastWaveConfigSet({ setup: fastWaveConfig.setup }, true));
    dispatch(deleteOutputFolder());
    dispatch(clearMapContent());
  }, [dispatch, fastWaveConfig, project])

  const openPlatformExplorer = useCallback((filter: string) => {   
   if (project){
      const canListContent = project.capabilities && project.capabilities.canListContent
      if (canListContent){     
        reset();
        dispatch(setFilter(filter))
        dispatch(getProjectContent(project, filter))
        dispatch(setProjectContentDialogOpen(true, true))
      }
      else{
        dispatch(addMessage(intl.formatMessage({id: 'warnings.noAccessToProject'})))
      } 
    } 
  }, [dispatch, intl, project, reset])
 
  const handleImportGeometryFromPlatform = useCallback(() => {   
    const dataFromDataLinkAlreadyExtracted = fastWaveConfig && fastWaveConfig.data_link_output_file && fastWaveConfig.data_link_output_file.dataset_id
    if (dataFromDataLinkAlreadyExtracted){
      setWarning({warning: intl.formatMessage({id: 'warnings.outputAndConfigDeleted'}), type: WARNINGTYPE.COPYPLATFORMMESH})       
    }
    else {
      openPlatformExplorer(MESH)
    }
  }, [fastWaveConfig, intl, openPlatformExplorer])

  const onDropMesh = useCallback(
    (files) => {
      if (files.length > 0){          
        const file = files[0]   
        const dataFromDataLinkAlreadyExtracted = fastWaveConfig && fastWaveConfig.data_link_output_file && fastWaveConfig.data_link_output_file.dataset_id
        if (dataFromDataLinkAlreadyExtracted){
          setFileTemp(file)
          setWarning({warning: intl.formatMessage({id: 'warnings.outputAndConfigDeleted'}), type: WARNINGTYPE.UPLOADMESH})       
        }
        else{ 
          reset();
          dispatch(uploadMesh(file, file.name)) 
        }   
      }
    },
    [dispatch, fastWaveConfig, intl, reset])  
 
  const canReplaceMesh = useMemo(() => {
    const canCreateContent = project && project.capabilities && project.capabilities.canCreateContent   
    return canCreateContent
  }, [project]) 

  const initialProjection = useMemo(() => {
    if (fastWaveConfig && fastWaveConfig.createMeshConfig && fastWaveConfig.createMeshConfig.targetSrid && allCoordSystems && allCoordSystems.length > 0){
      const projection = allCoordSystems.find((p: IProjection) => p.id === fastWaveConfig.createMeshConfig.targetSrid)
      return projection;
    }
    return undefined;
  }, [fastWaveConfig, allCoordSystems]) 

  const meshBuilderUrl = useMemo(() => {
    const meshBuilderBaseUrl = config && config.meshBuilderUri ? config.meshBuilderUri : ''   
    if (meshBuilderBaseUrl && project && project.id){
      return meshBuilderBaseUrl + '/project/' + project.id
    } 
    else{
      return meshBuilderBaseUrl
    }
  }, [project, config])

  const handleWarningConfirmed = useCallback(() => {
    setWarning(null)
    const warningType = warning && warning.type ? warning.type : ''
    switch (warningType){
      case WARNINGTYPE.MESHTYPE:
        dispatch(setMeshType(meshTypeTemp, true));
        setMeshTypeTemp('');
        break;
      case WARNINGTYPE.PROJECTION:
        dispatch(setProj4String('', null));
        dispatch(deleteOutputFolder());
        dispatch(fastWaveConfigSet(
          {
            createMeshConfig: { targetSrid: epsgTemp ? epsgTemp : 4326 },            
            setup: fastWaveConfig.setup
          }, true));  
        setEpsgTemp(null);
        break;
      case WARNINGTYPE.UPLOADMESH:
        reset();
        dispatch(uploadMesh(fileTemp, fileTemp.name)) 
        setFileTemp(null);
        break;
      case WARNINGTYPE.COPYPLATFORMMESH:
        openPlatformExplorer(MESH);
        break;
      default:
        break;
    }
    
  }, [warning, dispatch, meshTypeTemp, epsgTemp, reset, fileTemp, openPlatformExplorer, fastWaveConfig])

  const handleChangeMeshType = useCallback((event: { target: { value: React.SetStateAction<string>; }; }) => { 
    const changeToType = event.target.value.toString()
    setMeshTypeTemp(changeToType)
    setExpanded(changeToType)
    
    if (fastWaveConfig && fastWaveConfig.mesh_file){  
      setWarning({warning: intl.formatMessage({id: 'warnings.outputAndConfigDeleted'}), type: WARNINGTYPE.MESHTYPE})       
    }
    else {     
      dispatch(setMeshType(changeToType, true))
    }   
  }, [dispatch, fastWaveConfig, intl])

  const onProjectionSearchTextChanged = (searchText: string) => {
    isNumeric(searchText) ?
      dispatch(getProjectionSystemsById(Number(searchText))) :
      dispatch(filterProjectionSystems(searchText))
  };

  const onProjectionSelected = useCallback((epsgCode?: number) => {
    if (fastWaveConfig && fastWaveConfig.createMeshConfig && fastWaveConfig.createMeshConfig.targetSrid && fastWaveConfig.createMeshConfig.targetSrid !== epsgCode){      
      setEpsgTemp(epsgCode)
      setWarning({warning: intl.formatMessage({id: 'warnings.projectionReferenceDeleted'}), type: WARNINGTYPE.PROJECTION})      
    } 
    else{     
      dispatch(setProj4String('', null));
      dispatch(deleteOutputFolder());
      dispatch(fastWaveConfigSet(
        {
          createMeshConfig: { targetSrid: epsgCode ? epsgCode : 4326 },
          setup: fastWaveConfig.setup
        }, true))  
    }   
  }, [dispatch, fastWaveConfig, intl])

  const handlePanelChange = (panel) => (_event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleOnCancel = (_event?, reason?) => {
    if(!reason || (reason !== 'backdropClick' && reason !== 'escapeKeyDown')) {
      setWarning(null);
      setMeshTypeTemp('');
      setEpsgTemp(null);
    }
  }

  const handleChangeScenario = (event: { target: { value: React.SetStateAction<string>; }; }) => { 
    const changeToType = event.target.value.toString()
    dispatch(setWorkspaceScenario(changeToType)) 
  }

  return (
    <>
     <MikeDialog 
      open={warning && warning.warning ? true : false} 
      onCancel={handleOnCancel} 
      onOk={handleWarningConfirmed}
      dialogTitle={intl.formatMessage({id: 'warnings.pleaseConfirm'})}
      contentTitle={intl.formatMessage({id: 'warnings.confirmDialogContentTitle'})}
      message={warning && warning.warning ? warning.warning : ''}    
      okButtonLabel={intl.formatMessage({id: 'warnings.change'})}
     />
      <div>
        <div css={titleContainer}>
          <div>
            <Typography variant='h5' css={titleStyle}>{intl.formatMessage({id: 'initialSelection.meshingOptions'})}</Typography>
            <Typography variant='body2' css={subTitleStyle}>{intl.formatMessage({id: 'initialSelection.selectOption'})}</Typography>
          </div> 
        </div>
       
          <FormControl fullWidth component='fieldset'>
         
            <RadioGroup aria-label='fileformat' name='fileformat' value={meshType} onChange={handleChangeMeshType} sx={radioGroupSX}> 
          
              <Accordion expanded={expanded === MESHTYPE.AUTOMESH} onChange={handlePanelChange(MESHTYPE.AUTOMESH)}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-label='Expand'
                  aria-controls='additional-actions1-content'
                  id='additional-actions1-header'
                >
                  <FormControlLabel                    
                    value={MESHTYPE.AUTOMESH} 
                    control={<Radio color='primary' 
                    css={radioStandardStyle} />} 
                    label={<b>{intl.formatMessage({id: 'initialSelection.useAutoMeshingOption'})}</b>}                
                  />     
                </AccordionSummary>  
                <AccordionDetails>
                  <div css={accordionDetailsContainerStyle}>
                    {initialProjection ? <Typography>{intl.formatMessage({id: 'initialSelection.targetProjectionSystem'}) + ': ' + initialProjection.id} </Typography> : <Typography>{intl.formatMessage({id: 'initialSelection.byDefaultCoordinatesAreInLongLat'})}</Typography>}
                    {loadingCoordSystems ? 
                      <div css={spinnerContainerStyle}><CircularProgress/></div> :
                      <MikeProjectionSelect
                        disabled={meshType === MESHTYPE.UPLOAD}
                        onProjectionDelected={onProjectionSelected}
                        onProjectionSelected={onProjectionSelected}
                        onProjectionSearchTextChanged={onProjectionSearchTextChanged}
                        projections={coordSystems}
                        projectionsLoading={loadingCoordSystems}
                        searchById={searchProjectionSystemsById}
                      />
                    }
                     <Typography css={scenarioLabelStyle}>{intl.formatMessage({id: 'initialSelection.selectScenario'}) + ':'}</Typography>  
                      {loadingScenarios ? 
                      <div css={spinnerContainerStyle}><CircularProgress/></div> :   <div css={scenarioOptionsStyle}>  
                      <RadioGroup aria-label='fileformat' name='fileformat' value={workspaceScenario} onChange={handleChangeScenario} sx={radioGroupSX}> 
                      {scenarioItems.map((scenarioItem) => 
                        <FormControlLabel  
                          key={scenarioItem.name}                   
                          value={scenarioItem.name} 
                          control={<Radio color='primary' 
                          css={radioStandardStyle} />} 
                          label={scenarioItem.label}                
                        /> 
                      )}
                      </RadioGroup>                
                      </div>
                      }                    
                    </div>
                </AccordionDetails>
              </Accordion>    
              <Accordion expanded={expanded === MESHTYPE.UPLOAD} onChange={handlePanelChange(MESHTYPE.UPLOAD)} >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-label='Expand'
                  aria-controls='additional-actions2-content'
                  id='additional-actions2-header'
                >
                  <FormControlLabel 
                    value={MESHTYPE.UPLOAD} 
                    control={<Radio color='primary' 
                    css={radioStandardStyle} />} 
                    label= {<b>{intl.formatMessage({id: 'initialSelection.useOwnMeshOption'})}</b>}             
                  /> 
                </AccordionSummary>      
                <AccordionDetails>
                  <div css={accordionDetailsContainerStyle}>
                    <div css={flexStyle}>
                      <Typography css={useOwnMeshSubnoteTextStyle} variant='body2'>{intl.formatMessage({id: 'initialSelection.ownMeshSubNote1'})}</Typography>
                      <Link href={meshBuilderUrl} target='_blank' rel='noopener' css={linkStyle}>
                        <MikeButton css={linkButtonStyle}  variant='text'>
                        {intl.formatMessage({id: 'initialSelection.meshBuilder'})}
                          {/* <Typography variant='body2'>{intl.formatMessage({id: 'initialSelection.meshBuilder'})}</Typography> */}
                        </MikeButton>
                      </Link>
                      <Typography css={useOwnMeshSubnoteTextStyle} variant='body2'>{intl.formatMessage({id: 'initialSelection.ownMeshSubNote2'})}</Typography>                      
                    </div> 
                    <Typography css={useOwnMeshSubnoteTextStyle} variant='body2'>{intl.formatMessage({id: 'initialSelection.ownMeshSubNote3'})}</Typography>  
                    <div css={geometryButtonsContainerStyle}> 
                      <Button disabled={!canReplaceMesh || meshType === MESHTYPE.AUTOMESH} variant='text' onClick={handleImportGeometryFromPlatform} css={geometryButtonStyle}>                      
                        <Duplicate css={iconSecondaryStyle(!canReplaceMesh || meshType === MESHTYPE.AUTOMESH)} width={24} height={24} viewBox={'0 0 40 40'}/>
                        {intl.formatMessage({id: selectedMesh ? 'platform.replace' : 'platform.copy'})}             
                      </Button>   
                      <UploadButton 
                        disabled={!canReplaceMesh || meshType === MESHTYPE.AUTOMESH} 
                        fileType={'.mesh'} 
                        label={intl.formatMessage({id: selectedMesh ? 'desktop.replace' : 'desktop.upload'})} 
                        onDrop={onDropMesh}
                      /> 
                    </div> 
                    {loadingMesh ? <CircularProgress/> :            
                      <div>              
                        <MeshTable 
                          data={selectedMesh ? [selectedMesh] : []}
                          loading={false}
                          selectedItems={[]}               
                        />
                        {selectedMesh ? null : <Typography css={noMeshStyle} variant='body2'>{intl.formatMessage({id: 'initialSelection.noMeshUploaded'})}</Typography>}
                      </div>
                    }
                  </div>
                </AccordionDetails>
              </Accordion>           
            </RadioGroup>
          </FormControl>  
      </div>
      </>
  )
}

export default InitialSelection