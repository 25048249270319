/** @jsxImportSource @emotion/react */
/* eslint-disable react-refresh/only-export-components */
import { css } from '@emotion/react';
import theme from './../../styles/mikeSharedTheme';
import React, { useCallback } from 'react';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import MuiDialogTitle from '@mui/material/DialogTitle';
import MuiDialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

import MikeButton from './../mike-button';
import MikeDialogTitle from './MikeDialogTitle';

export const dialogContentStyle = css`
    background-color: ${theme.palette.background.paper}; 
    padding-bottom: ${theme.spacing(5)}; 
  }`

export const contentTitleStyle = css`
  font-weight: bold;
  padding-bottom: ${theme.spacing(1)}; 
  padding-top: ${theme.spacing(1)}; 
}`

export const dialogActionsStyle = css`
  background-color: ${theme.palette.background.paper}; 
  color: ${theme.palette.background.paper}; 
  padding-right: ${theme.spacing(3)}; 
  width: 100%; 
}`

export const dialogTitleStyle = css`
  padding-left: ${theme.spacing(3)};      
  border-radius: 4px 4px 0px 0px;      
  height: ${theme.spacing(8)};
  color: ${theme.palette.background.paper};
  width: 100%;
`
interface IProps {
  cancelButtonLabel?: string;
  children?: React.ReactNode;  
  dialogActions?: React.ReactNode; 
  contentTitle?: string;
  dialogTitle?: string;
  hideActions?: boolean;
  message?: string;
  okButtonDisabled?: boolean;
  okButtonLabel?: string;
  open: boolean;
  onCancel: () => void;
  onOk?: () => void;
}

const MikeDialog = (props: IProps) => { 
  const {
    children,   
    dialogActions,  
    contentTitle,
    dialogTitle,
    hideActions,
    message,
    okButtonDisabled = false,
    okButtonLabel = 'Ok',
    cancelButtonLabel = 'Cancel',
    onCancel,
    onOk,
    open,
    ...rest
  } = props;

  const handleOK = useCallback(() => {
    if (onOk){
      onOk();
    }
  }, [onOk])

  return (
    <Dialog onClose={onCancel} open={open} scroll={'paper'} {...rest}>
      <MuiDialogTitle css={dialogTitleStyle}>
        <MikeDialogTitle title={dialogTitle} onClose={onCancel} />
      </MuiDialogTitle>
      {children ? (
        children
      ) : (
        <MuiDialogContent
          css={dialogContentStyle}
        >
          <Typography
            variant="h3"
            css={contentTitleStyle}
          >
            {contentTitle}
          </Typography>
          <Typography variant="body2">{message}</Typography>
        </MuiDialogContent>
      )}
      {hideActions ? null : dialogActions ? dialogActions : (
        <DialogActions css={dialogActionsStyle}>       
          <MikeButton size="small" onClick={onCancel} variant="text">
            {cancelButtonLabel}
          </MikeButton>
          <MikeButton variant="contained" size="small" onClick={handleOK} color="secondary" disabled={okButtonDisabled}>
            {okButtonLabel}
          </MikeButton>
        </DialogActions>
      )}
      
    </Dialog>
  );
};

export default MikeDialog;
