/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import React, { ReactNode } from 'react';

const PanelStyle = css`
  width: 100%;

  .panel__body {
    background-color: white;
  }
`;

const PanelBodyStyle = css`
  background-color: white;
`;

export interface IProps {
  /**
   * Optional content of the panel
   */
  children?: ReactNode;
}

/**
 * @name MikeStickyPanel
 * @summary A generic container that should be placed inside a sidepanel.
 *
 * @param props
 */
const MikeStickyPanel: React.FC<IProps> = ({ children }) => {
  return (
    <div css={PanelStyle}>
      {children && <section css={PanelBodyStyle}>{children}</section>}
    </div>
  );
};

export default MikeStickyPanel;
