/* eslint-disable @typescript-eslint/no-floating-promises */
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { useEffect } from 'react';
import { Typography } from '@mui/material';
import qs from 'qs';
import { useOidc } from '@axa-fr/react-oidc';
import LandingLayout from './LandingLayout';
import { useLocation } from 'react-router-dom';
import { LOGGINGOUT } from './MikeLogin';
import { defineMessages, useIntl } from 'react-intl';
import MikeButton from '../../mike-button';

const notesStyle = 
  css`
    margin-top: 20px;
    display: inline-block;
  `;

const containerStyle = 
  css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  `;

const messages = defineMessages({ 
  landingPageButtonText: { id: 'landingPage.buttonText'},
  landingPageHeader: { id: 'landingPage.header'},
  landingPageLinkLabel: { id: 'landingPage.linkLabel'},
  landingPageLinkText: { id: 'landingPage.linkText'}
})

const LandingPage = () => {
  const { login } = useOidc();
  const intl = useIntl(); 

  const { search, pathname } = useLocation();

  const pathAndSearch = search.length > 1 ? pathname + search : pathname;

  useEffect(() => {
    const loggingOut = sessionStorage.getItem(LOGGINGOUT);
    sessionStorage.removeItem(LOGGINGOUT);
    if (loggingOut !== 'true') {
      let extras: { prompt: string; tenantId?: string } = { prompt: 'none' };
      if (search) {
        const parameters = qs.parse(search.replace('?', '')) || {};
        if (parameters.tenantId) {
          // eslint-disable-next-line @typescript-eslint/no-base-to-string
          extras = { ...extras, tenantId: parameters.tenantId.toString() };
        }
      }
      login(pathAndSearch, extras);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <LandingLayout>
      <div css={containerStyle}>    
        <MikeButton color="secondary" variant="contained" onClick={() => login(pathAndSearch)}>
          {intl.formatMessage(messages.landingPageButtonText)}
        </MikeButton>
      
        <Typography variant="caption" css={notesStyle}>
          <label>{intl.formatMessage(messages.landingPageLinkLabel) + ' '}</label>
          &nbsp;
          <a href="mailto:mike@dhigroup.com">{intl.formatMessage(messages.landingPageLinkText)}</a>.
        </Typography>
     
      </div>
    </LandingLayout>
  );
};

export default LandingPage;
