import { IApplications } from "../components/mike-subscribed-apps/model/IApplications";
import ActionType from "./ActionType";

export const getApps = () => ({
  type: ActionType.GET_APPS
})

export const setLoadingApps = (loading = true) => ({
  type: ActionType.LOADING_APPS,
  data: loading
})

export const setApps = (applications: IApplications) => ({
  type: ActionType.SET_APPS,
  data: applications
})