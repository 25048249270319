/** @jsxImportSource @emotion/react */
import { AppBar,Toolbar, Typography } from '@mui/material';
import { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import React, { useCallback } from 'react';
import mikeSharedTheme from '../../styles/mikeSharedTheme';
import { css } from '@emotion/react'

interface IProps extends MuiAppBarProps {
  appIcon: React.ReactNode;
  appTitle: string;
  breadcrump?: React.ReactNode;
  homeButton: React.ReactNode;
  alertButton?: React.ReactNode;
  supportButton: React.ReactNode;
  loginButton: React.ReactNode;
  actionsArea?: React.ReactNode;
  alertArea?: React.ReactNode;
  appTitleCallback?: () => void;
  appsDropdown?: React.ReactNode;
}

const mikeTopbarAppIconStyle = css`
  margin-left: ${mikeSharedTheme.spacing(1.5)};
  margin-right: ${mikeSharedTheme.spacing(2.5)};
`;

const mikeTopbarAppTitleStyle = css`
  display: flex;
  align-items: center;
  padding: 0 5px;
  padding-right: 35px;
  min-height: 60;
  color: ${mikeSharedTheme.palette.text.primary};
  margin-top: ${mikeSharedTheme.spacing(-0.5)};
`;

const mikeTopbarAppTitleFontStyle = css`
  font-size: 14px;
  line-height: 16px;
  font-weight: bold;
`;

const mikeTopbarSupportButtonStyle = css`
  display: flex;
`;

const mikeTopbarLoginButtonStyle = css`
  display: flex;
  align-items: center;
  padding: 0 15px; 
  min-height: 60;
  &:hover: {
    background-color: ${mikeSharedTheme.palette.text.disabled};
  };
  margin-top: ${mikeSharedTheme.spacing(-0.5)};
`;

const mikeBreadCrumpStyle = css`
  display: flex;  
  margin-bottom: ${mikeSharedTheme.spacing(0.25)};
  flex-grow: 1;
  justify-content: center;
  font-size: 14px;
`;

const mikeAppNameButtonStyle = (hasCallback: boolean) => {
  if (hasCallback){
    return css`
      ${mikeTopbarAppTitleStyle};
      cursor: pointer;
      &:hover: {
        background: ${mikeSharedTheme.palette.text.disabled};
      };
    `
  }
  else{
    return mikeTopbarAppTitleStyle;
  }  
};


/**
 * @name MikeTopbar
 * @summary Default topbar for all MIKE applications. Holds application name, breadcrumbs, profile information and more.
 */
export const MikeTopbar = (props: IProps) => {
  const {
    appIcon,
    appTitle,
    breadcrump,
    homeButton,
    alertButton,
    supportButton,
    loginButton,
    actionsArea,
    alertArea,
    appTitleCallback,
    appsDropdown,
    ...rest
  } = props;  

  const handleAppTitleCallback = useCallback(() => {
    if (appTitleCallback) {
      appTitleCallback();
    }
  }, [appTitleCallback]);

  return (
    <AppBar {...rest}>
      <Toolbar disableGutters={true}>
        {homeButton && <div>{homeButton}</div>}
        {appsDropdown ? (
          appsDropdown
        ) : (
          <div
            css={mikeAppNameButtonStyle(appTitleCallback !== undefined)}
            onClick={handleAppTitleCallback}
          >
            <div css={mikeTopbarAppIconStyle}>{appIcon}</div>
            <Typography css={mikeTopbarAppTitleFontStyle}>{appTitle}</Typography>
          </div>
        )}

        <div css={mikeBreadCrumpStyle}>{breadcrump}</div>

        <div>{actionsArea}</div>

        {alertButton ? <div>{alertButton}</div> : alertArea ? <div>{alertArea}</div> : ''}
        {supportButton && <div css={mikeTopbarSupportButtonStyle}>{supportButton}</div>}
        {loginButton && <div css={mikeTopbarLoginButtonStyle}>{loginButton}</div>}
      </Toolbar>
    </AppBar>
  );
};

export default MikeTopbar;
