/** @jsxImportSource @emotion/react */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { css } from '@emotion/react';
import React from 'react';
import Button from '@mui/material/Button';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import { getYear, getDaysInMonth } from 'date-fns';
import { range } from 'lodash';
import DownTriangle from '../../icons/DownTriangle.svg?react';
import { selectStyle } from './MikeDatePickerStyles';
import { mikePalette } from '../../styles/mikeSharedTheme';

interface IProps {
  onChangeYear: (e) => void;
  onDateChange: (date: Date) => void;
  date: any;
  startYear: number;
  endYear: number;
}

const menuListStyle = css`
  max-height: 280px;  
  background: ${mikePalette.mediumGrey.light};
  margin-top: 5px;
  overflow-y: auto;
  width: 80px;
`;

const MikeDatePickerSelectYear: React.FC<IProps> = ({
  onChangeYear,
  onDateChange,
  date,
  startYear,
  endYear,
}) => {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLButtonElement>(null);
  const years = React.useMemo(() => {
    const yearRange = range(startYear, endYear);
    return [...yearRange, endYear]
  }, [startYear, endYear])    
 
  const index = years.indexOf(date.getFullYear());
  const [selectedIndex, setSelectedIndex] = React.useState(index);

  const handleToggle = () => {
    setOpen((wasOpen) => !wasOpen);
  };

  const handleClose = () => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }
    setOpen(false);
  };

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current!.focus();
    }

    prevOpen.current = open;
  }, [open]);

  const handleSelect = (e, currentindex: number) => {
    setSelectedIndex(currentindex);
    setOpen(!setOpen);
    onChangeYear(e);
    checkDayOfMonth(years[currentindex]);
  };

  const checkDayOfMonth = (year: number) => {
    let day = date.getDate();
    date.setDate(1);
    date.setYear(year);
    const daysInMonth = getDaysInMonth(date);
    if (day > daysInMonth) {
      day = daysInMonth;
    }
    date.setDate(day);
    onDateChange(date);
  };

  return (
    <span style={{ float: 'right', marginRight: '10px' }}>
      <Button
        css={selectStyle}
        ref={anchorRef}
        aria-controls={open ? 'menu-list-grow' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        style={{
          justifyContent: 'start',
          width: '85px',
        }}
        endIcon={<DownTriangle/>}
      >
        {getYear(date)}
      </Button>
      <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  autoFocusItem={open}
                  id="menu-list-grow"
                  onKeyDown={handleListKeyDown}
                  css={menuListStyle}
                >
                  {years.map((option, yearindex) => (
                    <MenuItem
                      key={option}
                      value={option}
                      selected={yearindex === selectedIndex}
                      onClick={(event) => handleSelect(event, yearindex)}
                    >                     
                      {option}                
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </span>
  );
};

export default MikeDatePickerSelectYear;
